import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, TextField, MenuItem, FormHelperText, Typography, withStyles } from '@material-ui/core';
// import { Label } from '../../components/Label';
import FormBase from '../../components/FormBase';
import HTMLEditor from '../../components/HTMLEditor';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class CreatePage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            org_id: "",
            type: "",
            category: "",
            plan_code: "",
            format: "",
            messages: {
                zh: {
                    subject: "",
                    content: ""
                },
                en: {
                    subject: "",
                    content: ""
                }
            }
        };
        this.state = {
            isSubmitting: false,
            errors: {},
            orgs: [],
            allPlans: [],
            plans: []
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Template" });
    }
    componentDidMount() {
        let user = AuthService.currentUser();
        if (user.org_id) {
            this.setState({orgs: [{ value: user.org_id, label: user.org_id }]});
        } else {
            NameService.organisationList((orgs) => {
                this.setState({ orgs: orgs });
            });
        }
        NameService.planList((plans) => {
            this.setState({ allPlans: plans });
        });
    }
    handleSelectCategory = name => event => {
        let cat = Global.getValueLabelPair(Global.templateCategories, event.target.value);
        this.setState({ [name]: event.target.value, type: cat.type });
    }
    onContentChange = (id, content) => {
        this.setState({ [id]: content });
    }
    handleSelectOrg = name => event => {
        var plans = [];
        for (var i = 0; i < this.state.allPlans.length; i++) {
            var plan = this.state.allPlans[i];
            if (plan.org_id === event.target.value) {
                plans.push(plan);
            }
        }
        this.setState({ [name]: event.target.value, plans: plans, plan_code: "" });
    }
    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        let output = JSON.parse(JSON.stringify(this.obj));
        Validator.compact(output);
        this.setState({
            isSubmitting: true
        });

        APIService.create(APIService.TEMPLATE, output)
            .then(result => {
                this.props.alert("Template created", "success");
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};
        if (!this.obj.org_id) {
            errors["org_id"] = Global.errors["ErrorRequired"];
        }
        if (!this.obj.category) {
            errors["category"] = Global.errors["ErrorRequired"];
        }
        if (!this.obj.messages.en.subject) {
            errors["messages.en.subject"] = Global.errors["ErrorRequired"];
        }
        if (!this.obj.messages.zh.subject) {
            errors["messages.zh.subject"] = Global.errors["ErrorRequired"];
        }

        if (this.obj.type === "email") {
            if (!this.obj.messages.en.content) {
                errors["messages.en.content"] = Global.errors["ErrorRequired"];
            }
            if (!this.obj.messages.zh.content) {
                errors["messages.zh.content"] = Global.errors["ErrorRequired"];
            }
        }

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }

    render() {
        if (!AuthService.userHasRole(['tpl'])) return null;
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" gutterBottom>NEW TEMPLATE</Typography>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="org_id"
                                    name="org_id"
                                    label="ORGANISATION"
                                    select
                                    value={this.state.org_id}
                                    onChange={this.handleSelectOrg("org_id")}
                                    required
                                    autoFocus
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {this.state.orgs.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["org_id"] && <FormHelperText error={true}>{errors["org_id"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="plan_code"
                                    name="plan_code"
                                    label="PLAN CODE"
                                    select
                                    value={this.state.plan_code}
                                    onChange={this.handleSelect("plan_code")}
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {this.state.plans.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["plan_code"] && <FormHelperText error={true}>{errors["plan_code"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="category"
                                    name="category"
                                    label="CATEGORY"
                                    select
                                    value={this.state.category}
                                    onChange={this.handleSelectCategory("category")}
                                    required
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {Global.templateCategories.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["category"] && <FormHelperText error={true}>{errors["category"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="messages.en.subject"
                                    name="messages.en.subject"
                                    label="SUBJECT (ENGLISH)"
                                    value={this.state["messages.en.subject"]}
                                    onChange={this.handleChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {this.state.type === "email" &&
                                    <React.Fragment>
                                        <div style={{ marginTop: 24 }}></div>
                                        <Typography variant="caption" color="textSecondary">CONTENT (ENGLISH)</Typography>
                                        <HTMLEditor id="messages.en.content" content={this.state["messages.en.content"]} onChange={this.onContentChange} />
                                        {errors["messages.en.content"] && <FormHelperText error={true}>{errors["messages.en.content"]}</FormHelperText>}
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="messages.zh.subject"
                                    name="messages.zh.subject"
                                    label="SUBJECT (CHINESE)"
                                    value={this.state["messages.zh.subject"]}
                                    onChange={this.handleChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {this.state.type === "email" &&
                                    <React.Fragment>
                                        <div style={{ marginTop: 24 }}></div>
                                        <Typography variant="caption" color="textSecondary">CONTENT (CHINESE)</Typography>
                                        <HTMLEditor id="messages.zh.content" content={this.state["messages.zh.content"]} onChange={this.onContentChange} />
                                        {errors["messages.zh.content"] && <FormHelperText error={true}>{errors["messages.zh.content"]}</FormHelperText>}
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form >
        );
    }
}

CreatePage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreatePage);