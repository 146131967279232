import React from 'react';
import {
	Home as HomeIcon,
	LocationCity as OrganisationIcon,
	Business as DepartmentIcon,
	SupervisorAccount as MemberIcon,
	Person as UserIcon,
	AttachMoney as PlanIcon,
	LocalAtmOutlined as OrderIcon,
	Email as TemplateIcon,
	Redeem as CampaignIcon,
	DonutSmall as QuotaIcon,
	History as TransactionIcon
	// BarChart as BarChartIcon,
	// EventSeat as ShowsIcon,
	// Search as SearchIcon,
	// Settings as SettingsIcon,
} from '@material-ui/icons';
import { AuthService } from './services/Auth';

var MENU = [
	{
		id: 'MAIN',
		children: [
			{
				id: 'Home',
				icon: <HomeIcon />,
				active: true,
				link: '/dashboard',
				required: ['dashboard']
			},
			{
				id: 'Members',
				icon: <MemberIcon />,
				link: '/members',
				required: ['member']
			},
			{
				id: 'Subscription Plans',
				icon: <PlanIcon />,
				link: '/plans',
				required: ['plan']
			},
			{
				id: 'Subscription Orders',
				icon: <OrderIcon />,
				link: '/orders',
				required: ['order']
			},
			{
				id: 'Transactions',
				icon: <TransactionIcon />,
				link: '/transactions',
				required: ['trans_sub', 'trans_point']
			},
			{
				id: 'Campaigns',
				icon: <CampaignIcon />,
				link: '/offers',
				required: ['offer_point', 'offer_coupon']
			}
		]
	},
	{
		id: 'ADMIN',
		children: [
			{
				id: 'Users',
				icon: <UserIcon />,
				link: '/users',
				required: ['user']
			},
			{
				id: 'Organisations',
				icon: <OrganisationIcon />,
				link: '/orgs',
				required: ['org']
			},
			{
				id: 'Departments',
				icon: <DepartmentIcon />,
				link: '/depts',
				required: ['dept']
			},
			{
				id: 'Templates',
				icon: <TemplateIcon />,
				link: '/tpls',
				required: ['tpl']
			},
			{
				id: 'Quotas',
				icon: <QuotaIcon />,
				link: '/quotas',
				required: ['quota']
			}
		]
	}
];

export function GetMenu() {
	var menu = [];
	let user = AuthService.currentUser();
	if (!user) return menu;
	for (var i = 0; i < MENU.length; i++) {
		var group = MENU[i];
		let id = group.id;
		var children = group.children;
		var items = [];
		for (var j = 0; j < children.length; j++) {
			let item = children[j];
			if (!item.required) {
				items.push(item);
				continue;
			}
			if (AuthService.userHasRole(item.required)) {
				items.push(item);
			}
		}
		if (items.length > 0) {
			menu.push({id: id, children: items});
		}
	}
	return menu;
}