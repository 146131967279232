import React from 'react';
import { Typography } from '@material-ui/core';

export default class RenewalRatio extends React.Component {
    render() {
        let { planCode, planGroupCode, stats } = this.props;

        if (!planCode || !planGroupCode || !stats) return null;

        var renewal = 0;
        var expired = 0;
        for (let i of stats) {
            if (planGroupCode) {
                if (planGroupCode !== i.name) {
                    continue;
                }
            } else {
                if (planCode !== i.name) {
                    continue;
                }
            }
            if (i.type === "subs-grp-expired") {
                expired = i.count;
            } else if (i.type === "subs-grp-renewal") {
                renewal = i.count;
            }
        }
        return (
            <React.Fragment>
                <Typography>
                    {renewal} : {expired}
                </Typography>
            </React.Fragment>
        );
    }
}