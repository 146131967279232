import React from 'react';
import { FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Button, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { APIService } from '../../services/API';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2)
    }
});

class TermDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            plan_code: "",
            org_id: "",
            m_id: 0,
            mshp_id: "",
            remark: "",
            errors: {},
            open: false,
            isSubmitting: false
        };
    }
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (member, membership) => {
        this.setState({ open: true, org_id: member.org_id, m_id: parseInt(member.m_id), mshp_id: membership.mshp_id, plan_code: membership.plan_code, expires_at: membership.expires_at, remark: "", errors: {}, isSubmitting: false });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleSubmit = event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        // if (!this.validateForm()) return;

        this.setState({
            isSubmitting: true
        });

        var req = {
            org_id: this.state.org_id,
            m_id: this.state.m_id,
            remark: this.state.remark
        };
        APIService.call("POST", APIService.SUBSCRIPTION + "/" + this.state.mshp_id + "/terminate", req)
            .then(result => {
                this.setState({isSubmitting: false, open: false}, () => {
                    this.props.alert("Membership terminated", "success");
                    this.props.onRefresh();
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    /*
    validateForm() {
        var errors = {};

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }*/
    render() {
        const { classes } = this.props;
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <form onSubmit={this.handleSubmit}>
                <DialogTitle id="form-dialog-title">Terminate Membership</DialogTitle>
                <DialogContent>
                    <DialogContentText>Terminate the subscription below immediately. Please note that terminated subscription cannot be reactivated.</DialogContentText>
                    <FormControl required fullWidth className={classes.formControl}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="plan_code"
                                    name="plan_code"
                                    label="SUBSCRIPTION PLAN CODE"
                                    value={this.state.plan_code}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    autoFocus
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" disabled={this.state.isSubmitting}>OK</Button>
                    <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    }
}

export default withStyles(styles)(TermDialog);