import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Typography, withStyles } from '@material-ui/core';
import {
    Edit as EditIcon,
    Close as CloseIcon,
    DeleteForever as DeleteIcon,
} from '@material-ui/icons';
import { Doughnut, Chart } from 'react-chartjs-2';
import Source from '../../components/Source';
import LastUpdated from '../../components/LastUpdated';
import { Label, DateLabel } from '../../components/Label';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import ConfirmDialog from '../../components/ConfirmDialog';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw: function () {
        originalDoughnutDraw.apply(this, arguments);

        var chart = this.chart;
        var width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx;

        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";

        var sum = 0;
        for (var i = 0; i < chart.config.data.datasets[0].data.length; i++) {
            let val = chart.config.data.datasets[0].data[i];
            if (val) {
                sum += val;
            }
        }

        var text = sum,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;

        ctx.fillText(text, textX, textY);
    }
});

class DetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {},
            plans: []
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Quota" });
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        APIService.get(APIService.QUOTA, id)
            .then(obj => {
                if (!obj.used) {
                    obj.used = 0;
                }
                if (!obj.reserved) {
                    obj.reserved = 0;
                }
                this.setState({ obj: obj });
            })
            .catch(error => {
                this.props.history.goBack();
            });
        APIService.list(APIService.PLAN + "/name", 0, -1, null, null, null, { quota_id: id })
            .then(result => {
                if (result.data) {
                    this.setState({ plans: result.data });
                }
            })
            .catch(error => { });
    }
    handleDelete = () => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to delete quota permanently. If the quota is being used in any of subscription plan or campaign offer, deletion will be rejected.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("DELETE", APIService.QUOTA + "/" + id)
                .then(obj => {
                    this.props.history.goBack();
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                });
        });
    }
    render() {
        if (!AuthService.userHasRole(['quota'])) return null;
        const { classes } = this.props;
        const { obj, plans } = this.state;
        const options = {
            legend: {
                display: false
            },
            cutoutPercentage: 65
        };
        const data = {
            labels: [
                'Free',
                'Reserved',
                'Used'
            ],
            datasets: [{
                data: [Math.max(obj.qty - obj.reserved - obj.used, 0), obj.reserved, obj.used],
                backgroundColor: [
                    '#36A2EB',
                    '#FF6384',
                    '#EEEEEE'
                ]
            }]
        };
        return (
            <Paper className={classes.paper}>
                <Grid container m={24}>
                    <Grid item xs={12} sm={8}>
                        <Typography variant="h4">{obj.quota_id}</Typography>
                        <Typography variant="body1" color="textSecondary">ID: {obj.quota_id}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} align="right">
                        <IconButton component={Link} to={"/quotas/" + this.props.match.params.id + "/edit"}><EditIcon /></IconButton>
                        <IconButton onClick={this.handleDelete}><DeleteIcon /></IconButton>
                        <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Doughnut data={data} options={options} />
                    </Grid>
                    <Grid container item xs={12} sm={7} m={24}>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">ORGANISATION</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.org_id} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">TYPE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.quotaTypes} value={obj.type} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">AUTO RESET</Typography>
                            <Typography variant="body1" color="textPrimary"><DateLabel date={obj.auto_reset} format="dd/MM" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">QUANTITY</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.qty} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">USED</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.used} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">RESERVED</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.reserved} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.desc} /></Typography>
                            </div>
                        </Grid>
                        {plans.length > 0 &&
                            <Grid item xs={12} sm={12}>
                                <Typography variant="caption" color="textSecondary">SUBSCRIPTION PLANS</Typography>
                                {plans.map(plan => (
                                    <Typography key={plan.plan_id} variant="body1" color="textPrimary">&bull;&nbsp;
                                        <Link to={"/plans/" + plan.plan_id} style={{ textDecoration: 'none' }}>{plan.plan_code} {plan.group_code}
                                        </Link>
                                    </Typography>
                                ))}
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={12} sm={12} align="right">
                        <Typography variant="caption" color="textSecondary">
                            <LastUpdated created_at={obj.created_at} created_by={obj.created_by} modified_at={obj.modified_at} modified_by={obj.modified_by} />
                        </Typography><Source data={obj} />
                    </Grid>
                </Grid>
                <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
            </Paper >
        );
    }
}

DetailPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DetailPage);