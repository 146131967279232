import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, IconButton, Toolbar, InputBase, InputAdornment, TextField, MenuItem, Divider, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import {
    AddCircleOutline as AddIcon,
    Sync as ResetIcon,
    Search as SearchIcon,
    Clear as ClearIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Label } from '../../components/Label';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { Global } from '../../Global';
import { loadList, saveList, updateList } from '../../List';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    }
});

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        var filters = props.filters;
        if (filters) {
            filters.keyword = props.keyword;
        } else {
            filters = {
                keyword: props.keyword,
                category: "",
                type: "",
                status: ""
            };
        }
        this.state = filters;
    }
    componentDidMount() {
        this.props.search(this.search);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.filters !== this.props.filters) {
            var category = nextProps.filters.category;
            var type = nextProps.filters.type;
            var status = nextProps.filters.status;
            this.setState({ category: category, type: type, status: status });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    getFilters() {
        var filters = { category: this.state.category, type: this.state.type, status: this.state.status };
        return filters;
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleClear = () => {
        this.setState({ keyword: "" });
    }
    search = () => {
        this.props.onKeywordChange(this.state.keyword);
        this.props.onSearchChanged(this.state.keyword);
    }
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    };
    render() {
        return <Toolbar style={{ paddingTop: 16 }}>
            <InputBase
                style={{ flex: 1, background: '#f0f0f0', padding: 4, borderRadius: 8 }}
                name="keyword"
                id="keyword"
                placeholder="Search plan code"
                value={this.state.keyword}
                onChange={this.handleChange}
                onKeyPress={this.onKeyPress}
                onFocus={event => { event.target.select() }}
                autoFocus
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={this.handleClear}>
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="category"
                name="category"
                label="CATEGORY"
                variant="outlined"
                margin="dense"
                select
                value={this.state.category}
                onChange={this.handleSelect("category")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 250 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.templateCategories.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="type"
                name="type"
                label="TYPE"
                variant="outlined"
                margin="dense"
                select
                value={this.state.type}
                onChange={this.handleSelect("type")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.templateTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="status"
                name="status"
                label="STATUS"
                variant="outlined"
                margin="dense"
                select
                value={this.state.status}
                onChange={this.handleSelect("status")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.templateStatuses.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <IconButton color="default" onClick={this.props.reset}>
                <ResetIcon />
            </IconButton>
        </Toolbar>;
    }
}

let ListKey = "tpl_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        this.list = loadList(ListKey, { category: "", type: "", status: "" });
        this.state = {
            keyword: this.list.keyword,
            filters: this.list.filters
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Templates" });
    }
    componentWillUnmount() {
        saveList(ListKey, this.list, this.state.keyword, this.state.filters);
    }
    onKeywordChange = keyword => {
        if (keyword === this.state.keyword) return;
        this.setState({ keyword: keyword });
    }
    onFiltersChange = filters => {
        this.setState({ filters: filters }, () => {
            this.onSearch();
        });
    }
    onReset = () => {
        this.setState({ keyword: "", filters: { category: "", type: "", status: "" } }, () => {
            this.onSearch();
        });
    }
    onSelect = (event, row) => {
        this.props.history.push("/tpls/" + row.tpl_id);
    }
    loadTable = query => {
        updateList(this.list, query);
        return APIService.search(APIService.TEMPLATE,
            this.list.offset,
            this.list.size,
            this.list.order,
            this.list.orderDir,
            this.state.keyword,
            this.state.filters)
            .then(result => {
                return {
                    data: result.data,
                    page: result.offset / this.list.size,
                    totalCount: result.total
                };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {
        if (!AuthService.userHasRole(['tpl'])) return null;
        const { classes } = this.props;
        const cols = [
            { field: 'tpl_id', title: 'ID', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 50, paddingRight: 4 } },
            {
                field: 'category', title: 'CATEGORY', cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={Global.templateCategories} value={row.category} />
                )
            },
            { field: 'org_id', title: 'ORG', cellStyle: { color: 'inherit', width: 50 } },
            {
                field: 'plan_code', title: 'PLAN CODE', cellStyle: { color: 'inherit', width: 180 }, render: row => (
                    <Label data={row.plan_code} />
                )
            },
            {
                field: 'type', title: 'TYPE', cellStyle: { color: 'inherit', width: 100 }, render: row => (
                    <Label data={Global.templateTypes} value={row.type} />
                )
            }
        ];
        return (
            <Grid container spacing={8}>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    <Button variant="contained" color="primary" className={classes.button} component={Link} to="/tpls/new">
                        New <AddIcon className={classes.rightIcon} />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.list ? this.list.size : 10,
                            toolbar: true,
                            rowStyle: rowData => ({
                                color: Global.listColor(rowData.status)
                            })
                        }}
                        components={{
                            Toolbar: props => (
                                <Searchbar {...props}
                                    keyword={this.state.keyword}
                                    filters={this.state.filters}
                                    onKeywordChange={this.onKeywordChange}
                                    onFiltersChange={this.onFiltersChange}
                                    search={callback => this.onSearch = callback}
                                    reset={this.onReset}
                                />
                            )
                        }}
                    />
                </Grid>
            </Grid >
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
