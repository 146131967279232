import { AuthService } from './Auth';
import { Global } from '../Global';

export const APIService = {
    MIME_JSON: 'application/json',
    AUTH: "auth",
    ORG: 'organisations',
    DEPT: 'departments',
    USER: 'users',
    PLAN: 'plans',
    MEMBER: 'members',
    SUBSCRIPTION: 'subscriptions',
    CREDENTIAL:  'credentials',
    OFFER: 'offers',
    TRANSACTION: 'transactions',
    TEMPLATE: 'templates',
    QUOTA: "quotas",
    ORDER: "orders",
    STATS: "stats",
    COUPONS: "coupons",
    create,
    get,
    update,
    remove,
    call,
    list,
    search,

    uploadFile,
    errorMessage,
    handleResponse
};

// sort direction: +1 asc, -1 desc
function list(resource, offset = 0, size = -1, sort = null, direction = null, search = null, params = null) {
    const options = {
        method: "GET",
        headers: AuthService.header()
    };
    var url = new URL(Global.APIUrl + "/" + resource);
    if (params) {
        params["offset"] = offset;
        params["size"] = size;
    } else {
        params = { offset, size };
    }
    if (sort) {
        if (direction) sort = sort + ":" + direction;
        params["sort"] = sort;
    }
    if (search) params["search"] = search;
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    return fetch(url, options).then(handleResponse);
}

function search(resource, offset = 0, size = -1, sort = null, direction = null, search = null, filters = null) {
    const options = {
        method: "POST",
        headers: { ...AuthService.header(), "Content-Type": APIService.MIME_JSON },
        body: JSON.stringify(filters)
    };
    var url = new URL(Global.APIUrl + "/" + resource);
    var params = { offset, size };
    if (sort) {
        if (direction) sort = sort + ":" + direction;
        params["sort"] = sort;
    }
    if (search) params["search"] = search;
    // params["debug"] = "t";
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    return fetch(url, options).then(handleResponse);
}

function get(resource, id, params = null) {
    const options = {
        method: "GET",
        headers: AuthService.header()
    };
    var url = new URL(Global.APIUrl + "/" + resource + "/" + id);
    if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    return fetch(url, options).then(handleResponse);
}

function create(resource, obj, params = null) {
    const options = {
        method: "POST",
        headers: { ...AuthService.header(), "Content-Type": APIService.MIME_JSON },
        body: JSON.stringify(obj)
    };
    var url = new URL(Global.APIUrl + "/" + resource + "/");
    if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    // return fetch(`${Global.APIUrl}/${resource}/`, options).then(handleResponse);
    return fetch(url, options).then(handleResponse);
}

function update(resource, obj, id, params = null) {
    const options = {
        method: "PUT",
        headers: { ...AuthService.header(), 'Content-Type': APIService.MIME_JSON },
        body: JSON.stringify(obj)
    };
    var url = new URL(Global.APIUrl + "/" + resource + "/" + id);
    if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    return fetch(url, options).then(handleResponse);
}

function call(method, path, obj, params = null) {
    const options = {
        method: method,
        headers: { ...AuthService.header(), 'Content-Type': APIService.MIME_JSON },
        body: obj ? JSON.stringify(obj) : null
    };
    var url = new URL(Global.APIUrl + "/" + path);
    if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    return fetch(url, options).then(handleResponse);
}

function remove(resource, id, params = null) {
    const options = {
        method: "DELETE",
        headers: { ...AuthService.header() },
    };
    var url = new URL(Global.APIUrl + "/" + resource + "/" + id);
    if (params) {
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    return fetch(url, options).then(handleResponse);
}

function uploadFile(resource, files) {
    var url = new URL(Global.APIUrl + "/" + resource);
    var formData = new FormData();
    formData.append('file', files[0]);
    return fetch(url, {
        method: 'POST',
        headers: AuthService.header(),
        body: formData
    }).then(handleResponse);
}

function errorMessage(error) {
    if (error instanceof Error) {
        return error.message;
    } else if (error.reason) {
        let reason = Global.errors[error.reason];
        let msg = error.msg;
        if (msg) {
            return reason + "\r\n" + msg;
        }
    } else if (error.msg) {
        return error.msg;
    }
    return "Unknown error";
}

function errorFromAPI(httpResponse, apiResponse) {
    var status, code, reason, msg;
    status = httpResponse.status;
    if (apiResponse) {
        reason = apiResponse.reason;
        code = apiResponse.code;
        if (apiResponse.msg) {
            msg = apiResponse.msg;
        } else {
            msg = Global.errors[apiResponse.reason];
        }
        msg = "ERROR (" + code + "): " + msg;
    } else {
        reason = "";
        code = -1;
        msg = "HTTP ERROR (" + status + "): " + httpResponse.statusText;
    }
    return { status, code, reason, msg };
}

// Common REST API error handler
export function handleResponse(response) {
    return response.text().then(text => {
        try {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    AuthService.deauthenticate();
                    // location.reload(true);
                }
                throw errorFromAPI(response, data);
            }
            return data;
        } catch (error) {
            if (error instanceof Error) {
                // failed to parse JSON?
            } else if (error instanceof Object) {
                throw error;
            }
            throw errorFromAPI(response, null);
        }
    });
}
