import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, FormHelperText, Button, Typography, withStyles } from '@material-ui/core';
import FilterOptions from '../../components/FilterOptions';
import { Global } from '../../Global';
import { DateLabel } from '../../components/Label';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { AuthService } from "../../services/Auth";

const styles = theme => ({
});

let ExportFields = [
    { value: "m_id", label: "Member ID" },
    { value: "org_id", label: "Organisation" },
    { value: "profile.region", label: "Region" },
    { value: "profile.locale", label: "Language" },
    { value: "profile.names.en", label: "English Name" },
    { value: "profile.names.zh", label: "Chinese Name" },
    { value: "profile.gender", label: "Gender" },
    { value: "profile.dob", label: "Date of Birth" },
    { value: "profile.contacts.msisdn", label: "Mobile Number" },
    { value: "profile.contacts.email", label: "E-mail" },
    { value: "profile.contacts.addr", label: "Address" },
    { value: "memberships.plan_code", label: "Subscription Plan" },
    { value: "memberships.plan_group_code", label: "Subscription Plan Group" },
    { value: "memberships.start", label: "Subscription Start" },
    { value: "memberships.expiry", label: "Subscription Expiry" },
    { value: "profile.birthYearRange", label: "Year Range" },
    { value: "modified_at", label: "Last Modified Date" },
    { value: "modified_by", label: "Last Modified By" }
];

class ExportDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: ["m_id", "org_id", "profile.names.en", "profile.names.zh", "profile.contacts.msisdn", "profile.contacts.email"],
            url: "",
            url_expires_at: null,
            offset: "",
            limit: "",
            open: false,
            errors: {},
            isSubmitting: false
        };
    }
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = () => {
        this.setState({ open: true, url: "", url_expires_at: null, offset: "", limit: "", errors: {}, isSubmitting: false });
    }
    handleFieldChange = (id, options) => {
        this.setState({ [id]: options });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };
    handleDownload = () => {
        window.open(this.state.url);
    }
    handleSubmit = () => {
        if (this.state.isSubmitting) return;
        if (!this.validateForm()) return;

        this.setState({ isSubmitting: true });
        var req = {};
        for (var key in this.props.filters) {
            req[key] = this.props.filters[key];
        }
        req.type = "csv";
        req.search = this.props.keyword;
        req.offset = parseInt(this.state.offset);
        req.limit = parseInt(this.state.limit);

        var sortedFields = [];
        for (var i = 0; i < ExportFields.length; i++) {
            let f = ExportFields[i];
            if (this.state.fields.includes(f.value)) {
                sortedFields.push(f.value);
            }
        }
        req.fields = sortedFields;
        APIService.call("POST", APIService.MEMBER + "/export", req)
            .then(result => {
                if (result.key) {
                    let user = AuthService.currentUser();
                    if (user) {
                        var token = user.token;
                        token = encodeURIComponent(token);
                        let url = Global.APIUrl + "/members/export/" + result.key + "?jwt=" + token;
                        this.setState({ url: url, url_expires_at: result.expires_at });
                    }
                }
                this.setState({ isSubmitting: false });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};

        Validator.verifyNumber(this.state.offset, value => {
            var err = null;
            if (isNaN(value) || !value) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["offset"] = err;
        });
        Validator.verifyNumber(this.state.limit, value => {
            var err = null;
            if ((isNaN(value) || !value) && value !== 0) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["limit"] = err;
        });
        if (this.state.fields.length === 0) {
            errors["fields"] = Global.errors["ErrorRequired"];
        }

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    render() {
        const { errors } = this.state;
        return <Dialog
            open={this.state.open}
            fullWidth={true}
            maxWidth="sm"
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Export CSV</DialogTitle>
            <DialogContent>
                <Grid container m={24}>
                    {!this.state.url &&
                        <React.Fragment>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="caption" color="textSecondary">FIELDS</Typography>
                                <FilterOptions id="fields" options={ExportFields} selected={this.state.fields} onChange={this.handleFieldChange} />
                                {errors["fields"] && <FormHelperText error={true}>{errors["fields"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="offset"
                                    name="offset"
                                    label="START"
                                    value={this.state.offset}
                                    onChange={this.handleChange}
                                    type="number"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["offset"] && <FormHelperText error={true}>{errors["offset"]}</FormHelperText>}
                                <FormHelperText>Starting row number, default is the first row. Row number is zero based.</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="limit"
                                    name="limit"
                                    label="LIMIT"
                                    value={this.state.limit}
                                    onChange={this.handleChange}
                                    type="number"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["limit"] && <FormHelperText error={true}>{errors["limit"]}</FormHelperText>}
                                <FormHelperText>If the limit value is omitted or 0, all records will be exported</FormHelperText>
                            </Grid>
                        </React.Fragment>
                    }
                    {this.state.url &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" color="textPrimary">The export file is available for download. The download link expires at <DateLabel date={this.state.url_expires_at} format={Global.DisplayDateTimeFormat} />.</Typography>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                {!this.state.url &&
                    <React.Fragment>
                        <Button onClick={this.handleSubmit} color="primary" disabled={this.state.isSubmitting}>OK</Button>
                        <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
                    </React.Fragment>
                }
                {this.state.url &&
                    <React.Fragment>
                        <Button onClick={this.handleDownload} color="primary">Download</Button>
                        <Button onClick={this.handleClose} color="secondary">Close</Button>
                    </React.Fragment>
                }
            </DialogActions>
        </Dialog>
    }
}

export default withStyles(styles)(ExportDialog);
