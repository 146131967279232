import { APIService } from './API';
import { Global } from '../Global';

export const NameService = {
    organisationList,
    departmentList,
    userList,
    planList,

    getUserName,
    getStats
};

var UserNames = [];
var Departments = [];
var Organisations = [];
var Stats = [];
var StatsTimestamp = null;

function organisationList(callback) {
    if (Organisations.length > 0) {
        callback(Organisations);
        return;
    }
    APIService.list(APIService.ORG + "/name")
        .then(result => {
            var objs = result.data.map(obj => (
                { value: obj.org_id, label: obj.org_id }
            ));
            Organisations = objs;
            if (callback) {
                callback(objs);
            }
        })
        .catch(error => { });
}

function departmentList(callback) {
    if (Departments.length > 0) {
        callback(Departments);
        return;
    }
    APIService.list(APIService.DEPT + "/name")
        .then(result => {
            var objs = result.data.map(obj => (
                { value: obj.dept_id, label: obj.dept_id }
            ));
            Departments = objs;
            if (callback) {
                callback(objs);
            }
        })
        .catch(error => { });
}

function userList(callback) {
    APIService.list(APIService.USER + "/name")
        .then(result => {
            var objs = result.data.map(obj => (
                { value: obj.u_id, label: obj.name }
            ));
            UserNames = objs;
            if (callback) {
                callback(objs);
            }
        })
        .catch(error => { });
}

function planList(callback) {
    APIService.list(APIService.PLAN + "/name")
        .then(result => {
            var objs = result.data.map(obj => (
                { value: obj.plan_code, label: obj.plan_code
            , plan_group_code: obj.plan_group_code, name: obj.names ? obj.names.en : ""
            , org_id: obj.org_id, plan_id: obj.plan_id, plan_code: obj.plan_code}
            ));
            if (callback) {
                callback(objs);
            }
        })
        .catch(error => { });
}

function getUserName(id, callback) {
    var name = Global.getValueLabel(UserNames, id);
    if (name) {
        callback(name);
        return;
    }
    userList((users) => {
        var name = Global.getValueLabel(UserNames, id);
        if (name) {
            callback(name);
        } else {
            callback(id);
        }
    });
}

function getStats(callback) {
    if (Stats.length > 0) {
        var now = new Date().getTime() / 1000;
        if (StatsTimestamp && (now - StatsTimestamp) < 1800) {
            callback(Stats);
            return;
        }
    }
    APIService.list(APIService.STATS)
        .then(result => {
            Stats = result.data;
            StatsTimestamp = new Date().getTime() / 1000;
            if (callback) {
                callback(Stats);
            }
        })
        .catch(error => { });
}