import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Button, Typography, Divider, withStyles } from '@material-ui/core';
import {
    Edit as EditIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Source from '../../components/Source';
import LastUpdated from '../../components/LastUpdated';
import ResetPasswordDialog from './ResetPasswordDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Label, CheckLabel } from '../../components/Label';
import RoleControl from '../../components/RoleControl';
import Status from '../../components/Status';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class DetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {},
            isSubmitting: false
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "User" });
    }
    componentDidMount() {
        this.load();
    }
    load() {
        let id = this.props.match.params.id;
        APIService.get(APIService.USER, id)
            .then(obj => {
                this.setState({ obj: obj });
            })
            .catch(error => {
                this.props.history.goBack();
            });
    }

    handleStatus = (activity, status) => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to " + activity + " user account.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("GET", APIService.USER + "/" + id + "/status/" + status)
                .then(() => {
                    this.setState({ isSubmitting: false }, () => { this.load(); });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                });
        });
    }
    render() {
        if (!AuthService.userHasRole(['user'])) return null;
        const { classes } = this.props;
        const { obj } = this.state;

        // sort out user roles
        var roleGroups = [];
        var roles = {};
        if (obj.roles) {
            var currentGroup = "";
            for (var i = 0; i < Global.userRoles.length; i++) {
                let userRole = Global.userRoles[i];
                if (userRole.group && userRole.group !== currentGroup) {
                    currentGroup = userRole.group;
                }
                if (obj.roles[userRole.value]) {
                    if (roleGroups.indexOf(currentGroup) === -1) {
                        roleGroups.push(currentGroup);
                    }
                    if (roles[currentGroup]) {
                        roles[currentGroup].push(userRole);
                    } else {
                        roles[currentGroup] = [userRole];
                    }
                }
            }
        }
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Grid container m={24}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h4">{obj.name}</Typography>
                            <Typography variant="body1" color="textSecondary">ID: {obj.u_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <IconButton component={Link} to={"/users/" + this.props.match.params.id + "/edit"}><EditIcon /></IconButton>
                            <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">NAME</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.name} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">ORGANISATION</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.org_id} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">DEPARTMENT</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.dept_id} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.desc} /></Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">Login</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.login} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">E-MAIL ADDRESS</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.email} /></Typography>
                        </Grid>
                        {obj.roles &&
                            <React.Fragment>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary">ROLES</Typography>
                                    <Divider light={true} />
                                </Grid>
                                {roleGroups.map(group => (
                                    <Grid item xs={12} sm={4} key={group}>
                                        <Typography variant="caption" color="textSecondary" gutterBottom>{group}</Typography>
                                        {roles[group].map(role => (
                                            <CheckLabel key={role.value} data={role.value} label={role.label} />
                                        ))}
                                    </Grid>
                                ))}
                            </React.Fragment>
                        }
                        <Grid item xs={12} sm={12}>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Status label={obj.status} />
                        </Grid>
                        <Grid item xs={12} sm={8} align="right">
                            <Typography variant="caption" color="textSecondary">
                                <LastUpdated created_at={obj.created_at} created_by={obj.created_by} modified_at={obj.modified_at} modified_by={obj.modified_by} />
                            </Typography><Source data={obj} />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    <Button variant="contained" className={classes.button} onClick={() => this.showResetPassword(obj)} disabled={this.state.isSubmitting}>Reset Password</Button>
                    <RoleControl required={["user_activate"]}>
                        {obj.status === "inactive" && 
                                <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("activate", "active")} disabled={this.state.isSubmitting}>Activate</Button>
                            }
                        {obj.status === "active" &&
                                <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("deactivate", "inactive")} disabled={this.state.isSubmitting}>Deactivate</Button>
                            }
                    </RoleControl>
                </Grid>
                <ResetPasswordDialog onDialog={dialog => this.showResetPassword = dialog} alert={this.props.alert} />
                <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
            </React.Fragment>
        );
    }
}

DetailPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DetailPage);