import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Divider, Button, Typography, withStyles } from '@material-ui/core';
import {
    Edit as EditIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import Source from '../../components/Source';
import { Label, DateLabel, PeriodLabel, CurrencyLabel } from '../../components/Label';
import Status from '../../components/Status';
import LastUpdated from '../../components/LastUpdated';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class DetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {}
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Subscription Plan" });
    }
    componentDidMount() {
        this.load();
    }
    load() {
        let id = this.props.match.params.id;
        APIService.get(APIService.PLAN, id)
            .then(obj => {
                this.setState({ obj: obj })
            })
            .catch(error => {
                this.props.history.goBack();
            })
    }
    handleStatus = (activity, status) => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to " + activity + " subscription plan.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("GET", APIService.PLAN + "/" + id + "/status/" + status)
                .then(() => {
                    this.setState({ isSubmitting: false }, () => { this.load(); });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                })
        })
    }
    render() {
        if (!AuthService.userHasRole(['plan'])) return null;
        const { classes } = this.props;
        const { obj } = this.state;
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Grid container m={24}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h4"><Label data={obj} field="names" locale="en" /></Typography>
                            <Typography variant="body1" color="textSecondary">ID: {obj.plan_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <IconButton component={Link} to={"/plans/" + this.props.match.params.id + "/edit"}><EditIcon /></IconButton>
                            <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">ORGANISATION</Typography>
                            <Typography variant="body1" color="textPrimary">{obj.org_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">NAME (ENGLISH)</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="names" locale="en" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">NAME (CHINESE)</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="names" locale="zh" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.desc} /></Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">PLAN CODE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.plan_code} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">PLAN GROUP CODE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.plan_group_code} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">MEDIUM</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.medium} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">REQUIRE SUBSCRIPTION</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="require_sub" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" color="textSecondary">DETAILS</Typography>
                            <Divider light={true} />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">TYPE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.subscriptionTypes} value={obj.type} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">PERIOD</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.subscriptionPeriods} value={obj.period} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">VALIDITY</Typography>
                            <Typography variant="body1" color="textPrimary"><DateLabel begin={obj.validity_begin} end={obj.validity_end} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">QUOTA</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="quota_id" /></Typography>
                        </Grid>
                        <Grid container item xs={12} sm={4}>
                            <Grid container item xs={12} sm={12} direction="row" m={16}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary">PERIODS</Typography>
                                    <Divider light={true} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">RENEWAL PERIOD</Typography>
                                    <Typography variant="body1" color="textPrimary"><PeriodLabel value={obj.renewal_period} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">GRACE PERIOD</Typography>
                                    <Typography variant="body1" color="textPrimary"><PeriodLabel value={obj.grace_period} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">RETENTION PERIOD</Typography>
                                    <Typography variant="body1" color="textPrimary"><PeriodLabel value={obj.retention_period} /></Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={4}>
                            <Grid container item xs={12} sm={12} direction="row" m={16}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary">PRICES</Typography>
                                    <Divider light={true} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">NEW</Typography>
                                    <Typography variant="body1" color="textPrimary"><CurrencyLabel data={obj} field="details.prices.new" /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">RENEWAL</Typography>
                                    <Typography variant="body1" color="textPrimary"><CurrencyLabel data={obj} field="details.prices.renewal" /></Typography>
                                </Grid>
                                {/* <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">REACTIVATION</Typography>
                                    <Typography variant="body1" color="textPrimary"><CurrencyLabel data={obj} field="details.prices.reactivation" /></Typography>
                                </Grid> */}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={4}>
                            <Grid container item xs={12} sm={12} direction="row" m={16}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary">REWARD POINTS</Typography>
                                    <Divider light={true} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">NEW</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.points.new" unit="pts" /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">ADVANCE RENEWAL</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.points.advance-renewal" unit="pts" /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">RENEWAL</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.points.renewal" unit="pts" /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="caption" color="textSecondary">BIRTHDAY</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.points.birthday" unit="pts" /></Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">REMARK</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.remark} /></Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Status label={obj.status} />
                        </Grid>
                        <Grid item xs={12} sm={8} align="right">
                            <Typography variant="caption" color="textSecondary">
                                <LastUpdated created_at={obj.created_at} created_by={obj.created_by} modified_at={obj.modified_at} modified_by={obj.modified_by} />
                            </Typography><Source data={obj} />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    {obj.status === "inactive" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("activate", "active")} disabled={this.state.isSubmitting}>Activate</Button>
                    }
                    {obj.status === "active" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("deactivate", "inactive")} disabled={this.state.isSubmitting}>Deactivate</Button>
                    }
                </Grid>
                <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
            </React.Fragment>
        );
    }
}

DetailPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DetailPage);