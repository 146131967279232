import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Typography, withStyles } from '@material-ui/core';
import {
    Close as CloseIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import Source from '../../components/Source';
import { Label, DateLabel, CurrencyLabel } from '../../components/Label';
import Username from '../../components/Username';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    }
});

class DetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {},
            isSubmitting: false
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Transaction" });
    }
    componentDidMount() {
        this.load();
    }
    load() {
        let id = this.props.match.params.id;
        APIService.get(APIService.TRANSACTION, id)
            .then(obj => {
                this.setState({ obj: obj });
            })
            .catch(error => {
                this.props.history.goBack();
            });
    }
    
    changeDateFormat(date){
            var new_date = new Date(date);
            var getfullYear = new_date.getFullYear();
            var getmonth = String(new_date.getMonth() + 1).padStart(2, '0');
            var getdate = String(new_date.getDate()).padStart(2, '0');
            return getfullYear + "-" + getmonth + "-" + getdate;
    }
    
    render() {
        if (!AuthService.userHasRole(['trans_sub', 'trans_point', 'trans_coupon'])) return null;
        const { classes } = this.props;
        const { obj } = this.state;
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Grid container m={24}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h4">Transaction No: {obj.txn_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">DATE TIME</Typography>
                            <Typography variant="body1" color="textPrimary"><DateLabel date={obj.at} format={Global.DisplayDateTimeFormat} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">ORG</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.org_id} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">DEPARTMENT</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.dept_id} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">HANDLED BY</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Username user={obj.u_id} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">CATEGORY</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Label data={Global.transClasses} value={obj.cls} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">TYPE</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Label data={Global.transTypes} value={obj.type} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">SUBTYPE</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Label data={obj.subtype} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">MEMBER ID</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Link to={(typeof obj.m_id === "undefined") ? "#" : "/members/" + obj.m_id} style={{ textDecoration: 'none' }}>
                                    <Label data={obj.m_id} />
                                </Link>
                            </Typography>
                        </Grid>
                        {obj.cls === "subscription" && obj.type === "subscription" && obj.details.subscribed_at !== "" &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">SUBSCRIBED AT</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Label data={this.changeDateFormat(obj.details.subscribed_at)} />
                                </Typography>
                            </Grid>
                        }
                        {obj.cls === "subscription" && obj.type === "subscription" && obj.details && obj.details.expires_at && 
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">EXPIRES AT</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Label data={obj.details.expires_at} />
                                </Typography>
                            </Grid>
                        }
                        {obj.cls === "subscription" && obj.type === "subscription" && obj.details && obj.details.last_subscribed_at && 
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">LAST SUBSCRIBED AT</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Label data={this.changeDateFormat(obj.details.last_subscribed_at)} />
                                </Typography>
                            </Grid>
                        }
                        {obj.cls === "subscription" && obj.type === "subscription" && obj.details && obj.details.last_expires_at && 
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">LAST EXPIRES AT</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Label data={obj.details.last_expires_at} />
                                </Typography>
                            </Grid>
                        }
                        {obj.order_id &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">ORDER ID</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Link to={((obj.cls === "coupon" && obj.type === "redemption" && (typeof obj.subtype === "undefined" || obj.subtype === null)) || (obj.cls === "point" && obj.type === "reward" && typeof obj.subtype !== "undefined" && obj.subtype === "ticketing")) ? "#" : "/orders/" + obj.order_id} style={{ textDecoration: 'none' }}>
                                        <Label data={obj.order_id} />
                                    </Link>
                                </Typography>
                            </Grid>
                        }
                        {obj.txn_no &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">REF NO</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.txn_no} /></Typography>
                            </Grid>
                        }
                        {obj.amount &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">AMOUNT</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <CurrencyLabel data={obj.amount} />
                                </Typography>
                            </Grid>
                        }
                        {obj.discount &&
                            <Grid item xs={12} sm={4}>
                                <Typography variant="caption" color="textSecondary">DISCOUNT</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <CurrencyLabel data={obj.discount} />
                                </Typography>
                            </Grid>
                        }
                        {obj.pay_method &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">PAYMENT METHOD</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.paymentMethods} value={obj.pay_method} /></Typography>
                            </Grid>
                        }
                        {obj.details && obj.details.offer_ids &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">OFFER ID</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {obj.details.offer_ids.map(offer_id => (
                                        <Link key={offer_id} to={"/offers/point/" + offer_id} style={{ textDecoration: 'none' }}>
                                            <Label data={offer_id} />
                                        </Link>
                                    ))}
                                </Typography>
                            </Grid>
                        }
                        {obj.points &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">POINTS</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Label data={obj.points} unit="pts" />
                                </Typography>
                            </Grid>
                        }
                        {obj.qty &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">QUANTITY</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Label data={obj.qty} />
                                </Typography>
                            </Grid>
                        }
                        {obj.cinema_id &&
                            <Grid item xs={12} sm={4}>
                                <Typography variant="caption" color="textSecondary">CINEMA ID</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.cinema_id} /></Typography>
                            </Grid>
                        }
                        {obj.details && obj.details.plan_id &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">PLAN ID</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Link to={"/plans/" + Global.getNestedValue(obj, "details.plan_id")} style={{ textDecoration: 'none' }}>
                                        <Label data={obj} field="details.plan_id" />
                                    </Link>
                                </Typography>
                            </Grid>
                        }
                        {obj.details && obj.details.plan_code &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">PLAN CODE</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.details.plan_code} /></Typography>
                            </Grid>
                        }
                        {obj.details && obj.details.promo_id &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">PROMO ID</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.details.promo_id} /></Typography>
                            </Grid>
                        }
                        {obj.details && obj.details.medium &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">MEDIUM</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.details.medium} /></Typography>
                            </Grid>
                        }
                        {obj.channel &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">CHANNEL</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.channel} /></Typography>
                            </Grid>
                        }
                        {obj.details && obj.details.batch_id &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">BATCH ID</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.details.batch_id} /></Typography>
                            </Grid>
                        }
                        {obj.details && obj.details.pay_gateway_ref &&
                            <Grid item xs={12} sm={3}>
                                <Typography variant="caption" color="textSecondary">VP NO.</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.details.pay_gateway_ref} /></Typography>
                            </Grid>
                        }
                        {obj.remark &&
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">Remarks</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Label data={obj.remark} />
                            </Typography>
                        </Grid>
                        }
                        <Grid item xs={12} sm={12} align="right">
                            <Source data={obj} />
                        </Grid>
                    </Grid>
                </Paper>
            </React.Fragment>
        );
    }
}

DetailPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DetailPage);
