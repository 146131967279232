import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Divider, Button, Typography, withStyles } from '@material-ui/core';
import {
    Edit as EditIcon,
    Close as CloseIcon,
} from '@material-ui/icons';
import Source from '../../components/Source';
import LastUpdated from '../../components/LastUpdated';
import { Label, DateLabel } from '../../components/Label';
import Status from '../../components/Status';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class DetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {}
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Organisation" });
    }
    componentDidMount() {
        this.load();
    }
    load() {
        let id = this.props.match.params.id;
        APIService.get(APIService.ORG, id)
            .then(obj => {
                this.setState({ obj: obj });
            })
            .catch(error => {
                this.props.history.goBack();
            });
    }
    handleStatus = (activity, status) => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to " + activity + " organisation.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("GET", APIService.ORG + "/" + id + "/status/" + status)
                .then(() => {
                    this.setState({ isSubmitting: false }, () => { this.load(); });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                });
        });
    }
    render() {
        if (!AuthService.userHasRole(['org'])) return null;
        const { classes } = this.props;
        const { obj } = this.state;
        var defaultKey = '';
        var bcc_recipients = '';
        var notice_recipients = '';
        if(typeof obj.details !== "undefined" && typeof obj.details.member_activation_email_urls !== "undefined"){
            var data;
            for(data in obj.details.member_activation_email_urls){
               if(data === 'Default'){
                   defaultKey = data;
               }
            }
        }
        if(typeof obj.details !== "undefined" && obj.details.bcc_recipients){
            bcc_recipients = obj.details.bcc_recipients.join();
        }
        if(typeof obj.details !== "undefined" && obj.details.notice_recipients){
            notice_recipients = obj.details.notice_recipients.join();
        }
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Grid container m={24}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h4"><Label data={obj} field="names" locale="en" /></Typography>
                            <Typography variant="body1" color="textSecondary">ID: {obj.org_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <IconButton component={Link} to={"/orgs/" + this.props.match.params.id + "/edit"}><EditIcon /></IconButton>
                            <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">NAME (ENGLISH)</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="names" locale="en" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">NAME (CHINESE)</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="names" locale="zh" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">REGION</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.regions} value={obj.region} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.desc} /></Typography>
                            </div>
                        </Grid>
                        {obj.details &&
                            <Grid container item m={24}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary">MAILING INFORMATION</Typography>
                                    <Divider light={true} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="caption" color="textSecondary">DOMAIN</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj.details.domain} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="caption" color="textSecondary">SENDER E-MAIL ADDRESS</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj.details.sender_email} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="caption" color="textSecondary">SENDER NAME</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj.details.sender_name} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="caption" color="textSecondary">RESET PASSWORD URL</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj.details.reset_password_url} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="caption" color="textSecondary">BCC RECIPIENT</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={bcc_recipients} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="caption" color="textSecondary">NOTICE RECIPIENT</Typography>
                                    <Typography variant="body1" color="textPrimary" style={{overflowWrap: 'break-word'}}><Label data={notice_recipients} /></Typography>
                                </Grid>
                                {obj.org_id === 'FREE' && 
                                    <React.Fragment>
                                        {typeof obj.details.member_activation_email_urls !== undefined && obj.details.member_activation_email_urls !== '' &&
                                        <React.Fragment>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="caption" color="textSecondary">KEY</Typography>
                                                <Typography variant="body1" color="textPrimary">{(obj.details.member_activation_email_urls.BW) !== undefined && obj.details.member_activation_email_urls.BW !== ''? 'BW' : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="caption" color="textSecondary">ACTIVATION REDIRECT URL</Typography>
                                                <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.member_activation_email_urls.BW"/></Typography>
                                            </Grid>
                                        </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                                {obj.org_id !== 'FREE' && 
                                    <React.Fragment>
                                        {defaultKey === 'Default' &&
                                        <React.Fragment>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="caption" color="textSecondary">KEY</Typography>
                                                <Typography variant="body1" color="textPrimary">{(typeof obj.details.member_activation_email_urls.Default) !== undefined && obj.details.member_activation_email_urls.Default !== ''? 'Default' : ''}</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="caption" color="textSecondary">ACTIVATION REDIRECT URL</Typography>
                                                <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.member_activation_email_urls.Default"/></Typography>
                                            </Grid>
                                        </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary" >REWARD POINTS</Typography>
                                    <Divider light={true} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="caption" color="textSecondary">CONVERSION RATE</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj.point_rate} unit="%" /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="caption" color="textSecondary">EXPIRY DATE</Typography>
                                    <Typography variant="body1" color="textPrimary"><DateLabel date={obj.point_expiry} format="dd/MM" /></Typography>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} sm={4}>
                            <Status label={obj.status} />
                        </Grid>
                        <Grid item xs={12} sm={8} align="right">
                            <Typography variant="caption" color="textSecondary">
                                <LastUpdated created_at={obj.created_at} created_by={obj.created_by} modified_at={obj.modified_at} modified_by={obj.modified_by} />
                            </Typography><Source data={obj} />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    {obj.status === "inactive" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("activate", "active")} disabled={this.state.isSubmitting}>Activate</Button>
                    }
                    {obj.status === "active" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("deactivate", "inactive")} disabled={this.state.isSubmitting}>Deactivate</Button>
                    }
                </Grid>
                <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
            </React.Fragment>
        );
    }
}

DetailPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DetailPage);
