import React from 'react';
import { FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, FormHelperText, Button, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { Global } from '../Global';
import { APIService } from '../services/API';
import { AuthService } from '../services/Auth';
import { Validator } from '../helpers/Validator';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2)
    }
});

class ChangePasswordDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            u_id: 0,
            pass: "",
            new_pass: "",
            repass: "",
            errors: {},
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = () => {
        let user = AuthService.currentUser();
        if (!user || !user.u_id) return;

        this.setState({ open: true, u_id: user.u_id, pass: "", new_pass: "", repass:"", errors: {}, isSubmitting: false });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleSubmit = event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        if (!this.validateForm()) return;

        this.setState({
            isSubmitting: true
        });

        let req = {
            pass: this.state.pass,
            new_pass: this.state.new_pass
        };
        APIService.call("POST", APIService.AUTH + "/change_pass", req)
            .then(result => {
                this.setState({ isSubmitting: false, open: false }, () => {
                    this.props.alert("Password updated", "success");
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};

        Validator.verifyString(this.state.new_pass, value => {
            if (!Validator.isValidPassword(value)) {
                errors["new_pass"] = Global.errors["ErrorInvalidPassword"];
            }
        });

        if (this.state.new_pass === this.state.pass) {
            errors["new_pass"] = Global.errors["ErrorNoChanged"];
        }
        if (this.state.new_pass !== this.state.repass) {
            errors["repass"] = Global.errors["ErrorConfirmPasswordMismatch"];
        }
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        const { classes } = this.props;
        const { errors } = this.state;
        return <Dialog
            open={this.state.open}
            fullWidth
            maxWidth="sm"
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <form onSubmit={this.handleSubmit}>

                <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>Change user login password.</DialogContentText>
                    <FormControl required fullWidth className={classes.formControl}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="pass"
                                    name="pass"
                                    label="CURRENT PASSWORD"
                                    value={this.state.pass}
                                    onChange={this.handleChange}
                                    type="password"
                                    required
                                    fullWidth
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["pass"] && <FormHelperText error={true}>{errors["pass"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="new_pass"
                                    name="new_pass"
                                    label="NEW PASSWORD"
                                    value={this.state.new_pass}
                                    onChange={this.handleChange}
                                    type="password"
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["new_pass"] && <FormHelperText error={true}>{errors["new_pass"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="repass"
                                    name="repass"
                                    label="CONFIRM PASSWORD"
                                    value={this.state.repass}
                                    onChange={this.handleChange}
                                    type="password"
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["repass"] && <FormHelperText error={true}>{errors["repass"]}</FormHelperText>}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" disabled={this.state.isSubmitting}>OK</Button>
                    <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    }
}

export default withStyles(styles)(ChangePasswordDialog);