import React from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, Toolbar, IconButton, withStyles } from '@material-ui/core';
import {
    Sync as ResetIcon,
    SaveAlt as ExportIcon,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { Label, DateLabel, CurrencyLabel } from '../../components/Label';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, parse } from 'date-fns';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
});

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        var filters = props.filters;
        if (!filters) {
            filters = {
                begin: null,
                end: null,
                offer_id: 0,
                type: "redemption"
            };
        }
        this.state = filters;
    }
    componentDidMount() {
        this.props.search(this.search);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.filters !== this.props.filters) {
            var begin = nextProps.filters.begin;
            var end = nextProps.filters.end;
            this.setState({ begin: begin, end: end });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    getFilters() {
        var filters = { begin: this.state.begin, end: this.state.end, type: this.state.type, offer_id: this.state.offer_id };
        if (filters.begin > filters.end) {
            let begin = parse(filters.begin, Global.DateFormat, new Date());
            filters.end = format(begin, Global.DateFormat);
        }
        return filters;
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.DateFormat);
        }
        this.setState({ [name]: value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleClear = () => {
        this.setState({ keyword: "" });
    }
    search = () => {
        this.props.onKeywordChange(this.state.keyword);
        this.props.onSearchChanged(this.state.keyword);
    }
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    };
    render() {
        return <Toolbar style={{ paddingTop: 16 }}>
            <KeyboardDatePicker
                keyboard="true"
                format={Global.DisplayDateFormat}
                placeholder="DD/MM/YYYY"
                label="FROM"
                inputVariant="outlined"
                margin="dense"
                value={this.state.begin}
                onChange={this.handleSelectDate("begin")}
                animateYearScrolling={false}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ maxWidth: 180 }}
            />
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <KeyboardDatePicker
                keyboard="true"
                format={Global.DisplayDateFormat}
                placeholder="DD/MM/YYYY"
                label="TILL"
                inputVariant="outlined"
                margin="dense"
                value={this.state.end}
                onChange={this.handleSelectDate("end")}
                animateYearScrolling={false}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ maxWidth: 180 }}
            />
            <div style={{flex: 1}}/>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <IconButton color="default" onClick={this.props.export} aria-label="Export">
                <ExportIcon />
            </IconButton>
            <IconButton color="default" onClick={this.props.reset}>
                <ResetIcon />
            </IconButton>
        </Toolbar>;
    }
}

class RedemptionLogDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            offer_id: 0,
            open: false,
            org_begin: "",
            filters: {
                begin: "",
                end: "",
                offer_id: 0,
                type: "redemption"
            }
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onShowLog(this.showLog);
    }
    onSelect = (event, row) => {
        this.props.history.push("/transactions/" + row.txn_id);
    }
    showLog = (title, offer_id, begin) => {
        offer_id = parseInt(offer_id);
        let end = format(new Date(), Global.DateFormat);
        this.setState({ open: true, title: title, offer_id: offer_id, org_begin: begin, filters: { begin: begin, end: end, type: "redemption", offer_id: offer_id } });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    loadTable = query => {
        return APIService.list(APIService.TRANSACTION,
            query.page * query.pageSize,
            query.pageSize,
            query.orderBy ? query.orderBy.field : null,
            query.orderDirection,
            "",
            this.state.filters)
            .then(result => {
                return { data: result.data, page: result.offset / query.pageSize, totalCount: result.total };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    onKeywordChange = keyword => {
        // if (keyword === this.state.keyword) return;
        // this.setState({ keyword: keyword });
    }
    onFiltersChange = filters => {
        this.setState({ filters: filters }, () => {
            this.onSearch();
        });
    }
    onReset = () => {
        let end = format(new Date(), Global.DateFormat);
        this.setState({ filters: { begin: this.state.org_begin, end: end, type: "redemption", offer_id: this.state.offer_id } }, () => {
            this.onSearch();
        });
    }
    onExportCSV = () => {
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);

        let { begin, end, type, offer_id } = this.state.filters;
        var url = new URL(Global.APIUrl + "/transactions/export");
        var params = { jwt: token };
        if (begin) params["begin"] = begin;
        if (end) params["end"] = end;
        if (type) params["type"] = type;
        if (offer_id) params["offer_id"] = offer_id;

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        window.open(url);
    }
    render() {
        const cols = [
            {
                field: 'at', title: 'DATE TIME', cellStyle: { width: 200 }, render: row => (
                    <DateLabel date={row.at} format={Global.DisplayDateTimeFormat} />
                )
            },
            {
                field: 'm_id', title: 'MEMBER', headerStyle: { padding: 4 }, cellStyle: { width: 50, padding: 4 }, render: row => (
                    <Link to={"members/" + row.m_id} style={{ textDecoration: 'none' }}><Label data={row.m_id} /></Link>
                )
            },
            {
                field: 'txn_no', title: 'REF NO', headerStyle: { padding: 4 }, cellStyle: { width: 150, padding: 4 }
            },
            {
                field: 'channel', title: 'CHANNEL', headerStyle: { padding: 4 }, cellStyle: { width: 50, padding: 4 }
            },
            {
                field: 'cinema_id', title: 'CINEMA', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4 }
            },
            {
                field: 'dept_id', title: 'DEPT', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4 }
            },
            {
                field: 'discount', title: 'DISCOUNT', type: 'numeric', sorting: false, headerStyle: { padding: 4 }, cellStyle: { width: 150, padding: 4 }, render: row => (
                    <CurrencyLabel data={row.discount} />
                )
            },
            {
                field: 'amount', title: 'AMOUNT', type: 'numeric', sorting: false, headerStyle: { padding: 4 }, cellStyle: { width: 150, padding: 4 }, render: row => (
                    <CurrencyLabel data={row.amount} />
                )
            }
        ];
        return <Dialog
            open={this.state.open}
            fullWidth={true}
            maxWidth="lg"
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{this.state.title}</DialogTitle>
            <DialogContent>
                <MaterialTable
                    title=""
                    columns={cols}
                    data={query => this.loadTable(query)}
                    onRowClick={this.onSelect}
                    options={{
                        toolbar: true,
                        pageSize: 10
                    }}
                    components={{
                        Toolbar: props => (
                            <Searchbar {...props}
                                keyword={this.state.keyword}
                                filters={this.state.filters}
                                onKeywordChange={this.onKeywordChange}
                                onFiltersChange={this.onFiltersChange}
                                search={callback => this.onSearch = callback}
                                reset={this.onReset}
                                export={this.onExportCSV}
                            />
                        ),
                        Container: props => (
                            <div>
                                {props.children}
                            </div>
                        )
                    }}
                />
            </DialogContent>
        </Dialog>
    }
}

export default withStyles(styles)(RedemptionLogDialog);
