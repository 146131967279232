import React from 'react';
import { format } from 'date-fns';
import { Global } from '../Global';

export default class FormBase extends React.Component {
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleChangeUpper = event => {
        this.setState({ [event.target.id]: event.target.value.toUpperCase() });
    }
    handleChangeLower = event => {
        this.setState({ [event.target.id]: event.target.value.toLowerCase() });
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value });
    }
    handlePhoneChange = (id, value) => {
        this.setState({ [id]: value });
    }
    handlePeriodChange = (id, value) => {
        this.setState({ [id]: value });
    }
    handleCheckbox = event => {
        this.setState({ [event.target.id]: event.target.checked });
    }
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            //value = date.toJSON();
            // new Date(date.getFullYear(), date.getMonth(), date.getDate())
            value = format(date, Global.DateFormat);
        }
        this.setState({ [name]: value });
    }
    handleCheck = event => {
        this.setState({ [event.target.id]: event.target.checked });
    }
}