import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, LinearProgress, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { AddCircleOutline as AddIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Label, DateLabel } from '../../components/Label';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { Global } from '../../Global';
import { loadList, saveList, updateList } from '../../List';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    }
});

let ListKey = "quota_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        this.list = loadList(ListKey);
        this.state = {
            keyword: this.list.keyword,
            filters: this.list.filters
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Quotas" });
    }
    componentWillUnmount() {
        saveList(ListKey, this.list);
    }
    onSelect = (event, row) => {
        this.props.history.push("/quotas/" + row.quota_id);
    }
    loadTable = query => {
        updateList(this.list, query);
        return APIService.list(APIService.QUOTA,
            this.list.offset,
            this.list.size,
            this.list.order,
            this.list.orderDir,
            this.state.keyword)
            .then(result => {
                return {
                    data: result.data,
                    page: result.offset / this.list.size,
                    totalCount: result.total
                };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {
        if (!AuthService.userHasRole(['quota'])) return null;
        const { classes } = this.props;
        const cols = [
            { field: 'quota_id', title: 'ID' },
            { field: 'org_id', title: 'ORG', cellStyle: { color: 'inherit', width: 50 } },
            {
                field: 'type', title: 'TYPE', cellStyle: { width: 150 }, render: row => (
                    <Label data={Global.quotaTypes} value={row.type} />
                )
            },
            {
                field: 'auto_reset', title: 'AUTO RESET', sorting: false, cellStyle: { width: 150 }, render: row => (
                    <DateLabel date={row.auto_reset} format="dd/MM" />
                )
            },
            {
                field: 'qty', title: 'QTY', type: 'numeric', cellStyle: { width: 50 }, render: row => (
                    <Label data={row.qty} />
                )
            },
            {
                field: 'qty', title: 'USED', type: 'numeric', cellStyle: { width: 50 }, render: row => (
                    <Label data={row.used} />
                )
            },
            {
                field: 'qty', title: 'RESERVED', type: 'numeric', cellStyle: { width: 50 }, render: row => (
                    <Label data={row.reserved} />
                )
            },
            {
                field: 'used', title: 'QUOTA USAGE', sorting: false, cellStyle: { width: 150 }, render: row => (
                    <LinearProgress color="secondary" variant="determinate" value={(row.qty - row.used - row.reserved) / row.qty * 100} style={{ marginBottom: 8 }} />
                )
            }
        ];
        return (
            <Grid container spacing={8}>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    <Button variant="contained" color="primary" className={classes.button} component={Link} to="/quotas/new">
                        New <AddIcon className={classes.rightIcon} />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.list ? this.list.size : 10,
                            toolbar: false
                        }} />
                </Grid>
            </Grid >
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
