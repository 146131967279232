import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, IconButton, Toolbar, InputBase, InputAdornment, TextField, MenuItem, Typography, Divider, withStyles } from '@material-ui/core';
import {
    Sync as ResetIcon,
    Search as SearchIcon,
    SaveAlt as ExportIcon,
    Clear as ClearIcon,
    ListAlt as SummaryIcon,
    AssignmentTurnedIn as SettlementIcon,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Label, DateLabel, CurrencyLabel } from '../../components/Label';
import { Global } from '../../Global';
import { format, parse } from 'date-fns';
import { loadList, saveList, updateList } from '../../List';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';
import SummaryDialog from './SummaryDialog';
import RoleControl from '../../components/RoleControl';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    }
});

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        var filters = props.filters;
        if (filters) {
            filters.keyword = props.keyword;
        } else {
            filters = {
                keyword: props.keyword,
                begin: null,
                end: null,
                pay_method: "",
                dept_id: "",
                status: ""
            };
        }
        filters.depts = [{ value: "", label: "" }];
        
        this.state = filters;
    }
    componentDidMount() {
        this.props.search(this.search);
        NameService.departmentList((depts) => {
            this.setState({ depts: depts });
        })
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.filters !== this.props.filters) {
            var begin = nextProps.filters.begin;
            var end = nextProps.filters.end;
            var pay_method = nextProps.filters.pay_method;
            var dept_id = nextProps.filters.dept_id;
            var status = nextProps.filters.status;
            this.setState({ begin: begin, end: end, pay_method: pay_method, status: status, dept_id: dept_id });
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    getFilters() {
        var filters = { begin: this.state.begin, end: this.state.end, pay_method: this.state.pay_method, status: this.state.status, dept_id: this.state.dept_id };
        if (filters.begin > filters.end) {
            let begin = parse(filters.begin, Global.DateFormat, new Date());
            filters.end = format(begin, Global.DateFormat);
        }
        return filters;
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.DateFormat);
        }
        this.setState({ [name]: value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleClear = () => {
        this.setState({ keyword: "" });
    }
    search = () => {
        this.props.onKeywordChange(this.state.keyword);
        this.props.onSearchChanged(this.state.keyword);
    }
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    };
    render() {
        return <Toolbar style={{ paddingTop: 16 }}>
            <InputBase
                style={{ flex: 1, background: '#f0f0f0', padding: 4, borderRadius: 8 }}
                name="keyword"
                id="keyword"
                placeholder="Search order id, ref no"
                value={this.state.keyword}
                onChange={this.handleChange}
                onKeyPress={this.onKeyPress}
                onFocus={event => { event.target.select() }}
                autoFocus
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={this.handleClear}>
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <KeyboardDatePicker
                keyboard="true"
                format={Global.DisplayDateFormat}
                placeholder="DD/MM/YYYY"
                value={this.state.begin}
                onChange={this.handleSelectDate("begin")}
                label="FROM"
                inputVariant="outlined"
                margin="dense"
                animateYearScrolling={false}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ maxWidth: 180 }}
            />
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <KeyboardDatePicker
                keyboard="true"
                format={Global.DisplayDateFormat}
                placeholder="DD/MM/YYYY"
                value={this.state.end}
                onChange={this.handleSelectDate("end")}
                animateYearScrolling={false}
                label="TILL"
                inputVariant="outlined"
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ maxWidth: 180 }}
            />
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <TextField
                id="pay_method"
                name="pay_method"
                select
                value={this.state.pay_method}
                onChange={this.handleSelect("pay_method")}
                label="PAYMENT"
                variant="outlined"
                margin="dense"
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.paymentMethods.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <TextField
                id="status"
                label="STATUS"
                name="status"
                variant="outlined"
                margin="dense"
                select
                value={this.state.status}
                onChange={this.handleSelect("status")}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.orderStatuses.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <TextField
                id="dept_id"
                label="DEPARTMENT"
                name="dept_id"
                variant="outlined"
                margin="dense"
                select
                value={(this.state.depts.length < 1 || this.state.depts[0].value === "") ? "" : this.state.dept_id}
                onChange={this.handleSelect("dept_id")}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {this.state.depts.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <IconButton color="default" onClick={this.props.export} aria-label="Export">
                <ExportIcon />
            </IconButton>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <IconButton color="default" onClick={this.props.reset}>
                <ResetIcon />
            </IconButton>
        </Toolbar>;
    }
}

class OrderItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: props.items
        };
    }
    render() {
        return (
            <React.Fragment>
                {this.state.items.map(item => {
                    return (
                        <React.Fragment key={item.code}>
                            <Typography variant="body1" color="inherit">{this.props.code ? item.code : item.name}</Typography>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }
}

let ListKey = "order_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        let now = new Date();
        let date = format(now, Global.DateFormat);
        this.list = loadList(ListKey, { begin: date, end: date, pay_method: "", status: "paid", dept_id: "" });
        this.state = {
            keyword: this.list.keyword,
            filters: this.list.filters
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Orders" });
    }
    componentWillUnmount() {
        saveList(ListKey, this.list, this.state.keyword, this.state.filters);
    }
    onSelect = (event, row) => {
        this.props.history.push("/orders/" + row.order_id);
    }
    onKeywordChange = keyword => {
        if (keyword === this.state.keyword) return;
        this.setState({ keyword: keyword });
    }
    onFiltersChange = filters => {
        this.setState({ filters: filters }, () => {
            this.onSearch();
        });
    }
    onReset = () => {
        let now = new Date();
        let date = format(now, Global.DateFormat);
        this.setState({ keyword: "", filters: { begin: date, end: date, pay_method: "", status: "paid", dept_id: "" } }, () => {
            this.onSearch();
        });
    }
    exportCSV = () => {
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);

        let { begin, end, pay_method, status, dept_id } = this.state.filters;
        var url = new URL(Global.APIUrl + "/orders/export");
        var params = { jwt: token };
        if (begin) params["begin"] = begin;
        if (end) params["end"] = end;
        if (pay_method) params["pay_method"] = pay_method;
        if (status) params["status"] = status;
        if (dept_id) params["dept_id"] = dept_id;

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        window.open(url);
    }

    loadTable = query => {
        updateList(this.list, query);
        return APIService.search(APIService.ORDER,
            this.list.offset,
            this.list.size,
            this.list.order,
            this.list.orderDir,
            this.state.keyword,
            this.state.filters)
            .then(result => {
                // ES limitation 10k records
                var total = 1;
                if(result.total >= 10000){
                    total = 10000;
                }else{
                    total = result.total;
                }
                return {
                    data: result.data,
                    page: result.offset / this.list.size,
                    totalCount: total
                };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {
        if (!AuthService.userHasRole(['order'])) return null;
        const { classes } = this.props;
        const cols = [
            { field: 'order_id', title: 'ID', headerStyle: { paddingRight: 8 }, cellStyle: { color: 'inherit', width: 80, paddingRight: 8 } },
            {
                field: 'pay_txn_at', title: 'DATE TIME', cellStyle: { color: 'inherit', width: 200 }, render: row => (
                    <DateLabel date={row.pay_txn_at} format={Global.DisplayDateTimeFormat} />
                )
            },
            {
                field: 'plan_code', title: 'PLAN CODE', sorting: false, cellStyle: { color: 'inherit', width: 180 }, render: row => (
                    <OrderItems color="inherit" items={row.items} code={true} />
                )
            },
            {
                field: 'items', title: 'ITEM', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <OrderItems color="inherit" items={row.items} code={false} />
                )
            },
            {
                field: 'total', title: 'TOTAL', type: 'numeric', cellStyle: { color: 'inherit', width: 100 }, sorting: false, render: row => (
                    <CurrencyLabel data={row.total} />
                )
            },
            {
                field: 'pay_method', title: 'PAYMENT', cellStyle: { color: 'inherit', width: 100 }, render: row => (
                    <Label data={Global.paymentMethods} value={row.pay_method} />
                )
            },
            {
                field: 'pay_txn_no', title: 'REF NO', cellStyle: { color: 'inherit', width: 150 }, render: row => (
                    <Label data={row.pay_txn_no} />
                )
            }
        ];
        return (
            <Grid container spacing={8}>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    <RoleControl required={["settlement"]}>
                        <Button variant="contained" color="primary" className={classes.button} component={Link} to="/orders/settlement">
                            Settlement <SettlementIcon className={classes.rightIcon} />
                        </Button>
                    </RoleControl>
                    <Button variant="contained" color="primary" className={classes.button} onClick={() => this.showSummary(this.state.filters)}>
                        Summary <SummaryIcon className={classes.rightIcon} />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.list ? this.list.size : 10,
                            toolbar: true,
                            rowStyle: rowData => ({
                                color: Global.listColor(rowData.status)
                            })
                        }}
                        components={{
                            Toolbar: props => (
                                <Searchbar {...props}
                                    keyword={this.state.keyword}
                                    filters={this.state.filters}
                                    onKeywordChange={this.onKeywordChange}
                                    onFiltersChange={this.onFiltersChange}
                                    search={callback => this.onSearch = callback}
                                    export={this.exportCSV}
                                    reset={this.onReset}
                                />
                            )
                        }} />
                </Grid>
                <SummaryDialog onDialog={dialog => this.showSummary = dialog} alert={this.props.alert} />
            </Grid>
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
