export function loadList(name, filters = {}) {
    var list = {
        size: 10,
        keyword: "",
        filters: filters,
        init: true
    };
    let saved = sessionStorage.getItem(name);
    if (saved) {
        try {
            list = JSON.parse(saved);
            if (!list.size) list.size = 10;
            list.init = true;
        } catch (e) { }
    }
    return list;
}

export function saveList(name, list, keyword = "", filters = {}) {
    if (!list) return;
    list.keyword = keyword;
    list.filters = filters;
    sessionStorage.setItem(name, JSON.stringify(list));
}

export function updateList(list, query) {
    if (list.init) {
        delete list.init;
    } else {
        list.offset = query.page * query.pageSize;
    }
    list.size = query.pageSize;
    list.order = query.orderBy ? query.orderBy.field : null;
    list.orderDir = query.orderDirection;
}