import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, FormControl, Grid, Input, InputLabel } from '@material-ui/core';
import {
    Warning as WarningIcon
} from '@material-ui/icons';

export default class ConfirmDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            message: "",
            action: null,
            open: false,
            remark: false,
            remarked: "",
        };
    }
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (title, message, action, remark) => {
        this.setState({ open: true, title: title, message: message, remark: remark, action: action });
    }
    handleClickOpen = () => {
        this.setState({ open: true });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleConfirm = () => {
        this.setState({ open: false }, () => {
            this.state.action(this.state.remarked);
        });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }

    render() {
        const { title, message } = this.state;
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <WarningIcon size="large" />&nbsp;&nbsp;{title}
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{message}</DialogContentText>
                {this.state.remark && 
                    <FormControl fullWidth>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <InputLabel htmlFor="remarked">Remark</InputLabel>
                                <Input
                                    name="remarked"
                                    id="remarked"
                                    autoFocus
                                    value={this.state.remarked}
                                    onChange={this.handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleConfirm} color="primary">OK</Button>
                <Button onClick={this.handleClose} color="secondary">Cancel</Button>
            </DialogActions>
        </Dialog>
    }
}
