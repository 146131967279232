import React from 'react';
import { Grid, Dialog, DialogContent, DialogTitle, withStyles, Link} from '@material-ui/core';
// import {} from '@material-ui/icons';
import MaterialTable from 'material-table';
import { Label, DateLabel } from '../../components/Label';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import ChangeStatusDialog from './ChangeStatusDialog';

const styles = theme => ({
});


class CouponDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            m_id: 0,
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.showCoupons);
    }
    showCoupons = (m_id) => {
        this.setState({ open: true, m_id: m_id, isSubmitting: false });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    render() {
        const cols = [
            {
                field: 'id', title: 'ID', headerStyle: { paddingRight: 4 }, cellStyle: { width: 100, paddingRight: 4 }, render: row => (
                    <Label data={row.coupon_id} />
                )
            },
            {
                field: 'offer_id', title: 'OFFER ID', headerStyle: { padding: 4 }, cellStyle: { width: 100, padding: 4 }, render: row => (
                    <Label data={row.offer_id} />
                )
            },
            {
                field: 'promo_id', title: 'PROMO ID', headerStyle: { padding: 4 }, cellStyle: { width: 100, padding: 4 }, render: row => (
                    <Label data={row.promo_id} />
                )
            },
            {
                field: 'names.en', title: 'NAME (ENGLISH)', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={row} field="names" locale="en" />
                )
            },
            {
                field: 'issued_at', title: 'ISSUED AT', headerStyle: { padding: 4 }, cellStyle: { width: 200, padding: 4 }, render: row => (
                    <DateLabel date={row.issued_at} format={Global.DisplayDateTimeFormat} />
                )
            },
            {
                field: 'redeemed_at', title: 'REDEEMED AT', headerStyle: { padding: 4 }, cellStyle: { width: 200, padding: 4 }, render: row => (
                    <DateLabel date={row.redeemed_at} format={Global.DisplayDateTimeFormat} />
                )
            },
            {
                field: 'expires_at', title: 'EXPIRES AT', headerStyle: { padding: 4 }, cellStyle: { width: 150, padding: 4 }, render: row => (
                    <DateLabel date={row.expires_at} format={Global.DisplayDateFormat} />
                )
            },
            {
                field: 'status', title: 'STATUS', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4 }, render: row => (
                    <Link onClick={() => (row.status === "valid" || row.status === "locked" || row.status === "invalid") ? this.showChangeStatusDialog(this.state.m_id, row.coupon_id, row.status) : null} style={(row.status === "valid" || row.status === "locked" || row.status === "invalid") ? {color: "#0000FF", textDecoration: "none", cursor: "pointer"} : {color:  "#000", textDecoration: "none"}}><Label data={row.status} /></Link>
                )
            }
        ];
        return <Grid>
            <Dialog
                open={this.state.open}
                fullWidth={true}
                maxWidth="lg"
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Coupons</DialogTitle>
                <DialogContent>
                    <MaterialTable title="" columns={cols} data={query =>
                        APIService.list(APIService.MEMBER + "/" + this.state.m_id + "/coupons?sort=issued_at:desc,coupon_id",
                            query.page * query.pageSize,
                            query.pageSize,
                            query.orderBy ? query.orderBy.field : null,
                            query.orderDirection,
                            query.search,
                            this.state.query)
                            .then(result => {
                                console.log(result.data);
                                return { data: result.data, page: result.offset / query.pageSize, totalCount: result.total }
                            })
                            .catch(error => {
                                this.props.alert(APIService.errorMessage(error));
                                return { data: [], page: 0, totalCount: 0 };
                            })
                    } onRowClick={this.onSelect} options={{ pageSize: 10, toolbar: false }}
                        components={{
                            Container: props => (
                                <div>
                                    {props.children}
                                </div>
                            )
                        }}
                         />
                </DialogContent>
            </Dialog>
            <ChangeStatusDialog onDialog={dialog => this.showChangeStatusDialog = dialog} alert={this.props.alert} onClose={this.handleClose}/>
         </Grid>
    }
}

export default withStyles(styles)(CouponDialog);