import React from 'react';
import { LinearProgress, Typography, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import RenewalRatio from './RenewalRatio';
import { Label, CurrencyLabel } from '../../components/Label';
import { Global } from '../../Global';
import { NameService } from '../../services/Name';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class NewSelectPlan extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: []
        };
    }
    componentDidMount() {
        this.props.onNext(this.commit);

        NameService.getStats((result) => {
            this.setState({ stats: result });
        });
    }

    onSelect = (event, row) => {
        if (row.quota_id && (row.qty - row.used - row.reserved) <= 0) return;
        this.props.handler(row);
    }
    price = (plan) => {
        if (!plan.details || !plan.details.prices) return 0;
        return plan.details.prices.new;
    }
    render() {
        let { plans } = this.props;

        const cols = [
            { field: 'plan_code', title: 'PLAN', cellStyle: { width: 180, color: 'inherit' } },
            {
                field: 'name', title: 'NAME', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={row} field="names" locale="en" />
                )
            },
            {
                field: 'price', title: 'PRICE', type: 'numeric', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <CurrencyLabel data={this.price(row)} />
                )
            },
            {
                field: 'quota', title: 'QUOTA USAGE', render: row => (
                    <React.Fragment>
                        {row.qty &&
                            <React.Fragment>
                                <Typography variant="caption">FREE: {row.qty - row.used - row.reserved}</Typography>
                                <LinearProgress color="secondary" variant="determinate" value={(row.qty - row.used - row.reserved) / row.qty * 100} style={{ marginBottom: 8 }} />
                            </React.Fragment>
                        }
                    </React.Fragment>
                )
            },
            {
                field: 'renewal_ratio', title: 'RENEW : EXPIRED', sorting: false, type: 'numeric', cellStyle: { color: 'inherit', width: 180 }, render: row => (
                    <RenewalRatio planCode={row.plan_code} planGroupCode={row.plan_group_code} stats={this.state.stats}/>
                )
            },
            { field: 'org_id', title: 'ORG',  cellStyle: { width: 100, color: 'inherit' } }
        ];

        return (
            <MaterialTable title="SELECT SUBSCRIPTION PLAN"
                columns={cols}
                data={plans}
                onRowClick={this.onSelect}
                options={{
                    pageSize: 10,
                    toolbar: true,
                    rowStyle: rowData => ({
                        color: (rowData.quota_id && (rowData.qty - rowData.used - rowData.reserved) <= 0) ? Global.colors.inactive : '#212121'
                    })
                 }} />
        );
    }
}

export default withStyles(styles)(NewSelectPlan);
