import React from 'react';
import { Global } from '../Global';
import { AuthService } from '../services/Auth';
import { DateLabel } from '../components/Label';
import Username from '../components/Username';
import { Link } from 'react-router-dom';

export default class LastUpdated extends React.Component {
    constructor(props) {
        super(props);

        let state = {
            created_at: this.props.created_at,
            created_by: this.props.created_by,
            modified_at: this.props.modified_at,
            modified_by: this.props.modified_by,
        };
        this.state = state;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.created_at !== this.props.created_at ||
            nextProps.created_by !== this.props.created_by ||
            nextProps.modified_at !== this.props.modified_at ||
            nextProps.modified_by !== this.props.modified_by) {
            this.setState({
                created_at: nextProps.created_at,
                created_by: nextProps.created_by,
                modified_at: nextProps.modified_at,
                modified_by: nextProps.modified_by,
            });
        }
    }
    render() {
        let { created_at, created_by, modified_at, modified_by } = this.state;

        let at = modified_at ? modified_at : created_at;
        let by = modified_by ? modified_by : created_by;
        if (!at) return null;

        return <React.Fragment>
            Last modified at <DateLabel date={at} format={Global.DisplayDateTimeFormat} />
            {by &&
                <React.Fragment>
                    &nbsp;by&nbsp;
                    {(AuthService.userHasRole(['user'])) ? (
                        <Link to={"/users/" + by} style={{ textDecoration: 'none' }}><Username user={by} /></Link>
                    ) : (
                            <Username user={by} />
                        )}
                </React.Fragment>
            }
        </React.Fragment>;
    }
};
