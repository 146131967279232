import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, withStyles } from '@material-ui/core';
import {
    Close as CloseIcon,
} from '@material-ui/icons';
import Source from '../../components/Source';
import { Label, DateLabel, CurrencyLabel } from '../../components/Label';
import Username from '../../components/Username';
import LastUpdated from '../../components/LastUpdated';
import Status from '../../components/Status';
import ConfirmDialog from '../../components/ConfirmDialog';
import ChangePaymentDialog from './ChangePaymentDialog';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import RoleControl from '../../components/RoleControl';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class DetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            obj: {},
            isSubmitting: false
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Order" });
    }
    componentDidMount() {
        this.load();
    }
    load = () => {
        let id = this.props.match.params.id;
        APIService.get(APIService.ORDER, id)
            .then(obj => {
                this.setState({ obj: obj });
            })
            .catch(error => {
                this.props.history.goBack();
            })
    }
    itemLinkBase(item) {
        if (item.type === "plan") {
            return "/plans/" + item.id;
        }
        return "";
    }
    handleReceipt = () => {
        let id = this.props.match.params.id;
        let user = AuthService.currentUser();
        if (!user) return null;
        var token = user.token;
        token = encodeURIComponent(token);
        let url = Global.APIUrl + "/orders/" + id + "/receipt?jwt=" + token;
        window.open(url);
    }
    handleStatus = (activity, status) => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to " + activity + " order.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            var obj = {
                value: status,
            }
            APIService.call("PUT", APIService.ORDER + "/" + id + "/statuses", obj)
                .then(() => {
                    this.setState({ isSubmitting: false }, () => { this.load(); });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                })
        })
    }
    deletePayment = (order_id) => {
        this.confirm("Confirmation", "Please confirm to delete transaction order " + order_id + " permanently. Please note that deleted transaction order cannot be recovered!", (remark) => {
                this.setState({ isSubmitting: true });
                var obj = {
                    value: "voided",
                }
                if(remark.trim() !== null && remark.trim() !== ""){
                    obj['remark'] = remark;
                }
                APIService.call("PUT", APIService.ORDER + "/" + order_id + "/statuses", obj)
                .then(obj => {
                    this.props.history.go(-3);
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                });
        }, true)
    }
    render() {
        if (!AuthService.userHasRole(['order'])) return null;
        const { classes } = this.props;
        const { obj } = this.state;
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Grid container m={24}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h5">Order No: {obj.order_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">DATE</Typography>
                            <Typography variant="body1" color="textPrimary"><DateLabel date={obj.pay_txn_at} format={Global.DisplayDateTimeFormat} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">ORGANISATION</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.org_id} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">DEPARTMENT</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.dept_id} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">MEMBER ID</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Link to={"/members/" + obj.m_id} style={{ textDecoration: 'none' }}>
                                    <Label data={obj.m_id} />
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">HANDLED BY</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Link to={"/users/" + obj.created_by} style={{ textDecoration: 'none' }}>
                                    <Username user={obj.created_by} />
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="caption" color="textSecondary">SETTLED BY</Typography>
                            <Typography variant="body1" color="textPrimary">
                                <Link to={"/users/" + obj.settled_by} style={{ textDecoration: 'none' }}>
                                    <Username user={obj.settled_by} />
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">REMARK</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.remark} /></Typography>
                            </div>
                        </Grid>
                        {obj.items &&
                            <Grid item xs={12} sm={12}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ITEM ID</TableCell>
                                            <TableCell>ITEM CODE</TableCell>
                                            <TableCell>ITEM TYPE</TableCell>
                                            <TableCell>DESCRIPTION</TableCell>
                                            <TableCell align="right">QTY</TableCell>
                                            <TableCell align="right">UNIT PRICE ({obj.ccy})</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {obj.items.map(item => (
                                            <TableRow key={item.code}>
                                                <TableCell>
                                                    <Link to={this.itemLinkBase(item)} style={{ textDecoration: 'none' }}>
                                                        <Typography variant="body1">{item.id}</Typography>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link to={this.itemLinkBase(item)} style={{ textDecoration: 'none' }}>
                                                        <Typography variant="body1">{item.code}</Typography>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body1">{item.type}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body1">{item.name}</Typography>
                                                </TableCell>
                                                <TableCell align="right">{item.qty}</TableCell>
                                                <TableCell align="right">
                                                    <CurrencyLabel data={item.price} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell rowSpan={3} colSpan={4} />
                                            <TableCell align="right"><Typography color="textSecondary">TOTAL ({obj.ccy})</Typography></TableCell>
                                            <TableCell align="right">
                                                <Typography variant="body2">
                                                    <CurrencyLabel data={obj.total} />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"><Typography color="textSecondary">PAYMENT METHOD</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="body2"><Label data={Global.paymentMethods} value={obj.pay_method} /></Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"><Typography color="textSecondary">REF NO</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="body2"><Label data={obj.pay_txn_no} /></Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12}>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Status label={obj.status} />
                        </Grid>
                        <Grid item xs={12} sm={8} align="right">
                            <Typography variant="caption" color="textSecondary">
                                <LastUpdated created_at={obj.created_at} created_by={obj.created_by} modified_at={obj.modified_at} modified_by={obj.modified_by} />
                            </Typography><Source data={obj} />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    {/* {obj.status === "pending" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("void", "voided")} disabled={this.state.isSubmitting}>Void</Button>
                    } */}
                    <RoleControl required={["offer_point_adj"]}>
                         <Button variant="contained" className={classes.button} onClick={() => { this.deletePayment(obj.order_id) }}>Void Order</Button>
                     </RoleControl>
                    <Button variant="contained" className={classes.button} onClick={() => { this.updatePaymentDialog(obj.order_id, obj.pay_method) }}>Change Payment Method</Button>
                    <Button variant="contained" color="primary" className={classes.button} onClick={this.handleReceipt}>RECEIPT</Button>
                </Grid>
                <ChangePaymentDialog onDialog={dialog => this.updatePaymentDialog = dialog} onRefresh={this.load} alert={this.props.alert} />
                <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
            </React.Fragment>
        );
    }
}

DetailPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DetailPage);
