import React from 'react';
import { FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, FormHelperText, Button, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { parse, format } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { Validator } from '../../helpers/Validator';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2)
    }
});

class BanDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            m_id: 0,
            remark: "",
            expires_at: null,
            errors: {},
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (member) => {
        this.setState({ open: true, m_id: parseInt(member.m_id), remark: "", errors: {}, isSubmitting: false });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.DateFormat);
        }
        this.setState({ [name]: value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleSubmit = event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        if (!this.validateForm()) return;

        this.setState({
            isSubmitting: true
        });

        var req = {
            expires_at: this.state.expires_at,
            remark: this.state.remark
        };
        APIService.call("POST", APIService.MEMBER + "/" + this.state.m_id + "/ban", req, { debug: "t" })
            .then(result => {
                this.setState({ isSubmitting: false, open: false }, () => {
                    this.props.alert("Member banned", "success");
                    this.props.onRefresh();
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};

        if (this.state.expires_at) {
            let now = Date.now();
            let expires_at = parse(this.state.expires_at, Global.DisplayDateFormat, new Date());
            if (expires_at.getTime() <= now) {
                errors["expires_at"] = Global.errors["ErrorInvalidValue"];
            }
        }

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        const { classes } = this.props;
        const { errors } = this.state;
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <form onSubmit={this.handleSubmit}>

                <DialogTitle id="form-dialog-title">Ban Member</DialogTitle>
                <DialogContent>
                    <DialogContentText>Banning member disables member account until expiration or unban manually. Banned member cannot login or register new account using the same mobile number or e-mail address.</DialogContentText>
                    <FormControl required fullWidth className={classes.formControl}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <DatePicker
                                    keyboard="true"
                                    label="EXPIRATION DATE"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    mask={value =>
                                        value ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/] : []
                                    }
                                    value={this.state.expires_at}
                                    onChange={this.handleSelectDate("expires_at")}
                                    animateYearScrolling={false}
                                    fullWidth
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["expires_at"] && <FormHelperText error={true}>{errors["expires_at"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" disabled={this.state.isSubmitting}>OK</Button>
                    <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    }
}

export default withStyles(styles)(BanDialog);