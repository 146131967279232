import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Divider, Button, Typography, withStyles } from '@material-ui/core';
import {
    Edit as EditIcon,
    Close as CloseIcon,
    History as HistoryIcon,
    CameraAlt as UploadIcon,
} from '@material-ui/icons';
import Source from '../../components/Source';
import LastUpdated from '../../components/LastUpdated';
import { Label, DateLabel, PeriodLabel, CheckLabel, TextLabel } from '../../components/Label';
import Status from '../../components/Status';
import ConfirmDialog from '../../components/ConfirmDialog';
import FilterOptions from '../../components/FilterOptions';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';
import { parse } from 'date-fns';
import Carousel from '../../components/Carousel';
import OfferLogDialog from './OfferLogDialog';
import UploadDialog from '../../components/UploadDialog';
import RedemptionLogDialog from '../trans/RedemptionLogDialog';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class OfferImage extends React.Component {
    render() {
        let { offer, type } = this.props;
        if (!offer.details || !offer.details.images || !offer.details.images[type]) return null;
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);
        let url = Global.getImage(offer.details.images, type, "jwt=" + token);
        return (
            <img alt={type} src={url} style={{ width: 'auto', maxHeight: 64 }} onClick={this.props.onClick} />
        )
    }
}

class DetailCouponPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {},
            plans: [],
            plan_groups: [],
            isSubmitting: false
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Campaign Offer" });
    }
    componentDidMount() {
        this.load();
        NameService.planList((plans) => {
            var plan_groups = [];
            for (var i = 0; i < plans.length; i++) {
                let plan = plans[i];
                if (!plan.plan_group_code) continue;
                var exist = false;
                for (var j = 0; j < plan_groups.length; j++) {
                    let plan_group = plan_groups[j];
                    if (plan_group.value === plan.plan_group_code) {
                        exist = true;
                        break;
                    }
                }
                if (!exist) plan_groups.push({ value: plan.plan_group_code, label: plan.plan_group_code });
            }
            this.setState({ plans: plans, plan_groups: plan_groups });
        })
    }
    load = () => {
        let id = this.props.match.params.id;
        APIService.get(APIService.OFFER, id)
            .then(obj => {
                this.setState({ obj: obj })
            })
            .catch(error => {
                this.props.history.goBack();
            })
    }
    uploadThumbnail = () => {
        this.showUploadImageDialog("thumbnail");
    }
    uploadFeatureImage = () => {
        this.showUploadImageDialog("banner");
    }
    showImage = (type) => {
        const { obj } = this.state;
        if (!obj || !obj.details || !obj.details.images) return;

        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);
        var images = [];
        let url = Global.getImage(obj.details.images, type, "jwt=" + token)
        images.push({ name: type, url: url })
        this.showImagesDialog(images);
    }
    handleLog = () => {
        let id = this.props.match.params.id;
        this.showLog(id);
    }
    handleRedemptionlog = () => {
        let id = this.props.match.params.id;
        let { obj } = this.state;
        var begin = "";
        if (obj.validity_begin) begin = obj.validity_begin;

        this.showRedemptionLog("Redemption Log", id, begin);
    }
    handlePromoCodeList = () => {
        let id = this.props.match.params.id;
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);
        var url = new URL(Global.APIUrl + "/offers/" + id + "/details/conditions/multi-redemption-codes/codes/export");
        var params = { jwt: token };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        window.open(url);
    }
    canIssue = (requiredRecipients) => {
        const { obj } = this.state;
//        if (requiredRecipients && !obj.recipients) return false;
        let now = new Date();
        if (obj.validity_begin) {
            let begin_time = parse(obj.validity_begin, Global.DateFormat, now);
            if (begin_time.getTime() > now.getTime()) return false
        }
        if (obj.validity_end) {
            let end_time = parse(obj.validity_end, Global.DateFormat, now);
            if (end_time.getTime() < now.getTime()) return false
        }
        return true;
    }
    handleStatus = (activity, status) => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to " + activity + " offer.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("GET", APIService.OFFER + "/" + id + "/status/" + status)
                .then(() => {
                    this.setState({ isSubmitting: false }, () => { this.load(); });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                })
        })
    }
    handleDistribution = () => {
        this.showUploadDialog();
    }
    distribute = (result) => {
        console.log(result);
        if (this.state.isSubmitting) return;
        if (result.count <= 0) {
            this.props.alert("No member found in the distribution file");
            return;
        } else if (result.limit !== 0) {
            if (result.count > result.limit) {
                this.props.alert("Insufficient quota, required " + result.count + ", remaining " + result.limit + " available");
                return;
            }
        }
        this.confirm("Confirmation", "Please confirm to issue coupons. An estimate of the number of members found in the distribution file is " + result.count + ". Please note that issuing coupon via distribution list ignore \"Applicable To\" scope filter.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("GET", APIService.OFFER + "/" + id + "/issue_dist/" + result.id)
                .then(() => {
                    this.setState({ isSubmitting: false });
                    this.props.alert("Request submitted successfully", "success");
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                })
        })
    }
    handleIssue = () => {
        if (this.state.isSubmitting) return;
        let id = this.props.match.params.id;
        APIService.call("GET", APIService.OFFER + "/" + id + "/estimate_members")
            .then(obj => {
                if (obj.total === 0) {
                    this.setState({ isSubmitting: false });
                    this.props.alert("No members were found");
                } else {
                    this.issue(id, obj.total);
                }
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            })
    }
    issue = (id, total) => {
        this.confirm("Confirmation", "Please confirm to issue coupons. An estimate of the number of members is " + total + ".", () => {
            this.setState({ isSubmitting: true });
            APIService.call("GET", APIService.OFFER + "/" + id + "/issue")
                .then(obj => {
                    this.setState({ isSubmitting: false });
                    this.props.alert("Request submitted successfully", "success");
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                })
        })
    }
    render() {
        if (!AuthService.userHasRole(['offer_coupon'])) return null;
        const { classes } = this.props;
        const { obj } = this.state;
        obj.promo_code = "";
        if(obj.details && obj.details.conditions){
            if(obj.nature === "movie" && obj.method === "redemption-code" ){
                obj.promo_code = (obj.details.conditions.multi_redemption_code) ? "true" : "false";
            }
        }
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Grid container m={24}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h4"><Label data={obj} field="names" locale="en" /></Typography>
                            <Typography variant="body1" color="textSecondary">ID: {obj.offer_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <IconButton component={Link} to={"/offers/coupon/" + this.props.match.params.id + "/edit"}><EditIcon /></IconButton>
                            {obj.method === "push" &&
                                <IconButton onClick={this.handleLog} ><HistoryIcon /></IconButton>
                            }
                            <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">ORGANISATION</Typography>
                            <Typography variant="body1" color="textPrimary">{(obj.org_id) ? obj.org_id : "ALL"}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">NAME (ENGLISH)</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="names" locale="en" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">NAME (CHINESE)</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="names" locale="zh" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.desc} /></Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">PROMO ID</Typography>
                            <Typography variant="body1" color="textPrimary">{obj.promo_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">OFFER NATURE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.offerNatures} value={obj.nature} /></Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">OFFER TYPE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.offerTypes} value={obj.type} /></Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">EVENT TYPE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.offerEventTypes} value={obj.event_type} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">DELIVERY METHOD</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.couponMethods} value={obj.method} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">VALIDITY</Typography>
                            <Typography variant="body1" color="textPrimary"><DateLabel begin={obj.validity_begin} end={obj.validity_end} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">PROMO CODE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="redemption_code" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">MANUAL REDEEM CODE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="redemption_pass" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">COUPON QTY</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="coupon_qty" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">QUOTA</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="quota_id" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">PROMO CODE NATURE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.promoCodeNature} value={obj.promo_code} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Typography variant="caption" color="textSecondary">REMARK</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.remark} /></Typography>
                            </div>
                        </Grid>
                        {obj.details &&
                            <React.Fragment>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary">COUPON DETAILS</Typography>
                                    <Divider light={true} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">THUMBNAIL <IconButton size="small" onClick={this.uploadThumbnail} ><UploadIcon /></IconButton></Typography>
                                    <OfferImage offer={obj} type="thumbnail" onClick={() => this.showImage("thumbnail")} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">FEATURE IMAGE <IconButton size="small" onClick={this.uploadFeatureImage} ><UploadIcon /></IconButton></Typography>
                                    <OfferImage offer={obj} type="banner" onClick={() => this.showImage("banner")} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container m={24}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body1" color="textPrimary"><TextLabel data="(1416 x 372 px)"  /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" color="textSecondary">DESCRIPTION (ENGLISH)</Typography>
                                            <Typography variant="body1" color="textPrimary"><TextLabel data={obj} field="details.desc.en" /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" color="textSecondary">INSTRUCTION (ENGLISH)</Typography>
                                            <Typography variant="body1" color="textPrimary"><TextLabel data={obj} field="details.usage.en" /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" color="textSecondary">TERMS &amp; CONDITIONS (ENGLISH)</Typography>
                                            <Typography variant="body1" color="textPrimary"><TextLabel data={obj} field="details.tnc.en" /></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Grid container m={24}>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="body1" color="textPrimary"><TextLabel data="(280 x 131 px)"  /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" color="textSecondary">DESCRIPTION (CHINESE)</Typography>
                                            <Typography variant="body1" color="textPrimary"><TextLabel data={obj} field="details.desc.zh" /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" color="textSecondary">INSTRUCTION (CHINESE)</Typography>
                                            <Typography variant="body1" color="textPrimary"><TextLabel data={obj} field="details.usage.zh" /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12}>
                                            <Typography variant="caption" color="textSecondary">TERMS &amp; CONDITIONS (CHINESE)</Typography>
                                            <Typography variant="body1" color="textPrimary"><TextLabel data={obj} field="details.tnc.zh" /></Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" color="textSecondary">COUPON VALIDITY</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.couponValidityTypes} value={obj.coupon_validity_type} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" color="textSecondary">PERIOD</Typography>
                            <Typography variant="body1" color="textPrimary"><PeriodLabel value={obj.coupon_validity_period} /></Typography>
                        </Grid>
                        {obj.details && obj.details.conditions &&
                            <React.Fragment>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary">RULES</Typography>
                                    <Divider light={true} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CheckLabel data={obj} field="details.conditions.non-exclusive" label={Global.getValueLabel(Global.offerRules, "non-exclusive")} />
                                    <CheckLabel data={obj} field="details.attributes.buy_one_get_one" label={Global.getValueLabel(Global.offerRules, "buy_one_get_one")} />
                                    <CheckLabel data={obj} field="details.conditions.multi_ticket" label={Global.getValueLabel(Global.offerRules, "multi_ticket")} />
                                    <CheckLabel data={obj} field="details.conditions.dynamic_redemption_code" label={Global.getValueLabel(Global.offerRules, "dynamic_redemption_code")} />
                                    {obj.details.conditions.points_eligible &&
                                        <React.Fragment>
                                            {/* <CheckLabel data={obj} field="details.conditions.points_eligible" label={Global.getValueLabel(Global.offerRules, "points_eligible")} /> */}
                                            <Typography variant="caption" color="textSecondary">Points required</Typography>
                                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.conditions.points_required" /></Typography>
                                        </React.Fragment>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">Redeem limit per transaction</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.conditions.coupon_per_trans" /></Typography>
                                    <div style={{ height: 16 }} />
                                    <Typography variant="caption" color="textSecondary">Redeem limit per account</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={obj} field="details.conditions.coupon_per_member" /></Typography>
                                </Grid>
                            </React.Fragment>
                        }
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" color="textSecondary">APPLICABLE TO</Typography>
                            <Divider light={true} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" color="textSecondary">SUBSCRIPTION</Typography>
                            {obj.recipients && obj.recipients.membership &&
                                <FilterOptions id="recipients.membership" options={this.state.plans} selected={obj.recipients.membership} readonly />
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" color="textSecondary">SUBSCRIPTION GROUP</Typography>
                            {obj.recipients && obj.recipients["membership-group"] &&
                                <FilterOptions id="recipients.membership-group" options={this.state.plan_groups} selected={obj.recipients["membership-group"]} readonly />
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Status label={obj.status} />
                        </Grid>
                        <Grid item xs={12} sm={8} align="right">
                            <Typography variant="caption" color="textSecondary">
                                <LastUpdated created_at={obj.created_at} created_by={obj.created_by} modified_at={obj.modified_at} modified_by={obj.modified_by} />
                            </Typography><Source data={obj} />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    {obj.status === "inactive" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("activate", "active")} disabled={this.state.isSubmitting}>Activate</Button>
                    }
                    {obj.status === "active" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("deactivate", "inactive")} disabled={this.state.isSubmitting}>Deactivate</Button>
                    }
                    {obj.promo_code.toString() === "true" && 
                        <Button variant="contained" className={classes.button} onClick={this.handlePromoCodeList}>Promo code list</Button>
                    }
                    <Button variant="contained" className={classes.button} onClick={this.handleRedemptionlog} disabled={this.state.isSubmitting}>Redemption Log</Button>
                    {obj.method === "push" && obj.status === "active" &&
                        <React.Fragment>
                            <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={this.handleDistribution} disabled={this.state.isSubmitting || !this.canIssue(true)}>DISTRIBUTION LIST</Button>
                            <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={this.handleIssue} disabled={this.state.isSubmitting || !this.canIssue(false)}>ISSUE</Button>
                        </React.Fragment>
                    }
                </Grid>
                <OfferLogDialog onShowLog={dialog => this.showLog = dialog} alert={this.props.alert} />
                <RedemptionLogDialog onShowLog={dialog => this.showRedemptionLog = dialog} history={this.props.history} alert={this.props.alert} />
                <UploadDialog onDialog={dialog => this.showUploadImageDialog = dialog} url={"offers/" + obj.offer_id + "/images/"} onRefresh={this.load} alert={this.props.alert} />
                <UploadDialog onDialog={dialog => this.showUploadDialog = dialog}
                    url={"offers/" + obj.offer_id + "/import"}
                    acceptedFiles={[]}
                    onRefresh={this.distribute}
                    alert={this.props.alert}
                    description="Please provide the distribution list file (CSV format) exported from the members list. The CSV must contain member Id column." />
                <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
                <Carousel onDialog={dialog => this.showImagesDialog = dialog} />
            </React.Fragment>
        );
    }
}

DetailCouponPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailCouponPage);
