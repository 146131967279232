import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, Table, TableBody, TableCell, TableHead, TableRow, Button, withStyles, Typography } from '@material-ui/core';
import { Label, DateLabel, CurrencyLabel } from '../../components/Label';
import { APIService } from '../../services/API';
import { Global } from '../../Global';

const styles = theme => ({
});

class SummaryDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            total: "0",
            count: 0,
            subtotal: {},
            filters: {},
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.showSummary);
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    showSummary = (filters) => {
        this.setState({ open: true, rows: [], total: "0", count: 0, subtotal: {}, filters: filters, isSubmitting: false });
        var req = filters;
        APIService.call("POST", APIService.ORDER + "/summary", req)
            .then(result => {
                this.setState({ rows: result.data, total: result.total, count: result.count, subtotal: result.subtotal_methods });
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
    }
    render() {
        const { classes } = this.props;
        let { begin, end } = this.state.filters;
        return <Dialog
            open={this.state.open}
            fullWidth={true}
            maxWidth="lg"
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Order Summary <DateLabel begin={begin} end={end}/></DialogTitle>
            <DialogContent>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>PLAN CODE</TableCell>
                            <TableCell align="right" style={{width: 50}} >COUNT</TableCell>
                            {Global.paymentMethods.map(row => (
                                <TableCell align="center" key={row.value}>{row.label.toUpperCase()}</TableCell>
                            ))}
                            <TableCell align="right">SUBTOTAL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.rows.map(row => (
                            <React.Fragment>
                                <TableRow key={row.plan_code}>
                                    <TableCell><Label data={row.plan_code} /></TableCell>
                                    <TableCell style={{ width: 50 }} align="right">{row.count}</TableCell>
                                    {Global.paymentMethods.map(method => (
                                        <TableCell align="right" key={method.value}>
                                            <CurrencyLabel data={row.amount_methods[method.value]} />
                                        </TableCell>
                                    ))}
                                    <TableCell style={{ width: 150 }} align="right">
                                        <Typography variant="body2">
                                            <CurrencyLabel data={row.subtotal} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                        <TableRow>
                            <TableCell>TOTAL</TableCell>
                            <TableCell align="right" style={{width: 50}}>
                                <Typography variant="body2">
                                    {this.state.count}
                                </Typography>
                            </TableCell>
                            {Global.paymentMethods.map(method => (
                                <TableCell align="right" key={method.value}>
                                    <Typography variant="body2">
                                        <CurrencyLabel data={this.state.subtotal[method.value]} />
                                    </Typography>
                                </TableCell>
                            ))}
                            <TableCell align="right">
                                <Typography variant="h6">
                                    <CurrencyLabel data={this.state.total} />
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Close</Button>
            </DialogActions>
        </Dialog>
    }
}

export default withStyles(styles)(SummaryDialog);