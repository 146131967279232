import React from 'react';
import { AuthService } from '../services/Auth';

export default class RoleControl extends React.Component {
    render() {
        let { required } = this.props;
        if (AuthService.userHasRole(required)) return this.props.children;
        return null;
    }
};
