import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, FormControl, FormLabel, FormControlLabel, FormHelperText, RadioGroup, Radio, TextField, Checkbox, MenuItem, Typography, withStyles, Divider } from '@material-ui/core';
import {
    Warning as WarningIcon
} from '@material-ui/icons';
import { KeyboardDatePicker  } from '@material-ui/pickers';
import FormBase from '../../components/FormBase';
import PhoneInput from '../../components/PhoneInput';
import DistrictInput from '../../components/DistrictInput';
import DropDownCombo from '../../components/DropDownCombo';
// import NumberFormat from 'react-number-format';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';
import { E164 } from '../../helpers/E164';
import { APIService } from '../../services/API';
import { format } from 'date-fns';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class NewRegisterForm extends FormBase {
    constructor(props) {
        super(props);

        this.obj = this.props.member;
        this.obj.org_id = this.props.plan.org_id;

        this.state = {
            errors: {},
            isSubmitting: false
        };
        Validator.flattenNested(this.obj, this.state);
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onNext(this.commit);        
        if (this.props.copy_m_id) {
            APIService.get(APIService.MEMBER, this.props.copy_m_id)
                .then(obj => {
                    // discard properties
                    delete obj.org_id;
                    delete obj.m_id;
                    delete obj.status;
                    delete obj.created_by;
                    delete obj.created_at;
                    delete obj.modified_by;
                    delete obj.modified_at;
                    delete obj.memberships;
                    delete obj.credentials;
                    var map = {};
                    Validator.flattenNested(obj, map);
                    this.setState(map);
                })
                .catch(error => {
                    this.props.alert(APIService.errorMessage(error));
                });
        }
    }
    commit = () => {
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) {
            return;
        }

        this.setState({ isSubmitting: true });
        var req = {
            org_id: this.obj.org_id,
            email: this.obj.profile.contacts.email,
            msisdn: this.obj.profile.contacts.msisdn
        };        
        APIService.call("POST", APIService.MEMBER + "/verify_ids", req)
        .then(resp => {
            this.createOrder();
        })
        .catch(error => {
            var errors = {};
            // var alertMessage = APIService.errorMessage(error);
            if (error.reason) {
                if (error.reason === "ErrorEmailAlreadyExists" || error.reason === "ErrorEmailBlacklisted") {
                    errors["profile.contacts.email"] = Global.errors[error.reason];
                } else if (error.reason === "ErrorMSISDNAlreadyExists" || error.reason === "ErrorMSISDNBlacklisted") {
                    errors["profile.contacts.msisdn"] = Global.errors[error.reason];
                }
            }
            this.setState({ errors: errors, isSubmitting: false });
        });
    }
    price = (plan) => {
        if (!plan.details || !plan.details.prices) return 0;
        return plan.details.prices.new;
    }
    createOrder() {
        let plan = this.props.plan;
        let names = plan.names;
        let name = names["en"] + " Subscription";
        let price = this.price(plan);
        let ccy = "HKD";
        if (plan.details) {
            ccy = plan.details.ccy;
        }

        let req = {
            org_id: this.obj.org_id,
            items: [{ id: plan.plan_id, type: "plan", code: plan.plan_code, name: name, price: price, ccy: ccy, qty: 1, quota_id: plan.quota_id }],
            subtotal: price,
            total: price,
            ccy: ccy
        };
        APIService.create(APIService.ORDER, req, { debug: 't' })
            .then(order => {
                this.setState({ isSubmitting: false }, () => {
                    this.props.handler(this.obj, order);
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};
        if (this.obj.profile.names.en.full.length === 0) {
            errors["profile.names.en.full"] = Global.errors["ErrorRequired"];
        }
        if (this.obj.profile.contacts.email.length === 0) {
            errors["profile.contacts.email"] = Global.errors["ErrorRequired"];
        } else {
            this.obj.profile.contacts.email = Validator.verifyString(this.obj.profile.contacts.email, value => {
                if (!Validator.isValidEmail(value)) {
                    errors["profile.contacts.email"] = Global.errors["ErrorInvalidEmail"];
                }
            });
        }

        if (this.obj.profile.dob) {
            this.obj.profile.dob = Validator.verifyString(this.obj.profile.dob, value => {
                if (!Validator.isValidDate(value)) {
                    errors["profile.dob"] = Global.errors["ErrorInvalidValue"];
                }
            });
        }

        var tel = E164.parse(this.obj.profile.contacts.msisdn);
        if (tel.number.length === 0 && tel.cc.length > 0) this.obj.profile.contacts.msisdn = "";
        if (this.obj.profile.contacts.msisdn.length === 0) {
            errors["profile.contacts.msisdn"] = Global.errors["ErrorRequired"];
        } else {
            this.obj.profile.contacts.msisdn = Validator.verifyString(this.obj.profile.contacts.msisdn, value => {
                if (!Validator.isValidE164(value)) {
                    errors["profile.contacts.msisdn"] = Global.errors["ErrorInvalidPhoneNumber"];
                }
            });
        }
        tel = E164.parse(this.obj.profile.contacts.tel);
        if (tel.number.length === 0 && tel.cc.length > 0) this.obj.profile.contacts.tel = "";
        this.obj.profile.contacts.tel = Validator.verifyString(this.obj.profile.contacts.tel, value => {
            if (!Validator.isValidE164(value)) {
                errors["profile.contacts.tel"] = Global.errors["ErrorInvalidPhoneNumber"];
            }
        });
        tel = E164.parse(this.obj.profile.contacts.fax);
        if (tel.number.length === 0 && tel.cc.length > 0) this.obj.profile.contacts.fax = "";
        this.obj.profile.contacts.fax = Validator.verifyString(this.obj.profile.contacts.fax, value => {
            if (!Validator.isValidE164(value)) {
                errors["profile.contacts.fax"] = Global.errors["ErrorInvalidPhoneNumber"];
            }
        });
        this.obj.survey["residence-area"] = Validator.verifyString(this.obj.survey["residence-area"], value => { });
        if (!this.obj.survey["residence-area"]) {
            errors["district"] = Global.errors["ErrorRequired"];
        }
        this.obj.survey["residence-place"] = Validator.verifyString(this.obj.survey["residence-place"], value => { });
        if (!this.obj.survey["residence-place"]) {
            errors["district"] = Global.errors["ErrorRequired"];
        }
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    handleDistrict = (area, district, subdistrict) => {
        this.setState({ "survey.residence-area": area, "survey.residence-place": district, "survey.residence-subdistrict": subdistrict });
    }
    handleOccupation = (option, others) => {
        this.setState({ "survey.occupation": option, "survey.occupation-others": others });
    }
    handleMMSource = (option, others) => {
        this.setState({ "survey.mm-source": option, "survey.mm-source-others": others });
    }
    handleSelectDate = name => (date, insertValue) => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.DateFormat);
        }else if(insertValue === "29/02"){
            var today = new Date();
            var getYear = today.getFullYear() - (today.getFullYear() % 4);
            value = getYear + "-02-29";
        }
        this.setState({ [name]: value });
    }
    render() {
        const { classes } = this.props;
        const { errors } = this.state;        
        return (
            <form className={classes.form}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4">Personal Information</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} align="right">
                                {!Validator.isEmpty(this.state.errors) &&
                                    <WarningIcon color="primary" />
                                }
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="org_id"
                                    name="org_id"
                                    label="ORGANISATION"
                                    value={this.props.plan.org_id}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="plan.plan_code"
                                    name="plan.plan_code"
                                    label="PLAN CODE"
                                    value={this.props.plan.plan_code}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="plan.name"
                                    name="plan.name"
                                    label="PLAN NAME"
                                    value={this.props.plan.names.en}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="profile.names.en.full"
                                    name="profile.names.en.full"
                                    label="FULL NAME (ENGLISH)"
                                    value={this.state["profile.names.en.full"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    required
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["profile.names.en.full"] && <FormHelperText error={true}>{errors["profile.names.en.full"]}</FormHelperText>}
                                <FormHelperText>Same name as it appears on the identification document</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="profile.names.zh.full"
                                    name="profile.names.zh.full"
                                    label="FULL NAME (CHINESE)"
                                    value={this.state["profile.names.zh.full"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["profile.names.zh.full"] && <FormHelperText error={true}>{errors["profile.names.zh.full"]}</FormHelperText>}
                                <FormHelperText>Same name as it appears on the identification document</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend"><Typography variant="caption">GENDER</Typography></FormLabel>
                                    <RadioGroup
                                        id="profile.gender"
                                        name="profile.gender"
                                        className={classes.group}
                                        value={this.state["profile.gender"]}
                                        onChange={this.handleSelect("profile.gender")}
                                        row={true}
                                    >
                                        {Global.genders.map(option => (
                                            <FormControlLabel key={option.value} value={option.value} control={<Radio color="primary" />} label={option.label} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker 
                                    keyboard="true"
                                    label="DATE OF BIRTH"
                                    format={Global.DisplayDateMonthFormat}
                                    placeholder="DD/MM"
                                    value={this.state["profile.dob"]}
                                    onChange={this.handleSelectDate("profile.dob")}
                                    clearable
                                    animateYearScrolling={false}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["profile.dob"] && <FormHelperText error={true}>{errors["profile.dob"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}></Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="profile.birthYearRange"
                                    name="profile.birthYearRange"
                                    select
                                    label="YEAR RANGE"
                                    value={this.state["profile.birthYearRange"]}
                                    onChange={this.handleSelect("profile.birthYearRange")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.getBirthYearRange(new Date().getFullYear()).map(range => (
                                        <MenuItem key={range.value} value={range.value}>
                                            {range.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">CONTACTS</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container m={24}>
                                    <Grid item xs={12} sm={12}>
                                        <PhoneInput
                                            label="MOBILE"
                                            required
                                            id="profile.contacts.msisdn"
                                            name="profile.contacts.msisdn"
                                            value={this.state["profile.contacts.msisdn"]}
                                            onChange={this.handlePhoneChange}
                                        />
                                        {errors["profile.contacts.msisdn"] && <FormHelperText error={true}>{errors["profile.contacts.msisdn"]}</FormHelperText>}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <PhoneInput
                                            label="TEL"
                                            id="profile.contacts.tel"
                                            name="profile.contacts.tel"
                                            value={this.state["profile.contacts.tel"]}
                                            onChange={this.handlePhoneChange}
                                        />
                                        {errors["profile.contacts.tel"] && <FormHelperText error={true}>{errors["profile.contacts.tel"]}</FormHelperText>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container m={24}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="profile.contacts.email"
                                            name="profile.contacts.email"
                                            label="E-MAIL ADDRESS"
                                            type="email"
                                            value={this.state["profile.contacts.email"]}
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {errors["profile.contacts.email"] && <FormHelperText error={true}>{errors["profile.contacts.email"]}</FormHelperText>}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="profile.contacts.addr.line1"
                                            name="profile.contacts.addr.line1"
                                            label="ADDRESS"
                                            value={this.state["profile.contacts.addr.line1"]}
                                            onChange={this.handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <DistrictInput
                                            id="district"
                                            name="district"
                                            area={this.state["survey.residence-area"]}
                                            district={this.state["survey.residence-place"]}
                                            subdistrict={this.state["survey.residence-subdistrict"]}
                                            onChange={this.handleDistrict}
                                            fullWidth
                                        />
                                        {errors["district"] && <FormHelperText error={true}>{errors["district"]}</FormHelperText>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">MISCELLANEOUS</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <DropDownCombo
                                    label="OCCUPATION"
                                    fullWidth
                                    value={this.state["survey.occupation"]}
                                    others={this.state["survey.occupation-others"]}
                                    options={Global.occupations(this.obj.org_id)}
                                    onChange={this.handleOccupation}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="survey.edu-level"
                                    name="survey.edu-level"
                                    select
                                    label="EDUCATION"
                                    value={this.state["survey.edu-level"]}
                                    onChange={this.handleSelect("survey.edu-level")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.educations(this.obj.org_id).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="survey.income-range"
                                    name="survey.income-range"
                                    select
                                    label="INCOME RANGE"
                                    value={this.state["survey.income-range"]}
                                    onChange={this.handleSelect("survey.income-range")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.incomeRanges(this.obj.org_id).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="profile.locale"
                                    name="profile.locale"
                                    label="LANGUAGE"
                                    select
                                    value={this.state["profile.locale"]}
                                    onChange={this.handleSelect("profile.locale")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.locales.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="profile.spoken_lang"
                                    name="profile.spoken_lang"
                                    label="SPOKEN LANGUAGE"
                                    select
                                    value={this.state["profile.spoken_lang"]}
                                    onChange={this.handleSelect("profile.spoken_lang")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.spokenLanguages.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend"><Typography variant="caption">OTHERS</Typography></FormLabel>
                                    <FormControlLabel
                                        control={<Checkbox id="profile.prefs.accept-newsletter" color="primary" onChange={this.handleCheckbox} checked={this.state["profile.prefs.accept-newsletter"]} />}
                                        label="Accept receiving marketing / promotional updates via E-mail or SMS"
                                    />
                                    {this.props.plan.org_id === "MM" &&
                                        <React.Fragment>
                                            <FormControlLabel
                                                control={<Checkbox id="survey.residence-taikoo" color="primary" onChange={this.handleCheckbox} checked={this.state["survey.residence-taikoo"]} />}
                                                label="Residence of Taikoo"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox id="survey.nowtv" color="primary" onChange={this.handleCheckbox} checked={this.state["survey.nowtv"]} />}
                                                label="NowTV Subscriber"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox id="survey.nowtv-ch116" color="primary" onChange={this.handleCheckbox} checked={this.state["survey.nowtv-ch116"]} />}
                                                label="NowTV Channel 116 Subscriber"
                                            />
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="survey.work-mm"
                                                    name="survey.work-mm"
                                                    label="Work in Admiralty or Taikoo (for MM)"
                                                    select
                                                    value={this.state["survey.work-mm"]}
                                                    onChange={this.handleSelect("survey.work-mm")}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                {Global.workMM.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                                </TextField>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </FormControl>
                                {this.props.plan.org_id === "MM" &&
                                    <DropDownCombo
                                        label="How do you know about membership programme?"
                                        fullWidth
                                        value={this.state["survey.mm-source"]}
                                        others={this.state["survey.mm-source-others"]}
                                        options={Global.mmSources}
                                        onChange={this.handleMMSource}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </FormControl>
            </form >
        );
    }
}

NewRegisterForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewRegisterForm);
