import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, FormLabel, FormControlLabel, FormHelperText, TextField, RadioGroup, Radio, MenuItem, Checkbox, Typography, withStyles, Divider } from '@material-ui/core';
import { KeyboardDatePicker  } from '@material-ui/pickers';
// import NumberFormat from 'react-number-format';
import FormBase from '../../components/FormBase';
import PhoneInput from '../../components/PhoneInput';
import DistrictInput from '../../components/DistrictInput';
import DropDownCombo from '../../components/DropDownCombo';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';
import { E164 } from '../../helpers/E164';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { format } from 'date-fns';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class EditPage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            display_name: "",
            org_id: "",
            m_id: "",
            remark: "",
            profile: {
                names: {
                    en: {
                        first: "",
                        last: "",
                        middle: "",
                        full: ""
                    },
                    "zh": {
                        first: "",
                        last: "",
                        middle: "",
                        full: ""
                    }
                },
                title: "",
                fullname: "",
                gender: "",
                dob: null,
                birthDay: null,
                birthMonth: null,
                birthYearRange: "",
                region: "",
                locale: "",
                spoken_lang: "",
                tz: "",
                contacts: {
                    email: "",
                    msisdn: "",
                    tel: "",
                    fax: "",
                    addr: {
                        line1: "", line2: "", city: "", state: "", postal_code: "", cc: ""
                    },
                },
                prefs: {
                    "accept-newsletter": false,
                    "accept-tnc": Global.TNCDocVersion,
                    "contact-method": ""
                }
            },
            survey: {
                "occupation": "",
                "occupation-others": "",
                "income-range": "",
                "edu-level": "",
                "residence-area": "",
                "residence-place": "",
                "residence-subdistrict": "",
                // "work-area": "",
                "work-place": "",
                // "work-subdistrict": "",
                "mm-source": "",
                "mm-source-others": "",
                "nowtv": false,
                "nowtv-ch116": false,
                "work-mm": "",
                "residence-taikoo": false,
            }
        };
        this.state = {
            isSubmitting: false,
            errors: {}
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Member" });
    }
    componentDidMount() {
        APIService.get(APIService.MEMBER, this.props.match.params.id, { full: 'n' })
            .then(obj => {
                var map = { display_name: Global.getMemberName(obj, null) };
                Validator.flattenNested(obj, map);
                this.setState(map);
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                this.props.history.goBack();
            });
    }
    handleDistrict = (area, district, subdistrict) => {
        this.setState({ "survey.residence-area": area, "survey.residence-place": district, "survey.residence-subdistrict": subdistrict });
    }
    handleOccupation = (option, others) => {
        this.setState({ "survey.occupation": option, "survey.occupation-others": others });
    }
    handleMMSource = (option, others) => {
        this.setState({ "survey.mm-source": option, "survey.mm-source-others": others });
    }
    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        // let output = JSON.parse(JSON.stringify(this.obj));
        // Validator.compact(output);        
        let output = this.obj;
        if(this.obj.profile.dob !== null){
            var dob = this.obj.profile.dob.split("-");
            output.profile.birthDay = parseInt(dob[2]);
            output.profile.birthMonth = parseInt(dob[1]);
        }
        this.setState({
            isSubmitting: true
        });

        APIService.update(APIService.MEMBER, output, output.m_id)
        .then(result => {
            this.props.alert("Member updated", "success");
            this.props.history.goBack();
        })
        .catch(error => {
            this.setState({ isSubmitting: false });
            this.props.alert(APIService.errorMessage(error));
        });
    }
    validateForm() {
        var errors = {};
        
        this.obj.profile.dob = Validator.verifyString(this.obj.profile.dob, value => {
            if (!Validator.isValidDate(value)) {
                errors["profile.dob"] = Global.errors["ErrorInvalidValue"];
            }
        });

        this.obj.profile.contacts.email = Validator.verifyString(this.obj.profile.contacts.email, value => {
            if (!Validator.isValidEmail(value)) {
                errors["profile.contacts.email"] = Global.errors["ErrorInvalidEmail"];
            }
        });
        var tel = E164.parse(this.obj.profile.contacts.msisdn);
        if (tel.number.length === 0 && tel.cc.length > 0) this.obj.profile.contacts.msisdn = "";
        if (this.obj.profile.contacts.msisdn.length === 0) {
            errors["profile.contacts.msisdn"] = Global.errors["ErrorRequired"];
        } else {
            this.obj.profile.contacts.msisdn = Validator.verifyString(this.obj.profile.contacts.msisdn, value => {
                if (!Validator.isValidE164(value)) {
                    errors["profile.contacts.msisdn"] = Global.errors["ErrorInvalidPhoneNumber"];
                }
            });
        }
        tel = E164.parse(this.obj.profile.contacts.tel);
        if (tel.number.length === 0 && tel.cc.length > 0) this.obj.profile.contacts.tel = "";
        this.obj.profile.contacts.tel = Validator.verifyString(this.obj.profile.contacts.tel, value => {
            if (!Validator.isValidE164(value)) {
                errors["profile.contacts.tel"] = Global.errors["ErrorInvalidPhoneNumber"];
            }
        });
        tel = E164.parse(this.obj.profile.contacts.fax);
        if (tel.number.length === 0 && tel.cc.length > 0) this.obj.profile.contacts.fax = "";
        this.obj.profile.contacts.fax = Validator.verifyString(this.obj.profile.contacts.fax, value => {
            if (!Validator.isValidE164(value)) {
                errors["profile.contacts.fax"] = Global.errors["ErrorInvalidPhoneNumber"];
            }
        });

        if (this.state.org_id === "BC" || this.state.org_id === "MM") {
            this.obj.survey["residence-area"] = Validator.verifyString(this.obj.survey["residence-area"], value => {});
            if (!this.obj.survey["residence-area"]) {
                errors["district"] = Global.errors["ErrorRequired"];
            }
            this.obj.survey["residence-place"] = Validator.verifyString(this.obj.survey["residence-place"], value => {});
            if (!this.obj.survey["residence-place"]) {
                errors["district"] = Global.errors["ErrorRequired"];
            }
        }
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    
    handleSelectDate = name => (date, insertValue) => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.DateFormat);
        }else if(insertValue === "29/02"){
            var today = new Date();
            var getYear = today.getFullYear() - (today.getFullYear() % 4);
            value = getYear + "-02-29";
        }
        this.setState({ [name]: value });
    }

    render() {
        if (!AuthService.userHasRole(['member'])) return null;
        const { classes } = this.props;
        const { errors } = this.state;
        var income = (this.state["survey.income-range"]) ? this.state["survey.income-range"] : "";
        if(income === "0-10000" || income === "10001-15000" || income === "15001-20000"){
            income = '0-20000';
        }           
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h4">{this.state.display_name}</Typography>
                                <Typography variant="body1" color="textSecondary"> ID: {this.props.match.params.id}</Typography>
                            </Grid>
                            {(this.state.org_id !== "FREE") ? (
                                <React.Fragment>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="profile.names.en.full"
                                            name="profile.names.en.full"
                                            label="FULL NAME (ENGLISH)"
                                            value={this.state["profile.names.en.full"]}
                                            onChange={this.handleChange}
                                            autoFocus
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {errors["profile.names.en.full"] && <FormHelperText error={true}>{errors["profile.names.en.full"]}</FormHelperText>}
                                        <FormHelperText>Same name as it appears on the identification document</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="profile.names.zh.full"
                                            name="profile.names.zh.full"
                                            label="FULL NAME (CHINESE)"
                                            value={this.state["profile.names.zh.full"]}
                                            onChange={this.handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {errors["profile.names.zh.full"] && <FormHelperText error={true}>{errors["profile.names.zh.full"]}</FormHelperText>}
                                        <FormHelperText>Same name as it appears on the identification document</FormHelperText>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="profile.names.en.first"
                                                name="profile.names.en.first"
                                                label="FIRST NAME (ENGLISH)"
                                                value={this.state["profile.names.en.first"]}
                                                onChange={this.handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {errors["profile.names.en.first"] && <FormHelperText error={true}>{errors["profile.names.en.first"]}</FormHelperText>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="profile.names.en.last"
                                                name="profile.names.en.last"
                                                label="LAST NAME (ENGLISH)"
                                                value={this.state["profile.names.en.last"]}
                                                onChange={this.handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {errors["profile.names.en.last"] && <FormHelperText error={true}>{errors["profile.names.en.last"]}</FormHelperText>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="profile.names.zh.first"
                                                name="profile.names.zh.first"
                                                label="FIRST NAME (CHINESE)"
                                                value={this.state["profile.names.zh.first"]}
                                                onChange={this.handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {errors["profile.names.zh.first"] && <FormHelperText error={true}>{errors["profile.names.zh.first"]}</FormHelperText>}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                id="profile.names.zh.last"
                                                name="profile.names.zh.last"
                                                label="LAST NAME (CHINESE)"
                                                value={this.state["profile.names.zh.last"]}
                                                onChange={this.handleChange}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            {errors["profile.names.zh.last"] && <FormHelperText error={true}>{errors["profile.names.zh.last"]}</FormHelperText>}
                                        </Grid>
                                    </React.Fragment>
                                )}
                            <Grid item xs={12} sm={6}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend"><Typography variant="caption">GENDER</Typography></FormLabel>
                                    <RadioGroup
                                        id="profile.gender"
                                        name="profile.gender"
                                        className={classes.group}
                                        value={this.state["profile.gender"]}
                                        onChange={this.handleSelect("profile.gender")}
                                        row={true}
                                    >
                                        {Global.genders.map(option => (
                                            <FormControlLabel key={option.value} value={option.value} control={<Radio color="primary" />} label={option.label} />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker 
                                    keyboard="true"
                                    label="DATE OF BIRTH"
                                    format={Global.DisplayDateMonthFormat}
                                    placeholder="DD/MM"
                                    value={this.state["profile.dob"]}
                                    onChange={this.handleSelectDate("profile.dob")}
                                    clearable
                                    animateYearScrolling={false}
                                    minDate="01/01/1800"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["profile.dob"] && <FormHelperText error={true}>{errors["profile.dob"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}></Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="profile.birthYearRange"
                                    name="profile.birthYearRange"
                                    select
                                    label="YEAR RANGE"
                                    value={this.state["profile.birthYearRange"]}
                                    onChange={this.handleSelect("profile.birthYearRange")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.getBirthYearRange(new Date().getFullYear()).map(range => (
                                        <MenuItem key={range.value} value={range.value}>
                                            {range.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">CONTACTS</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container m={24}>
                                    <Grid item xs={12} sm={12}>
                                        <PhoneInput
                                            label="MOBILE"
                                            id="profile.contacts.msisdn"
                                            name="profile.contacts.msisdn"
                                            value={this.state["profile.contacts.msisdn"]}
                                            onChange={this.handlePhoneChange}
                                        />
                                        {errors["profile.contacts.msisdn"] && <FormHelperText error={true}>{errors["profile.contacts.msisdn"]}</FormHelperText>}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <PhoneInput
                                            label="TEL"
                                            id="profile.contacts.tel"
                                            name="profile.contacts.tel"
                                            value={this.state["profile.contacts.tel"]}
                                            onChange={this.handlePhoneChange}
                                        />
                                        {errors["profile.contacts.tel"] && <FormHelperText error={true}>{errors["profile.contacts.tel"]}</FormHelperText>}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sm={6}>
                                <Grid container m={24}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="profile.contacts.email"
                                            name="profile.contacts.email"
                                            label="E-MAIL ADDRESS"
                                            type="email"
                                            value={this.state["profile.contacts.email"]}
                                            onChange={this.handleChange}
                                            required
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {errors["profile.contacts.email"] && <FormHelperText error={true}>{errors["profile.contacts.email"]}</FormHelperText>}
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="profile.contacts.addr.line1"
                                            name="profile.contacts.addr.line1"
                                            label="ADDRESS"
                                            value={this.state["profile.contacts.addr.line1"]}
                                            onChange={this.handleChange}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    {(this.state.org_id === "MM" || this.state.org_id === "BC") &&
                                        <Grid item xs={12} sm={12}>
                                            <DistrictInput
                                                id="district"
                                                name="district"
                                                area={this.state["survey.residence-area"]}
                                                district={this.state["survey.residence-place"]}
                                                subdistrict={this.state["survey.residence-subdistrict"]}
                                                onChange={this.handleDistrict}
                                                fullWidth
                                            />
                                            {errors["district"] && <FormHelperText error={true}>{errors["district"]}</FormHelperText>}
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">MISCELLANEOUS</Typography>
                                <Divider light={true} />
                            </Grid>
                            {this.state.org_id === "FREE" &&
                                <React.Fragment>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="survey.residence-place"
                                            name="survey.residence-place"
                                            label="PLACE OF RESIDENCE"
                                            select
                                            value={this.state["survey.residence-place"]}
                                            onChange={this.handleSelect("survey.residence-place")}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                            {Global.districts.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            id="survey.work-place"
                                            name="survey.work-place"
                                            label="PLACE OF WORK"
                                            select
                                            value={this.state["survey.work-place"]}
                                            onChange={this.handleSelect("survey.work-place")}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                            {Global.districts.map(option => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item xs={12} sm={6}>
                                <DropDownCombo
                                    label="OCCUPATION"
                                    fullWidth
                                    value={this.state["survey.occupation"]}
                                    others={this.state["survey.occupation-others"]}
                                    options={Global.occupations((this.state.org_id))}
                                    onChange={this.handleOccupation}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="survey.edu-level"
                                    name="survey.edu-level"
                                    select
                                    label="EDUCATION"
                                    value={this.state["survey.edu-level"]}
                                    onChange={this.handleSelect("survey.edu-level")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.educations(this.state.org_id).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="survey.income-range"
                                    name="survey.income-range"
                                    select
                                    label="INCOME RANGE"
                                    value={income}
                                    onChange={this.handleSelect("survey.income-range")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.incomeRanges(this.state.org_id).map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="profile.locale"
                                    name="profile.locale"
                                    label="LANGUAGE"
                                    select
                                    value={this.state["profile.locale"]}
                                    onChange={this.handleSelect("profile.locale")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.locales.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="profile.spoken_lang"
                                    name="profile.spoken_lang"
                                    label="SPOKEN LANGUAGE"
                                    select
                                    value={this.state["profile.spoken_lang"]}
                                    onChange={this.handleSelect("profile.spoken_lang")}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.spokenLanguages.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend"><Typography variant="caption">OTHERS</Typography></FormLabel>
                                    <FormControlLabel
                                        control={<Checkbox id="profile.prefs.accept-newsletter" color="primary" onChange={this.handleCheckbox} checked={this.state["profile.prefs.accept-newsletter"]} />}
                                        label="Accept receiving marketing / promotional updates via E-mail or SMS"
                                    />
                                    {this.state.org_id === "MM" &&
                                        <React.Fragment>
                                            <FormControlLabel
                                                control={<Checkbox id="survey.residence-taikoo" color="primary" onChange={this.handleCheckbox} checked={this.state["survey.residence-taikoo"]} />}
                                                label="Are you residence of Taikoo?"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox id="survey.nowtv" color="primary" onChange={this.handleCheckbox} checked={this.state["survey.nowtv"]} />}
                                                label="NowTV Subscriber"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox id="survey.nowtv-ch116" color="primary" onChange={this.handleCheckbox} checked={this.state["survey.nowtv-ch116"]} />}
                                                label="NowTV Channel 116 Subscriber"
                                            />
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    id="survey.work-mm"
                                                    name="survey.work-mm"
                                                    label="Work in Admiralty or Taikoo (for MM)"
                                                    select
                                                    value={this.state["survey.work-mm"]}
                                                    onChange={this.handleSelect("survey.work-mm")}
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                {Global.workMM.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                                </TextField>
                                            </Grid>
                                        </React.Fragment>
                                    }
                                </FormControl>
                                {this.state.org_id === "MM" &&
                                    <DropDownCombo
                                        label="How do you know about membership programme?"
                                        fullWidth
                                        value={this.state["survey.mm-source"]}
                                        others={this.state["survey.mm-source-others"]}
                                        options={Global.mmSources}
                                        onChange={this.handleMMSource}
                                    />
                                }
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form >
        );
    }
}

EditPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditPage);
