import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, withStyles, DialogContent } from '@material-ui/core';
import {
    SupervisorAccount as MemberIcon,
    Stars as PointIcon,
    LocalPlay as CouponIcon,
    SaveAlt as ExportIcon,
} from '@material-ui/icons'; import { NameService } from '../services/Name';
import { DateLabel } from '../components/Label';
import { format } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Global } from '../Global';
import { AuthService } from '../services/Auth';
import RoleControl from '../components/RoleControl';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
        width: '100%',
        height: '100%',
        overflowX: 'auto',
        overflowY: 'hidden'
    },
    card: {
    },
    table: {
        marginBottom: `${theme.spacing(3)}px`
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default
        }
    },
    tableCell: {
    }
});

class DashboardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            stats: [],
            lastUpdated: null,
            begin: null
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Dashboard" });
    }
    componentDidMount() {
        NameService.getStats(result => {
            var state = {
                stats: result
            };
            for (var i of result) {
                if (i.at) {
                    state.lastUpdated = i.at;
                    break;
                }
            }
            this.setState(state);
        });
    }
    background(name, type) {
        // red: cc0000, 444444
        // orange: ff8800 ffbb33
        // green: 007e33 00c851
        // blue: 0099cc 33b5e5
        // dark green: 00695c 2bbbad
        // dark blue: 0d47a1 4285f4
        // purple: 9933cc aa66cc
        // black: 212121 2e2e2e
        // gray: 3e4551 4b515d
        switch (name) {
            case 'BC': return '#aa66cc';
            case 'MM': return '#ff8800';
            case 'FREE': return '#2bbbad';
            default: return '#4b515d';
        }
    }
    expiredCount(name) {
        if (!this.state.stats) return Global.NotAvailable;
        for (var i of this.state.stats) {
            if (i.name === name && i.type === "subs-expired") {
                return i.count;
            }
        }
        return Global.NotAvailable;
    }
    newCount(name) {
        if (!this.state.stats) return Global.NotAvailable;
        for (var i of this.state.stats) {
            if (i.name === name && i.type === "subs-new") {
                return i.count;
            }
        }
        return Global.NotAvailable;
    }
    details(org_id) {
        if (!this.state.stats) return null;
        var coupons = 0;
        var points = 0;
        for (var i of this.state.stats) {
            if (i.org_id === org_id) {
                if (i.type === "points") {
                    points = i.count;
                } else if (i.type === "coupons") {
                    coupons = i.count;
                }
            }
        }
        if (points > 0 || coupons > 0) {
            return (
                <Typography variant="body1" style={{ color: '#eee', display: 'flex', alignItems: 'center' }}>
                    <PointIcon fontSize="small" />&nbsp;{points} pts &nbsp;&nbsp;&nbsp;&nbsp;
                    <CouponIcon fontSize="small" />&nbsp;{coupons}
                </Typography>
            );
        }
        return null;
    }
    exportCSV = () => {
        var req = {
            begin: this.state.begin
        };
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);
        var dateRange = "";
        if(req.begin){
            dateRange = "?export_date=" + req.begin;
        }
        var url = new URL(Global.APIUrl + "/stats/export" + dateRange);
        var params = { jwt: token };
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
        window.open(url);
    }
    getFilters() {
        var filters = { begin: this.state.begin};
        return filters;
    }
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.MonthFormat);
        }
        this.setState({ [name]: value });
    }

    render() {
        const { classes } = this.props;
        const { stats, lastUpdated } = this.state;
        return (
                <DialogContent>
            <RoleControl required={['dashboard']}>
                <Grid container>
                    <Grid item xs={12} sm={4} justify="flex-start" container>
                        {stats.filter(obj => obj.type === "org").map(row => (
                            <Grid item xs={12} sm={12} key={row.name}>
                                <Card className={classes.card} style={{ background: this.background(row.name) }}>
                                    <CardContent style={{ paddingTop: 8, paddingBottom: 16 }}>
                                        <Typography variant="subtitle1" style={{ color: '#eee', display: 'flex', alignItems: 'center' }}><MemberIcon />&nbsp;{row.name}</Typography>
                                        <Typography variant="h3" style={{ color: '#fff', lineHeight: 1.04 }} align="right">{row.count}</Typography>
                                        <Typography variant="caption" style={{ color: '#eee' }} align="right" gutterBottom>members</Typography>
                                        {this.details(row.org_id)}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    {stats && stats.length > 0 &&
                        <Grid item xs={12} sm={8}>
                            <Paper className={classes.paper}>
                                <Grid container>
                                    <Grid item xs={4} sm={4}>
                                        <Typography variant="h5" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }} >SUBSCRIPTION</Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <KeyboardDatePicker 
                                            keyboard="true"
                                            format={Global.DisplayMonthFormat}
                                            placeholder="yyyy/MM"
                                            value={this.state.begin}
                                            onChange={this.handleSelectDate("begin")}
                                            label="FROM"
                                            inputVariant="outlined"
                                            margin="dense"
                                            animateYearScrolling={false}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            style={{ maxWidth: 180 }}
                                            views={["year", "month"]}
                                        />
                                    </Grid>
                                    <Grid item xs={4} sm={4}>
                                        <IconButton color="default" onClick={this.exportCSV} aria-label="Export">
                                            <ExportIcon />
                                        </IconButton>

                                    </Grid>
                                </Grid>
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableCell}>PLAN</TableCell>
                                            <TableCell className={classes.tableCell} align="right" width={140}>ACTIVE #</TableCell>
                                            <TableCell className={classes.tableCell} align="right" width={140}>NEW #</TableCell>
                                            <TableCell className={classes.tableCell} align="right" width={140}>EXPIRED #</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {stats.filter(obj => obj.type === "subs").map(row => (
                                            <TableRow key={row.name} className={classes.tableRow}>
                                                <TableCell className={classes.tableCell} scope="row">{row.name}</TableCell>
                                                <TableCell className={classes.tableCell} align="right" width={140}>{row.count}</TableCell>
                                                <TableCell className={classes.tableCell} align="right" width={140}>{this.newCount(row.name)}</TableCell>
                                                <TableCell className={classes.tableCell} align="right" width={140}>{this.expiredCount(row.name)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {lastUpdated &&
                                    <Typography variant="caption" color="textSecondary" align="right">Last updated at <DateLabel date={lastUpdated} format={Global.DisplayDateTimeFormat} /></Typography>
                                }
                            </Paper>
                        </Grid>
                    }
                </Grid>
            </RoleControl>
            </DialogContent>
        );
    }
}

DashboardPage.propTypes = {
    classes: PropTypes.object.isRequired
};


export default withStyles(styles)(DashboardPage);
