import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { AddCircleOutline as AddIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Label } from '../../components/Label';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { Global } from '../../Global';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    }
});

let ListKey = "org_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        let listState = sessionStorage.getItem(ListKey);
        var list = { offset: 0, size: 10, order: null, orderDir: "asc", keyword: "" };
        if (listState) {
            try {
                list = JSON.parse(listState);
                list.init = true;
            } catch (e) { }
        }
        this.state = list;
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Organisations" });
    }
    componentWillUnmount() {
        let list = JSON.stringify(this.state);
        sessionStorage.setItem(ListKey, list);
    }
    onSelect = (event, row) => {
        this.props.history.push("/orgs/" + row.org_id);
    }
    loadTable = query => {
        var offset, size, order, orderDir, keyword;
        if (this.state.init) {
            offset = this.state.offset;
            size = this.state.size;
            order = this.state.order;
            orderDir = this.state.orderDir;
            keyword = this.state.keyword;
            this.setState({ init: undefined });
        } else {
            offset = query.page * query.pageSize;
            size = query.pageSize;
            order = query.orderBy ? query.orderBy.field : null;
            orderDir = query.orderDirection;
            keyword = this.state.keyword;
            this.setState({ offset: offset, size: size, order: order, orderDir: orderDir, keyword: keyword });
        }
        return APIService.list(APIService.ORG, offset, size, order, orderDir, keyword)
            .then(result => {
                return { data: result.data, page: result.offset / size, totalCount: result.total };
            })
            .catch(error => {
                this.setState({ offset: 0, size: 10, order: null, orderDir: "asc", keyword: "" });
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {
        if (!AuthService.userHasRole(['org'])) return null;
        const { classes } = this.props;
        const cols = [
            { field: 'org_id', title: 'ID', cellStyle: { color: 'inherit', width: 50 } },
            {
                field: 'name', title: 'NAME (ENGLISH)', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={row} field="names" locale="en" />
                )
            },
            {
                field: 'name', title: 'NAME (CHINESE)', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={row} field="names" locale="zh" />
                )
            }
        ];
        return (
            <Grid container spacing={8}>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button variant="contained" color="primary" className={classes.button} component={Link} to="/orgs/new">
                            New <AddIcon className={classes.rightIcon} />
                        </Button>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.state.size,
                            toolbar: false,
                            rowStyle: rowData => ({
                                color: Global.listColor(rowData.status)
                            })
                        }} />
                </Grid>
            </Grid>
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
