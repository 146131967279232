import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { Period } from '../helpers/Period';
import { Global } from '../Global';

class PeriodInput extends React.Component {
    constructor(props) {
        super(props);

        let value = Period.parse(this.props.value);
        this.state = {
            duration: value.duration,
            unit: value.unit
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            let value = Period.parse(nextProps.value);
            if (value.duration !== this.state.duration || value.unit !== this.state.unit) {
                this.setState({ duration: value.duration, unit: value.unit });
            }
        }
    }

    update = () => {
        var value;
        var { duration, unit } = this.state;
        value = duration + unit;
        this.props.onChange(this.props.id, value);
    }
    handleDuration = event => {
        this.setState({ duration: event.target.value }, () => {
            this.update();
        });
    };
    handleUnit = event => {
        this.setState({ unit: event.target.value }, () => {
            this.update();
        });
    };
    render() {
        const { label, disabled, required } = this.props;

        return (
            <div style={{ display: 'flex' }}>
                <TextField
                    label={label}
                    value={this.state.duration}
                    onChange={this.handleDuration}
                    type="number"
                    disabled={disabled}
                    required={required}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ flex: 1 }}
                />
                <TextField
                    value={this.state.unit}
                    onChange={this.handleUnit}
                    select
                    disabled={disabled}
                    required={required}
                    style={{ alignSelf: 'flex-end', width: 100, marginLeft: 8 }}
                >
                    {Global.periodUnits.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
        );
    }
}

export default PeriodInput;