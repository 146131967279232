import React from 'react';
import { FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, FormHelperText, Button, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { Validator } from '../../helpers/Validator';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2)
    }
});

class ResetPasswordDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            u_id: 0,
            pass: "",
            errors: {},
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (user) => {
        this.setState({ open: true, u_id: parseInt(user.u_id), pass: "", errors: {}, isSubmitting: false });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleSubmit = event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        if (!this.validateForm()) return;

        this.setState({
            isSubmitting: true
        });

        let req = {
            pass: this.state.pass
        };
        APIService.call("PUT", APIService.USER + "/" + this.state.u_id + "/set_pass", req)
        .then(result => {
            this.setState({isSubmitting: false, open: false}, () => {
                this.props.alert("Password updated", "success");
            });
        })
        .catch(error => {
            this.setState({ isSubmitting: false });
            this.props.alert(APIService.errorMessage(error));
        });
    }
    validateForm() {
        var errors = {};

        if (!this.state.pass) {
            errors["pass"] = Global.errors["ErrorRequired"];
        }
        Validator.verifyString(this.state.pass, value => {
            if (!Validator.isValidPassword(value)) {
                errors["pass"] = Global.errors["ErrorInvalidPassword"];
            }
        });

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        const { classes } = this.props;
        const { errors } = this.state;
        return <Dialog
            open={this.state.open}
            fullWidth
            maxWidth="sm"
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <form onSubmit={this.handleSubmit}>

                <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>Change user login password.</DialogContentText>
                    <FormControl required fullWidth className={classes.formControl}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="pass"
                                    name="pass"
                                    label="NEW PASSWORD"
                                    value={this.state.pass}
                                    onChange={this.handleChange}
                                    type="password"
                                    required
                                    fullWidth
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["pass"] && <FormHelperText error={true}>{errors["pass"]}</FormHelperText>}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" disabled={this.state.isSubmitting}>OK</Button>
                    <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    }
}

export default withStyles(styles)(ResetPasswordDialog);