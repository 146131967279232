import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, FormHelperText, TextField, MenuItem, InputAdornment, Typography, withStyles, Divider } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormBase from '../../components/FormBase';
import PeriodInput from '../../components/PeriodInput';
import { Global } from '../../Global';
import { parse } from 'date-fns';
import { Validator } from '../../helpers/Validator';
import { Period } from '../../helpers/Period';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class CreatePage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            org_id: "",
            names: {
                en: "",
                zh: ""
            },
            desc: "",
            remark: "",
            plan_code: "",
            plan_group_code: "",
            medium: "",
            type: "basic",
            period: "annual",
            validity_begin: null,
            validity_end: null,
            grace_period: "1m",
            renewal_period: "1y",
            retention_period: "1y",
            require_sub: "",
            quota_id: "",
            details: {
                ccy: "HKD",
                prices: {
                    new: "", renewal: "", reactivation: ""
                },
                points: {
                    new: "", "advance-renewal": "", renewal: "", reactivation: "", birthday: ""
                },
            },
            status: "active"
        };
        this.state = {
            plans: [],
            quotas: [],
            orgs: [],
            isSubmitting: false,
            errors: {}
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Subscription Plan" });
    }
    componentDidMount() {
        // load organisations for selection
        let user = AuthService.currentUser();
        if (user.org_id) {
            this.setState({orgs: [{ value: user.org_id, label: user.org_id }]});
        } else {
            NameService.organisationList((orgs) => {
                this.setState({ orgs: orgs });
            })
        }
        // load quotas
        APIService.list(APIService.QUOTA, 0, -1, null, null, null, {type: "subscription"})
            .then(result => {
                var quotas = result.data.map(quota => (
                    { value: quota.quota_id, label: quota.quota_id + " (" + quota.qty + ")" }
                ));
                this.setState({ quotas: quotas });
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
        // load plans for selection
        NameService.planList((plans) => {
            this.setState({ plans: plans });
        })
    }
    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        let output = JSON.parse(JSON.stringify(this.obj));
        if (output.details.prices) {
            if (output.details.prices.new) output.details.prices.new = '' + output.details.prices.new;
            if (output.details.prices.renewal) output.details.prices.renewal = '' + output.details.prices.renewal;
            if (output.details.prices.reactivation) output.details.prices.reactivation = '' + output.details.prices.reactivation;
        }
        if (output.details.points) {
            if (output.details.points.new) output.details.points.new = parseInt(output.details.points.new);
            if (output.details.points["advance-renewal"]) output.details.points["advance-renewal"] = parseInt(output.details.points["advance-renewal"]);
            if (output.details.points.renewal) output.details.points.renewal = parseInt(output.details.points.renewal);
            if (output.details.points.reactivation) output.details.points.reactivation = parseInt(output.details.points.reactivation);
            if (output.details.points.birthday) output.details.points.birthday = parseInt(output.details.points.birthday);
        }
        Validator.compact(output);
        this.setState({
            isSubmitting: true
        });

        APIService.create(APIService.PLAN, output)
            .then(result => {
                this.props.alert("Plan created", "success");
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};
        if (!this.obj.org_id) {
            errors["org_id"] = Global.errors["ErrorRequired"];
        }
        this.obj.names.en = Validator.verifyString(this.obj.names.en, value => { });
        this.obj.names["zh"] = Validator.verifyString(this.obj.names["zh"], value => { });
        this.obj.desc = Validator.verifyString(this.obj.desc, value => { });
        this.obj.remark = Validator.verifyString(this.obj.remark, value => { });
        this.obj.plan_code = Validator.verifyString(this.obj.plan_code, value => {
            if (!Validator.isValidCode(value)) {
                errors["plan_code"] = Global.errors["ErrorInvalidCode"];
            }
        });
        if (this.obj.plan_code.length > 64) {
            errors["plan_code"] = Global.errors["ErrorExceedMaxLength"];
        }
        this.obj.plan_group_code = Validator.verifyString(this.obj.plan_group_code, value => {
            if (!Validator.isValidCode(value)) {
                errors["plan_group_code"] = Global.errors["ErrorInvalidCode"];
            }
        });
        if (this.obj.plan_group_code.length > 64) {
            errors["plan_group_code"] = Global.errors["ErrorExceedMaxLength"];
        }
        if (!this.obj.validity_begin) {
            errors["validity_begin"] = Global.errors["ErrorRequired"];
        }
        if (this.obj.validity_begin && this.obj.validity_end) {
            let begin_time = parse(this.obj.validity_begin, Global.DisplayDateFormat, new Date());
            let end_time = parse(this.obj.validity_end, Global.DisplayDateFormat, new Date());
            if (begin_time.getTime() >= end_time.getTime()) {
                errors["validity_end"] = Global.errors["ErrorEndTimeEarlier"];
            }
        }
        if (this.obj.renewal_period) {
            let p = Period.parse(this.obj.renewal_period);
            if (p.duration === "" && p.unit !== "") {
                errors["renewal_period"] = Global.errors["ErrorInvalidValue"];
            } else {
                let d = parseInt(p.duration);
                if (isNaN(d) || d <= 0) {
                    errors["renewal_period"] = Global.errors["ErrorInvalidValue"];
                }
            }
        }
        if (this.obj.grace_period) {
            let p = Period.parse(this.obj.grace_period);
            if (p.duration === "" && p.unit !== "") {
                errors["grace_period"] = Global.errors["ErrorInvalidValue"];
            } else {
                let d = parseInt(p.duration);
                if (isNaN(d) || d <= 0) {
                    errors["grace_period"] = Global.errors["ErrorInvalidValue"];
                }
            }
        }
        if (this.obj.retention_period) {
            let p = Period.parse(this.obj.retention_period);
            if (p.duration === "" && p.unit !== "") {
                errors["retention_period"] = Global.errors["ErrorInvalidValue"];
            } else {
                let d = parseInt(p.duration);
                if (isNaN(d) || d <= 0) {
                    errors["retention_period"] = Global.errors["ErrorInvalidValue"];
                }
            }
        }

        this.obj.details.prices.new = Validator.verifyNumber(this.obj.details.prices.new, value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.prices.new"] = err;
        });
        this.obj.details.prices.renewal = Validator.verifyNumber(this.obj.details.prices.renewal, value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.prices.renewal"] = err;
        });
        this.obj.details.prices.reactivation = Validator.verifyNumber(this.obj.details.prices.reactivation, value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.prices.reactivation"] = err;
        });
        this.obj.details.points.new = Validator.verifyNumber(this.obj.details.points.new, value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.points.new"] = err;
        });
        this.obj.details.points["advance-renewal"] = Validator.verifyNumber(this.obj.details.points["advance-renewal"], value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.points.advance-renewal"] = err;
        });
        this.obj.details.points.renewal = Validator.verifyNumber(this.obj.details.points.renewal, value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.points.renewal"] = err;
        });
        this.obj.details.points.reactivation = Validator.verifyNumber(this.obj.details.points.reactivation, value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.points.reactivation"] = err;
        });
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        if (!AuthService.userHasRole(['plan'])) return null;
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" gutterBottom>NEW SUBSCRIPTION PLAN</Typography>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="org_id"
                                    name="org_id"
                                    label="ORGANISATION"
                                    select
                                    value={this.state.org_id}
                                    onChange={this.handleSelect("org_id")}
                                    required
                                    autoFocus
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {this.state.orgs.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["org_id"] && <FormHelperText error={true}>{errors["org_id"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="names.en"
                                    name="name.en"
                                    label="NAME (ENGLISH)"
                                    value={this.state["names.en"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["names.en"] && <FormHelperText error={true}>{errors["names.en"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="names.zh"
                                    name="names.zh"
                                    label="NAME (CHINESE)"
                                    value={this.state["names.zh"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["names.zh"] && <FormHelperText error={true}>{errors["names.zh"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    label="DESCRIPTION"
                                    multiline
                                    fullWidth
                                    value={this.state.desc}
                                    onChange={this.handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="plan_code"
                                    name="plan_code"
                                    label="PLAN CODE"
                                    value={this.state.plan_code}
                                    onChange={this.handleChangeUpper}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["plan_code"] && <FormHelperText error={true}>{errors["plan_code"]}</FormHelperText>}
                                <FormHelperText>Maximum length of 64 characters</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="plan_group_code"
                                    name="plan_group_code"
                                    label="PLAN GROUP CODE"
                                    value={this.state.plan_group_code}
                                    onChange={this.handleChangeUpper}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["plan_group_code"] && <FormHelperText error={true}>{errors["plan_group_code"]}</FormHelperText>}
                                <FormHelperText>Maximum length of 64 characters</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="medium"
                                    name="medium"
                                    label="MEDIUM"
                                    fullWidth
                                    value={this.state.medium}
                                    onChange={this.handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="require_sub"
                                    name="require_sub"
                                    label="REQUIRE SUBSCRIPTION"
                                    value={this.state["require_sub"]}
                                    onChange={this.handleSelect("require_sub")}
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem key="" value=""><em>None</em></MenuItem>
                                    {this.state.plans.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">DETAILS</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="type"
                                    name="type"
                                    label="TYPE"
                                    value={this.state.type}
                                    onChange={this.handleSelect("type")}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {Global.subscriptionTypes.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="period"
                                    name="period"
                                    label="PERIOD"
                                    value={this.state["period"]}
                                    onChange={this.handleSelect("period")}
                                    select
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {Global.subscriptionPeriods.map(option => (
                                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <KeyboardDatePicker
                                    keyboard="true"
                                    label="VALIDITY START"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.validity_begin}
                                    onChange={this.handleSelectDate("validity_begin")}
                                    clearable
                                    animateYearScrolling={false}
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["validity_begin"] && <FormHelperText error={true}>{errors["validity_begin"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <KeyboardDatePicker
                                    keyboard="true"
                                    label="VALIDITY END"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.validity_end}
                                    onChange={this.handleSelectDate("validity_end")}
                                    required = {this.state.period === "fixed" ? true : false}
                                    clearable
                                    animateYearScrolling={false}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["validity_end"] && <FormHelperText error={true}>{errors["validity_end"]}</FormHelperText>}
                                <FormHelperText>Date time is exclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <PeriodInput
                                    id="renewal_period"
                                    name="renewal_period"
                                    label="RENEWAL"
                                    value={this.state["renewal_period"]}
                                    onChange={this.handlePeriodChange}
                                    fullWidth
                                />
                                {errors["renewal_period"] && <FormHelperText error={true}>{errors["renewal_period"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <PeriodInput
                                    id="grace_period"
                                    name="grace_period"
                                    label="GRACE"
                                    value={this.state["grace_period"]}
                                    onChange={this.handlePeriodChange}
                                    fullWidth
                                />
                                {errors["grace_period"] && <FormHelperText error={true}>{errors["grace_period"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <PeriodInput
                                    id="retention_period"
                                    name="retention_period"
                                    label="RETENTION"
                                    value={this.state["retention_period"]}
                                    onChange={this.handlePeriodChange}
                                    fullWidth
                                />
                                {errors["retention_period"] && <FormHelperText error={true}>{errors["retention_period"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="quota_id"
                                    name="quota_id"
                                    label="QUOTA"
                                    value={this.state["quota_id"]}
                                    onChange={this.handleSelect("quota_id")}
                                    select
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem key="" value=""><em>None</em></MenuItem>
                                    {this.state.quotas.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">PRICES</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="details.prices.new"
                                    name="details.prices.new"
                                    label="NEW"
                                    value={this.state["details.prices.new"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">HKD</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["details.prices.new"] && <FormHelperText error={true}>{errors["details.prices.new"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="details.prices.renewal"
                                    name="details.prices.renewal"
                                    label="RENEWAL"
                                    value={this.state["details.prices.renewal"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">HKD</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["details.prices.renewal"] && <FormHelperText error={true}>{errors["details.prices.renewal"]}</FormHelperText>}
                            </Grid>
                            {/* <Grid item xs={12} sm={3}>
                                <TextField
                                    id="details.prices.reactivation"
                                    name="details.prices.reactivation"
                                    label="REACTIVATION"
                                    value={this.state["details.prices.reactivation"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">HKD</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.prices.reactivation"] && <FormHelperText error={true}>{errors["details.prices.reactivation"]}</FormHelperText>}
                            </Grid> */}
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">REWARD POINTS</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="details.points.new"
                                    name="details.points.new"
                                    label="NEW"
                                    type="number"
                                    value={this.state["details.points.new"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">pts</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["details.points.new"] && <FormHelperText error={true}>{errors["details.points.new"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="details.points.advance-renewal"
                                    name="details.points.advance-renewal"
                                    label="ADVANCE RENEWAL"
                                    type="number"
                                    value={this.state["details.points.advance-renewal"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">pts</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["details.points.advance-renewal"] && <FormHelperText error={true}>{errors["details.points.advance-renewal"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="details.points.renewal"
                                    name="details.points.renewal"
                                    label="RENEWAL"
                                    type="number"
                                    value={this.state["details.points.renewal"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">pts</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["details.points.renewal"] && <FormHelperText error={true}>{errors["details.points.renewal"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="details.points.birthday"
                                    name="details.points.birthday"
                                    label="BIRTHDAY"
                                    type="number"
                                    value={this.state["details.points.birthday"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">pts</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["details.points.birthday"] && <FormHelperText error={true}>{errors["details.points.birthday"]}</FormHelperText>}
                            </Grid>
                            {/* <Grid item xs={12} sm={4}>
                                <TextField
                                    id="details.points.reactivation"
                                    name="details.points.reactivation"
                                    label="REACTIVATION"
                                    type="number"
                                    value={this.state["details.points.reactivation"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">pts</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.points.reactivation"] && <FormHelperText error={true}>{errors["details.points.reactivation"]}</FormHelperText>}
                            </Grid> */}
                            <Grid item xs={12} sm={12}>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form>
        );
    }
}

CreatePage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreatePage);