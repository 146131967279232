import React from 'react';
import { Grid, TextField, MenuItem, Typography } from '@material-ui/core';
import { Global } from '../Global';

export default class DropDownCombo extends React.Component {
    constructor(props) {
        super(props);
        
        let selected = Global.getValueLabelPair(props.options, props.value);
        this.state = {
            option: props.value ? props.value : "",
            others: props.others ? props.others : "",
            enableOthers: selected && selected.others,
            options: props.options
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value || nextProps.options !== this.props.options) {
            let selected = Global.getValueLabelPair(nextProps.options, nextProps.value);
            this.setState({
                option: nextProps.value,
                others: nextProps.others,
                enableOthers: selected && selected.others,
            })
        }
    }
    handleChange = event => {
        this.setState({ others: event.target.value }, () => {
            this.props.onChange(this.state.option, this.state.others);
        });
    }
    handleSelect = name => event => {
        let value = event.target.value;
        var state = { [name]: value };
        let selected = Global.getValueLabelPair(this.props.options, value);
        if (selected && selected.others) {
            state.enableOthers = true;
        } else {
            state.enableOthers = false;
        }
        state.others = "";
        this.setState(state, () => {
            this.props.onChange(this.state.option, this.state.others);
        });
    }
    render() {
        const { label } = this.props;
        var col1 = 12;
        var col2 = 0;
        if (this.state.enableOthers) {
            col1 = 4;
            col2 = 8;
        }
        return (
            <Grid container style={{ display: 'flex' }}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="caption">{label}</Typography>
                </Grid>
                <Grid container m={16}>
                    <Grid item xs={12} sm={col1}>
                        <TextField
                            value={this.state.option}
                            onChange={this.handleSelect("option")}
                            select
                            fullWidth
                            required={this.props.required}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                            {this.state.options.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    {col2 > 0 &&
                        <Grid item xs={12} sm={col2}>
                            <TextField
                                placeholder="Please specify"
                                value={this.state.others}
                                onChange={this.handleChange}
                                fullWidth
                                disabled={!this.state.enableOthers}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
        );
    }
}
