import React from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

let toolbarOptions = {
    //    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'image', 'remove', 'history'],
    options: ['inline', 'blockType', 'list', 'textAlign', 'fontFamily', 'fontSize', 'colorPicker', 'link', 'image'],
    fontFamily: {
        options: ['Arial', 'Georgia', 'Impact', 'Sans-serif', 'Tahoma', 'Times New Roman', 'Verdana'],
        className: undefined,
        component: undefined,
        dropdownClassName: undefined
    }
};

export default class HTMLEditor extends React.Component {
    constructor(props) {
        super(props);

        var editorState;
        let content = this.props.content;
        if (content) {
            const contentBlock = convertFromHTML(content);
            if (contentBlock.contentBlocks !== null){
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                editorState = EditorState.createWithContent(contentState);
            } else {
                editorState = EditorState.createEmpty();
            }
        } else {
            editorState = EditorState.createEmpty();
        }
        this.state = {
            editorState: editorState
        };
    }
    onChange = (editorState) => {
        this.setState({
            editorState: editorState,
        });
    }
    onBlur = () => {
        if (this.props.onChange) {
            this.props.onChange(this.props.id, draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())));
        }
    }
    render() {
        const { editorState } = this.state;
        return (
            <div style={{
                border: '1px solid #ddd',
                marginTop: 8,
                padding: '8px 16px 8px 16px',
                borderRadius: 8,
                minHeight: 200,
            }}>
                <Editor
                    toolbar={toolbarOptions}
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={this.onChange}
                    onBlur={this.onBlur}
                />
            </div>
        );
    }
}
