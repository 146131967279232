import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { Global } from '../Global';
import { handleResponse } from './API';

export const AuthService = {
	USER_KEY: "user",

	isAuthenticated,
	authenticate,
	deauthenticate,
	currentUser,
	header,
	userHasRole
};

function isAuthenticated() {
	let user = JSON.parse(localStorage.getItem(AuthService.USER_KEY));
	if (!user) return false;
	let token = user.token;
	if (!token) return false;
	let jwt = JSON.parse(atob(token.split('.')[1]));
	if (jwt.exp * 1000 < new Date().getTime()) {
		return false;
	}

	return true;
}

function authenticate(login, pass) {
	const options = {
		method: 'POST',
		headers: { 'Content-Type': Global.MIME_JSON },
		body: JSON.stringify({ login, pass })
	};
	return fetch(`${Global.APIUrl}/auth/login`, options)
		.then(handleResponse)
		.then(user => {
			// login successful if there's a jwt token in the response
			if (user.token) {
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				localStorage.setItem(AuthService.USER_KEY, JSON.stringify(user));
			}

			return user;
		});
}

function deauthenticate() {
	localStorage.removeItem(AuthService.USER_KEY);
}

function currentUser() {
	return JSON.parse(localStorage.getItem(AuthService.USER_KEY));
}

function userHasRole(requiredRoles) {
	let user = currentUser();
    if (!user || !user.roles) return false;
    for (var role of requiredRoles) {
        if (user.roles[role]) {
            return true;
        }
    }
    return false;
}

function header() {
	// return authorization header with jwt token
	let user = JSON.parse(localStorage.getItem(AuthService.USER_KEY));
	if (user && user.token) {
		return { 'Authorization': 'Bearer ' + user.token };
	} else {
		return {};
	}
}

export const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuthenticated() ? (
				<Component {...props} {...rest} />
			) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location }
						}}
					/>
				)
		}
	/>
);
