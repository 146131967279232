import React from 'react';
import { Chip, withStyles } from '@material-ui/core';
import { Global } from '../Global';

const styles = theme => ({
    chip: {
        margin: 4
    }
});

class FilterOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: props.selected
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.selected !== this.props.selected) {
            this.setState({selected: nextProps.selected});
        }
    }
    handleToggle = option => () => {
        var selected = this.state.selected;
        if (!selected) return;

        let index = selected.indexOf(option.value);
        if (index !== -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option.value);
        }
        this.props.onChange(this.props.id, selected);
        this.setState({
            selected: selected
        });
    }
    isSelected = option => {
        if (!this.state.selected) return false;
        return this.state.selected.indexOf(option.value) !== -1;
    }
    render() {
        const { classes, options, readonly } = this.props;
        if (!options || options.length === 0) return Global.NotAvailable;
        if (readonly) {
            var list = [];
            for (var i = 0; i < options.length; i++) {
                let option = options[i];
                if (this.isSelected(option)) {
                    list.push(option);
                }
            }
            return (
                <React.Fragment>
                    {list.map(option => {
                        return (
                            <Chip
                                className={classes.chip}
                                key={option.value}
                                color="primary"
                                variant="default"
                                label={option.label}
                            />
                        );
                    })}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    {options.map(option => {
                        return (
                            <Chip
                                className={classes.chip}
                                key={option.value}
                                color={this.isSelected(option) ? "primary" : "default"}
                                variant={this.isSelected(option) ? "default" : "outlined"}
                                label={option.label}
                                onClick={this.handleToggle(option)}
                            />
                        );
                    })}
                </React.Fragment>
            );
        }
    }
};

export default withStyles(styles)(FilterOptions);