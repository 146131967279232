import React from 'react';
import { Paper, Grid, Button, IconButton, Toolbar, Table, TableBody, TableCell, TableHead, TableRow, TextField, MenuItem, Typography, Divider, withStyles } from '@material-ui/core';
import {
    Close as CloseIcon,
} from '@material-ui/icons';
import { Label, CurrencyLabel } from '../../components/Label';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format } from 'date-fns';
import { Global } from '../../Global';
import ConfirmDialog from '../../components/ConfirmDialog';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    },
    searchbar: {
    }
});

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        let user = AuthService.currentUser();
        var dept_id = "";
        var depts = [];
        if (user.dept_id) {
            // if user belong to specific department, not allow to change
            dept_id = user.dept_id;
            depts.push({ value: dept_id, label: dept_id });
        } else {
            // if user does not belong to any department, can settle any department orders
            depts.push({ value: dept_id, label: "NONE" });
        }
        var filters = props.filters;
        if (!filters) {
            filters = {
                date: null,
                status: ""
            };
        }
        filters.dept_id = dept_id;
        filters.depts = depts;
        this.state = filters;
    }
    componentDidMount() {
        var { depts } = this.state;
        let user = AuthService.currentUser();
        if (!user.dept_id) {
            // if user does not belong to any department, pull all departments for selection
            NameService.departmentList((objs) => {
                if (objs) {
                    this.setState({ depts: depts.concat(objs) });
                }
            });
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.filters !== this.props.filters) {
            let date = nextProps.filters.date;
            let status = nextProps.filters.status;
            this.setState({ date: date, status: status });
        }
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    getFilters() {
        var filters = { date: this.state.date, status: this.state.status, dept_id: this.state.dept_id };
        return filters;
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.DateFormat);
        }
        this.setState({ [name]: value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    render() {
        return <Toolbar style={{ paddingLeft: 0 }}>
            <TextField
                id="dept_id"
                name="dept_id"
                label="DEPARTMENT"
                value={this.state.dept_id}
                onChange={this.handleSelect("dept_id")}
                variant="outlined"
                margin="dense"
                select
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ maxWidth: 250 }}
            >
                {this.state.depts.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <KeyboardDatePicker
                keyboard="true"
                label="DATE"
                inputVariant="outlined"
                margin="dense"
                format={Global.DisplayDateFormat}
                placeholder="DD/MM/YYYY"
                value={this.state.date}
                onChange={this.handleSelectDate("date")}
                animateYearScrolling={false}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ maxWidth: 180 }}
            />
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
        </Toolbar>;
    }
}

class Settlement extends React.Component {
    constructor(props) {
        super(props);

        let today = new Date();
        let date = format(today, Global.DateFormat);
        let status = "paid";
        this.state = {
            rows: [],
            total: "0",
            count: 0,
            subtotal: {},
            filters: { date: date, status: status, dept_id: "" },
            settled: true,
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.load(this.state.filters);
    }
    load = (filters) => {
        APIService.call("POST", APIService.ORDER + "/summary", filters, {"settlement": "y"})
            .then(result => {
                this.setState({ rows: result.data, total: result.total, count: result.count, subtotal: result.subtotal_methods, settled: result.settled });
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
    }
    onFiltersChange = filters => {
        this.setState({ filters: filters }, () => {
            this.load(filters);
        });
    }
    handleClose = () => {
        this.props.history.goBack();
    }
    handleSettlement = () => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to settle orders.", () => {
            this.setState({ isSubmitting: true });
            let filters = this.state.filters;
            let url = APIService.ORDER + "/settle?dept_id=" + filters.dept_id + "&date=" + filters.date;
            APIService.call("GET", url)
                .then(result => {
                    this.setState({ isSubmitting: false });
                    this.props.alert("Order settled", "success");
                    this.props.history.goBack();
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                });
        });
    }
    handlePreview = () => {
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);
        let filters = this.state.filters;
        let url = Global.APIUrl + "/" + APIService.ORDER + "/settle?preview=y&dept_id=" + filters.dept_id + "&date=" + filters.date + "&jwt=" + token;
        window.open(url);
    }
    render() {
        if (!AuthService.userHasRole(['order'])) return null;
        const { classes } = this.props;
        return <React.Fragment>
            <Paper className={classes.paper}>
                <Grid container>
                    <Grid item xs={12} sm={10}>
                        <Searchbar
                            className={classes.searchbar}
                            history={this.props.history}
                            filters={this.state.filters}
                            onFiltersChange={this.onFiltersChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} align="right">
                        <IconButton color="default" onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>PLAN CODE</TableCell>
                                    <TableCell align="right" style={{ width: 50 }} >COUNT</TableCell>
                                    {Global.paymentMethods.map(row => (
                                        <TableCell align="center" key={row.value}>{row.label.toUpperCase()}</TableCell>
                                    ))}
                                    <TableCell align="right">SUBTOTAL</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.rows.map(row => (
                                    <React.Fragment key={row.plan_code}>
                                        <TableRow>
                                            <TableCell><Label data={row.plan_code} /></TableCell>
                                            <TableCell style={{ width: 50 }} align="right">{row.count}</TableCell>
                                            {Global.paymentMethods.map(method => (
                                                <TableCell align="right" key={method.value}>
                                                    <CurrencyLabel data={row.amount_methods[method.value]} />
                                                </TableCell>
                                            ))}
                                            <TableCell style={{ width: 150 }} align="right">
                                                <Typography variant="body2">
                                                    <CurrencyLabel data={row.subtotal} />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                                <TableRow>
                                    <TableCell>TOTAL</TableCell>
                                    <TableCell align="right" style={{ width: 50 }}>
                                        <Typography variant="body2">
                                            {this.state.count}
                                        </Typography>
                                    </TableCell>
                                    {Global.paymentMethods.map(method => (
                                        <TableCell align="right" key={method.value}>
                                            <Typography variant="body2">
                                                <CurrencyLabel data={this.state.subtotal[method.value]} />
                                            </Typography>
                                        </TableCell>
                                    ))}
                                    <TableCell align="right">
                                        <Typography variant="h6">
                                            <CurrencyLabel data={this.state.total} />
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </Paper>
            <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                <Button variant="contained" color="default" className={classes.button} onClick={this.handlePreview}>Preview</Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSettlement} disabled={this.state.settled}>Settle</Button>
            </Grid>
            <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
        </React.Fragment>
    }
}

export default withStyles(styles)(Settlement);