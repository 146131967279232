import { parseISO } from 'date-fns';
var baseUrl = "https://crm.cinema.com.hk/crm-api";   // Production API server
var apiUrl = baseUrl + "/v1";
//var imageBaseUrl = baseUrl + "/images";
var imageBaseUrl = "https://crm.cinema.com.hk/crmimages";

export const Global = {
    AppName: "CRM",
    AppVersio: "v1.19",
    APIUrl: apiUrl,
    ImageBaseUrl: imageBaseUrl,

    DateFormat: "yyyy-MM-dd",
    MonthFormat: "yyyy-MM",
    DateTimeFormat: "yyyy-MM-dd HH:mm",
    DisplayDateFormat: "dd/MM/yyyy",
    DisplayMonthFormat: "yyyy/MM",
    DisplayDateTimeFormat: "dd/MM/yyyy HH:mm",
    DisplayDateMonthFormat: "dd/MM",

    NotAvailable: "- -",
    TNCDocVersion: "1",

    regions: [
        { value: "HK", label: "Hong Kong (HK)" },
        { value: "MO", label: "Macau (MO)" },
        { value: "CN", label: "China (CN)" }
    ],
    locales: [
        { value: "en", label: "English" },
        { value: "zh", label: "Chinese" }
        // { value: "zh.Hant", label: "Traditional Chinese" },
        // { value: "zh.Hans", label: "Simplified Chinese" },
    ],
    spokenLanguages: [
        { value: "en", label: "English" },
        { value: "zh-cmn", label: "Mandarin Chinese" },
        { value: "zh-yue", label: "Cantonese Chinese" }
    ],
    telephoneCountryCodes: [
        { value: "852", label: "+852" },
        { value: "853", label: "+853" },
        { value: "86", label: "+86" }
    ],
    titles: [
        { value: "mr", label: "Mr." },
        { value: "mrs", label: "Mrs." },
        { value: "miss", label: "Miss" },
        { value: "dr", label: "Dr." }
    ],
    genders: [
        { value: "m", label: "Male" },
        { value: "f", label: "Female" },
        { value: "", label: "Not specified" }
    ],
    subscriptionTypes: [
        { value: "basic", label: "Basic" },
        { value: "advanced", label: "Advanced" }
        // { value: "premium", label: "Premium" },
        // { value: "free", label: "Free" },
    ],
    subscriptionPeriods: [
        { value: "annual", label: "Annual" },
        { value: "weekly", label: "Weekly" },
        { value: "fixed", label: "Fixed" }
        // { value: "monthly", label: "Monthly" },
        // { value: "permanent", label: "Permanent" },
    ],
    subscriptionPeriodUnits: {
        annual: "yr",
        monthly: "mo",
        weekly: "wk"
    },
    periodUnits: [
        { value: "d", label: "day(s)" },
        { value: "m", label: "month(s)" },
        { value: "y", label: "year(s)" }
    ],
    offerTypes: [
        { value: "point", label: "Point" },
        { value: "coupon", label: "Coupon" },
        // { value: "gift", label: "Gift" },
    ],
    offerNatures: [
        { value: "movie", label: "Movie Coupon" },
        { value: "special", label: "Special Coupon" }
    ],
    offerEventTypes: [
        { value: "adhoc", label: "Ad hoc" },
        // { value: "point", label: "Point" },
        { value: "registration", label: "Registration" },
        { value: "subscription", label: "Subscription" },
        { value: "renewal", label: "Renewal" },
        { value: "advance-renewal", label: "Advance Renewal" },
        { value: "birthday", label: "Birthday" }
    ],
    couponEventTypes: [
        { value: "adhoc", label: "Ad hoc" },
        { value: "registration", label: "Registration" },
        { value: "subscription", label: "Subscription" },
        { value: "renewal", label: "Renewal" },
        { value: "advance-renewal", label: "Advance Renewal" },
        { value: "birthday", label: "Birthday" }
    ],
    // pointTypes: [
    //     { value: "point", label: "Point" },
    // ],
    pointEventTypes: [
        { value: "adhoc", label: "Ad hoc" }
    ],
    // couponTypes: [
    //     { value: "coupon", label: "Coupon" },
    // ],
    couponMethods: [
        { value: "claim", label: "Generic" },
        { value: "push", label: "Assigned" },
        { value: "redemption-code", label: "Redemption Code" }
    ],
    couponValidityTypes: [
        { value: "fixed", label: "Period" },
        { value: "custom", label: "Day" }
    ],
    promoCodeNature: [
        { value: "false", label: "Generic"},
        { value: "true", label: "Assigned"},
    ],
    occupationsFREE: [
        { value: "student", label: "Student 學生" },
        { value: "manager.executive", label: "Manager & Executive 經理及行政級人員" },
        { value: "professional", label: "Professional 專業人士" },
        { value: "clerical", label: "Clerical 文職人員" },
        { value: "service.sales", label: "Service & Sales 服務及銷售" },
        { value: "technical", label: "Technical 技術人員" },
        { value: "others", label: "Others 其他", others: true }
    ],
    occupationsBCMM: [
        { value: "student", label: "Student 學生" },
        { value: "education", label: "Education 教育" },
        { value: "medical", label: "Medical 醫療" },
        { value: "it", label: "IT 資訊科技" },
        { value: "bank.finance", label: "Bank/Finance 銀行金融" },
        { value: "media.advertising.design", label: "Media/Advertising/Design 傳播／廣告／設計" },
        { value: "retail.service", label: "Retail/Service/F&B 銷售／服務／餐飲" },
        { value: "professional.service", label: "Professional Service 專業人士" },
        { value: "homemaker", label: "Homemaker 家庭主婦" },
        { value: "retiree", label: "Retiree 退休人士" },
        { value: "general", label: "General 一般" },
        { value: "others", label: "Others 其他", others: true }
    ],
    workMM: [
        {value: "taikoo", label: "Taikoo"},
        {value: "admiralty", label: "Admiralty"},
        {value: "others", label: "None of the above"},
    ],
    educationsBC: [
        { value: "primary.secondary", label: "Primary/Secondary School 小學／中學" },
        { value: "college", label: "College 專上教育" },
        { value: "university", label: "University 大學" },
        { value: "postgraduate", label: "Postgraduate 碩士或以上" }
    ],
    educationsMM: [
        { value: "secondary", label: "Secondary School 中學" },
        { value: "undergraduate", label: "Undergraduate 大學" },
        { value: "postgraduate", label: "Postgraduate 碩士或以上" }
    ],
    incomeRangesFREE: [
        { value: "0-5000", label: "$5,000 or below" },
        { value: "5001-10000", label: "$5,001 - $10,000" },
        { value: "10001-15000", label: "$10,001 - $15,000" },
        { value: "15001-20000", label: "$15,001 - $20,000" },
        { value: "20001-25000", label: "$20,001 - $25,000" },
        { value: "25001-30000", label: "$25,001 - $30,000" },
        { value: "30001-999999", label: "$30,001 or above" }
    ],
    incomeRangesBCMM: [
        { value: "0-20000", label: "$20,000 or below" },
        { value: "20001-30000", label: "$20,001 - $30,000" },
        { value: "30001-40000", label: "$30,001 - $40,000" },
        { value: "40001-50000", label: "$40,001 - $50,000" },
        { value: "50001-60000", label: "$50,001 - $60,000" },
        { value: "60001-999999", label: "$60,001 or above" },
    ],
    mmSources: [
        { value: "mmm", label: "MMM (Magazine)" },
        { value: "mm-cinema", label: "MOViE MOViE Cityplaza Cinema" },
        { value: "pp-cinema", label: "MOViE MOViE Pacific Place Cinema" },
        { value: "mm-facebook", label: "MOViE MOViE Facebook" },
        { value: "mm-web", label: "MOViE MOViE Website" },
        { value: "cinema.com.hk", label: "Cinema.com.hk" },
        { value: "nowtv", label: "NowTV" },
        { value: "friends", label: "Recommended by friends" },
        { value: "websites", label: "Other Websites", others: true },
        { value: "others", label: "Others", others: true }
    ],
    areas: [
        { value: "hk", label: "Hong Kong Island 香港島" },
        { value: "kln", label: "Kowloon 九龍" },
        { value: "nt", label: "New Territories 新界" },
        { value: "i", label: "Outlying Islands 離島" }
    ],
    districts: [
        { value: "hk.cw", label: "Central & Western 中西區" },
        { value: "hk.e", label: "Eastern 東區" },
        { value: "hk.s", label: "Southern 南區" },
        { value: "hk.wc", label: "Wan Chai 灣仔區" },

        { value: "kln.kc", label: "Kowloon City 九龍城區" },
        { value: "kln.kt", label: "Kwun Tong 觀塘區" },
        { value: "kln.ssp", label: "Sham Shui Po 深水埗區" },
        { value: "kln.wts", label: "Wong Tai Sin 黃大仙區" },
        { value: "kln.ytm", label: "Yau Tsim Mong 油尖旺區" },

        { value: "nt.kt", label: "Kwai Tsing 葵青區" },
        { value: "nt.n", label: "North 北區" },
        { value: "nt.sk", label: "Sai Kung 西貢區" },
        { value: "nt.st", label: "Sha Tin 沙田區" },
        { value: "nt.tp", label: "Tai Po 大埔區" },
        { value: "nt.tw", label: "Tsuen Wan 荃灣區" },
        { value: "nt.tm", label: "Tuen Mun 屯門區" },
        { value: "nt.yl", label: "Yuen Long 元朗區" },

        { value: "i.l", label: "Lantau Island 大嶼山" },
        { value: "i.cc", label: "Cheung Chau 長洲" },
        { value: "i.lm", label: "Lamma Island 南丫島" },
        { value: "i.pc", label: "Peng Chau 坪洲" },
        { value: "i.o", label: "Others 其他" }
    ],
    subdistricts: [
        { value: "hk.cw.a", label: "Admiralty 金鐘" },
        { value: "hk.cw.kt", label: "Kennedy Town 堅尼地城" },
        { value: "hk.cw.c", label: "Central 中環" },
        { value: "hk.cw.m", label: "Mid-levels 半山" },
        { value: "hk.cw.sw", label: "Sai Wan 西環" },
        { value: "hk.cw.syp", label: "Sai Ying Pun 西營盤" },
        { value: "hk.cw.shw", label: "Sheung Wan 上環" },
        { value: "hk.cw.tp", label: "The Peak 山頂" },

        { value: "hk.e.cw", label: "Chai Wan 柴灣" },
        { value: "hk.e.fh", label: "Fortress Hill 炮台山" },
        { value: "hk.e.hfc", label: "Heng Fa Chuen 杏花村" },
        { value: "hk.e.np", label: "North Point 北角" },
        { value: "hk.e.qb", label: "Quarry Bay 鰂魚涌" },
        { value: "hk.e.swh", label: "Sai Wan Ho 西灣河" },
        { value: "hk.e.skw", label: "Shau Kei Wan 筲箕灣" },
        { value: "hk.e.ssw", label: "Siu Sai Wan 小西灣" },
        { value: "hk.e.tk", label: "Tai Koo 太古" },
        { value: "hk.e.th", label: "Tin Hau 天后" },

        { value: "hk.s.a", label: "Aberdeen 香港仔" },
        { value: "hk.s.alc", label: "Ap Lei Chau 鴨脷洲" },
        { value: "hk.s.dwb", label: "Deep Water Bay 深水灣" },
        { value: "hk.s.pfl", label: "Pok Fu Lam 薄扶林" },
        { value: "hk.s.rb", label: "Repulse Bay 淺水灣" },
        { value: "hk.s.so", label: "Shek O 石澳" },
        { value: "hk.s.s", label: "Stanley 赤柱" },
        { value: "hk.s.wf", label: "Wah Fu 華富" },
        { value: "hk.s.wch", label: "Wong Chuk Hang 黃竹坑" },

        { value: "hk.wc.cb", label: "Causeway Bay 銅鑼灣" },
        { value: "hk.wc.hv", label: "Happy Valley 跑馬地" },
        { value: "hk.wc.th", label: "Tai Hang 大坑" },
        { value: "hk.wc.wc", label: "Wan Chai 灣仔" },

        { value: "kln.kc.hmt", label: "Ho Man Tin 何文田" },
        { value: "kln.kc.hh", label: "Hung Hom 紅磡" },
        { value: "kln.kc.kt", label: "Kai Tak 啟德" },
        { value: "kln.kc.klc", label: "Kowloon City 九龍城" },
        { value: "kln.kc.klt", label: "Kowloon Tong 九龍塘" },
        { value: "kln.kc.mtw", label: "Ma Tau Wai 馬頭圍" },
        { value: "kln.kc.tkw", label: "To Kwa Wan 土瓜灣" },
        { value: "kln.kc.w", label: "Whampoa 黃埔" },

        { value: "kln.kt.kb", label: "Kowloon Bay 九龍灣" },
        { value: "kln.kt.kt", label: "Kwun Tong 觀塘" },
        { value: "kln.kt.lt", label: "Lam Tin 藍田" },
        { value: "kln.kt.ntk", label: "Ngau Tau Kok 牛頭角" },
        { value: "kln.kt.smp", label: "Sau Mau Ping 秀茂坪" },
        { value: "kln.kt.sl", label: "Shun Lee 順利" },
        { value: "kln.kt.yt", label: "Yau Tong 油塘" },

        { value: "kln.ssp.csw", label: "Cheung Sha Wan 長沙灣" },
        { value: "kln.ssp.lck", label: "Lai Chi Kok 荔枝角" },
        { value: "kln.ssp.mf", label: "Mei Foo 美孚" },
        { value: "kln.ssp.nm", label: "Nam Cheong 南昌" },
        { value: "kln.ssp.ssp", label: "Sham Shui Po 深水埗" },
        { value: "kln.ssp.skm", label: "Shek Kip Mei 石硤尾" },

        { value: "kln.wts.ch", label: "Choi Hung 彩虹" },
        { value: "kln.wts.dh", label: "Diamond Hill 鑽石山" },
        { value: "kln.wts.lf", label: "Lok Fu 樂富" },
        { value: "kln.wts.ncw", label: "Ngau Chi Wan 牛池灣" },
        { value: "kln.wts.spk", label: "San Po Kong 新蒲崗" },
        { value: "kln.wts.tws", label: "Tsz Wan Shan 慈雲山" },
        { value: "kln.wts.wth", label: "Wang Tau Hom 橫頭磡" },
        { value: "kln.wts.wts", label: "Wong Tai Sin 黃大仙" },

        { value: "kln.ytm.a", label: "Austin 柯士甸" },
        { value: "kln.ytm.j", label: "Jordan 佐敦" },
        { value: "kln.ytm.mk", label: "Mong Kok 旺角" },
        { value: "kln.ytm.pe", label: "Prince Edward 太子" },
        { value: "kln.ytm.tkt", label: "Tai Kok Tsui 大角咀" },
        { value: "kln.ytm.tst", label: "Tsim Sha Tsui 尖沙咀" },
        { value: "kln.ytm.ymt", label: "Yau Ma Tei 油麻地" },

        { value: "nt.kt.kc", label: "Kwai Chung 葵涌" },
        { value: "nt.kt.kf", label: "Kwai Fong 葵芳" },
        { value: "nt.kt.kh", label: "Kwai Hing 葵興" },
        { value: "nt.kt.lk", label: "Lai King 茘景" },
        { value: "nt.kt.ty", label: "Tsing Yi 青衣" },

        { value: "nt.n.f", label: "Fanling 粉嶺" },
        { value: "nt.n.kt", label: "Kwu Tung 古洞" },
        { value: "nt.n.nd", label: "North District 北區" },
        { value: "nt.n.pc", label: "Ping Che 坪輋" },
        { value: "nt.n.stk", label: "Sha Tau Kok 沙頭角" },
        { value: "nt.n.sh", label: "Sheung Shui 上水" },
        { value: "nt.n.tkl", label: "Ta Kwu Ling 打鼓嶺" },

        { value: "nt.sk.hh", label: "Hang Hau 坑口" },
        { value: "nt.sk.lp", label: "LOHAS Park 康城" },
        { value: "nt.sk.pl", label: "Po Lam 寶琳" },
        { value: "nt.sk.sk", label: "Sai Kung 西貢" },
        { value: "nt.sk.tkl", label: "Tiu Keng Leng 調景嶺" },
        { value: "nt.sk.tko", label: "Tseung Kwan O 將軍澳" },

        { value: "nt.st.ft", label: "Fo Tan 火炭" },
        { value: "nt.st.mls", label: "Ma Liu Shui 馬料水" },
        { value: "nt.st.mos", label: "Ma On Shan 馬鞍山" },
        { value: "nt.st.st", label: "Sha Tin 沙田" },
        { value: "nt.st.sm", label: "Shek Mun 石門" },
        { value: "nt.st.sly", label: "Siu Lek Yuen 小瀝源" },
        { value: "nt.st.tw", label: "Tai Wai 大圍" },
        { value: "nt.st.wc", label: "Wo Che 禾輋" },

        { value: "nt.tp.lt", label: "Lam Tsuen 林村" },
        { value: "nt.tp.tw", label: "Tai Wo 太和" },
        { value: "nt.tp.tp", label: "Tai Po 大埔" },
        { value: "nt.tp.tph", label: "Tai Po Hui 大埔墟" },
        { value: "nt.tp.tpk", label: "Tai Po Kau 大埔滘" },
        { value: "nt.tp.tk", label: "Ting Kok 汀角" },

        { value: "nt.tw.st", label: "Sham Tseng 深井" },
        { value: "nt.tw.twh", label: "Tai Wo Hau 大窩口" },
        { value: "nt.tw.tk", label: "Ting Kau 汀九" },
        { value: "nt.tw.tw", label: "Tsuen Wan 荃灣" },

        { value: "nt.tm.lt", label: "Lam Tei 藍地" },
        { value: "nt.tm.sh", label: "San Hui 新墟" },
        { value: "nt.tm.sl", label: "Siu Lam 小欖" },
        { value: "nt.tm.skw", label: "So Kwun Wat 掃管笏" },
        { value: "nt.tm.tl", label: "Tai Lam 大欖" },
        { value: "nt.tm.tm", label: "Tuen Mun 屯門" },

        { value: "nt.yl.ht", label: "Ha Tsuen 厦村" },
        { value: "nt.yl.ksr", label: "Kam Sheung Road 錦上路" },
        { value: "nt.yl.kt", label: "Kam Tin 錦田" },
        { value: "nt.yl.ph", label: "Pat Heung 八鄉" },
        { value: "nt.yl.ps", label: "Ping Shan 屏山" },
        { value: "nt.yl.st", label: "San Tin 新田" },
        { value: "nt.yl.sk", label: "Shek Kong 石崗" },
        { value: "nt.yl.tt", label: "Tai Tong 大棠" },
        { value: "nt.yl.tm", label: "Tam Mei 潭尾" },
        { value: "nt.yl.tsw", label: "Tin Shui Wai 天水圍" },
        { value: "nt.yl.yl", label: "Yuen Long 元朗" },

        { value: "i.l.clk", label: "Chek Lap Kok 赤臘角" },
        { value: "i.l.cs", label: "Cheung Sha 長沙" },
        { value: "i.l.db", label: "Discovery Bay 愉景灣" },
        { value: "i.l.mw", label: "Ma Wan 馬灣" },
        { value: "i.l.mwo", label: "Mui Wo 梅窩" },
        { value: "i.l.np", label: "Ngong Ping 昂坪" },
        { value: "i.l.po", label: "Pui O 貝澳" },
        { value: "i.l.to", label: "Tai O 大澳" },
        { value: "i.l.tf", label: "Tong Fuk 塘福" },
        { value: "i.l.tc", label: "Tung Chung 東涌" }
    ],
    preferContactMethods: [
        { value: "email", label: "E-mail" },
        { value: "sms", label: "SMS" }
    ],
    miscFlags: [
        { value: "accept-newsletter", label: "Accept receiving marketing / promotional updates via E-mail or SMS" },
        { value: "nowtv", label: "NowTV Subscriber" },
        { value: "nowtv-ch116", label: "NowTV Ch116 Subscriber" },
        { value: "work-taikoo", label: "Work in Taikoo" },
        { value: "residence-taikoo", label: "Residence of Taikoo" }
    ],
    paymentMethods: [
        { value: "visa", label: "Visa" },
        { value: "master", label: "Mastercard" },
        { value: "ae", label: "AMEX" },
        { value: "octopus", label: "Octopus Card" },
        { value: "cash", label: "Cash" },
//        { value: "credit", label: "Credit" },
        { value: "cheque", label: "Cheque" }
    ],
    templateTypes: [
        { value: "sms", label: "SMS" },
        { value: "email", label: "E-mail" }
    ],
    templateCategories: [
        { value: "otp", label: "Mobile Verification Code", type: "sms" },
        { value: "reg-new", label: "New Registration", type: "email" },
        { value: "reg-new-activation", label: "New Registration Activation", type: "email" },
        { value: "reg-new-greet", label: "New Registration Greeting", type: "email" },
        { value: "sub-new", label: "New Subscription", type: "email" },
        { value: "sub-new-activation", label: "New Subscription Activation", type: "email" },
        { value: "sub-new-greet", label: "New Subscription Greeting", type: "email" },
        { value: "sub-renewal", label: "Subscription Renewal", type: "email" },
        { value: "sub-renewal-reminder", label: "Subscription Renewal Reminder", type: "email" },
        { value: "birthday", label: "Birthday Message", type: "email" },
        { value: "reset-password", label: "Reset Password", type: "email" }
    ],
    templateFormats: [
        { value: "text", label: "Text" },
        { value: "html", label: "HTML" }
    ],
    offerRules: [
        { value: "non-exclusive", label: "Can use with others" },
        { value: "buy_one_get_one", label: "Member Buy 1 Get 1" },
        { value: "multi_ticket", label: "Multiple ticket redemption" },
        { value: "coupon_per_trans", label: "Max coupons per transaction" },
        { value: "coupon_per_member", label: "Max coupons per member" },
        { value: "points_eligible", label: "Coupon point eligible" },
        { value: "points_required", label: "Number of points required" },
        { value: "dynamic_redemption_code", label: "Real time promo code" },
    ],
    cinemas: [
        { value: "3", label: "Tsuen Wan", code: "TW" },
        { value: "5", label: "Mong Kok", code: "MK" },
        { value: "6", label: "Kwai Fong", code: "KF" },
        { value: "9", label: "Kingswood", code: "KG" },
        { value: "10", label: "Cinematheque", code: "BC" },
        { value: "12", label: "ifc", code: "PI" },
        { value: "13", label: "Cyberport", code: "CP" },
        { value: "14", label: "apm", code: "AP" },
        { value: "17", label: "The ONE", code: "TO" },
        { value: "18", label: "Hollywood", code: "HW" },
        { value: "19", label: "YOHO MALL", code: "YH" },
        { value: "20", label: "Cityplaza", code: "MM" },
        { value: "21", label: "ELEMENTS", code: "PE" },
        { value: "22", label: "MOKO", code: "MO" }
    ],
    memberStatuses: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "banned", label: "Banned" },
        { value: "deleted", label: "Deleted" }
    ],
    membershipStatuses: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "expired", label: "Expired" }
    ],
    userStatuses: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" }
    ],
    planStatuses: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" }
    ],
    credentialStatuses:[
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "locked", label: "Locked" }
    ],
    templateStatuses: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" }
    ],
    offerStatuses: [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" },
        { value: "expired", label: "Expired" }
    ],
    orderStatuses: [
        { value: "pending", label: "Pending" },
        { value: "paid", label: "Paid" },
        { value: "expired", label: "Expired" }
        // { value: "invoiced", label: "Invoiced" },
        // { value: "authorised", label: "Authorized" },
        // { value: "voided", label: "Voided" },
        // { value: "refunded", label: "Refunded" }
    ],
    dept_id: [
        { value: "AMC", label: "AMC" },
        { value: "APM", label: "APM" },
        { value: "BC", label: "BC" },
        { value: "BCMEMBER", label: "BCMEMBER" },
        { value: "CP", label: "CP" },
        { value: "HWB", label: "HWB" },
        { value: "IFC", label: "IFC" },
        { value: "KF", label: "KF" },
        { value: "KG", label: "KG" },
        { value: "MK", label: "MK" }
    ],
    transClasses: [
        { value: "subscription", label: "Membership" },
        { value: "point", label: "Point" },
        { value: "coupon", label: "Coupon" },
    ],
    transTypes: [
        { value: "subscription", label: "Subscription" },
        { value: "expiration", label: "Expiration" },
        { value: "termination", label: "Termination" },
        { value: "reward", label: "Reward" },
        { value: "claim", label: "Claim" },
        { value: "redemption", label: "Redemption" },
        { value: "adjustment", label: "Adjustment" }
    ],
    quotaTypes: [
        { value: "subscription", label: "Subscription" },
        { value: "offer", label: "Offer" }
    ],
    channels: [
        { value: "CRM", label: "CRM" },
        { value: "WEB", label: "WEB" },
        { value: "APP", label: "APP" },
         { value: "BO", label: "BO" },
         { value: "KIOSK", label: "KIOSK" },
    ],
    activationType: [
        {value: "registration", label: "Registration Activation"},
        {value: "subscription", label: "Subscription Activation"},
    ],
    userRoles: [
        { value: "dashboard", label: "Dashboard", group: "GENERAL", spacing: false },
        { value: "org", label: "Organisation management" },
        { value: "plan", label: "Subscription plan management" },
        { value: "member", label: "Member management" },
        { value: "member_reg", label: "Member registration" },
        { value: "member_activation", label: "Member activation" },
        { value: "member_reset_pass", label: "Member reset password" },
        { value: "member_blacklist", label: "Member blacklist" },
        { value: "trans_sub", label: "Transactions (subscription)" },
        { value: "order", label: "Subscription orders" },
        { value: "settlement", label: "Daily settlement" },
        { value: "settlement-report", label: "Daily settlement report" },

        { value: "tpl", label: "Email template management", group: "EMAIL MANAGEMENT", spacing: true },
        { value: "member_export", label: "Export member list" },

        { value: "offer_point", label: "Bonus point campaign offers", group: "BONUS POINTS", spacing: true },
        { value: "offer_point_adj", label: "Subscription/Point Adjustment" },
        { value: "trans_point", label: "Transaction (bonus point)" },

        { value: "offer_coupon", label: "Coupon campaign offers", group: "COUPONS", spacing: true },
        { value: "trans_coupon", label: "Transaction (coupon)" },

        { value: "user", label: "User management", group: "OTHERS", spacing: true },
        { value: "user_activate", label: "User activation/deactivation" },
        { value: "dept", label: "Department management" },
        { value: "quota", label: "Quota management" },
        { value: "analytic_access", label: "Analytic Access" }
    ],
    errors: {
        Success: "Success",
        ErrorAccessDenied: "Access denied",
        ErrorServer: "Server error",
        ErrorService: "Service not available",
        ErrorUnsupport: "Unsupport",
        ErrorExpired: "Expired",
        ErrorRejected: "Rejected",
        ErrorStatus: "Invalid status",
        ErrorRequired: "Required",
        ErrorConfiguration: "Configuration error",
        ErrorProcessing: "Processing",
        ErrorPending: "Pending",
        ErrorAlreadyExists: "Already exists",
        ErrorEmailAlreadyExists: "E-mail address already exists",
        ErrorEmailBlacklisted: "E-mail address blacklisted",
        ErrorMSISDNAlreadyExists: "Mobile number already exists",
        ErrorMSISDNBlacklisted: "Mobile number blacklisted",
        ErrorUsernameAlreadyExists: "Username already exists",
        ErrorMissingParameter: "Missing parameter",
        ErrorInvalidParameter: "Invalid parameter",
        ErrorInvalidFormat: "Invalid format",
        ErrorInvalidValue: "Invalid value",
        ErrorNegativeValue: "Negative value",
        ErrorExceedMaxLength: "Exceed maximum length",
        ErrorInvalidAuthHeader: "Invalid or malformed authorization header",
        ErrorMissingAuthHeader: "Missing authorization header",
        ErrorAccessTokenInvalidated: "Access token had already been invalidated",
        ErrorInvalidAccessToken: "Invalid access token",
        ErrorInvalidEmail: "Invalid e-mail format",
        ErrorInvalidUsername: "Invalid username format",
        ErrorInvalidPhoneNumber: "Invalid phone number format",
        ErrorInvalidPassword: "Invalid password",
        ErrorWeakPassword: "Password is too weak",
        ErrorConfirmPasswordMismatch: "Password and confirmation password do not match",
        ErrorAuthAccountNotFound: "Account not found",
        ErrorAuthEmailNotFound: "E-mail address not found",
        ErrorAuthMSISDNNotFound: "Mobile number not found",
        ErrorAuthUsernameNotFound: "Username not found",
        ErrorAuthAccountDisabled: "Account disabled",
        ErrorAuthAccountLocked: "Account locked",
        ErrorAuthFailed: "Authentication failed",
        ErrorOTPMismatch: "Verification code mismatch",
        ErrorExceedMaximumRetry: "Too many failed attempts",
        ErrorInsufficientPoints: "Insufficient points",
        ErrorInsufficientQuota: "Insufficient quota",
        ErrorNotWithinRenewalPeriod: "Not within renewal period",
        ErrorNotWithinGracePeriod: "Not within grace period",
        ErrorNotEligible: "Member not eligible for the offer",
        ErrorAlreadyRedeemed: "Already redeemed",
        ErrorEndTimeEarlier: "End date time earlier than start date time",
        ErrorNoChanged: "Not changed"
    },
    colors: {
        active: '#2bbbad',
        paid: '#2bbbad',
        pending: '#ff8800',
        processing: '#ff8800',
        banned: '#cc0000',
        deleted: '#cc0000',
        locked: '#cc0000',
        invalid: '#cc0000',
        inactive: '#cccccc',
        expired: '#cccccc'
    },
    listColor,

    getNestedValue,
    getValueLabelPair,
    getValueLabel,

    getMemberName,
    getImage,
    getElapsedPeriod,

    occupations,
    educations,
    incomeRanges,
    miscOptions,
    
    getBirthYearRange
};

function getValueLabelPair(array, value) {
    if (!array || !value) return null;
    for (let i of array) {
        if (i.value === value) return i;
    }
    return null;
}

function getValueLabel(array, value) {
    let item = getValueLabelPair(array, value);
    if (item) {
        return item.label;
    }
    return null;
}

function occupations(org) {
    if (org === "FREE") {
        return Global.occupationsFREE;
    } else {
        return Global.occupationsBCMM;
    }
}

function educations(org) {
    if (org === "BC") {
        return Global.educationsBC;
    } else if (org === "MM") {
        return Global.educationsMM;
    } else {
        return [];
    }
}

function incomeRanges(org) {
    if (org === "FREE") {
        return Global.incomeRangesFREE;
    } else {
        return Global.incomeRangesBCMM;
    }
}

function miscOptions(org) {
    if (org === "FREE" || org === "BC") {
        return [
            { value: "accept-newsletter", label: "Accept receiving marketing / promotional updates via E-mail or SMS" }
        ];
    } else if (org === "MM") {
        return [
            { value: "accept-newsletter", label: "Accept receiving marketing / promotional updates via E-mail or SMS" },
            { value: "nowtv", label: "NowTV Subscriber" },
            { value: "nowtv-ch116", label: "NowTV Ch116 Subscriber" },
            { value: "work-taikoo", label: "Work in Taikoo" },
            { value: "residence-taikoo", label: "Residence of Taikoo" }
        ];
    }
    return Global.miscFlags;
}

function getFieldValue(obj, paths, index = 0) {
    if (index >= paths.length) return null;
    let child = obj[paths[index]];
    if (!child) return null;
    if (index < paths.length - 1) {
        if (child instanceof Object) {
            return getFieldValue(child, paths, index + 1);
        } else {
            return null;
        }
    } else {
        return child;
    }
}

function getNestedValue(obj, name) {
    if (obj == null) return null;
    let paths = name.split(".");
    if (paths.length > 1) {
        return getFieldValue(obj, paths);
    }
    return obj[name];
}

function getImage(obj, key, query) {
    if (obj === null || JSON.stringify(obj) === '{}'
            || JSON.stringify(obj) === undefined) return null;   // default
    var url = obj[key];
    if (!url) {
        for (var k in obj) {
            url = obj[k];
            if (url) break;
        }
    }
    if (url.startsWith("https") || url.startsWith("http")) return url;  // absolute url
    return Global.ImageBaseUrl + "/" + url + "?" + query;
}

function getMemberName(obj, locale) {
    if (!obj) return null;
    if (!obj.profile || !obj.profile.names) return Global.NotAvailable;
    let names = obj.profile.names;
    var name = null;
    for (var l in names) {
        if (!locale || l.startsWith(locale)) {
            name = names[l];
            break;
        }
    }
    if (!name) return Global.NotAvailable;
    if (name.full && name.full.length > 0) return name.full;
    let first = name.first;
    let last = name.last;
    if (first && first.length > 0 && last && last.length > 0) {
        if (locale === "en") {
            return first + " " + last;
        } else {
            return last + first;
        }
    } else if (first && first.length > 0) {
        return first;
    } else if (last && last.length > 0) {
        return last;
    }
    return Global.NotAvailable;
}

function getElapsedPeriod(start, end) {
    if (!start || !end) return 0;
    let st = parseISO(start);
    let et = parseISO(end);
    if (st && et) {
        if (st >= et) return 0;
        let now = new Date();
        let duration = et.getTime() - st.getTime();
        let elapsed = now.getTime() - st.getTime();
        return 100 * (elapsed / duration);
    }
    return 0;
}

function listColor(status) {
    switch (status) {
        case "locked": case "banned": case "voided": case "refunded": case "deleted":
            return "#c00";
        case "inactive": case "expired": case "created":
            return "#999";
        default:
            return "#212121";
    }
}

function getBirthYearRange(current_year){
    var diff = parseInt(current_year - 1960);
    var year_range = [{value: '-1960', label: '1960 or before'}];
    for(var i = 0; i < diff; i++){
//            if(i % 10 == 0 && diff - i <= 10 && diff - 1 != i){
//                var head = 1970 + parseInt(i+1);
//                var end = 1970 + parseInt(i-1) + parseInt(diff - i);
//                year_range.push({value: head + "-" + end, label: head + " - " + end});
//            }else 
        if(i % 5 === 0 && diff - i > 5){
            var head = 1960 + parseInt(i+1);
            var end = 1960 + parseInt(i+1) + 4;
            year_range.push({value: head + "-" + end, label: head + "-" + end});
        }
    }
    
    return year_range;
}
// red: cc0000, 444444
// orange: ff8800 ffbb33
// green: 007e33 00c851
// blue: 0099cc 33b5e5
// dark green: 00695c 2bbbad
// dark blue: 0d47a1 4285f4
// purple: 9933cc aa66cc
// black: 212121 2e2e2e
// gray: 3e4551 4b515d
