import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { E164 } from '../helpers/E164';
import { Global } from '../Global';

export default class PhoneInput extends React.Component {
    constructor(props) {
        super(props);

        let value = E164.parse(this.props.value);
        if (value.cc === "") {
            value.cc = Global.telephoneCountryCodes[0].value;
        }
        this.state = {
            cc: value.cc,
            number: value.number,
        };
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            let value = E164.parse(nextProps.value);
            if (value.cc === "") {
                value.cc = Global.telephoneCountryCodes[0].value;
            }
            this.setState({ cc: value.cc, number: value.number })
        }
    }

    update = () => {
        var value;
        let { cc, number } = this.state;
        if (cc.length > 0) {
            value = "+" + cc + number;
        } else {
            value = number;
        }
        this.props.onChange(this.props.id, value);
    }
    handleNumber = event => {
        this.setState({ number: event.target.value }, () => {
            this.update();
        });
    };
    handleCountryCode = event => {
        this.setState({ cc: event.target.value }, () => {
            this.update();
        });
    };
    render() {
        const { label } = this.props;

        return (
            <div style={{ display: 'flex' }}>
                <TextField
                    label={label}
                    value={this.state.cc}
                    onChange={this.handleCountryCode}
                    select
                    required={this.props.required}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ width: 80 }}
                >
                    {Global.telephoneCountryCodes.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    required={this.props.required}
                    value={this.state.number}
                    onChange={this.handleNumber}
                    style={{ alignSelf: 'flex-end', flex: 1, marginLeft: 8 }}
                />
            </div>
        );
    }
}
