import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, FormControl, FormHelperText, TextField, Table, TableHead, TableBody, TableRow, TableCell, MenuItem, Typography, withStyles } from '@material-ui/core';
import {
    Warning as WarningIcon
} from '@material-ui/icons';
import FormBase from '../../components/FormBase';
import { DateLabel, CurrencyLabel } from '../../components/Label';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class SubscribePaymentForm extends FormBase {
    constructor(props) {
        super(props);

        this.state = {
            pay_method: "",
            pay_txn_no: "",
            order: props.order,
            errors: {}
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onNext(this.commit);
    }
    commit = () => {
        if (!this.validateForm()) {
            return;
        }
        var { order } = this.state;
        order.pay_method = this.state.pay_method;
        order.pay_txn_no = this.state.pay_txn_no.trim();
        this.props.handler(order);
    }
    validateForm() {
        var errors = {};
        if (this.state.pay_method.length === 0) {
            errors["pay_method"] = Global.errors["ErrorRequired"];
        }

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }

    render() {
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <form className={classes.form}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4" gutterBottom>Payment</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} align="right">
                                {!Validator.isEmpty(this.state.errors) &&
                                    <WarningIcon color="primary" />
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} align="left">
                                <Typography variant="caption" color="textSecondary">ORDER NO</Typography>
                                <Typography variant="h6" color="textPrimary">{this.state.order.order_id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} align="right">
                                <Typography variant="caption" color="textSecondary">ORDER DATE</Typography>
                                <Typography variant="h6" color="textPrimary"><DateLabel date={this.state.order.created_at} format={Global.DisplayDateTimeFormat} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ITEM CODE</TableCell>
                                            <TableCell>DESCRIPTION</TableCell>
                                            <TableCell align="right">QTY</TableCell>
                                            <TableCell align="right">UNIT PRICE ({this.state.order.ccy})</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.order.items.map(item => (
                                            <TableRow key={item.code}>
                                                <TableCell>
                                                    <Typography variant="body1">{item.code}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body1">{item.name}</Typography>
                                                </TableCell>
                                                <TableCell align="right">{item.qty}</TableCell>
                                                <TableCell align="right">
                                                    <CurrencyLabel data={item.price} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell rowSpan={3} colSpan={2} />
                                            <TableCell align="right"><Typography color="textSecondary">TOTAL ({this.state.order.ccy})</Typography></TableCell>
                                            <TableCell align="right">
                                                <Typography variant="h5">
                                                    <CurrencyLabel data={this.state.order.total} />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"><Typography color="textSecondary">PAYMENT METHOD</Typography></TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="pay_method"
                                                    name="pay_method"
                                                    select
                                                    value={this.state.pay_method}
                                                    onChange={this.handleSelect("pay_method")}
                                                    required
                                                    fullWidth
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                >
                                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                                    {Global.paymentMethods.map(option => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                                {errors["pay_method"] && <FormHelperText error={true}>{errors["pay_method"]}</FormHelperText>}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"><Typography color="textSecondary">REF NO</Typography></TableCell>
                                            <TableCell align="right">
                                                <TextField
                                                    id="pay_txn_no"
                                                    name="pay_txn_no"
                                                    label=""
                                                    value={this.state["pay_txn_no"]}
                                                    onChange={this.handleChange}
                                                    fullWidth
                                                    required
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                                {errors["pay_txn_no"] && <FormHelperText error={true}>{errors["pay_txn_no"]}</FormHelperText>}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Paper>
                </FormControl>
            </form >
        );
    }
}

SubscribePaymentForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SubscribePaymentForm);