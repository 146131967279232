import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, TextField, Typography, withStyles } from '@material-ui/core';
import { Label } from '../../components/Label';
import FormBase from '../../components/FormBase';
import HTMLEditor from '../../components/HTMLEditor';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class EditPage extends FormBase {
    constructor(props) {
        super(props);

        this.tpl = {
            tpl_id: "",
            org_id: "",
            type: "",
            category: "",
            plan_code: "",
            format: "",
            messages: {
                zh: {
                    subject: "",
                    content: ""
                },
                en: {
                    subject: "",
                    content: ""
                }
            }
        };
        this.state = {
            isSubmitting: false,
            errors: {}
        };
        Validator.flattenNested(this.tpl, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Template" });
    }
    componentDidMount() {
        APIService.get(APIService.TEMPLATE, this.props.match.params.id)
            .then(tpl => {
                var map = {};
                Validator.flattenNested(tpl, map);
                this.setState(map);
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                this.props.history.goBack();
            });
    }
    onContentChange = (id, content) => {
        this.setState({ [id]: content });
    }

    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.tpl, this.state);
        if (!this.validateForm()) return;

        let output = JSON.parse(JSON.stringify(this.tpl));
        Validator.compact(output);
        this.setState({
            isSubmitting: true
        });

        APIService.update(APIService.TEMPLATE, output, output.tpl_id)
            .then(result => {
                this.props.alert("Template updated", "success");
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }

    render() {
        if (!AuthService.userHasRole(['tpl'])) return null;
        const { classes } = this.props;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4"><Label data={Global.templateCategories} value={this.state.category} /></Typography>
                                <Typography variant="body1" color="textSecondary">ID: {this.state.tpl_id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} align="right">
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">ORGANISATION</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={this.state.org_id} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">PLAN CODE</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={this.state.plan_code} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="messages.en.subject"
                                    name="messages.en.subject"
                                    label="SUBJECT (ENGLISH)"
                                    value={this.state["messages.en.subject"]}
                                    onChange={this.handleChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {this.state.type === "email" &&
                                    <React.Fragment>
                                        <div style={{ marginTop: 24 }}></div>
                                        <Typography variant="caption" color="textSecondary">CONTENT (ENGLISH)</Typography>
                                        <HTMLEditor id="messages.en.content" content={this.state["messages.en.content"]} onChange={this.onContentChange} />
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="messages.zh.subject"
                                    name="messages.zh.subject"
                                    label="SUBJECT (CHINESE)"
                                    value={this.state["messages.zh.subject"]}
                                    onChange={this.handleChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {this.state.type === "email" &&
                                    <React.Fragment>
                                        <div style={{ marginTop: 24 }}></div>
                                        <Typography variant="caption" color="textSecondary">CONTENT (CHINESE)</Typography>
                                        <HTMLEditor id="messages.zh.content" content={this.state["messages.zh.content"]} onChange={this.onContentChange} />
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form >
        );
    }
}

EditPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditPage);