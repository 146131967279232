import React from 'react';
import { Dialog, DialogContent, Grid, withStyles, Typography } from '@material-ui/core';
// import {} from '@material-ui/icons';

const styles = theme => ({
    dialogPaper: {
        minWidth: '80vw',
        maxWidth: '80vw',
        minHeight: '80vh',
        maxHeight: '80vh'
    }
});

class Carousel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: [],
            open: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (images) => {
        this.setState({ open: true, images: images });
    };
    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const { images } = this.state;
        const names = {
            idphoto: "Member Image (for Insight Pass and Library Services)",
            photo: "Member IN-APP Profile Image",
            fb: "Facebook Photo"
        };

        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogContent>
                <Grid container m={24} justify="center">
                    {images.map(image => (
                        <Grid item xs={12} sm={6} key={image.name}>
                            <Typography variant="h6" color="textPrimary">{names[image.name]}</Typography>
                            <img alt={image.name} src={image.url} style={{height: '100%', width: '100%', objectFit: 'contain'}}/>
                        </Grid>
                    ))};
                </Grid>
            </DialogContent>
        </Dialog>
    }
}

export default withStyles(styles)(Carousel);