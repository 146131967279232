import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, IconButton, Toolbar, InputBase, InputAdornment, TextField, MenuItem, Divider, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import {
    AddCircleOutline as AddIcon,
    Sync as ResetIcon,
    Search as SearchIcon,
    Clear as ClearIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { APIService } from '../../services/API';
import { NameService } from '../../services/Name';
import { AuthService } from '../../services/Auth';
import { Global } from '../../Global';
import { loadList, saveList, updateList } from '../../List';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    }
});

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        var filters = props.filters;
        if (filters) {
            filters.keyword = props.keyword;
        } else {
            filters = {
                keyword: props.keyword,
                org_id: "",
                dept_id: "",
                status: ""
            };
        }
        filters.orgs = [{ value: "", label: "" }];
        filters.depts = [{ value: "", label: "" }];

        this.state = filters;
    }
    componentDidMount() {
        this.props.search(this.search);
        let user = AuthService.currentUser();
        if (!user.org_id) {
            NameService.organisationList((objs) => {
                if (objs) {
                    this.setState({ orgs: objs });
                }
            });
        } else {
            this.setState({ orgs: [{ value: user.org_id, label: user.org_id }] });
        }
        NameService.departmentList((depts) => {
            this.setState({ depts: depts });
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.filters !== this.props.filters) {
            var org_id = nextProps.filters.org_id;
            var dept_id = nextProps.filters.dept_id;
            var status = nextProps.filters.status;
            this.setState({ org_id: org_id, dept_id: dept_id, status: status });
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }
    
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    getFilters() {
        var filters = { org_id: this.state.org_id, dept_id: this.state.dept_id, status: this.state.status };
        return filters;
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleClear = () => {
        this.setState({ keyword: "" });
    }
    search = () => {
        this.props.onKeywordChange(this.state.keyword);
        this.props.onSearchChanged(this.state.keyword);
    }
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    };
    render() {
        return <Toolbar style={{ paddingTop: 16 }}>
            <InputBase
                style={{ flex: 1, background: '#f0f0f0', padding: 4, borderRadius: 8 }}
                name="keyword"
                id="keyword"
                placeholder="Search name, e-mail"
                value={this.state.keyword}
                onChange={this.handleChange}
                onKeyPress={this.onKeyPress}
                onFocus={event => { event.target.select() }}
                autoFocus
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={this.handleClear}>
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="org_id"
                name="org_id"
                label="ORGANISATION"
                variant="outlined"
                margin="dense"
                select
                value={(this.state.orgs.length < 1 || this.state.orgs[0].value === "") ? "" : this.state.org_id}
                onChange={this.handleSelect("org_id")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {this.state.orgs.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="dept_id"
                name="dept_id"
                label="DEPARTMENT"
                variant="outlined"
                margin="dense"
                select
                value={(this.state.depts.length < 1 || this.state.depts[0].value === "") ? "" : this.state.dept_id}
                onChange={this.handleSelect("dept_id")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {this.state.depts.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="status"
                name="status"
                label="STATUS"
                variant="outlined"
                margin="dense"
                select
                value={this.state.status}
                onChange={this.handleSelect("status")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.userStatuses.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <IconButton color="default" onClick={this.props.reset}>
                <ResetIcon />
            </IconButton>
        </Toolbar>;
    }
}

let ListKey = "user_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        this.list = loadList(ListKey, { org_id: "", dept_id: "", status: "" });
        this.state = {
            keyword: this.list.keyword,
            filters: this.list.filters
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Users" });
    }
    componentWillUnmount() {
        saveList(ListKey, this.list, this.state.keyword, this.state.filters);
    }
    onKeywordChange = keyword => {
        if (keyword === this.state.keyword) return;
        this.setState({ keyword: keyword });
    }
    onFiltersChange = filters => {
        this.setState({ filters: filters }, () => {
            this.onSearch();
        });
    }
    onReset = () => {
        this.setState({ keyword: "", filters: { org_id: "", dept_id: "", status: "" } }, () => {
            this.onSearch();
        });
    }
    onSelect = (event, row) => {
        this.props.history.push("/users/" + row.u_id);
    }
    loadTable = query => {
        updateList(this.list, query);
        return APIService.search(APIService.USER,
            this.list.offset,
            this.list.size,
            this.list.order,
            this.list.orderDir,
            this.state.keyword,
            this.state.filters)
            .then(result => {
                return {
                    data: result.data,
                    page: result.offset / this.list.size,
                    totalCount: result.total
                };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {
        if (!AuthService.userHasRole(['user'])) return null;
        const { classes } = this.props;
        const cols = [
            { field: 'u_id', title: 'ID', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 50, paddingRight: 4 } },
            { field: 'name', title: 'NAME', cellStyle: { color: 'inherit' } },
            { field: 'login', title: 'LOGIN', cellStyle: { color: 'inherit' } },
            { field: 'email', title: 'EMAIL', cellStyle: { color: 'inherit' } },
            { field: 'org_id', title: 'ORG', cellStyle: { color: 'inherit', width: 50 } },
            { field: 'dept_id', title: 'DEPT', cellStyle: { color: 'inherit', width: 80 } }
        ];

        return (
            <Grid container spacing={8}>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                       <Button variant="contained" color="primary" className={classes.button} component={Link} to="/users/new">New <AddIcon className={classes.rightIcon} /></Button>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.list ? this.list.size : 10,
                            toolbar: true,
                            rowStyle: rowData => ({
                                color: Global.listColor(rowData.status)
                            })
                        }}
                        components={{
                            Toolbar: props => (
                                <Searchbar {...props}
                                    keyword={this.state.keyword}
                                    filters={this.state.filters}
                                    onKeywordChange={this.onKeywordChange}
                                    onFiltersChange={this.onFiltersChange}
                                    search={callback => this.onSearch = callback}
                                    reset={this.onReset}
                                />
                            )
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
