import React from 'react';
import { Chip } from '@material-ui/core';
import { Global} from '../Global';

export default class Status extends React.Component {
    render() {
        let { label } = this.props;
        if (!label) {
            return null;
        }
        var background = Global.colors[label];
        if (!background) background = '#d0d0d0';
        label = label.toUpperCase();
        let style = {
            color: '#ffffff',
            backgroundColor: background,
            height: 25
        };
        return <Chip label={label} style={style}/>
    }
}

Status.propTypes = {
// label: PropTypes.string.isRequired
};
