import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText, Drawer, List, withStyles } from '@material-ui/core';
import { GetMenu } from '../menu';

const styles = theme => ({
	categoryHeader: {
		paddingTop: 16,
		paddingBottom: 4
	},
	categoryHeaderPrimary: {
		fontSize: 13,
		color: theme.palette.common.white
	},
	item: {
		paddingTop: 4,
		paddingBottom: 4,
		color: 'rgba(255, 255, 255, 0.7)'
	},
	itemCategory: {
		// backgroundColor: '#232f3e',
		// boxShadow: '0 -1px 0 #404854 inset',
		paddingTop: 16,
		paddingBottom: 16
	},
	brand: {
		fontSize: 24,
		fontFamily: theme.typography.fontFamily,
		fontWeight: 600,
		color: '#d32f2f'
	},
	itemActionable: {
		'&:hover': {
			backgroundColor: 'rgba(255, 255, 255, 0.08)'
		}
	},
	// itemActiveItem: {
	// 	color: '#4fc3f7',
	// },
	itemPrimary: {
		color: 'inherit',
		fontSize: theme.typography.fontSize,
		'&$textDense': {
			fontSize: theme.typography.fontSize
		}
	},
//	textDense: {},
	divider: {
		marginTop: theme.spacing(2)
	}
});

function Navigator(props) {
	const { classes, ...other } = props;

	return (
		<Drawer variant="permanent" {...other}>
			<List disablePadding>
				<ListItem className={classNames(classes.brand)}>
					<img src="/logo.png" alt="Broadway CRM"/>
				</ListItem>
				{GetMenu().map(({ id, children }) => (
					<React.Fragment key={id}>
						<ListItem className={classes.categoryHeader}>
							<ListItemText
								classes={{
									primary: classes.categoryHeaderPrimary,
								}}>
								{id}
							</ListItemText>
						</ListItem>
						{children.map(({ id: childId, icon, active, link }) => (
							<Link to={link} key={childId} style={{textDecoration: 'none'}}>
								<ListItem
									button
									dense
									key={childId}
									className={classNames(
										classes.item,
										classes.itemActionable,
										active && classes.itemActiveItem,
									)}
								>
									<ListItemIcon>{icon}</ListItemIcon>
									<ListItemText
										classes={{
											primary: classes.itemPrimary
//											textDense: classes.itemPrimary.textDense
										}}
									>
										{childId}
									</ListItemText>
								</ListItem>
							</Link>
						))}
						{/* <Divider className={classes.divider} /> */}
					</React.Fragment>
				))}
			</List>
		</Drawer>
	);
}

Navigator.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);