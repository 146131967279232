import React from 'react';
import { Grid, TextField, MenuItem, Typography } from '@material-ui/core';
import { Global } from '../Global';

export default class DistrictInput extends React.Component {
    constructor(props) {
        super(props);
        let state = this.update(props);
        this.state = state;
    }
    update(props) {
        var state = {
            area: props.area ? props.area : "",
            district: props.district ? props.district : "",
            subdistrict: props.subdistrict ? props.subdistrict : "",
        };

        var areas = Global.areas;
        var districts = [];
        var subdistricts = [];
        var i;
        if (state.area) {
            for (i = 0; i < Global.districts.length; i++) {
                let district = Global.districts[i];
                if (district.value.startsWith(state.area)) {
                    districts.push(district);
                }
            }
            if (!Global.getValueLabel(districts, state.district)) {
                state.district = "";
                state.subdistrict = "";
            }
        } else {
            state.district = "";
            state.subdistrict = "";
        }
        if (state.district) {
            for (i = 0; i < Global.subdistricts.length; i++) {
                let subdistrict = Global.subdistricts[i];
                if (subdistrict.value.startsWith(state.district)) {
                    subdistricts.push(subdistrict);
                }
            }
            if (!Global.getValueLabel(subdistricts, state.subdistrict)) {
                state.subdistrict = "";
            }
        } else {
            state.subdistrict = "";
        }
        state.areas = areas;
        state.districts = districts;
        state.subdistricts = subdistricts;
        return state;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.area !== this.props.area || nextProps.district !== this.props.district || nextProps.subdistrict !== this.props.subdistrict) {
            let state = this.update(nextProps);
            this.setState(state);
        }
    }
    handleSelect = name => event => {
        let value = event.target.value;
        var state = { [name]: value };
        var i;
        if (name === "area") {
            var districts = [];
            if (value) {
                for (i = 0; i < Global.districts.length; i++) {
                    let district = Global.districts[i];
                    if (district.value.startsWith(value)) {
                        districts.push(district);
                    }
                }
            }
            if (!Global.getValueLabel(districts, this.state.district)) {
                state.district = "";
                state.subdistrict = "";
            }
            state.districts = districts;
        } else if (name === "district") {
            var subdistricts = [];
            if (value) {
                for (i = 0; i < Global.subdistricts.length; i++) {
                    let subdistrict = Global.subdistricts[i];
                    if (subdistrict.value.startsWith(value)) {
                        subdistricts.push(subdistrict);
                    }
                }
            }
            if (!Global.getValueLabel(subdistricts, this.state.subdistrict)) {
                state.subdistrict = "";
            }
            state.subdistricts = subdistricts;
        }
        this.setState(state, () => {
            this.props.onChange(this.state.area, this.state.district, this.state.subdistrict)
        });
    }
    render() {
        const { label } = this.props;
        return (
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Typography variant="caption">{label}</Typography>
                </Grid>
                <Grid container m={24}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            value={this.state.area}
                            label="AREA"
                            onChange={this.handleSelect("area")}
                            select
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                            {this.state.areas.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            value={this.state.district}
                            label="DISTRICT"
                            onChange={this.handleSelect("district")}
                            select
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                            {this.state.districts.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            value={this.state.subdistrict}
                            label="CONSTITUENCY"
                            onChange={this.handleSelect("subdistrict")}
                            select
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                            {this.state.subdistricts.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}
