import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, IconButton, Toolbar, InputBase, InputAdornment, TextField, MenuItem, Divider, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import {
    LocalPlay as CouponIcon,
    Stars as PointIcon,
    Sync as ResetIcon,
    Search as SearchIcon,
    SaveAlt as ExportIcon,
    Clear as ClearIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Label, DateLabel } from '../../components/Label';
import { KeyboardDatePicker  } from '@material-ui/pickers';
import { APIService } from '../../services/API';
import { NameService } from '../../services/Name';
import { AuthService } from '../../services/Auth';
import { Global } from '../../Global';
import { format, parse } from 'date-fns';
import { loadList, saveList, updateList } from '../../List';
import RoleControl from '../../components/RoleControl';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    }
});

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        var filters = props.filters;
        if (filters) {
            filters.keyword = props.keyword;
        } else {
            filters = {
                keyword: props.keyword,
                begin: null,
                end: null,
                org_id: "",
                type: "",
                event_type: "",
                status: "",
                method: "",
                nature: ""
            };
        }
        
        filters.orgs = [{ value: "", label: "" }];

        this.state = filters;
    }
    componentDidMount() {
        this.props.search(this.search);
        let user = AuthService.currentUser();
        if (!user.org_id) {
            NameService.organisationList((objs) => {
                if (objs) {
                    this.setState({ orgs: objs });
                }
            });
        } else {
            this.setState({ orgs: [{ value: user.org_id, label: user.org_id }] });
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.filters !== this.props.filters) {
            var org_id = nextProps.filters.org_id;
            var type = nextProps.filters.type;
            var event_type = nextProps.filters.event_type;
            var method = nextProps.filters.method;
            var nature = nextProps.filters.nature;
            var status = nextProps.filters.status;
            var begin = nextProps.filters.begin;
            var end = nextProps.filters.end;
            var issued_quantity = nextProps.filters.issued_quantity;
            var claimed_quantity = nextProps.filters.claimed_quantity;
            var redeemed_quantity = nextProps.filters.redeemed_quantity;
            this.setState({ org_id: org_id, type: type, event_type: event_type, method: method, nature: nature, status: status, begin: begin, end: end , issued_quantity: issued_quantity
                , claimed_quantity: claimed_quantity
                , redeemed_quantity: redeemed_quantity});
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.DateFormat);
        }
        this.setState({ [name]: value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    getFilters() {
        var filters = { org_id: this.state.org_id, type: this.state.type, event_type: this.state.event_type, method: this.state.method, nature: this.state.nature, status: this.state.status, begin: this.state.begin, end: this.state.end };
        if (filters.begin > filters.end) {
            let begin = parse(filters.begin, Global.DateFormat, new Date());
            filters.end = format(begin, Global.DateFormat);
        }
        return filters;
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value }, () => {
                this.props.onFiltersChange(this.getFilters());
        });
    }
    handleClear = () => {
        this.setState({ keyword: "" });
    }
    search = () => {
        this.props.onKeywordChange(this.state.keyword);
        this.props.onSearchChanged(this.state.keyword);
    }
    couponExportCSV = () => {
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);

        let { begin, end, type, org_id, event_type, method, nature, status, keyword } = this.state;
        var url = new URL(Global.APIUrl + "/offers/export"); 
        var params = { jwt: token };
        if (begin) params["begin"] = begin;
        if (end) params["end"] = end;
        if (type) params["type"] = type;
        if (org_id) params["org_id"] = org_id.toString().toUpperCase();
        if (event_type) params["event_type"] = event_type;
        if (status) params["status"] = status;
        if (method) params["method"] = method;
        if (nature) params["nature"] = nature;
        if (keyword) params["search"] = keyword;
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        window.open(url);
    }
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    };
    render() {
        return <Toolbar style={{ paddingTop: 16, flexWrap: "wrap"}}>
            <InputBase
                style={{ flex: 1, flexBasis: '24%', background: '#f0f0f0', padding: 4, borderRadius: 8 }}
                name="keyword"
                id="keyword"
                placeholder="Search name, promo id, promo code"
                value={this.state.keyword}
                onChange={this.handleChange}
                onKeyPress={this.onKeyPress}
                onFocus={event => { event.target.select() }}
                autoFocus
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={this.handleClear}>
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="org_id"
                name="org_id"
                label="ORGANISATION"
                variant="outlined"
                margin="dense"
                select
                value={(this.state.orgs.length < 1 || this.state.orgs[0].value === "") ? "" : this.state.org_id}
                onChange={this.handleSelect("org_id")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                <MenuItem key="ALL" value="ALL"><em>ALL</em></MenuItem>
                {this.state.orgs.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="type"
                name="type"
                label="CAMPAIGN"
                variant="outlined"
                margin="dense"
                select
                value={this.state.type}
                onChange={this.handleSelect("type")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.offerTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="event_type"
                name="event_type"
                label="EVENT TYPE"
                variant="outlined"
                margin="dense"
                select
                value={this.state.event_type}
                onChange={this.handleSelect("event_type")}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.offerEventTypes.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <TextField
                id="method"
                name="method"
                label="DELIVERY METHOD"
                variant="outlined"
                margin="dense"
                select
                value={this.state.method}
                onChange={this.handleSelect("method")}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.couponMethods.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <TextField
                id="nature"
                name="nature"
                label="OFFER NATURE"
                variant="outlined"
                margin="dense"
                select
                value={this.state.nature}
                onChange={this.handleSelect("nature")}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ minWidth: 130 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.offerNatures.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <KeyboardDatePicker 
                keyboard="true"
                format={Global.DisplayDateFormat}
                placeholder="DD/MM/YYYY"
                value={this.state.begin}
                onChange={this.handleSelectDate("begin")}
                label="FROM"
                inputVariant="outlined"
                margin="dense"
                animateYearScrolling={false}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ maxWidth: 180 }}
            />
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <KeyboardDatePicker
                keyboard="true"
                format={Global.DisplayDateFormat}
                placeholder="DD/MM/YYYY"
                value={this.state.end}
                onChange={this.handleSelectDate("end")}
                animateYearScrolling={false}
                label="TILL"
                inputVariant="outlined"
                margin="dense"
                InputLabelProps={{
                    shrink: true
                }}
                style={{ maxWidth: 180 }}
            />
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <TextField
                id="status"
                name="status"
                label="STATUS"
                variant="outlined"
                margin="dense"
                select
                value={this.state.status}
                onChange={this.handleSelect("status")}
                InputLabelProps={{
                    shrink: true
                }}
                style={{ minWidth: 150 }}
            >
                <MenuItem key="" value=""><em>All</em></MenuItem>
                {Global.offerStatuses.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            {this.state.type === "coupon" && 
            <IconButton color="default" onClick={this.couponExportCSV} aria-label="Export">
                <ExportIcon />
            </IconButton>
            }
            <Divider style={{ width: 0, height: 28, margin: 4 }} />
            <IconButton color="default" onClick={this.props.reset}>
                <ResetIcon />
            </IconButton>
        </Toolbar>;
    }
}

let ListKey = "offer_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        this.list = loadList(ListKey, { org_id: "", type: "", event_type: "", method: "", nature: "", status: "", begin: null, end: null });
        this.state = {
            keyword: this.list.keyword,
            filters: this.list.filters
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Campaigns" });
    }
    componentWillUnmount() {
        saveList(ListKey, this.list, this.state.keyword, this.state.filters);
    }
    onKeywordChange = keyword => {
        if (keyword === this.state.keyword) return;
        this.setState({ keyword: keyword });
    }
    onFiltersChange = filters => {
        this.setState({ filters: filters }, () => {
            this.onSearch();
        });
    }
    onReset = () => {
        this.setState({ keyword: "", filters: { org_id: "", type: "", event_type: "", method: "", nature: "", status: "", begin: null, end: null } }, () => {
            this.onSearch();
        });
    }
    onSelect = (event, row) => {
        this.props.history.push("/offers/" + row.type + "/" + row.offer_id);
    }
    loadTable = query => {
        if(!this.state.filters.org_id || this.state.filters.org_id === null || this.state.filters.org_id === ""){delete(this.state.filters.org_id);}
        if(!this.state.filters.type || this.state.filters.type === null || this.state.filters.type === ""){delete(this.state.filters.type);}
        if(!this.state.filters.begin || this.state.filters.begin === null || this.state.filters.begin === ""){delete(this.state.filters.begin);}
        if(!this.state.filters.end || this.state.filters.end === null || this.state.filters.end === ""){delete(this.state.filters.end);}
        if(!this.state.filters.event_type || this.state.filters.event_type === null || this.state.filters.event_type === ""){delete(this.state.filters.event_type);}
        if(!this.state.filters.keyword || this.state.filters.keyword === null || this.state.filters.keyword === ""){delete(this.state.filters.keyword);}
        if(!this.state.filters.method || this.state.filters.method === null || this.state.filters.method === ""){delete(this.state.filters.method);}
        if(!this.state.filters.nature || this.state.filters.nature === null || this.state.filters.nature === ""){delete(this.state.filters.nature);}
        if(!this.state.filters.status || this.state.filters.status === null || this.state.filters.status === ""){delete(this.state.filters.status);}
        delete(this.state.filters.orgs);
        
        updateList(this.list, query);
        return APIService.list(APIService.OFFER,
            this.list.offset,
            this.list.size,
            this.list.order,
            this.list.orderDir,
            this.state.keyword,
            this.state.filters)
            .then(result => {
                return {
                    data: result.data,
                    page: result.offset / this.list.size,
                    totalCount: result.total
                };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {
        if (!AuthService.userHasRole(['offer_point', 'offer_coupon'])) return null;
        const { classes } = this.props;
        const cols = [
            { field: 'offer_id', title: 'ID', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 50, paddingRight: 4 } },
            { field: 'org_id', title: 'ORG', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 50, paddingRight: 4 }, render: row => (
                    <Label data={(typeof row.org_id !== "undefined") ? row.org_id : "ALL"} value={(typeof row.org_id !== "undefined") ? row.org_id : null} />
                )
            },
            {
                field: 'type', title: 'TYPE', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 50, paddingRight: 4 }, render: row => (
                    <Label data={Global.offerTypes} value={row.type} />
                )
            },
            {
                field: 'event_type', title: 'EVENT TYPE', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 200, paddingRight: 4 }, render: row => (
                    <Label data={Global.offerEventTypes} value={row.event_type} />
                )
            },
            { field: 'promo_id', title: 'PROMO ID', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 200, paddingRight: 4 } },
            {
                field: 'validity', title: 'VALIDITY', sorting: false, headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 300, paddingRight: 4 }, render: row => (
                    <DateLabel begin={row.validity_begin} end={row.validity_end} />
                )
            },
            {
                field: 'names.en', title: 'NAME (ENGLISH)', sorting: false, headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', paddingRight: 4 }, render: row => (
                    <Label data={row} field="names" locale="en" />
                )
            },
            {
                field: 'names.zh', title: 'NAME (CHINESE)', sorting: false, headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', paddingRight: 4 }, render: row => (
                    <Label data={row} field="names" locale="zh" />
                )
            },
            { field: 'issued_quantity', title: 'Issued Quantity', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 50, padding: 4 } },
            { field: 'claimed_quantity', title: 'Claimed Quantity', headerStyle: { paddingRight: 4 }, cellStyle: { color: 'inherit', width: 50, padding: 4 } },
            { field: 'redeemed_quantity', title: 'Redeemed Quantity', cellStyle: { color: 'inherit', width: 50, padding: 4 } },
        ];
        return (
            <Grid container spacing={8}>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    <RoleControl required={['offer_coupon']}>
                        <Button variant="contained" color="primary" className={classes.button} component={Link} to="/offers/coupon">
                            New Coupon <CouponIcon className={classes.rightIcon} />
                        </Button>
                    </RoleControl>

                    <RoleControl required={['offer_point']}>
                        <Button variant="contained" color="primary" className={classes.button} component={Link} to="/offers/point">
                            New Points <PointIcon className={classes.rightIcon} />
                        </Button>
                    </RoleControl>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.list ? this.list.size : 10,
                            toolbar: true,
                            rowStyle: rowData => ({
                                color: Global.listColor(rowData.status)
                            })
                        }}
                        components={{
                            Toolbar: props => (
                                <Searchbar {...props}
                                    keyword={this.state.keyword}
                                    filters={this.state.filters}
                                    onKeywordChange={this.onKeywordChange}
                                    onFiltersChange={this.onFiltersChange}
                                    search={callback => this.onSearch = callback}
                                    reset={this.onReset}
                                />
                            )
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
