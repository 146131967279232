import React from 'react';
import { NameService } from '../services/Name';
import { Global } from '../Global';

export default class Username extends React.Component {
    constructor(props) {
        super(props);

        var state = { name: "" };
        if (this.props.user) {
            state.name = props.user;
        }
        this.state = state;
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user !== this.props.user) {
            NameService.getUserName(nextProps.user, (name) => {
                this.setState({ name: name });
            });
        }
    }
    componentDidMount() {
        if (!this.props.user) return;
        NameService.getUserName(this.props.user, (name) => {
            this.setState({ name: name });
        });
    }
    render() {
        if (!this.state.name) return Global.NotAvailable;
        return <React.Fragment>{this.state.name}</React.Fragment>;
    }
};
