import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, } from '@material-ui/core';
import { APIService } from '../../services/API';
import {
    Warning as WarningIcon
} from '@material-ui/icons';

export default class ActivationDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            m_id: "",
            coupon_id: "",
            coupon_status: "",
            open: false,
            isSubmitting: false,
        };
    }
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (mId, couponId, couponStatus) => {
        this.setState({ open: true, m_id: mId, coupon_id: couponId, coupon_status: couponStatus, isSubmitting: false});
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleConfirm = status => event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        
        this.setState({
            isSubmitting: true
        });
        
        var params = {
            status: status
        };
        APIService.call("PUT", APIService.MEMBER + "/" + this.state.m_id + "/" + APIService.COUPONS + "/" + this.state.coupon_id + "/status", params)
            .then(obj => {
                this.setState({ isSubmitting: false, open: false });
                this.props.onClose();
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }

    render() {
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Coupons</DialogTitle>
            <DialogContent>
                    {(this.state.coupon_status === "locked" || this.state.coupon_status === "invalid") &&
                        <DialogContentText>
                            <span style={{display: 'flex', alignItems: 'center', fontWeight: 'bold', color: '#000'}}>
                                <WarningIcon size="large" />&nbsp;&nbsp;The change is not reversable
                            </span>
                            Change coupon {this.state.coupon_id} status to Valid?
                        </DialogContentText>
                    }
                    {this.state.coupon_status === "valid" && 
                        <DialogContentText>
                             Change coupon {this.state.coupon_id} status to Invalid? &nbsp;&nbsp;&nbsp;
                             <Button onClick={this.handleConfirm("invalid")} disabled={this.state.isSubmitting} style={{border: "1px solid #000", backgroundColor: "red", color: "#FFF", fontWeight: "bold", float: "right", height: "28px"}}>Confirm</Button>
                             <br/><br/><br/>
                            <span style={{display: 'flex', alignItems: 'center', fontWeight: 'bold', color: '#000'}}>
                               <WarningIcon size="large"/>&nbsp;&nbsp;The change is not reversable
                            </span>
                             Change coupon {this.state.coupon_id} status to Redeemed? &nbsp;&nbsp;&nbsp;
                             <Button onClick={this.handleConfirm("redeemed")} disabled={this.state.isSubmitting} style={{border: "1px solid #000", backgroundColor: "red", color: "#FFF", fontWeight: "bold", float: "right", height: "30px"}}>Confirm</Button>
                            
                        </DialogContentText>
                    }
            </DialogContent>
            <DialogActions>
                {(this.state.coupon_status === "locked" || this.state.coupon_status === "invalid") &&
                    <Button onClick={this.handleConfirm("valid")} color="primary" disabled={this.state.isSubmitting}>OK</Button>
                }
                <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
            </DialogActions>
        </Dialog>
    }
}
