import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, withStyles } from '@material-ui/core';
import MaterialTable from 'material-table';
import { AddCircleOutline as AddIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Label } from '../../components/Label';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { Global } from '../../Global';
import { loadList, saveList, updateList } from '../../List';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    }
});

let ListKey = "dept_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        this.list = loadList(ListKey);
        this.state = {
            keyword: this.list.keyword,
            filters: this.list.filters
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Departments" });
    }
    componentWillUnmount() {
        saveList(ListKey, this.list);
    }
    onSelect = (event, row) => {
        this.props.history.push("/depts/" + row.dept_id);
    }
    loadTable = query => {
        updateList(this.list, query);
        return APIService.list(APIService.DEPT,
            this.list.offset,
            this.list.size,
            this.list.order,
            this.list.orderDir,
            this.state.keyword)
            .then(result => {
                return {
                    data: result.data,
                    page: result.offset / this.list.size,
                    totalCount: result.total
                };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {
        if (!AuthService.userHasRole(["dept"])) return null;
        const { classes } = this.props;
        const cols = [
            { field: 'dept_id', title: 'ID', cellStyle: { color: 'inherit', width: 50 } },
            {
                field: 'name', title: 'NAME (ENGLISH)', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={row} field="names" locale="en" />
                )
            },
            {
                field: 'name', title: 'NAME (CHINESE)', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={row} field="names" locale="zh" />
                )
            }
        ];
        return (
            <Grid container spacing={8}>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    <Button variant="contained" color="primary" className={classes.button} component={Link} to="/depts/new">
                        New <AddIcon className={classes.rightIcon} />
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.list ? this.list.size : 10,
                            toolbar: false,
                            rowStyle: rowData => ({
                                color: Global.listColor(rowData.status)
                            })
                        }} />
                </Grid>
            </Grid>
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
