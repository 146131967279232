import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Grid, Hidden, IconButton, Menu, MenuItem, Toolbar, Typography, withStyles } from '@material-ui/core';
import {
    AccountCircle as UserIcon,
    Menu as MenuIcon,
    // Help as HelpIcon,
    // Notifications as NotificationsIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { AuthService } from '../services/Auth';

const styles = theme => ({
    primaryBar: {
        zIndex: 0,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: 'none',
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: 'rgba(255, 255, 255, 0.7)',
    },
});

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            anchorEl: null
        };
    }
    UNSAFE_componentWillReceiveProps(props) {
         this.setState({ title: props.context.title })
    }
    
    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    }
    handleClose = () => {
        this.setState({ anchorEl: null });
    }
    handleChangePassword = () => {
        this.setState({ anchorEl: null }, () => {
            this.props.changePassword();
        });
    }
    render() {
        const { classes, onDrawerToggle } = this.props;
        const { anchorEl } = this.state;
        const isMenuOpen = Boolean(anchorEl);
        let user = AuthService.currentUser();

        const renderMenu = (
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={isMenuOpen}
              onClose={this.handleClose}
            >
                <MenuItem onClick={this.handleChangePassword}>Change Password</MenuItem>
                <Link to="/logout" style={{textDecoration: 'none'}}>
                    <MenuItem onClick={this.handleClose}>Logout</MenuItem>
                </Link>
            </Menu>
        );

        return (
            <React.Fragment>
                <AppBar
                    component="div"
                    className={classes.primaryBar}
                    color="primary"
                    position="static"
                    elevation={2}
                >
                    <Toolbar>
                        <Grid container alignItems="center" spacing={8}>
                            <Hidden smUp>
                                <Grid item>
                                    <IconButton
                                        color="inherit"
                                        aria-label="Open drawer"
                                        onClick={onDrawerToggle}
                                        className={classes.menuButton}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                            </Hidden>
                            <Grid item xs>
                                <Typography color="inherit" variant="subtitle1">
                                    {this.state.title}
                                </Typography>
                            </Grid>
                            {/* <Grid item>
                                <Tooltip title="Alerts • No alters">
                                    <IconButton color="inherit">
                                        <NotificationsIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid> */}
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-owns={isMenuOpen ? 'user-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={this.handleClick}>
                                    <UserIcon /><Typography variant="body2" color="inherit"> &nbsp;{user ? user.name : ""}</Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                {/* <AppBar
                    component="div"
                    className={classes.secondaryBar}
                    color="primary"
                    position="static"
                    elevation={0}
                >
                    <Tabs value={0} textColor="inherit">
                        <Tab textColor="inherit" label="Users" />
                        <Tab textColor="inherit" label="Sign-in method" />
                        <Tab textColor="inherit" label="Templates" />
                        <Tab textColor="inherit" label="Usage" />
                    </Tabs>
                </AppBar> */}
            </React.Fragment>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);
