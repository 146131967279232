let regex = /^\+?(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(.*)$/;

export const E164 = {
    regex,
    parse,
    format,
    strip
};

function parse(number) {
    var match = regex.exec(number);
    if (match && match.length > 2) {
        return {cc: match[1], number: match[2]};
    }
    return  {cc: "", number: number};
}

function format(number) {
    let e164 = parse(strip(number));
    if (e164.cc) {
        return "+" + e164.cc + " " + e164.number;
    }
    return e164.number;
}

function strip(number) {
    return number.replace(/[-\s]/g, "");
}