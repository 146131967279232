import React from 'react';
import { Global } from '../Global';
import { E164 } from '../helpers/E164';
import { parseISO, format } from 'date-fns';
import { Period } from '../helpers/Period';
import { Validator } from '../helpers/Validator';
import { Typography } from '@material-ui/core';

export class Label extends React.Component {
    render() {
        // data=[key/label pair], value={key}
        // data={object}, field={field}, locale={en|zh}, name={key}, unit={%|pts|$}, link="mailto:tel"
        // where
        
        var data = this.props.data;
        if (!data) return Global.NotAvailable;

        var out = "";
        if (data instanceof Array) {
            // data=[key/label pair], value={key}
            let value = this.props.value;
            for (var i = 0; i < data.length; i++) {
                var row = data[i];
                if (row.value === value) {
                    out = row.label;
                    break;
                }
            }
        } else if (data instanceof Object) {
            let field = this.props.field;
            if (field) {
                data = Global.getNestedValue(data, field);
                if (data instanceof Object) {
                    let locale = this.props.locale;
                    if (locale) {
                        for (var name in data) {
                            if (name.startsWith(locale)) {
                                out = data[name];
                            }
                        }
                        if (!out) {
                            let any = this.props.any;
                            if (any) {
                                for (name in data) {
                                    out = data[name];
                                    break;
                                }
                            }
                        }
                    }
                    if (out instanceof Object) {
                        // for returning names.[locale].[first|last|middle|full]
                        let name = this.props.name;
                        if (name) {
                            out = out[name];
                        } else {
                            out = null;
                        }
                    }
                } else {
                    out = data;
                }
            } else {
                out = "ERROR";
            }
        } else {
            out = data;
        }
        let unit = this.props.unit;
        if (unit) {
            if (unit === "%") {
                out = parseInt(out);
                if (isNaN(out) || out === 0) {
                    out = "";
                } else {
                    out = out + unit;
                }
            } else if (unit === "pts") {
                if (isNaN(out) || out === 0) {
                    out = "";
                } else {
                    out = out + " " + unit;
                }
            }
        }
        var isEmpty = false;
        if (!out || out === "") {
            isEmpty = true;
            out = Global.NotAvailable;
        }
        var link = this.props.link;
        if (link && !isEmpty) {
            if (link === "tel") {
                out = E164.format(out);
            }
            let url = link + ":" + out;
            return <a href={url} style={{textDecoration: 'none'}}>{out}</a>
        } else {
            if (this.props.multiline) {
                return <div style={{whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>{out}</div>
            }
            return <span>{out}</span>
        }
    }
};

export class CurrencyLabel extends React.Component {
    render() {
        var data = this.props.data;
        if (!data) return Global.NotAvailable;

        var out = "";
        if (data instanceof Array) {
            // data=[key/label pair], value={key}
            let value = this.props.value;
            for (var i = 0; i < data.length; i++) {
                var row = data[i];
                if (row.value === value) {
                    out = row.label;
                    break;
                }
            }
        } else if (data instanceof Object) {
            let field = this.props.field;
            if (field) {
                data = Global.getNestedValue(data, field);
                out = data;
            } else {
                out = "ERROR";
            }
        } else {
            out = data;
        }
        if (!out || out === "") {
            return Global.NotAvailable;
        }
        let currency = this.props.currency;
        if (!currency) currency = "HKD";
        // let formatter = new Intl.NumberFormat(locale, {
        //     style: "currency",
        //     currency: "HKD",
        // });
        let locale = this.props.locale;
        if (!locale) locale = "zh-HK";
        let formatter = new Intl.NumberFormat(locale, {});
        out = formatter.format(out);
        return <span>${out}</span>
    }
};

export class DateLabel extends React.Component {
    render() {
        var fmt = this.props.format;
        if (!fmt) fmt = Global.DisplayDateFormat;

        var out = "";
        let begin = this.props.begin;
        let end = this.props.end;
        if (begin) {
            var label = this.props.label;
            if (!label) label = " - ";
            else label = " " + label + " ";
            let begin_time = parseISO(begin);
            if (end) {
                let end_time = parseISO(end);
                out = format(begin_time, fmt) + " " + label + " " + format(end_time, fmt);
            } else {
                out = format(begin_time, fmt) + " " + label + " forever";
            }
        } else if (end) {
            let end_time = parseISO(end);
            out = "till " + format(end_time, fmt);
        } else {
            let date = this.props.date;
            if (!date) return Global.NotAvailable;

            // let expiry = this.props.expiry;
            let time = parseISO(date);
            // if (expiry) {   // expiry date is exclusive for period
            //     time.setTime(time.getTime() - 24 * 3600000);
            // }
            out = format(time, fmt);
        }
        return <span>{out}</span>
    }
};

export class AddressLabel extends React.Component {
    render() {
        let data = this.props.data;
        let field = this.props.field;
        let addr = Global.getNestedValue(data, field);
        if (!addr || Validator.isEmpty(addr)) return <React.Fragment>{Global.NotAvailable}</React.Fragment>;
        return <React.Fragment>
            {addr.line1}
        </React.Fragment>
    }
};

export class PeriodLabel extends React.Component {
    render() {
        let value = this.props.value;
        if (!value) return Global.NotAvailable;

        var out = "";
        let period = Period.parse(value);
        out = period.duration + " " + Global.getValueLabel(Global.periodUnits, period.unit);
        if (!out || out === "") out = "--";

        return <span>{out}</span>
    }
};

export class TextLabel extends React.Component {
    render() {
        var data = this.props.data;
        if (!data) return Global.NotAvailable;

        var out = "";
        if (data instanceof Array) {
            let value = this.props.value;
            for (var i = 0; i < data.length; i++) {
                var row = data[i];
                if (row.value === value) {
                    out = row.label;
                    break;
                }
            }
        } else if (data instanceof Object) {
            let field = this.props.field;
            if (field) {
                data = Global.getNestedValue(data, field);
                out = data;
            } else {
                out = "ERROR";
            }
        } else {
            out = data;
        }
        if (!out || out === "") {
            return Global.NotAvailable;
        }
        return <div style={{whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>{out}</div>
    }
};

export class CheckLabel extends React.Component {
    render() {
        var data = this.props.data;
        if (!data) return null;

        var out = false;
        if (data instanceof Object) {
            let field = this.props.field;
            if (field) {
                data = Global.getNestedValue(data, field);
                out = data;
            } else {
                return null;
            }
        } else {
            out = data;
        }
        if (!out) {
            return null;
        }
        return <Typography variant="body1" color="textPrimary">&bull;&nbsp; {this.props.label}</Typography>
    }
};


