import React from 'react';
import { FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, FormHelperText, Button, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { format } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { Validator } from '../../helpers/Validator';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2)
    }
});

class ExtendDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            plan_code: "",
            org_id: "",
            m_id: 0,
            mshp_id: "",
            remark: "",
            org_expires_at: null,
            expires_at: null,
            org_subscribed_at: null,
            subscribed_at: null,
            errors: {},
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (member, membership) => {
        this.setState({ open: true, org_id: member.org_id, m_id: parseInt(member.m_id), mshp_id: membership.mshp_id, plan_code: membership.plan_code, expires_at: membership.expires_at, subscribed_at: membership.subscribed_at, org_expires_at: membership.expires_at, org_subscribed_at: membership.subscribed_at, remark: "", errors: {}, isSubmitting: false });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
            value = format(date, Global.DateFormat);
        }
        this.setState({ [name]: value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleSubmit = event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        if (!this.validateForm()) return;

        this.setState({
            isSubmitting: true
        });

        var req = {
            org_id: this.state.org_id,
            m_id: this.state.m_id,
            expires_at: this.state.expires_at,
            remark: this.state.remark
        };
        if(this.state.org_subscribed_at !== this.state.subscribed_at){
            var subscribed_at = null;
            if(this.state.subscribed_at !== null){
                subscribed_at = (new Date(this.state.subscribed_at)).toISOString();
            }
            req['subscribed_at'] = subscribed_at;
        }
        APIService.call("POST", APIService.SUBSCRIPTION + "/" + this.state.mshp_id + "/extend", req, { debug: "t" })
            .then(result => {
                this.setState({ isSubmitting: false, open: false }, () => {
                    this.props.alert("Membership updated", "success");
                    this.props.onRefresh();
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};

        if (this.state.expires_at) {
            if (this.state.org_expires_at === this.state.expires_at && this.state.org_subscribed_at === this.state.subscribed_at) {
                errors["expires_at"] = Global.errors["ErrorNoChanged"];
                errors["subscribed_at"] = Global.errors["ErrorNoChanged"];
            } else {
                // Commented below to allow expiring membership
                // let now = Date.now()
                // let expires_at = parse(this.state.expires_at, Global.DisplayDateFormat, new Date());
                // if (expires_at.getTime() <= now) {
                //     errors["expires_at"] = Global.errors["ErrorInvalidValue"];
                // }
            }
        } else {
            errors["expires_at"] = Global.errors["ErrorRequired"];
        }
        
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        const { classes } = this.props;
        const { errors } = this.state;
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <form onSubmit={this.handleSubmit}>

                <DialogTitle id="form-dialog-title">Extend Membership</DialogTitle>
                <DialogContent>
                    <DialogContentText>Adjust the expiration date of the subscription below for extending or shortening membership duration.</DialogContentText>
                    <FormControl required fullWidth className={classes.formControl}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="plan_code"
                                    name="plan_code"
                                    label="SUBSCRIPTION PLAN CODE"
                                    value={this.state.plan_code}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <KeyboardDatePicker
                                    keyboard="true"
                                    label="EXPIRATION DATE"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.expires_at}
                                    onChange={this.handleSelectDate("expires_at")}
                                    animateYearScrolling={false}
                                    fullWidth
                                    required
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["expires_at"] && <FormHelperText error={true}>{errors["expires_at"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <KeyboardDatePicker
                                    keyboard="true"
                                    label="SUBSCRIPTION DATE"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.subscribed_at}
                                    onChange={this.handleSelectDate("subscribed_at")}
                                    animateYearScrolling={false}
                                    fullWidth
                                    autoFocus
                                    disableFuture={true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["subscribed_at"] && <FormHelperText error={true}>{errors["subscribed_at"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" disabled={this.state.isSubmitting}>OK</Button>
                    <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    }
}

export default withStyles(styles)(ExtendDialog);