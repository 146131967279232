import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, FormControl, Table, TableHead, TableBody, TableRow, TableCell, Typography, withStyles } from '@material-ui/core';
import FormBase from '../../components/FormBase';
import { Label, DateLabel, CurrencyLabel } from '../../components/Label';
import { Global } from '../../Global';
import { APIService } from '../../services/API';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class RenewReviewForm extends FormBase {
    constructor(props) {
        super(props);
        this.state = {
            plan: props.plan,
            member: props.member,
            order: props.order,
            errors: {},
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onNext(this.commit);
    }
    commit = () => {
        if (this.state.isSubmitting) { return; }

        var req = {
            org_id: this.state.member.org_id,
            m_id: parseInt(this.state.member.m_id),
            order: this.props.order
        };
        APIService.create(APIService.SUBSCRIPTION, req, { debug: "t" })
            .then(member => {
                this.setState({ isSubmitting: false }, () => {
                    this.props.handler(member);
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h4" gutterBottom>Review Order</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} align="left">
                                <Typography variant="caption" color="textSecondary">ORDER NO</Typography>
                                <Typography variant="h6" color="textPrimary">{this.state.order.order_id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} align="right">
                                <Typography variant="caption" color="textSecondary">ORDER DATE</Typography>
                                <Typography variant="h6" color="textPrimary"><DateLabel date={this.state.order.created_at} format={Global.DisplayDateTimeFormat}/></Typography>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ITEM CODE</TableCell>
                                            <TableCell>DESCRIPTION</TableCell>
                                            <TableCell align="right">QTY</TableCell>
                                            <TableCell align="right">UNIT PRICE ({this.state.order.ccy})</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.order.items.map(item => (
                                            <TableRow key={item.code}>
                                                <TableCell>
                                                    <Typography variant="body1">{item.code}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body1">{item.name}</Typography>
                                                </TableCell>
                                                <TableCell align="right">{item.qty}</TableCell>
                                                <TableCell align="right">
                                                    <CurrencyLabel data={item.price} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell rowSpan={3} colSpan={2} />
                                            <TableCell align="right"><Typography color="textSecondary">TOTAL ({this.state.order.ccy})</Typography></TableCell>
                                            <TableCell align="right">
                                                <Typography variant="h5">
                                                <CurrencyLabel data={this.state.order.total} />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"><Typography color="textSecondary">PAYMENT METHOD</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="h6"><Label data={Global.paymentMethods} value={this.state.order.pay_method} /></Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"><Typography color="textSecondary">REF NO</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="h6"><Label data={this.state.order.pay_txn_no} /></Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Paper>
                </FormControl>
            </form >
        );
    }
}

RenewReviewForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RenewReviewForm);