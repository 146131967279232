import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, Button, FormControl, FormControlLabel, FormHelperText, Checkbox, Input, InputLabel, Paper, Typography, withStyles } from '@material-ui/core';
import { Global } from '../Global';
import PopupMessage from '../components/PopupMessage';
import { APIService } from '../services/API';
import { AuthService } from '../services/Auth';
import { Validator } from '../helpers/Validator';

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        [theme.breakpoints.up(450 + theme.spacing(4) * 2)]: {
            width: 450,
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        marginTop: theme.spacing(3)
    }
});

class LoginPage extends React.Component {
    static LOGINID_KEY = 'loginId';

    constructor(props) {
        super(props);

        AuthService.deauthenticate();

        var loginId = localStorage.getItem(this.LOGINID_KEY);
        var remember = loginId ? true : false;

        this.state = {
            login: loginId ? loginId : "",
            password: "",
            remember: remember,
            errors: {},
            isSubmitting: false
        };
    }
	alert = (message, variant = "error") => {
		this.showPopup(message, variant);
	}
    handleCheckbox = event => {
        this.setState({ [event.target.id]: event.target.checked });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleSubmit = event => {
        event.preventDefault();

        if (!this.validateForm()) {
            return;
        }
        this.setState({
            isSubmitting: true
        });

        AuthService.authenticate(this.state.login, this.state.password)
            .then(user => {
                if (this.state.remember) {
                    localStorage.setItem(this.LOGINID_KEY, this.state.login);
                } else {
                    localStorage.removeItem(this.LOGINID_KEY);
                }
                this.props.history.push("/");
            })
            .catch(error => {
                var errors = {};
                var alertMessage = APIService.errorMessage(error);
                if (error.reason) {
                    if (error.reason === "ErrorAuthAccountNotFound" || error.reason === "ErrorAuthAccountLocked") {
                        errors["login"] = Global.errors[error.reason];
                        alertMessage = null;
                    } else if (error.reason === "ErrorAuthFailed") {
                        errors["password"] = Global.errors[error.reason];
                        alertMessage = null;
                    }
                }
                this.setState({ isSubmitting: false, errors: errors });
                if (alertMessage) {
                    this.alert(alertMessage);
                }
            });
    }
    validateForm() {
        var errors = {};
        if (!Validator.isValidPassword(this.state.password)) {
            errors["password"] = Global.errors["ErrorInvalidPassword"];
        }
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        const { classes } = this.props;
        const { errors } = this.state;

        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        <img src="/logo.png" alt="Broadway CRM"/>
                    </Typography>
                    <form className={classes.form} onSubmit={this.handleSubmit}>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="login">Login or Email Address</InputLabel>
                            <Input
                                id="login"
                                name="login"
                                autoComplete="login"
                                autoFocus
                                required
                                value={this.state.login}
                                onChange={this.handleChange}
                                error={errors["login"] ? true : false}
                            />
                            {errors["login"] &&
                                <FormHelperText error={true}>{errors["login"]}</FormHelperText>
                            }
                        </FormControl>
                        <FormControl margin="normal" required fullWidth>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={this.state.password}
                                onChange={this.handleChange}
                                error={errors["password"] ? true : false}
                            />
                            {errors["password"] &&
                                < FormHelperText error={true}>{errors["password"]}</FormHelperText>
                            }
                        </FormControl>
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" id="remember" onChange={this.handleCheckbox} checked={this.state.remember} />}
                            label="Remember me"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={this.state.isSubmitting}
                        >
                            Log in
                        </Button>
                    </form>
                </Paper>
                <PopupMessage handler={popup => this.showPopup = popup} />
            </main >
        );
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LoginPage);