import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, FormHelperText, TextField, MenuItem, Typography, withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormBase from '../../components/FormBase';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class CreatePage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            quota_id: "",
            org_id: "",
            type: "subscription",
            qty: 0,
            auto_reset: null,
            desc: ""
        };
        this.state = {
            orgs: [],
            isSubmitting: false,
            errors: {}
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Quota" });
    }
    componentDidMount() {
        // load organisations for selection
        let user = AuthService.currentUser();
        if (user.org_id) {
            this.setState({orgs: [{ value: user.org_id, label: user.org_id }]});
        } else {
            NameService.organisationList((orgs) => {
                this.setState({ orgs: orgs });
            });
        }
    }
    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        Validator.compact(this.obj);
        if(this.obj.org_id === "null"){
            this.obj.org_id = null;
        }
        this.setState({
            isSubmitting: true
        });
        
        APIService.create(APIService.QUOTA, this.obj)
            .then(result => {
                this.props.alert("Quota created", "success");
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};
        if (!this.obj.org_id) {
            errors["org_id"] = Global.errors["ErrorRequired"];
        }
        this.obj.quota_id = Validator.verifyString(this.obj.quota_id, value => {
            if (!Validator.isValidCode(value)) {
                errors["quota_id"] = Global.errors["ErrorInvalidFormat"];
            }
        });
        if (this.obj.quota_id.length > 20) {
            errors["quota_id"] = Global.errors["ErrorExceedMaxLength"];
        }
        this.obj.qty = Validator.verifyNumber(this.obj.qty, value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["qty"] = err;
        });
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }     
                
        return true;
    }

    render() {
        if (!AuthService.userHasRole(['quota'])) return null;
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" gutterBottom>NEW QUOTA</Typography>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="quota_id"
                                    name="quota_id"
                                    label="ID"
                                    value={this.state.quota_id}
                                    onChange={this.handleChangeUpper}
                                    required
                                    autoFocus
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["quota_id"] && <FormHelperText error={true}>{errors["quota_id"]}</FormHelperText>}
                                <FormHelperText>Maximum length of 20 characters</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="org_id"
                                    name="org_id"
                                    label="ORGANISATION"
                                    select
                                    value={this.state.org_id}
                                    onChange={this.handleSelect("org_id")}
                                    required
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem key="null" value="null"><em>ALL</em></MenuItem>
                                    {this.state.orgs.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["org_id"] && <FormHelperText error={true}>{errors["org_id"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="type"
                                    name="type"
                                    label="TYPE"
                                    value={this.state.type}
                                    onChange={this.handleSelect("type")}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu }
                                    }}
                                    required
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {Global.quotaTypes.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="qty"
                                    name="qty"
                                    label="QUANTITY"
                                    value={this.state.qty}
                                    type="number"
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["qty"] && <FormHelperText error={true}>{errors["qty"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <KeyboardDatePicker
                                    keyboard="true"
                                    label="AUTO RESET"
                                    format="dd/MM"
                                    placeholder="DD/MM"
                                    value={this.state.auto_reset}
                                    onChange={this.handleSelectDate("auto_reset")}
                                    clearable
                                    animateYearScrolling={false}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["auto_reset"] && <FormHelperText error={true}>{errors["auto_reset"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    label="DESCRIPTION"
                                    value={this.state.desc}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} component={Link} to={"/quotas"}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form >
        );
    }
}

CreatePage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreatePage);
