import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { DropzoneDialog } from 'material-ui-dropzone'
import { withStyles } from '@material-ui/core';
import { APIService } from '../services/API';

const styles = theme => ({
});

class UploadDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            subject: null
        };
    }
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (subject = null) => {
        this.setState({
            open: true,
            subject: subject
        });
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    handleSave = (files) => {
        var url = this.props.url;
        if (this.state.subject) {
            url += this.state.subject;
        }
        console.log(this.state.subject);
        APIService.uploadFile(url, files)
        .then(result => {
            this.setState({
                open: false
            }, () => {
                this.props.onRefresh(result);
            });
        })
        .catch(error => {
            this.props.alert(APIService.errorMessage(error));
        });
    };

    render() {
        let acceptedFiles = this.props.acceptedFiles;
        if (!acceptedFiles) {
            acceptedFiles = ['image/jpeg', 'image/png'];
        }
        return (
            <React.Fragment>
                <DropzoneDialog
                    open={this.state.open}
                    acceptedFiles={acceptedFiles}
                    showPreviews={false}
                    showPreviewsInDropzone={true}
                    showAlerts={false}
                    maxFileSize={2000000}
                    filesLimit={1}
                    dropzoneText="&nbsp;&nbsp;&nbsp;Choose a file or drag it here.&nbsp;&nbsp;&nbsp;"
                    onSave={this.handleSave}
                    onClose={this.handleClose}
                    // dropZoneClass={{}}
                    dropzoneParagraphClass={{fontSize: 10}}
                />
            </React.Fragment>
        );
    }
}

UploadDialog.propTypes = {
    url: PropTypes.string.isRequired
};

export default withStyles(styles)(UploadDialog);