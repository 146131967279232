import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Avatar, InputBase, InputAdornment, Button, IconButton, Toolbar, Badge, Typography, Divider, withStyles } from '@material-ui/core';
import {
    AddCircleOutline as AddIcon,
    Sync as ResetIcon,
    Search as SearchIcon,
    FilterList as FilterIcon,
    SaveAlt as ExportIcon,
    Clear as ClearIcon
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import { APIService } from "../../services/API";
import { AuthService } from "../../services/Auth";
import { Label, DateLabel } from '../../components/Label';
import { Validator } from '../../helpers/Validator';
import { Global } from '../../Global';
import { loadList, saveList, updateList } from '../../List';
import FilterDialog from './FilterDialog';
import ExportDialog from './ExportDialog';
import RoleControl from '../../components/RoleControl';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    },
    avatar: {
        width: 36,
        height: 36,
        background: '#eee'
    }
});

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            keyword: props.keyword,
            filterCount: 0
        };
    }
    componentDidMount() {
        this.props.search(this.search);
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateFilterBadge();
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleClear = () => {
        this.setState({ keyword: "" });
    }
    updateFilterBadge = () => {
        var count = 0;
        for (var key in this.props.filters) {
            var options = this.props.filters[key];
            if (options) {
                count += options.length;
            }
        }
        if (this.state.filterCount !== count) {
            this.setState({ filterCount: count });
        }
    }
    search = () => {
        if (Validator.isQRCode(this.state.keyword)) {
            this.props.onQRCode(this.state.keyword);
            return;
        }
        this.props.onKeywordChange(this.state.keyword);
        this.props.onSearchChanged(this.state.keyword);
    }
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.search();
        }
    };
    render() {
        return <Toolbar style={{ paddingTop: 16 }}>
            <InputBase
                style={{ flex: 1, background: '#f0f0f0', padding: 4, borderRadius: 8 }}
                name="keyword"
                id="keyword"
                placeholder="Search name, member id, e-mail, mobile no, TEL"
                value={this.state.keyword}
                onChange={this.handleChange}
                onKeyPress={this.onKeyPress}
                onFocus={event => { event.target.select() }}
                autoFocus
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={this.handleClear}>
                            <ClearIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <IconButton color="default" onClick={this.props.showFilters} aria-label="Filters">
                <Badge badgeContent={this.state.filterCount} color="primary">
                    <FilterIcon />
                </Badge>
            </IconButton>
            <RoleControl required={["member_export"]}>
                <IconButton color="default" onClick={this.props.showExport} aria-label="Export">
                    <ExportIcon />
                </IconButton>
            </RoleControl>
            <Divider style={{ width: 0, height: 28, margin: 8 }} />
            <IconButton color="default" onClick={this.props.reset}>
                <ResetIcon />
            </IconButton>
        </Toolbar>;
    }
}

class PlanField extends React.Component {
    renderField(field, mshp) {
        switch (field) {
            case 'code':
                return mshp.plan_code;
            case 'status':
                return mshp.status;
            case 'expiry':
                return <DateLabel date={mshp.expires_at} />;
            default:
                return null;
        }
    }

    render() {
        const { field, memberships } = this.props;
        if (!memberships) return Global.NotAvailable;
        var mshps = [];
        for (var key in memberships) {
            let mshp = memberships[key];
            if (mshp.status === "expired") continue;
            mshps.push({ plan_code: key, status: mshp.status, expires_at: mshp.expires_at });
        }
        if (mshps.length === 0) return Global.NotAvailable;
        return (
            <React.Fragment>
                {mshps.map(mshp => {
                    return (
                        <React.Fragment key={mshp.plan_code}>
                            <Typography variant="body1" color="inherit">{this.renderField(field, mshp)}</Typography>
                        </React.Fragment>
                    );
                })}
            </React.Fragment>
        );
    }
};

let ListKey = "member_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        this.list = loadList(ListKey);
        this.state = {
            keyword: this.list.keyword,
            filters: this.list.filters
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Members" });
    }
    componentWillUnmount() {
        saveList(ListKey, this.list, this.state.keyword, this.state.filters);
    }
    onSelect = (event, row) => {
        this.props.history.push("/members/" + row.m_id);
    }
    onQRCode = code => {
        APIService.call("GET", APIService.MEMBER + "/search", null, { code: code })
            .then(result => {
                if (result.m_id && result.timestamp) {
                    let expiry = new Date((result.timestamp + 900) * 1000);
                    let now = new Date();
                    if (expiry < now) {
                        // this.setState({ keyword: "" + result.m_id });
                        this.props.alert("QR Code already expired", "warning");
                    }
                    this.props.history.push("/members/" + result.m_id);
                }
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
    }
    onKeywordChange = keyword => {
        if (keyword === this.state.keyword) return;
        this.setState({ keyword: keyword });
    }
    onFiltersChange = filters => {
        this.setState({ filters: filters }, () => {
            this.onSearch();
        });
    }
    onReset = () => {
        this.setState({ keyword: "", filters: {} }, () => {
            this.onSearch();
        });
    }
    loadTable = query => {
        updateList(this.list, query);
        return APIService.search(APIService.MEMBER + "/search",
            this.list.offset,
            this.list.size,
            this.list.order,
            this.list.orderDir,
            this.state.keyword,
            this.state.filters)
            .then(result => {
                // ES limitation 10k records
                var total = 1;
                if(result.total >= 10000){
                    total = 10000;
                }else{
                    total = result.total;
                }
                return {
                    data: result.data,
                    page: result.offset / this.list.size,
                    totalCount: total
                };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {
        if (!AuthService.userHasRole(['member'])) return null;
        const { classes } = this.props;
        let user = AuthService.currentUser();
        if (!user) return null;
        let token = user.token;
        const cols = [
            {
                field: 'image', title: '', sorting: false, headerStyle: { paddingRight: 8 }, cellStyle: { width: 50, color: 'inherit', paddingRight: 8 }, render: row => (
                    <Avatar className={classes.avatar} alt={Global.getMemberName(row.profile, "en")} sizes="80px" src={Global.getImage(row.profile ? row.profile.images : null, "photo", "jwt=" + encodeURIComponent(token))} />
                )
            },
            {
                field: 'm_id', title: 'ID', headerStyle: { padding: 8 }, cellStyle: { color: 'inherit', width: 80, padding: 8 }
            },
            {
                field: 'full.en', title: 'NAME (ENGLISH)', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={Global.getMemberName(row, "en")} />
                )
            },
            {
                field: 'name.zh', title: 'NAME (CHINESE)', sorting: false, cellStyle: { color: 'inherit' }, render: row => (
                    <Label data={Global.getMemberName(row, "zh")} />
                )
            },
            {
                field: 'plan', title: 'PLAN', sorting: false, cellStyle: { color: 'inherit', width: 150 }, render: row => (
                    <PlanField field="code" memberships={row.memberships} color="inherit" />
                )
            },
            {
                field: 'plan', title: 'EXPIRES AT', sorting: false, cellStyle: { color: 'inherit', width: 180 }, render: row => (
                    <PlanField field="expiry" memberships={row.memberships} color="inherit" />
                )
            },
            {
                field: 'plan', title: 'STATUS', sorting: false, cellStyle: { color: 'inherit', width: 120 }, render: row => (
                    <PlanField field="status" memberships={row.memberships} color="inherit" />
                )
            },
            { field: 'org_id', title: 'ORG', cellStyle: { color: 'inherit', width: 50 } }
        ];

        return (
            <Grid container spacing={8}>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    <RoleControl required={["member_reg"]}>
                        <Button variant="contained" color="primary" className={classes.button} component={Link} to="/members/new">
                            New <AddIcon className={classes.rightIcon} />
                        </Button>
                    </RoleControl>
                </Grid>
                <Grid item xs={12}>
                    <MaterialTable
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.list ? this.list.size : 10,
                            toolbar: true,
                            rowStyle: rowData => ({
                                color: Global.listColor(rowData.status)
                            })
                        }}
                        components={{
                            Toolbar: props => (
                                <Searchbar {...props}
                                    keyword={this.state.keyword}
                                    filters={this.state.filters}
                                    showFilters={this.showFiltersDialog}
                                    showExport={this.showExportDialog}
                                    onKeywordChange={this.onKeywordChange}
                                    onQRCode={this.onQRCode}
                                    search={callback => this.onSearch = callback}
                                    reset={this.onReset}
                                />
                            ),
                        }} />
                </Grid>
                <FilterDialog filters={this.state.filters}
                    onDialog={dialog => this.showFiltersDialog = dialog}
                    onFiltersChange={this.onFiltersChange}
                    alert={this.props.alert} />
                <ExportDialog
                    onDialog={dialog => this.showExportDialog = dialog}
                    filters={this.state.filters}
                    keyword={this.state.keyword}
                    alert={this.props.alert} />
            </Grid>
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
