import React from 'react';
import { Switch, Route } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LoginPage from './views/LoginPage';
import HomePage from './views/HomePage';
import { PrivateRoute, AuthService } from './services/Auth';

let theme = createMuiTheme({
	palette: {
		primary: {
			light: '#c54949',
			main: '#b71c1c',
			dark: '#801313'
		},
		secondary: {
			light: '#7CC2F2',
			main: '#36A2EB',
			dark: '#137BC1'
		}
	},
	shape: {
		borderRadius: 5
	}
});

theme = {
	...theme,
	overrides: {
		MuiDrawer: {
			paper: {
				backgroundColor: '#303030'
			}
		},
		// MuiPaper: {
		// 	contained: {
		// 		padding: `${theme.spacing(4)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
		// 	},
		// },
		MuiTabs: {
			root: {
				marginLeft: theme.spacing(1)
			},
			indicator: {
				height: 3,
				borderTopLeftRadius: 3,
				borderTopRightRadius: 3,
				backgroundColor: theme.palette.common.white
			}
		},
		// MuiTab: {
		// 	root: {
		// 		margin: '0 16px',
		// 		minWidth: 0,
		// 		[theme.breakpoints.up('md')]: {
		// 			minWidth: 0,
		// 		},
		// 	},
		// 	labelContainer: {
		// 		padding: 0,
		// 		[theme.breakpoints.up('md')]: {
		// 			padding: 0,
		// 		},
		// 	},
		// },
		MuiIconButton: {
			root: {
				padding: theme.spacing(1)
			}
		},
		// MuiTooltip: {
		// 	tooltip: {
		// 		borderRadius: 4,
		// 	},
		// },
		// MuiDivider: {
		// 	root: {
		// 		backgroundColor: '#404854',
		// 	},
		// },
		MuiListItemText: {
			primary: {
				fontWeight: theme.typography.fontWeightRegular
			}
		},
		MuiListItemIcon: {
			root: {
				color: 'inherit',
				marginRight: 0,
				'& svg': {
					fontSize: 20
				}
			}
		}
	},
	props: {
		MuiTab: {
			disableRipple: true
		}
	},
	mixins: {
		...theme.mixins,
		toolbar: {
			minHeight: 44
		}
	}
};

export default class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			invalid: false
		}
	}

	logout = (event) => {
		event.preventDefault();

		AuthService.deauthenticate();
		this.setState({ invalid: false })
	}

	render() {
		return (
			<MuiThemeProvider theme={theme}>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<Switch>
                                                <Route path="/login" component={LoginPage} />
						<Route path="/logout" component={LoginPage} />
						<PrivateRoute path="/" component={HomePage} />
					</Switch>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		);
	}
}
