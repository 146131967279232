import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, FormHelperText, TextField, Typography, withStyles } from '@material-ui/core';
import FormBase from '../../components/FormBase';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class EditPage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            dept_id: "",
            names: {
                en: "",
                "zh": ""
            },
            region: "HK",
            desc: ""
        };
        this.state = {
            isSubmitting: false,
            errors: {}
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Department" });
    }
    componentDidMount() {
        APIService.get(APIService.DEPT, this.props.match.params.id)
            .then(obj => {
                var map = {};
                Validator.flattenNested(obj, map);
                this.setState(map);
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                this.props.history.goBack();
            });
    }
    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        let output = JSON.parse(JSON.stringify(this.obj));
        Validator.compact(output);
        this.setState({
            isSubmitting: true
        });

        APIService.update(APIService.DEPT, output, output.dept_id)
            .then(result => {
                this.props.alert("Deparment updated", "success");
                this.props.history.push('/depts/' + output.dept_id);
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};
        this.obj.names.en = Validator.verifyString(this.obj.names.en, value => { });
        this.obj.names["zh"] = Validator.verifyString(this.obj.names["zh"], value => { });
        this.obj.desc = Validator.verifyString(this.obj.desc, value => { });

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }

    render() {
        if (!AuthService.userHasRole(["dept"])) return null;
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4">{this.state["names.en"]}</Typography>
                                <Typography variant="body1" color="textSecondary"> ID: {this.props.match.params.id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} align="right">
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="names.en"
                                    name="name.en"
                                    label="NAME (ENGLISH)"
                                    value={this.state["names.en"]}
                                    onChange={this.handleChange}
                                    required
                                    autoFocus
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["names.en"] && <FormHelperText error={true}>{errors["names.en"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="names.zh"
                                    name="names.zh"
                                    label="NAME (CHINESE)"
                                    value={this.state["names.zh"]}
                                    onChange={this.handleChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["names.zh"] && <FormHelperText error={true}>{errors["names.zh"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    label="DESCRIPTION"
                                    value={this.state.desc}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form >
        );
    }
}

EditPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditPage);