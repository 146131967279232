import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, IconButton, Toolbar, InputBase, InputAdornment, TextField, MenuItem, Divider, withStyles } from '@material-ui/core';
import {
    Sync as ResetIcon,
    Search as SearchIcon,
    SaveAlt as ExportIcon,
    Clear as ClearIcon
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Label, DateLabel, CurrencyLabel } from '../../components/Label';
import { Global } from '../../Global';
import { format, parse } from 'date-fns';
import { loadList, saveList, updateList } from '../../List';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';

const styles = theme => ({
    button: {
        minWidth: 150,
        marginLeft: `${theme.spacing(2)}px`
    },
    rightIcon: {
        marginLeft: 8
    }
});

class Searchbar extends React.Component {
    constructor(props) {
        super(props);

        // var cls = "";

        var filters = props.filters;
        if (filters) {
            filters.keyword = props.keyword;
        } else {
            filters = {
                keyword: props.keyword,
                begin: null,
                end: null,
                type: "",
                dept_id: "",
                channel: "",
                pay_method: "",
                plan_id: "",
                org_id: "",
                subtype: "",
                cls: "",
                cinema_id: "",
                event_type: "",
                method: "",
                offer_id: "",
                nature: "" 
           };
        }
        filters.orgs = [];
        filters.depts = [{ value: "", label: "" }];
        filters.plans = [{ value: "", label: "", plan_id: "", name: "", org_id: "", plan_code: ""}];
        this.state = filters;
    }
    UNSAFE_componentWillMount() {
        this.props.search(this.search);
        let user = AuthService.currentUser();
        if (!user.org_id) {
            NameService.organisationList((objs) => { 
                if (objs) {
                    this.setState({ orgs: objs });
                }
            });
        } else {
            this.setState({ orgs: [{ value: user.org_id, label: user.org_id }] });
        }
        NameService.departmentList((depts) => {
            this.setState({ depts: depts });
        });
        NameService.planList((plans) => {
            this.setState({ plans: plans });
        });
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.filters !== this.props.filters) {
            var begin = nextProps.filters.begin;
            var end = nextProps.filters.end;
            var type = nextProps.filters.type;
            var dept_id = nextProps.filters.dept_id;
            var channel = nextProps.filters.channel;
            var pay_method = nextProps.filters.pay_method;
            var plan_id = nextProps.filters.plan_id;
            var org_id = nextProps.filters.org_id;
            var subtype = nextProps.filters.subtype;
            var cls = nextProps.filters.cls;
            this.setState({ begin: begin, end: end, type: type, subtype: subtype, cls: cls
                , dept_id: dept_id, channel: channel, pay_method: pay_method
                , plan_id: plan_id, org_id: org_id });
        }
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }

    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    getFilters() {
        var filters = { begin: this.state.begin, end: this.state.end
            , type: this.state.type, subtype: this.state.subtype
            , cls: this.state.cls, dept_id: this.state.dept_id
            , channel: this.state.channel, pay_method: this.state.pay_method
            , plan_id: this.state.plan_id, org_id: this.state.org_id, event_type: this.state.event_type
            , method: this.state.method, nature: this.state.nature, cinema_id: this.state.cinema_id, offer_id: this.state.offer_id};
        if (filters.begin > filters.end) {
            let begin = parse(filters.begin, Global.DateFormat, new Date());
            filters.end = format(begin, Global.DateFormat);
        }
        return filters;
    }
    filterCoupon() {
        var query_params = "";
        var offer_id = "?";
        if(this.state.method !== "" && this.state.event_type !== ""){
            query_params = "&method="+this.state.method+"&event_type="+this.state.event_type;
        }else if(this.state.event_type !== ""){
            query_params = "&event_type="+this.state.event_type;
        }else if(this.state.method !== ""){
            query_params = "&method="+this.state.method;
        }   
        if(isNaN(this.state.keyword)){
            query_params = query_params.concat("&search="+this.state.keyword);
        } 
        APIService.call(
        "GET",
        APIService.OFFER+"?offset=0&size=10000"+query_params)
        .then(result => {
             for(var i = 0; i < result.data.length; i++){
                 if(this.state.nature !== "" && result.data[i]["nature"] === this.state.nature){
                    if(i === result.data.length - 1){
                       offer_id = offer_id.concat("offer_id="+result.data[i]["offer_id"]);
                    } else{
                       offer_id = offer_id.concat("offer_id="+result.data[i]["offer_id"]+"&");
                    }
                 }else if(this.state.nature === ""){
                    if(i === result.data.length - 1){
                       offer_id = offer_id.concat("offer_id="+result.data[i]["offer_id"]);
                    } else{
                       offer_id = offer_id.concat("offer_id="+result.data[i]["offer_id"]+"&");
                    }
                 }
             }
             if(offer_id !== "?" && (this.state.nature !== "" || this.state.method !== "" || this.state.event_type !== "" || (isNaN(this.state.keyword) && this.state.keyword !== ""))){
                this.setState({offer_id: offer_id}, () => {
                    this.props.onFiltersChange(this.getFilters());
                });
             }else{
                this.setState({offer_id: ""}, () => {
                    this.props.onFiltersChange(this.getFilters());
                });
             }
            }) 
            .catch(error => {
        });
    }
    handleSelect = name => event => {   
        this.setState({ [name]: event.target.value }, () => {
            this.props.onFiltersChange(this.getFilters());
        });
    }
    handleSelectMethod = name => event => {
        this.setState({ [name]: event.target.value }, () => {
            this.filterCoupon();
        });
    } 
    handleSelectDate = name => date => {
        var value = null;
        if (date !== null && date.toString() !== "Invalid Date") {
                value = format(date, Global.DateFormat);
                this.setState({ [name]: value }, () => {
                    this.props.onFiltersChange(this.getFilters());
                });
        }
    }
    handleClear = () => {
        this.setState({ keyword: "" });
    }
    search = () => {
        this.props.onKeywordChange(this.state.keyword);
        this.props.onSearchChanged(this.state.keyword);
    }
    transExportCSV = () => {
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);

        let { begin, end, type, pay_method, cls, dept_id, channel, plan_id, org_id, keyword } = this.state;
        var url = new URL(Global.APIUrl + "/transactions/export" + this.state.offer_id); 
        var params = { jwt: token };
        if (begin) params["begin"] = begin;
        if (end) params["end"] = end;
        if (type) params["type"] = type;
        if (pay_method) params["pay_method"] = pay_method;
        if (cls) params["cls"] = cls;
        if (dept_id) params["dept_id"] = dept_id;
        if (channel) params["channel"] = channel.toString().toUpperCase();
        if (org_id) params["org_id"] = org_id.toString().toUpperCase();
        if (plan_id) params["plan_id"] = plan_id;
        if (keyword) params["search"] = keyword;

        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
        window.open(url);
    }
    onKeyPress = (e) => {
        if (e.key === 'Enter') {
            if(isNaN(this.state.keyword)){
                this.filterCoupon();
            }
            this.search();
        }
    };
    render() {
        if (!AuthService.userHasRole(['trans_sub', 'trans_point', 'trans_coupon'])) return null;
        var allTransClasses = [];
        if (AuthService.userHasRole(["trans_sub"])) {
            allTransClasses.push({ value: "subscription", label: "Membership" });
        }
        if (AuthService.userHasRole(["trans_point"])) {
            allTransClasses.push({ value: "point", label: "Point" });
        }
        if (AuthService.userHasRole(["trans_coupon"])) {
            allTransClasses.push({ value: "coupon", label: "Coupon" });
        }
        return <Toolbar style={{ paddingTop: 16, flexWrap: "wrap", maxWidth: 1130}}>
        <InputBase
            style={{ flex: 1, background: '#f0f0f0', padding: 4, borderRadius: 8 }}
            name="keyword"
            id="keyword"
            placeholder="Search member id, order no, ref no, campaign title eng/chi"
            value={this.state.keyword}
            onChange={this.handleChange}
            onKeyPress={this.onKeyPress}
            onFocus={event => { event.target.select() }}
            autoFocus
            startAdornment={
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="end">
                    <IconButton size="small" onClick={this.handleClear}>
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            }
        />
        <Divider style={{ width: 0, height: 28, margin: 8 }} />
        <KeyboardDatePicker
            keyboard="true"
            format={Global.DisplayDateFormat}
            placeholder="DD/MM/YYYY"
            label="FROM"
            inputVariant="outlined"
            margin="dense"
            value={this.state.begin}
            onChange={this.handleSelectDate("begin")}
            animateYearScrolling={false}
            InputLabelProps={{
                shrink: true
            }}
            style={{ maxWidth: 180 }}
        />
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <KeyboardDatePicker
            keyboard="true"
            format={Global.DisplayDateFormat}
            placeholder="DD/MM/YYYY"
            label="TILL"
            inputVariant="outlined"
            margin="dense"
            value={this.state.end}
            onChange={this.handleSelectDate("end")}
            animateYearScrolling={false}
            InputLabelProps={{
                shrink: true
            }}
            style={{ maxWidth: 180 }}
        />
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="cls"
            name="cls"
            label="CATEGORY"
            variant="outlined"
            margin="dense"
            select
            value={this.state.cls}
            onChange={this.handleSelect("cls")}
            InputLabelProps={{
                shrink: true
            }}
            style={{ minWidth: 150 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {allTransClasses.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="type"
            name="type"
            label="TYPE"
            variant="outlined"
            margin="dense"
            select
            value={this.state.type}
            onChange={this.handleSelect("type")}
            InputLabelProps={{
                shrink: true
            }}
            style={{ minWidth: 100 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {Global.transTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="dept_id"
            name="dept_id"
            label="DEPARTMENT"
            variant="outlined"
            margin="dense"
            select
            value={(this.state.depts.length < 1 || this.state.depts[0].value === "") ? "" : this.state.dept_id}
            onChange={this.handleSelect("dept_id")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 150 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {this.state.depts.map(option => (
                <MenuItem key={(option.value !== null ? option.value : "")} value={(option.value !== null ? option.value : "")}>
                    {(option.label !== null ? option.label : "")}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="channel"
            name="channel"
            label="CHANNEL"
            variant="outlined"
            margin="dense"
            select
            value={this.state.channel}
            onChange={this.handleSelect("channel")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 80 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {Global.channels.map(option => (
                <MenuItem key={option.value} value={option.label}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="pay_method"
            name="pay_method"
            label="PAYMENT METHOD"
            variant="outlined"
            margin="dense"
            select
            value={this.state.pay_method}
            onChange={this.handleSelect("pay_method")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 150 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {Global.paymentMethods.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="plan_id"
            name="plan_id"
            label="PLAN NAME"
            variant="outlined"
            margin="dense"
            select
            value={(this.state.plans.length < 1 || this.state.plans[0].plan_id === "") ? "" : this.state.plan_id}
            onChange={this.handleSelect("plan_id")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 150 }}
        >
            <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
            {this.state.plans.map(option => (
                <MenuItem key={option.name} value={option.plan_id}>
                    {option.name + " (" + option.plan_code + ")"}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="org_id"
            name="org_id"
            label="ORGANISATIONS"
            variant="outlined"
            margin="dense"
            select
            value={this.state.org_id}
            onChange={this.handleSelect("org_id")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 150 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {this.state.orgs.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="event_type"
            name="event_type"
            label="EVENT TYPE"
            variant="outlined"
            margin="dense"
            select
            value={this.state.event_type}
            onChange={this.handleSelectMethod("event_type")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 100 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {Global.couponEventTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="method"
            name="method"
            label="DELIVERY METHOD"
            variant="outlined"
            margin="dense"
            select
            value={this.state.method}
            onChange={this.handleSelectMethod("method")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 150 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {Global.couponMethods.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="nature"
            name="nature"
            label="OFFER NATURE"
            variant="outlined"
            margin="dense"
            select
            value={this.state.nature}
            onChange={this.handleSelectMethod("nature")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 130 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {Global.offerNatures.map(option => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <TextField
            id="cinema_id"
            name="cinema_id"
            label="CINEMA"
            variant="outlined"
            margin="dense"
            select
            value={this.state.cinema_id}
            onChange={this.handleSelect("cinema_id")}
            InputLabelProps={{
                shrink: true,
            }}
            style={{ minWidth: 100 }}
        >
            <MenuItem key="" value=""><em>All</em></MenuItem>
            {Global.cinemas.map(option => (
                <MenuItem key={option.value} value={option.code}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />
        <IconButton color="default" onClick={this.transExportCSV} aria-label="Export">
            <ExportIcon />
        </IconButton>
        <Divider style={{ width: 0, height: 28, margin: 4 }} />    
        <IconButton color="default" onClick={this.props.reset}>
            <ResetIcon />
        </IconButton>
    </Toolbar>;
    }
}

let ListKey = "trans_list";

class ListPage extends React.Component {
    constructor(props) {
        super(props);

        let now = new Date();
        let date = format(now, Global.DateFormat);
        this.list = loadList(ListKey, { begin: date, end: date, type: ""
            , subtype: "", cls: "", dept_id: "", channel: "", pay_method: ""
            , plan_id: "", org_id: "", event_type: "", method: "", nature: "", cinema_id: "", offer_id: ""});
        this.state = {
            keyword: this.list.keyword,
            filters: this.list.filters,
            plans: ""
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Transactions" });
    }
    componentWillUnmount() {
        saveList(ListKey, this.list, this.state.keyword, this.state.filters);
    }
    onSelect = (event, row) => {
        this.props.history.push("/transactions/" + row.txn_id);
    }
    onKeywordChange = keyword => {
        if (keyword === this.state.keyword) return;
        this.setState({ keyword: keyword });
    }
    onFiltersChange = filters => {
        this.setState({ filters: filters }, () => {
                this.onSearch();
        });
    }
    onReset = () => {
        let now = new Date();
        let date = format(now, Global.DateFormat);
        this.setState({ keyword: "", filters: { begin: date, end: date
                , type: "", subtype: "", cls: "", dept_id: "", channel: ""
                , pay_method: "", plan_id: "", org_id: "", event_type: "", method: "", nature: "", cinema_id: ""
                , offer_id: ""} }, () => {
            this.onSearch();
        });
    }
    loadTable = query => {
        updateList(this.list, query);
        var url = APIService.TRANSACTION + this.state.filters.offer_id;
        if((this.state.filters.method !== "" || this.state.filters.event_type !== "" || (isNaN(this.state.keyword) && this.state.keyword !== "")) && this.state.filters.offer_id === ""){
            url = APIService.TRANSACTION + "?offer_id=0";
        }
        if(url.slice(-1) === "&"){
            url = url.substring(0, url.length - 1);
        }
        return APIService.list(url,
            this.list.offset,
            this.list.size,
            this.list.order,
            this.list.orderDir,
            (!isNaN(this.state.keyword)) ? this.state.keyword : "",
            this.state.filters)
            .then(result => {
                // ES limitation 10k records
                var total = 1;
                if(result.total >= 10000){
                    total = 10000;
                }else{
                    total = result.total;
                }      
                return {
                    data: result.data,
                    page: result.offset / this.list.size,
                    totalCount: total
                };
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                return { data: [], page: 0, totalCount: 0 };
            });
    }
    render() {  
        const hash = {};
        if(this.state.plans === ""){
            APIService.search(APIService.PLAN,
                0,
                10000,
                this.list.order,
                this.list.orderDir,
                this.state.keyword,
                this.state.filters)
                .then(planResult => {
                    planResult.data.forEach(element => {
                        hash[element.plan_id] = element.plan_code;
                    });
                    hash[0] = ''; 
                    this.setState({plans: hash});
                });
        }
        
        const cols = [
            {
                field: 'at', title: 'DATE TIME', headerStyle: { paddingRight: 4 }, cellStyle: { width: 200, paddingRight: 4, fontSize: 12 }, render: row => (
                    <DateLabel date={row.at} format={Global.DisplayDateTimeFormat} />
                )
            },
            {
                field: 'm_id', title: 'MEMBER', headerStyle: { padding: 4 }, cellStyle: { width: 50, padding: 4, fontSize: 12 }, render: row => (
                    <Link to={(typeof row.m_id === "undefined") ? "#" : "members/" + row.m_id} style={{ textDecoration: 'none' }} onClick={(event) => {if(typeof row.m_id !== "undefined") event.stopPropagation();}}><Label data={row.m_id} /></Link>
                )
            },
            {
                field: 'org_id', title: 'ORG', headerStyle: { padding: 4 }, cellStyle: { width: 50, padding: 4, fontSize: 12 }
            },
            {
                field: 'type', title: 'TYPE', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4, fontSize: 12 }, render: row => (
                    <Label data={Global.transTypes} value={row.type} />
                )
            },
            {
                field: 'subtype', title: 'SUBTYPE', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4, fontSize: 12 }
            },
            {
                field: 'details.plan_id', title: 'PLAN', headerStyle: { padding: 4 }, cellStyle: { width: 100, padding: 4, fontSize: 12 }, render: row => (
                    <div><Label data={this.state.plans[(typeof row.details !== "undefined" && typeof row.details.plan_id !== "undefined") ? row.details.plan_id : 0]} /><br/>(<Label data={row} field="details.plan_id" />)</div>
                )
            },
            {
                field: 'cls', title: 'CATEGORY', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4 , fontSize: 12}, render: row => (
                    <Label data={Global.transClasses} value={row.cls} />
                )
            },
            {
                field: 'order_id', title: 'ORDER ID', headerStyle: { padding: 4 }, cellStyle: { width: 200, padding: 4, fontSize: 12 }, render: row => (
                    <Link to={((row.cls === "coupon" && row.type === "redemption" && (typeof row.subtype === "undefined" || row.subtype === null)) || (row.cls === "point" && row.type === "reward" && row.subtype === "ticketing")) ? "#" : "orders/" + row.order_id} style={{ textDecoration: 'none' }} 
                    onClick={(event) => {if(!(row.cls === "coupon" && row.type === "redemption" && (typeof row.subtype === "undefined" || row.subtype === null)) && !(row.cls === "point" && row.type === "reward" && row.subtype === "ticketing")) {event.stopPropagation();}}}>
                    {row.order_id}</Link>
                )
            },
            {
                field: 'details.pay_gateway_ref', title: 'VP no.', headerStyle: { padding: 4 }, cellStyle: { width: 100, padding: 4 , fontSize: 12}, render: row => (
                    <Label data={row} field="details.pay_gateway_ref" />
                )
            },
            {
                field: 'pay_method', title: 'PAYMENT', headerStyle: { padding: 4 }, cellStyle: { width: 200, padding: 4, fontSize: 12 }, render: row => (
                    <Label data={row.pay_method} />
                )
            },
            {
                field: 'txn_no', title: 'REF NO', headerStyle: { padding: 4 }, cellStyle: { width: 140, padding: 4, maxWidth: 140, fontSize: 12, textOverflow: 'ellipsis', overflow: 'hidden' }
            },
            {
                field: 'channel', title: 'CHANNEL', headerStyle: { padding: 4 }, cellStyle: { width: 50, padding: 4, fontSize: 12 }
            },
            {
                field: 'cinema_id', title: 'CINEMA', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4, fontSize: 12 }
            },
            {
                field: 'dept_id', title: 'DEPT', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4, fontSize: 12 }
            },
            {
                field: 'amount', title: 'AMOUNT', type: 'numeric', sorting: false, headerStyle: { padding: 4 }, cellStyle: { width: 150, padding: 4, fontSize: 12 }, render: row => (
                    <CurrencyLabel data={row.amount} />
                )
            },
            {
                field: 'points', title: 'POINTS', type: 'numeric', sorting: false, cellStyle: { width: 150, fontSize: 12}, render: row => (
                    <Label data={row.points} unit="pts" />
                )
            }
        ];
        return (
            <Grid container spacing={8}>
                <Grid item xs={12}>
                    <MaterialTable
                        title=""
                        columns={cols}
                        data={query => this.loadTable(query)}
                        onRowClick={this.onSelect}
                        options={{
                            pageSize: this.list ? this.list.size : 10,
                            toolbar: true
                        }}
                        components={{
                            Toolbar: props => (
                                <Searchbar {...props}
                                    keyword={this.state.keyword}
                                    filters={this.state.filters}
                                    onKeywordChange={this.onKeywordChange}
                                    onFiltersChange={this.onFiltersChange}
                                    search={callback => this.onSearch = callback}
                                    export={this.transExportCSV}
                                    reset={this.onReset}
                                />
                            )
                        }} />
                </Grid>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                </Grid>
            </Grid>
        );
    }
}

ListPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ListPage);
