import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, FormHelperText, TextField, MenuItem, InputAdornment, Typography, withStyles, Divider } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormBase from '../../components/FormBase';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class CreatePage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            org_id: "",
            names: {
                en: "",
                "zh": ""
            },
            desc: "",
            region: "HK",
            point_rate: "",
            point_expiry: null,
            details: {
                domain: "",
                sender_email: "",
                sender_name: "",
                member_activation_fail_redirect_url: "",
                member_activation_success_redirect_url: "",
                reset_password_url: "",
                bcc_recipients: "",
                notice_recipients: "",
                member_activation_email_urls: {
//                    AMC: "",
                    BW: "",
                    Default: ""
                }
            }
        };
        this.state = {
            isSubmitting: false,
            errors: {}
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Organisation" });
    }
    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        let output = JSON.parse(JSON.stringify(this.obj));
        Validator.compact(output);
        this.setState({
            isSubmitting: true
        });           
        if(output.details.bcc_recipients !== "" && typeof output.details.bcc_recipients !== "undefined"){
            var bcc_recipients = output.details.bcc_recipients.toString().replaceAll(" ", "");
            output.details.bcc_recipients = bcc_recipients.split(",");
        }else{
            output.details.bcc_recipients = [];
        }
        if(output.details.notice_recipients !== "" && typeof output.details.notice_recipients !== "undefined"){
            var notice_recipients = output.details.notice_recipients.toString().replaceAll(" ", "");
            output.details.notice_recipients = notice_recipients.split(",");
        }else{
            output.details.notice_recipients = [];
        }     

        APIService.create(APIService.ORG, output)
            .then(result => {
                this.props.alert("Organisation created", "success");
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};
        this.obj.org_id = Validator.verifyString(this.obj.org_id, value => {
            if (!Validator.isValidCode(value)) {
                errors["org_id"] = Global.errors["ErrorInvalidFormat"];
            }
        });
        if (this.obj.org_id.length > 8) {
            errors["org_id"] = Global.errors["ErrorExceedMaxLength"];
        }
        this.obj.names.en = Validator.verifyString(this.obj.names.en, value => { });
        this.obj.names["zh"] = Validator.verifyString(this.obj.names["zh"], value => { });
        this.obj.desc = Validator.verifyString(this.obj.desc, value => {});
        this.obj.details.domain = Validator.verifyString(this.obj.details.domain, value => {
            if (!Validator.isValidDomain(value)) {
                errors["details.domain"] = Global.errors["ErrorInvalidFormat"];
            }
        });
        this.obj.details.sender_email = Validator.verifyString(this.obj.details.sender_email, value => {
            if (!Validator.isValidEmail(value)) {
                errors["details.sender_email"] = Global.errors["ErrorInvalidEmail"];
            }
        });
        this.obj.point_rate = Validator.verifyNumber(this.obj.point_rate, value => {
            var err = null;
            if (isNaN(value)) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["point_rate"] = err;
        });

        this.obj.details.member_activation_fail_redirect_url = Validator.verifyString(this.obj.details.member_activation_fail_redirect_url, value => {
            if (!Validator.isValidURL(value)) {
                errors["details.member_activation_fail_redirect_url"] = Global.errors["ErrorInvalidFormat"];
            }
        });
        this.obj.details.member_activation_success_redirect_url = Validator.verifyString(this.obj.details.member_activation_success_redirect_url, value => {
            if (!Validator.isValidURL(value)) {
                errors["details.member_activation_success_redirect_url"] = Global.errors["ErrorInvalidFormat"];
            }
        });
        this.obj.details.reset_password_url = Validator.verifyString(this.obj.details.reset_password_url, value => {
            if (!Validator.isValidURL(value)) {
                errors["details.reset_password_url"] = Global.errors["ErrorInvalidFormat"];
            }
        });
//        this.obj.details.member_activation_email_urls.AMC = Validator.verifyString(this.obj.details.member_activation_email_urls.AMC, value => {
//            if (!Validator.isValidURL(value)) {
//                errors["details.reset_password_url.AMC"] = Global.errors["ErrorInvalidFormat"];
//            }
//        });
        this.obj.details.member_activation_email_urls.BW = Validator.verifyString(this.obj.details.member_activation_email_urls.BW, value => {
            if (!Validator.isValidURL(value)) {
                errors["details.reset_password_url.BW"] = Global.errors["ErrorInvalidFormat"];
            }
        });
        this.obj.details.member_activation_email_urls.Default = Validator.verifyString(this.obj.details.member_activation_email_urls.Default, value => {
            if (!Validator.isValidURL(value)) {
                errors["details.reset_password_url.Default"] = Global.errors["ErrorInvalidFormat"];
            }
        });

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }

    render() {
        if (!AuthService.userHasRole(['org'])) return null;
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" gutterBottom>NEW ORGANISATION</Typography>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="org_id"
                                    name="org_id"
                                    label="ID"
                                    value={this.state.org_id}
                                    onChange={this.handleChangeUpper}
                                    required
                                    autoFocus
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["org_id"] && <FormHelperText error={true}>{errors["org_id"]}</FormHelperText>}
                                <FormHelperText>Maximum length of 8 characters</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="names.en"
                                    name="name.en"
                                    label="NAME (ENGLISH)"
                                    value={this.state["names.en"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["names.en"] && <FormHelperText error={true}>{errors["names.en"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="names.zh"
                                    name="names.zh"
                                    label="NAME (CHINESE)"
                                    value={this.state["names.zh"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["names.zh"] && <FormHelperText error={true}>{errors["names.zh"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="region"
                                    name="region"
                                    label="REGION"
                                    value={this.state.region}
                                    onChange={this.handleSelect("region")}
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {Global.regions.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    label="DESCRIPTION"
                                    value={this.state.desc}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">MAILING</Typography>
                                <Divider light={true} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="details.domain"
                                    name="details.domain"
                                    label="DOMAIN NAME"
                                    value={this.state["details.domain"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.domain"] && <FormHelperText error={true}>{errors["details.domain"]}</FormHelperText>}
                                <FormHelperText>e.g. cinema.com.hk</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="details.sender_email"
                                    name="details.sender_email"
                                    label="SENDER E-MAIL ADDRESS"
                                    type="email"
                                    value={this.state["details.sender_email"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.sender_email"] && <FormHelperText error={true}>{errors["details.sender_email"]}</FormHelperText>}
                                <FormHelperText>e.g. no-reply@cinema.com.hk</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="details.sender_name"
                                    name="details.sender_name"
                                    label="SENDER NAME"
                                    value={this.state["details.sender_name"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.sender_name"] && <FormHelperText error={true}>{errors["details.sender_name"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="details.reset_password_url"
                                    name="details.reset_password_url"
                                    label="RESET PASSWORD URL"
                                    value={this.state["details.reset_password_url"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.reset_password_url"] && <FormHelperText error={true}>{errors["details.reset_password_url"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="details.bcc_recipients"
                                    name="details.bcc_recipients"
                                    label="BCC RECIPIENT"
                                    value={this.state["details.bcc_recipients"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <FormHelperText>e.g. no-reply@cinema.com.hk,...</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="details.notice_recipients"
                                    name="details.notice_recipients"
                                    label="NOTICE RECIPIENT"
                                    value={this.state["details.notice_recipients"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.notice_recipients"] && <FormHelperText error={true}>{errors["details.notice_recipients"]}</FormHelperText>}
                                <FormHelperText>e.g. no-reply@cinema.com.hk,...</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="details.member_activation_email_urls"
                                    name="details.member_activation_email_urls"
                                    label="KEY"
                                    value="BW"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.member_activation_email_urls"] && <FormHelperText error={true}>{errors["details.member_activation_email_urls"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="details.member_activation_email_urls.BW"
                                    name="details.member_activation_email_urls.BW"
                                    label="ACTIVATION REDIRECT URL"
                                    value={this.state["details.member_activation_email_urls.BW"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.member_activation_email_urls.BW"] && <FormHelperText error={true}>{errors["details.member_activation_email_urls.BW"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="details.member_activation_email_urls"
                                    name="details.member_activation_email_urls"
                                    label="KEY"
                                    value="Default"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.member_activation_email_urls"] && <FormHelperText error={true}>{errors["details.member_activation_email_urls"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="details.member_activation_email_urls.Default"
                                    name="details.member_activation_email_urls.Default"
                                    label="ACTIVATION REDIRECT URL"
                                    value={this.state["details.member_activation_email_urls.Default"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["details.member_activation_email_urls.Default"] && <FormHelperText error={true}>{errors["details.member_activation_email_urls.Default"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">REWARD POINTS</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="point_rate"
                                    name="point_rate"
                                    label="CONVERSION RATE"
                                    value={this.state["point_rate"]}
                                    type="number"
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["point_rate"] && <FormHelperText error={true}>{errors["point_rate"]}</FormHelperText>}
                                <FormHelperText>Dollar to points ratio</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <KeyboardDatePicker
                                    keyboard="true"
                                    label="EXPIRY DATE"
                                    format="dd/MM"
                                    placeholder="DD/MM"
                                    value={this.state["point_expiry"]}
                                    onChange={this.handleSelectDate("point_expiry")}
                                    clearable
                                    animateYearScrolling={false}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["point_expiry"] && <FormHelperText error={true}>{errors["point_expiry"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form >
        );
    }
}

CreatePage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreatePage);