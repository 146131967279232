import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Stepper, Step, StepLabel, Button, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import NewSelectPlan from './NewSelectPlan';
import NewRegisterForm from './NewRegisterForm';
import NewPaymentForm from './NewPaymentForm';
import NewReviewForm from './NewReviewForm';
import queryString from 'query-string';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    stepper: {
        backgroundColor: '#00000000',
        padding: `0px 0 ${theme.spacing(3)}px`
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(4)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class CreatePage extends React.Component {
    constructor(props) {
        super(props);

        this.steps = ['Select Plan', 'Personal Information', 'Payment Order', 'Review Order'];

        let member = {
            org_id: "",
            remark: "",
            profile: {
                names: {
                    en: {
                        first: "",
                        last: "",
                        middle: "",
                        full: ""
                    },
                    "zh": {
                        first: "",
                        last: "",
                        middle: "",
                        full: ""
                    }
                },
                title: "",
                fullname: "",
                gender: "",
                dob: null,
                birthDay: null,
                birthMonth: null,
                birthYearRange: "",
                region: "",
                locale: "",
                spoken_lang: "",
                tz: "",
                channel: "CRM",
                contacts: {
                    email: "",
                    msisdn: "",
                    tel: "",
                    fax: "",
                    addr: {
                        line1: "", line2: "", city: "", state: "", postal_code: "", cc: ""
                    }
                },
                prefs: {
                    "accept-newsletter": true,
                    "accept-tnc": Global.TNCDocVersion,
                    "contact-method": ""
                }
            },
            survey: {
                "occupation": "",
                "occupation-others": "",
                "income-range": "",
                "edu-level": "",
                "residence-area": "",
                "residence-place": "",
                "residence-subdistrict": "",
                // "work-area": "",
                "work-place": "",
                // "work-subdistrict": "",
                "mm-source": "",
                "mm-source-others": "",
                "nowtv": false,
                "nowtv-ch116": false,
                "work-mm": "",
                "residence-taikoo": false,
            },
            credentials: {
                email: "",
                msisdn: "",
                pass: ""
            }
        };
        var copy_m_id = null;
        let params = queryString.parse(this.props.location.search);
        if (params && params.m_id) {
            copy_m_id = params.m_id;    // copy profile from this member
        }
        this.state = {
            activeStep: 0,
            plans: [],
            plan: {},
            member: member,
            copy_m_id: copy_m_id,
            order: {},
            receipt_url: "",
            isSubmitting: false
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Member" });
    }
    componentDidMount() {
        APIService.list(APIService.PLAN + "/quota")
            .then(result => {
                var plans = [];
                if (result.data) {
                    for (var i = 0; i < result.data.length; i++) {
                        let plan = result.data[i];
                        if (plan.require_sub) { continue; }
                        plans.push(plan);
                    }
                }
                this.setState({ plans: plans });
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
    }
    handleNext = () => {
        if(this.state.activeStep === 3){
            document.getElementById("btnDiv").style.display = "none";
        }
        this.childNext();
    }

    handleBack = () => {
        // TODO: save form?
        this.setState(state => ({
            activeStep: state.activeStep - 1
        }));
    }

    handleReset = () => {
        this.setState({
            activeStep: 0
        });
    }

    handlePlan = (plan) => {
        this.setState(state => ({
            plan: plan,
            activeStep: state.activeStep + 1
        }));
    }
    handleProfile = (member, order) => {
        this.setState(state => ({
            member: member,
            order: order,
            activeStep: state.activeStep + 1
        }));
    }
    handlePayment = (order) => {
        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);
        let url = Global.APIUrl + "/orders/" + order.order_id + "/receipt?jwt=" + token;

        this.setState(state => ({
            order: order,
            receipt_url: url,
            activeStep: state.activeStep + 1
        }));
    };
    handleReview = (member) => {
        this.setState(state => ({
            member: member,
            activeStep: state.activeStep + 1
        }));
    };
    handleReceipt = () => {
        window.open(this.state.receipt_url);
    };
    handleViewMember = () => {
        this.props.history.push("/members/" + this.state.member.m_id);
    };
    handleCancel = () => {
        if (this.state.order && this.state.order.order_id) {
            this.setState({
                isSubmitting: true
            });
//            APIService.remove(APIService.ORDER, this.state.order.order_id)
//                .then(resp => {
//                    this.setState({ isSubmitting: false }, () => {
//                        this.props.history.goBack();
//                    });
//                })
//                .catch(error => {
//                    this.setState({ isSubmitting: false }, () => {
//                        this.props.history.goBack();
//                    });
//                });

                var obj = {
                    value: "pending"
                };
                APIService.call("PUT", APIService.ORDER+'/'+this.state.order.order_id+'/statuses', obj).then(resp => {
                    this.setState({ isSubmitting: false }, () => {
                        this.props.history.goBack();
                    });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false }, () => {
                        this.props.history.goBack();
                    });
                });
        } else {
            this.props.history.goBack();
        }
    };
    render() {
        if (!AuthService.userHasRole(['member_reg'])) return null;
        const { classes } = this.props;
        const { activeStep, plans } = this.state;

        var content = null;
        switch (activeStep) {
            case 0:
                content = <NewSelectPlan plans={plans} order={this.state.order} alert={this.props.alert} handler={this.handlePlan} onNext={next => this.childNext = next} />;
                break;
            case 1:
                content = <NewRegisterForm plan={this.state.plan} member={this.state.member} alert={this.props.alert} handler={this.handleProfile} onNext={next => this.childNext = next} copy_m_id={this.state.copy_m_id} />;
                break;
            case 2:
                content = <NewPaymentForm plan={this.state.plan} member={this.state.member} order={this.state.order} alert={this.props.alert} handler={this.handlePayment} onNext={next => this.childNext = next} />;
                break;
            case 3:
                content = <NewReviewForm plan={this.state.plan} member={this.state.member} order={this.state.order} alert={this.props.alert} handler={this.handleReview} onNext={next => this.childNext = next} />;
                break;
            default:
                break;
        }

        return (
            <React.Fragment>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {this.steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <React.Fragment>
                    {activeStep === this.steps.length ? (
                        <React.Fragment>
                            <Paper className={classes.paper} style={{ minHeight: '300px' }}>
                                <Grid container m={24} style={{ flexGrow: 1 }} justify="center" alignItems="center">
                                    <Grid item xs={12} sm={12} align="center">
                                        <Typography variant="h4" color="textPrimary" gutterBottom>Order Completed Successfully</Typography>
                                        <Typography variant="body1" color="textSecondary">ORDER NO</Typography>
                                        <Typography variant="h6" color="textPrimary" gutterBottom>
                                            <Link to={"/orders/" + this.state.order.order_id} style={{ textDecoration: 'none' }}>
                                                {this.state.order.order_id}
                                            </Link>
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">MEMBER ID</Typography>
                                        <Typography variant="h6" color="textPrimary">{this.state.member.m_id}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </React.Fragment>
                    ) : (
                            <React.Fragment>
                                {content}
                            </React.Fragment>
                        )}
                </React.Fragment>
                <div id="btnDiv" className={classes.buttons}>
                    {activeStep !== this.steps.length &&
                        <Button variant="contained" onClick={this.handleCancel} className={classes.button} disabled={this.state.isSubmitting}>CANCEL</Button>
                    }
                    {activeStep !== 0 && activeStep < this.steps.length &&
                        <React.Fragment>
                            <Button variant="contained" onClick={this.handleBack} className={classes.button} disabled={this.state.isSubmitting}>BACK</Button>
                            <Button variant="contained" color="primary" onClick={this.handleNext} className={classes.button} disabled={this.state.isSubmitting}>{activeStep === this.steps.length - 1 ? 'CONFIRM' : 'NEXT'}</Button>
                        </React.Fragment>
                    }
                    {activeStep === this.steps.length &&
                        <React.Fragment>
                            <Button variant="contained" onClick={this.handleViewMember} className={classes.button} disabled={this.state.isSubmitting}>VIEW MEMBER</Button>
                            <Button variant="contained" color="primary" onClick={this.handleReceipt} className={classes.button} disabled={this.state.isSubmitting}>RECEIPT</Button>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>

        );
    }
}

CreatePage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CreatePage);
