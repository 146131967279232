
import { E164 } from './E164';

var regexCode = /^[A-Za-z0-9_-]+$/;
var regexDomain = /^[a-zA-Z0-9]+([-.][a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
var regexUsername = /^[A-Za-z0-9.]+$/;
var regexEmail = /^\S+@\S+$/;
//var regexURL = /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/;
var regexURL = /^https?:\/\/(.*)/;
var regexDate = /^([0-9]{4})-([0-9]{2})-([0-9]{2})$/;
var regexBASE64 = /^[a-zA-Z0-9+/]+={0,2}$/;
var regexRedemptionPass = /^[A-Za-z0-9]+$/;

export const Validator = {
    isValidUsername,
    isValidEmail,
    isValidE164,
    isValidPassword,
    isValidCode,
    isValidRedemptionPass,
    isValidDomain,
    isValidURL,
    isValidDate,
    isQRCode,
    isEmpty,
    isEmptyObject,
    isNumber,
    isString,
    nullify,
    verifyString,
    verifyNumber,
    verifyDecimal,
    flattenNested,
    unflattenNested,
    compact
};

function isValidUsername(value) {
    return regexUsername.test(value);
}

function isValidEmail(value) {
    return regexEmail.test(value);
}

function isValidPassword(value) {
    if (!value || value.length < 8 || value.length > 20) { return false; }
    return true;
}

function isValidDomain(value) {
    return regexDomain.test(value);
}

function isValidE164(value) {
    return E164.regex.test(value);
}

function isValidURL(value) {
    return regexURL.test(value);
}

function isValidCode(value) {
    return regexCode.test(value);
}

function isValidRedemptionPass(value) {
    if (!value || value.length === 0) return true;
    if (value.length > 4) return false;
    return regexRedemptionPass.test(value);
}

function isValidDate(value) {
    if (!value) return true;

    var match = regexDate.exec(value);
    if (match && match.length > 3) {
        let y = parseInt(match[1]);
        let m = parseInt(match[2]);
        let d = parseInt(match[3]);
        if (d < 1 || d > 31) return false;
        if (m < 1 || m > 12) return false;
        if (y < 1800) return false;
        return true;
    }

    return false;
}

function isString(value) {
    return typeof(value) === 'string' || value instanceof String;
}

function isQRCode(value) {
    if (!value || (value.length % 4) !== 0 || value.length < 64) return false;
    return regexBASE64.test(value);
}

function isEmpty(value) {
    if (value instanceof Object) {
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                let v = value[key];
                if (isString(v)) {
                    if (v.length > 0) return false;
                } else {
                    return false;
                }
            }
        }
        return true;
    } else if (value instanceof Array) {
        if (value.length === 0) return true;
    } else if (isString(value)) {
        if (value.length === 0) return true;
    }
    return false;
}

function isEmptyObject(value) {
    if (value instanceof Object) {
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    } else if (value instanceof Array) {
        if (value.length === 0) return true;
    }
    return false;
}

function isNumber(value) {
    return !isNaN(parseFloat(value)) && isFinite(value);
}

function nullify(value, trim = true) {
    if (!value) return null;
    if (isString(value)) {
        if (trim) value = value.trim();
        if (value.length === 0) return null;
    } else if (value instanceof Object) {
        for (var key in value) {
            if (value.hasOwnProperty(key)) return value;
        }
        return null;
    } else if (value instanceof Array) {
        if (value.length === 0) return null;
    }
    return value;
}

function verifyString(value, validator) {
    if (value === undefined || value === null) return null;
    value = value.trim();
    if (value.length === 0) return value;
    validator(value);
    return value;
}

function verifyNumber(value, validator) {
    if (!value) return null;
    if (isString(value)) {
        value = value.trim();
        if (value.length === 0) return null;
        value = parseInt(value);
    }
    validator(value);
    return value;
}

function verifyDecimal(value, validator) {
    if (!value) return null;
    if (isString(value)) {
        value = value.trim();
        if (value.length === 0) return null;
        value = Number(value).toFixed(2);
    }
    validator(value);
    return value;
}

function flattenNested(obj, map, parent = "") {
    for (var name in obj) {
        let value = obj[name];
        if (parent.length > 0) {
            name = parent + "." + name;
        }
        if (Array.isArray(value)) {
            map[name] = value;
        } else if (value instanceof Object) {
            flattenNested(value, map, name);
        } else {
            map[name] = value;
        }
    }
}

function unflattenNested(obj, map, parent = "") {
    for (var name in obj) {
        var path;
        if (parent.length > 0) {
            path = parent + "." + name;
        } else {
            path = name;
        }
        let cur = obj[name]
        if (Array.isArray(cur)) {
            obj[name] = map[path];
        } else if (cur instanceof Object) {
            unflattenNested(cur, map, path);
        } else {
            obj[name] = map[path];
        }
    }
}

function compact(obj, depth = 0) {
    for (var name in obj) {
        let value = obj[name];
        if (value instanceof Object) {
            compact(value, depth + 1);
            if (isEmptyObject(value)) {
                delete obj[name];
            } else {
                obj[name] = value;
            }
        } else {
            if (depth > 0 && (value === null || value === "")) {
                delete obj[name];
            }
        }
    }
}

