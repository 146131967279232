import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, FormControl, FormHelperText, TextField, MenuItem } from '@material-ui/core';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { Validator } from '../../helpers/Validator';

export default class ActivationDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            m_id: 0,
            planCode: "",
            plan_code_list: null,
            title: "",
            message: "",
            open: false,
            isSubmitting: false,
            errors: {},
            activationType : "",
        };
    }
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (member) => {
        var plan_code_list = Object.keys(member.memberships).map(plan_code => (
            { value: plan_code, label: plan_code }
        ));
        if(plan_code_list.length === 1){
            this.setState({ open: true, m_id: parseInt(member.m_id), planCode: plan_code_list[0].value, errors: {}, isSubmitting: false});
        }else if(plan_code_list.length > 1){
            this.setState({ open: true, m_id: parseInt(member.m_id), planCode: plan_code_list[0].value, plan_code_list: plan_code_list, errors: {}, isSubmitting: false});
        }else{
            this.setState({ open: true, m_id: parseInt(member.m_id), errors: {}, isSubmitting: false});
        }
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleConfirm = event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        
        if (!this.validateForm()) return;
        
        this.setState({
            isSubmitting: true
        });
        
        var id = this.state.m_id;
        var plan_code = this.state.planCode;
        var params = {
           email_type: this.state.activationType
        };
        if(plan_code !== ""){
            params['plan_code'] = plan_code;
        }
        APIService.call("POST", APIService.MEMBER + "/" + id + "/activation", params)
            .then(obj => {
                this.setState({ isSubmitting: false, open: false });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value });
    }
    validateForm() {
        var errors = {};
        
        if (this.state.activationType === "") {
            errors["activation_type"] = Global.errors["ErrorRequired"];
        }
        if (this.state.plan_code_list != null && this.state.plan_code_list.length > 1 && this.state.planCode === "") {
            errors["plan_code"] = Global.errors["ErrorRequired"];
        }

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }

    render() {
        const { errors } = this.state;
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>Please confirm to send activation e-mail to member.</DialogContentText>
                <FormControl fullWidth>
                    <TextField
                        id="activationType"
                        name="activationType"
                        select
                        label="Email Type"
                        value={this.state.activationType}
                        onChange={this.handleSelect("activationType")}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                    >
                        <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                        {Global.activationType.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {errors["activation_type"] && <FormHelperText error={true}>{errors["activation_type"]}</FormHelperText>}
                    {this.state.plan_code_list != null && this.state.plan_code_list.length > 1 && 
                        <TextField
                            id="planCode"
                            name="planCode"
                            select
                            label="Plan Code"
                            value={this.state.planCode}
                            onChange={this.handleSelect("planCode")}
                            required
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                        >
                            {this.state.plan_code_list.map(obj => (
                                <MenuItem key={obj.value} value={obj.value}>
                                    {obj.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    }
                    {errors["plan_code"] && <FormHelperText error={true}>{errors["plan_code"]}</FormHelperText>}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleConfirm} color="primary" disabled={this.state.isSubmitting}>OK</Button>
                <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
            </DialogActions>
        </Dialog>
    }
}
