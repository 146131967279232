import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, IconButton, Divider, Button, Typography, withStyles } from '@material-ui/core';
import {
    Edit as EditIcon,
    Close as CloseIcon,
    History as HistoryIcon,
} from '@material-ui/icons';
import Source from '../../components/Source';
import LastUpdated from '../../components/LastUpdated';
import { Label, DateLabel } from '../../components/Label';
import Status from '../../components/Status';
import ConfirmDialog from '../../components/ConfirmDialog';
import FilterOptions from '../../components/FilterOptions';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';
import { parse } from 'date-fns';
import OfferLogDialog from './OfferLogDialog';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class DetailPointPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {},
            plans: [],
            plan_groups: [],
            isSubmitting: false,
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Campaign Offer" });
    }
    componentDidMount() {
        this.load();
        NameService.planList((plans) => {
            var plan_groups = [];
            for (var i = 0; i < plans.length; i++) {
                let plan = plans[i];
                if (!plan.plan_group_code) continue;
                var exist = false;
                for (var j = 0; j < plan_groups.length; j++) {
                    let plan_group = plan_groups[j];
                    if (plan_group.value === plan.plan_group_code) {
                        exist = true;
                        break;
                    }
                }
                if (!exist) plan_groups.push({ value: plan.plan_group_code, label: plan.plan_group_code });
            }
            this.setState({ plans: plans, plan_groups: plan_groups });
        })
    }
    load() {
        let id = this.props.match.params.id;
        APIService.get(APIService.OFFER, id)
            .then(obj => {
                this.setState({ obj: obj })
            })
            .catch(error => {
                this.props.history.goBack();
            })
    }
    handleLog = () => {
        let id = this.props.match.params.id;
        this.showLog(id);
    }
    canIssue = () => {
        const { obj } = this.state;
        if (!obj.recipients) return false;
        let now = new Date();
        if (obj.validity_begin) {
            let begin_time = parse(obj.validity_begin, Global.DisplayDateFormat, now);
            if (begin_time.getTime() > now.getTime()) return false
        }
        if (obj.validity_end) {
            let end_time = parse(obj.validity_end, Global.DisplayDateFormat, now);
            if (end_time.getTime() < now.getTime()) return false
        }
        return true;
    }
    handleStatus = (activity, status) => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to " + activity + " offer.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("GET", APIService.OFFER + "/" + id + "/status/" + status)
                .then(() => {
                    this.setState({ isSubmitting: false }, () => { this.load(); });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                })
        })
    }
    handleIssue = () => {
        if (this.state.isSubmitting) return;
        let id = this.props.match.params.id;
        APIService.call("GET", APIService.OFFER + "/" + id + "/estimate_members")
            .then(obj => {
                if (obj.total === 0) {
                    this.setState({ isSubmitting: false });
                    this.props.alert("No members were found");
                } else {
                    this.issue(id, obj.total);
                }
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            })
    }
    issue = (id, total) => {
        this.confirm("Confirmation", "Please confirm to issue points. An estimate of the number of members is " + total + ".", () => {
            this.setState({ isSubmitting: true });
            APIService.call("GET", APIService.OFFER + "/" + id + "/issue")
                .then(obj => {
                    this.setState({ isSubmitting: false }, () => { this.load(); });
                    this.props.alert("Request submitted successfully", "success");
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                })
        })
    }
    render() {
        if (!AuthService.userHasRole(['offer_point'])) return null;
        const { classes } = this.props;
        const { obj } = this.state;
        return (
            <React.Fragment>
                <Paper className={classes.paper}>
                    <Grid container m={24}>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="h4"><Label data={obj} field="names" locale="en" /></Typography>
                            <Typography variant="body1" color="textSecondary">ID: {obj.offer_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} align="right">
                            <IconButton component={Link} to={"/offers/point/" + this.props.match.params.id + "/edit"}><EditIcon /></IconButton>
                            <IconButton onClick={this.handleLog} ><HistoryIcon /></IconButton>
                            <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">ORGANISATION</Typography>
                            <Typography variant="body1" color="textPrimary">{obj.org_id}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">NAME (ENGLISH)</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="names" locale="en" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">NAME (CHINESE)</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="names" locale="zh" /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.desc} /></Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">PROMO ID</Typography>
                            <Typography variant="body1" color="textPrimary">{obj.promo_id}</Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">OFFER TYPE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.offerTypes} value={obj.type} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">EVENT TYPE</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={Global.offerEventTypes} value={obj.event_type} /></Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">POINTS</Typography>
                            <Typography variant="body1" color="textPrimary"><Label data={obj.points} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="caption" color="textSecondary">VALIDITY</Typography>
                            <Typography variant="body1" color="textPrimary"><DateLabel begin={obj.validity_begin} end={obj.validity_end} /></Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">REMARK</Typography>
                            <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.remark} /></Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" color="textSecondary">APPLICABLE TO</Typography>
                            <Divider light={true} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" color="textSecondary">SUBSCRIPTION</Typography>
                            {obj.recipients && obj.recipients.membership &&
                                <FilterOptions id="recipients.membership" options={this.state.plans} selected={obj.recipients.membership} readonly />
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="caption" color="textSecondary">SUBSCRIPTION GROUP</Typography>
                            {obj.recipients && obj.recipients["membership-group"] &&
                                <FilterOptions id="recipients.membership-group" options={this.state.plan_groups} selected={obj.recipients["membership-group"]} readonly />
                            }
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Status label={obj.status} />
                        </Grid>
                        <Grid item xs={12} sm={8} align="right">
                            <Typography variant="caption" color="textSecondary">
                                <LastUpdated created_at={obj.created_at} created_by={obj.created_by} modified_at={obj.modified_at} modified_by={obj.modified_by} />
                            </Typography><Source data={obj} />
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                    {obj.status === "inactive" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("activate", "active")} disabled={this.state.isSubmitting}>Activate</Button>
                    }
                    {obj.status === "active" &&
                        <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("deactivate", "inactive")} disabled={this.state.isSubmitting}>Deactivate</Button>
                    }
                    {obj.status === "active" &&
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.handleIssue} disabled={this.state.isSubmitting || !this.canIssue()}>ISSUE</Button>
                    }
                </Grid>
                <OfferLogDialog onShowLog={dialog => this.showLog = dialog} alert={this.props.alert} />
                <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
            </React.Fragment>
        );
    }
}

DetailPointPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DetailPointPage);