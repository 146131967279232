let regex = /^(.+)([dmwy])$/;
let regexDigits = /^\d+$/;

export const Period = {
    regex,
    parse
};

function parse(value) {
    var match = regex.exec(value);
    if (match && match.length > 2) {
        return {duration: match[1], unit: match[2]};
    } else if (regexDigits.test(value)) {
        return {duration: "", unit: "d"};
    } else {
        return {duration: "", unit: value};
    }
}
