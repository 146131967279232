import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from "react-router-dom";
import { CssBaseline, Hidden, withStyles } from '@material-ui/core';
// import { Close as CloseIcon } from '@material-ui/icons';
import Navigator from '../components/Navigator';
import Header from '../components/Header';
import PopupMessage from '../components/PopupMessage';
import { PrivateRoute } from '../services/Auth';
import { default as DashboardPage } from './DashboardPage';
import { default as UserListPage } from './users/ListPage';
import { default as UserCreatePage } from './users/CreatePage';
import { default as UserEditPage } from './users/EditPage';
import { default as UserDetailPage } from './users/DetailPage';
import { default as OrgListPage } from './orgs/ListPage';
import { default as OrgCreatePage } from './orgs/CreatePage';
import { default as OrgEditPage } from './orgs/EditPage';
import { default as OrgDetailPage } from './orgs/DetailPage';
import { default as DeptListPage } from './depts/ListPage';
import { default as DeptCreatePage } from './depts/CreatePage';
import { default as DeptEditPage } from './depts/EditPage';
import { default as DeptDetailPage } from './depts/DetailPage';
import { default as MemberListPage } from './members/ListPage';
import { default as MemberCreatePage } from './members/CreatePage';
import { default as MemberDetailPage } from './members/DetailPage';
import { default as MemberEditPage } from './members/EditPage';
import { default as MemberSubscribePage } from './members/SubscribePage';
import { default as MemberRenewPage } from './members/RenewPage';
import { default as PlanListPage } from './plans/ListPage';
import { default as PlanCreatePage } from './plans/CreatePage';
import { default as PlanEditPage } from './plans/EditPage';
import { default as PlanDetailPage } from './plans/DetailPage';
import { default as OfferListPage } from './offers/ListPage';
import { default as OfferPointPage } from './offers/OfferPointPage';
import { default as OfferCouponPage } from './offers/OfferCouponPage';
import { default as OfferEditCouponPage } from './offers/EditCouponPage';
import { default as OfferEditPointPage } from './offers/EditPointPage';
import { default as OfferDetailPointPage } from './offers/DetailPointPage';
import { default as OfferDetailCouponPage } from './offers/DetailCouponPage';
import { default as TransactionListPage } from './trans/ListPage';
import { default as TransactionDetailPage } from './trans/DetailPage';
import { default as TemplateListPage } from './tpls/ListPage';
import { default as TemplateCreatePage } from './tpls/CreatePage';
import { default as TemplateEditPage } from './tpls/EditPage';
import { default as TemplateDetailPage } from './tpls/DetailPage';
import { default as OrderListPage } from './orders/ListPage';
import { default as OrderDetailPage } from './orders/DetailPage';
import { default as OrderSettlementPage } from './orders/Settlement';
import { default as QuotaListPage } from './quotas/ListPage';
import { default as QuotaCreatePage } from './quotas/CreatePage';
import { default as QuotaEditPage } from './quotas/EditPage';
import { default as QuotaDetailPage } from './quotas/DetailPage';
import ChangePasswordDialog from './ChangePasswordDialog';

const drawerWidth = 210;

const styles = theme => ({
	root: {
		display: 'flex'
//		minHeight: '100vh'
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0
		}
	},
	appContent: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column'
	},
	mainContent: {
		flex: 1,
		padding: '24px 24px 24px',
		background: '#eceff1'
	},
	contentWrapper: {
		margin: '16px 16px'
	}
});

class HomePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			currentContext: "",
			mobileOpen: false
		};
	}

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	}
	handleMenuSelection = () => {
	}
	alert = (message, variant = "error") => {
		this.showPopup(message, variant);
	}
	onContext = context => {
		this.setState({ currentContext: context });
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.root}>
				<CssBaseline />
				<nav className={classes.drawer}>
					<Hidden smUp implementation="js">
						<Navigator
							PaperProps={{ style: { width: drawerWidth } }}
							variant="temporary"
							open={this.state.mobileOpen}
							onClose={this.handleDrawerToggle}
							onMenuSelected={this.handleMenuSelection}
						/>
					</Hidden>
					<Hidden xsDown implementation="css">
						<Navigator PaperProps={{ style: { width: drawerWidth } }} />
					</Hidden>
				</nav>
				<div className={classes.appContent}>
					<Header onDrawerToggle={this.handleDrawerToggle} changePassword={this.showChangePassword} context={this.state.currentContext} />
					<main className={classes.mainContent}>
						<Switch>
							<PrivateRoute exact path="/" component={DashboardPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/orgs" component={OrgListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/orgs/new" component={OrgCreatePage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/orgs/:id/edit" component={OrgEditPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/orgs/:id" component={OrgDetailPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/depts" component={DeptListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/depts/new" component={DeptCreatePage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/depts/:id/edit" component={DeptEditPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/depts/:id" component={DeptDetailPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/users" component={UserListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/users/new" component={UserCreatePage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/users/:id/edit" component={UserEditPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/users/:id" component={UserDetailPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/members" component={MemberListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/members/new" component={MemberCreatePage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/members/:id/edit" component={MemberEditPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/members/:id/subscribe" component={MemberSubscribePage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/members/:id/renew/:plan_code" component={MemberRenewPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/members/:id" component={MemberDetailPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/plans" component={PlanListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/plans/new" component={PlanCreatePage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/plans/:id/edit" component={PlanEditPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/plans/:id" component={PlanDetailPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/offers" component={OfferListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/offers/coupon" component={OfferCouponPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/offers/point" component={OfferPointPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/offers/coupon/:id/edit" component={OfferEditCouponPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/offers/point/:id/edit" component={OfferEditPointPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/offers/coupon/:id" component={OfferDetailCouponPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/offers/point/:id" component={OfferDetailPointPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/tpls" component={TemplateListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/tpls/new" component={TemplateCreatePage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/tpls/:id/edit" component={TemplateEditPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/tpls/:id" component={TemplateDetailPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/orders" component={OrderListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/orders/settlement" component={OrderSettlementPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/orders/:id" component={OrderDetailPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/transactions" component={TransactionListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/transactions/:id" component={TransactionDetailPage} onContext={this.onContext} alert={this.alert} />

							<PrivateRoute exact path="/quotas" component={QuotaListPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute exact path="/quotas/new" component={QuotaCreatePage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/quotas/:id/edit" component={QuotaEditPage} onContext={this.onContext} alert={this.alert} />
							<PrivateRoute path="/quotas/:id" component={QuotaDetailPage} onContext={this.onContext} alert={this.alert} />
							<Redirect from='*' to='/' />
						</Switch>
					</main>
				</div>
				<ChangePasswordDialog onDialog={dialog => this.showChangePassword = dialog} alert={this.alert} />
				<PopupMessage handler={popup => this.showPopup = popup} />
			</div>
		);
	}
}

HomePage.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HomePage);