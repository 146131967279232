import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, FormHelperText, FormControlLabel, Checkbox, TextField, MenuItem, Divider, Typography, withStyles } from '@material-ui/core';
import FormBase from '../../components/FormBase';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { NameService } from '../../services/Name';
import { AuthService } from '../../services/Auth';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class EditPage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            u_id: "",
            org_id: "",
            dept_id: "",
            name: "",
            desc: "",
            login: "",
            email: "",
            roles: {}
        };
        for (var i = 0; i < Global.userRoles.length; i++) {
            this.obj.roles[Global.userRoles[i].value] = false;
        }
        this.state = {
            isSubmitting: false,
            depts: [{ value: "", label: "" }],
            orgs: [{ value: "", label: "" }],
            errors: {}
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "User" });
    }
    componentDidMount() {
        APIService.get(APIService.USER, this.props.match.params.id)
            .then(obj => {
                var map = {};
                Validator.flattenNested(obj, map);
                this.setState(map);
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                this.props.history.goBack();
            });
        let user = AuthService.currentUser();
        if (user.org_id) {
            this.setState({ orgs: [{ value: user.org_id, label: user.org_id }] });
        } else {
            NameService.organisationList((orgs) => {
                this.setState({ orgs: orgs });
            });
        }
        NameService.departmentList((depts) => {
            this.setState({ depts: depts });
        });
    }
    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        let output = JSON.parse(JSON.stringify(this.obj));
        Validator.compact(output);
        console.log(output);
        for (var role in output.roles) {
            if (!output.roles[role]) {
                delete output.roles[role];
            }
        }
        this.setState({
            isSubmitting: true
        });

        APIService.update(APIService.USER, output, output.u_id, { debug: "t" })
            .then(result => {
                this.props.alert("User updated", "success");
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};
        this.obj.name = Validator.verifyString(this.obj.name, value => { });
        this.obj.desc = Validator.verifyString(this.obj.desc, value => { });
        this.obj.login = Validator.verifyString(this.obj.login, value => {
            if (!Validator.isValidUsername(value)) {
                errors["login"] = Global.errors["ErrorInvalidUsername"];
            }
        });
        this.obj.email = Validator.verifyString(this.obj.email, value => {
            if (!Validator.isValidEmail(value)) {
                errors["email"] = Global.errors["ErrorInvalidEmail"];
            }
        });
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        if (!AuthService.userHasRole(['user'])) return null;
        const { classes } = this.props;
        const { errors } = this.state;

        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h4">{this.state.name}</Typography>
                                <Typography variant="body1" color="textSecondary">ID: {this.props.match.params.id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} align="right">
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="name"
                                    name="name"
                                    label="NAME"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    autoFocus
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["name"] && <FormHelperText error={true}>{errors["name"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="org_id"
                                    name="org_id"
                                    label="ORGANISATION"
                                    select
                                    value={this.state.org_id}
                                    onChange={this.handleSelect("org_id")}
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem key="" value=""><em>None</em></MenuItem>
                                    {this.state.orgs.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="dept_id"
                                    name="dept_id"
                                    label="DEPARTMENT"
                                    select
                                    value={this.state.dept_id}
                                    onChange={this.handleSelect("dept_id")}
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem key="" value=""><em>None</em></MenuItem>
                                    {this.state.depts.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    label="DESCRIPTION"
                                    value={this.state.desc}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="login"
                                    name="login"
                                    label="LOGIN"
                                    type="login"
                                    value={this.state.login}
                                    onChange={this.handleChange}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["login"] && <FormHelperText error={true}>{errors["login"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="E-MAIL ADDRESS"
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["email"] && <FormHelperText error={true}>{errors["email"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">ROLES</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={12} container>
                                {Global.userRoles.map(option => (
                                    <React.Fragment key={option.value}>
                                        {option.group &&
                                            <Grid item xs={12} sm={12}>
                                                {option.spacing &&
                                                    <br />
                                                }
                                                <Typography variant="caption" color="textSecondary" gutterBottom>{option.group}</Typography>
                                            </Grid>
                                        }
                                        <Grid item xs={12} sm={4}>
                                            <FormControlLabel
                                                control={<Checkbox id={"roles." + option.value} color="primary" onChange={this.handleCheckbox} checked={this.state["roles." + option.value]} />}
                                                label={option.label}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                ))}
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form>
        );
    }
}

EditPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditPage);