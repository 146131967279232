import React from 'react';
//import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button } from '@material-ui/core';
//import CodeIcon from '@material-ui/icons/Code';

export default class Source extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showSource: false, data: props.data };
    }
    showSource = () => {
        this.setState({
            showSource: true,
        });
    };
    hideSource = () => {
        this.setState({ showSource: false });
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.data !== this.props.data) {
            this.setState({ data: nextProps.data });
        }
    }
    render() {
        return null;
        /*
        return <span>
            <IconButton component="span" onClick={this.showSource}>
                <CodeIcon />
            </IconButton>
            <Dialog open={this.state.showSource} onClose={this.hideSource} fullWidth={true} maxWidth="md">
                <DialogTitle>SOURCE</DialogTitle>
                <DialogContent>
                    <pre>{JSON.stringify(this.state.data, null, 4)}</pre>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.hideSource} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </span>*/
    }
}
