import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames'
import { IconButton, Snackbar, SnackbarContent, withStyles } from '@material-ui/core';
import {
    CheckCircle as CheckCircleIcon,
    Error as ErrorIcon,
    Info as InfoIcon,
    Warning as WarningIcon,
    Close as CloseIcon
} from '@material-ui/icons';
import { green, amber, blue } from '@material-ui/core/colors';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const styles = theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: blue[600]
    },
    warning: {
        backgroundColor: amber[700]
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: 'flex',
        alignItems: 'center'
    }
});

class PopupMessage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            message: "",
            variant: "info",  // success, warning, error, info
            open: false
        };
    }
    componentDidMount() {
        this.props.handler(this.show);
    }
    show = (message, variant) => {
        this.setState({ open: true, message: message, variant: variant });
    }
    handleClose = () => {
        this.setState({ open: false, message: "" });
    }
    render() {
        const { classes, className } = this.props;
        const { message, variant, open } = this.state;
        const Icon = variantIcon[variant];
        return (
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={open}
                autoHideDuration={3000}
                onClose={this.handleClose}>
                <SnackbarContent
                    className={classnames(classes[variant], className)}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.message}>
                            <Icon className={classnames(classes.icon, classes.iconVariant)} />
                            <span style={{whiteSpace: "pre-line"}}>{message}</span>
                        </span>
                    }
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleClose}
                        >
                            <CloseIcon className={classes.icon} />
                        </IconButton>
                    ]}
                />
            </Snackbar>
        );
    }
}

PopupMessage.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    show: PropTypes.func
};

export default withStyles(styles)(PopupMessage);
