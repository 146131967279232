import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Card, CardHeader, CardContent, CardActions, Avatar, LinearProgress, IconButton, Button, Divider, Typography, withStyles } from '@material-ui/core';
import {
    Edit as EditIcon,
    AddCircleOutline as AddIcon,
    RemoveCircleOutline as DeductIcon,
    Close as CloseIcon,
    PhoneIphone as MobileIcon,
    Call as TelephoneIcon,
    MailOutline as MailIcon,
    Home as AddressIcon,
    DeleteForever as DeleteIcon,
    History as HistoryIcon,
    List as CouponListIcon,
    Stars as PointIcon,
    LocalPlay as CouponIcon,
    AssignmentInd as CredentialsIcon,
    CameraAlt as CameraIcon,
} from '@material-ui/icons';
import Source from '../../components/Source';
import { Label, DateLabel, AddressLabel, CheckLabel } from '../../components/Label';
import Status from '../../components/Status';
import LastUpdated from '../../components/LastUpdated';
import UploadDialog from '../../components/UploadDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import { Link } from 'react-router-dom';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import AddPointDialog from './AddPointDialog';
import DeductPointDialog from './DeductPointDialog';
import ExtendDialog from '../plans/ExtendDialog';
import CouponDialog from '../offers/CouponDialog';
import TermDialog from '../plans/TermDialog';
import TransDialog from '../trans/TransDialog';
import BanDialog from './BanDialog';
import ActivationDialog from './ActivationDialog';
import Carousel from '../../components/Carousel';
import RoleControl from '../../components/RoleControl';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`
    },
    iconButton: {
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    },
    points: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2)
    },
    coupons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2)
    },
    avatar: {
        float: "left",
        marginLeft: 0,
        marginRight: 16,
        width: 80,
        height: 80
    },
    banned: {
        backgroundColor: theme.palette.error.dark
    }
});

class MembershipSourceLabel extends React.Component {
    constructor(props) {
        super(props);

        let options = props.options;
        let value = props.value;
        let others = props.others;
        let selected = Global.getValueLabelPair(options, value);
        var label = "";
        if (selected) {
            if (selected.others) {
                if (others) {
                    label = others;
                } else {
                    label = selected.label;
                }
            } else {
                label = selected.label;
            }
        }
        this.state = {
            label: label
        };
    }

    render() {
        return <React.Fragment>{this.state.label}</React.Fragment>;
    }
}

class Membership extends React.Component {
    canExtend = () => {
        let status = this.props.membership.status;
        return status === 'active' || status === 'expired';
    }
    canRenew = () => {
        let status = this.props.membership.renewal_type;
        if (!status || status === "new") return false;
        return status;
    }
    canTerminate = () => {
        return this.props.membership.status === 'active';
    }
    render() {
        const { membership } = this.props;
        const { plans } = this.props;
        let plans_name = "";
        if(plans && plans[membership.plan_code]){
            plans_name = " - " + plans[membership.plan_code].names.en ;
        }
        let progress = Global.getElapsedPeriod(membership.subscribed_at, membership.expires_at)        
        return (
            <Card>
                <CardHeader title={membership.plan_code + plans_name} action={
                    <RoleControl required={["trans_sub"]}>
                        <IconButton onClick={() => this.props.handleTransaction(membership)} ><HistoryIcon /></IconButton>
                    </RoleControl>
                } />
                <CardContent>
                    <React.Fragment>
                        <Grid container>
                            <Grid item xs={6} sm={6} align="left"><Typography variant="caption" color="textPrimary"><DateLabel date={membership.renewed_at ? membership.renewed_at : membership.subscribed_at} format={Global.DisplayDateFormat} /></Typography></Grid>
                            <Grid item xs={6} sm={6} align="right"><Typography variant="caption" color="textPrimary"><DateLabel date={membership.expires_at} format={Global.DisplayDateFormat} expiry={true} /></Typography></Grid>
                        </Grid>
                        <LinearProgress variant="determinate" value={progress} style={{ marginBottom: 16 }} />
                    </React.Fragment>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            {membership.terminated_at &&
                                <React.Fragment>
                                    <Typography variant="caption" color="textSecondary">TERMINATED AT</Typography>
                                    <Typography variant="body1" color="textPrimary"><DateLabel date={membership.terminated_at} format={Global.DisplayDateFormat} /></Typography>
                                </React.Fragment>
                            }
                            {membership.renewals && !membership.terminated_at &&
                                <Typography variant="caption" color="textSecondary">{membership.renewals} renewal(s)</Typography>
                            }
                            {membership.channel &&
                                <React.Fragment>
                                    <Typography variant="caption" color="textSecondary">CHANNEL</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={membership.channel} /></Typography>
                                </React.Fragment>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} align="right">
                            <Status label={membership.status} />
                        </Grid>
                    </Grid>
                </CardContent>
                {!membership.terminated_at &&
                    <CardActions>
                        <RoleControl required={["member_reg"]}>
                            <Button size="small" color="primary" component={Link} to={"/members/" + this.props.member.m_id + "/renew/" + membership.plan_code} disabled={!this.canRenew()} >RENEW</Button>
                            <Button size="small" color="primary" onClick={() => this.props.handleExtend(membership)} disabled={!this.canExtend()}>EXTEND</Button>
                            <Button size="small" color="primary" onClick={() => this.props.handleTerminate(membership)} disabled={!this.canTerminate()} >TERMINATE</Button>
                        </RoleControl>
                    </CardActions>
                }
            </Card>
        );
    }
};

class RewardPoint extends React.Component {
    render() {
        const { classes, point_ac } = this.props;
        // let unit = point_ac.type === "point" ? "Reward Points" : "Stamp";
        return (
            <Card>
                <CardHeader title={
                    <Typography variant="body1" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}><PointIcon fontSize="small" /> &nbsp;POINTS</Typography>
                }
                    action={
                        <React.Fragment>
                            <RoleControl required={["offer_point_adj"]}>
                                <IconButton onClick={() => this.props.handleAddPoint(point_ac)}><AddIcon /></IconButton>
                                {point_ac.balance > 0 &&
                                    <IconButton onClick={() => this.props.handleDeductPoint(point_ac)}><DeductIcon /></IconButton>
                                }
                            </RoleControl>
                            <RoleControl required={["trans_point"]}>
                                <IconButton onClick={() => this.props.handleTransaction(point_ac)}><HistoryIcon /></IconButton>
                            </RoleControl>
                        </React.Fragment>
                    } />
                <CardContent>
                    <div className={classes.points}>
                        <Typography component="h2" variant="h4" color="textPrimary">{point_ac.balance}</Typography>
                        <Typography variant="h6" color="textSecondary">pts</Typography>
                    </div>
                    {point_ac.balanced_at &&
                        <React.Fragment>
                            <Typography variant="caption" color="textSecondary">BALANCED ON</Typography>
                            <Typography variant="body1" color="textPrimary" gutterBottom><DateLabel date={point_ac.balanced_at} format={Global.DisplayDateFormat} /></Typography>
                        </React.Fragment>
                    }
                    {point_ac.expires_at && point_ac.balance > 0 &&
                        <React.Fragment>
                            <Typography variant="caption" color="textSecondary">EXPIRATION</Typography>
                            <Typography variant="body1" color="textPrimary" gutterBottom><Label data={point_ac.expires_amount} /> (<Label data={Math.round(point_ac.expiry_pct * 100)} unit="%" />) points expire on <DateLabel date={point_ac.expires_at} format={Global.DisplayDateFormat} /></Typography>
                        </React.Fragment>
                    }
                </CardContent>
            </Card>
        );
    }
};

class Coupon extends React.Component {
    render() {
        const { classes, stats } = this.props;
        var valid = 0, redeemed = 0, expired = 0;
        for (var i of stats) {
            switch (i.status) {
                case "valid":
                    valid = i.count;
                    break;
                case "expired":
                    expired = i.count;
                    break;
                case "redeemed":
                    redeemed = i.count;
                    break;
                default:
                    break;
            }
        }
        return (
            <Card>
                <CardHeader title={
                    <Typography variant="body1" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}><CouponIcon fontSize="small" /> &nbsp;COUPONS</Typography>
                } action={
                    <IconButton onClick={this.props.handleViewCoupons}><CouponListIcon /></IconButton>
                } />
                <CardContent>
                    <div className={classes.coupons}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={4} align="center">
                                <Typography variant="caption" color="textSecondary">VALID</Typography>
                                <Typography variant="h5" color="textPrimary">{valid}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} align="center">
                                <Typography variant="caption" color="textSecondary">REDEEMED</Typography>
                                <Typography variant="h5" color="textPrimary">{redeemed}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} align="center">
                                <Typography variant="caption" color="textSecondary">EXPIRED</Typography>
                                <Typography variant="h5" color="textPrimary">{expired}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        );
    }
};

class DetailPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obj: {},
            point_acs: [],
            coupon_stats: [],
        };
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Member" });
    }
    componentDidMount() {
        this.load();
    }
    load = () => {
        this.loadMember();
        this.loadPoints();
        this.loadCouponStats();
    }
    loadMember = () => {
        let id = this.props.match.params.id;
        APIService.get(APIService.MEMBER, id)
            .then(obj => {
                this.setState({ obj: obj, banInfo: null });
                this.loadPlans(obj);
                if (obj.status === "banned") {
                    this.loadBannedInfo();
                }
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
    }
    loadBannedInfo = () => {
        let id = this.props.match.params.id;
        APIService.call("GET", APIService.MEMBER + "/" + id + "/ban_info")
            .then(info => {
                this.setState({ banInfo: info });
            })
            .catch(error => { });
    }
    loadPoints = () => {
        let id = this.props.match.params.id;
        APIService.list(APIService.MEMBER + "/" + id + "/points")
            .then(result => {
                if (result && result.data) {
                    this.setState({ point_acs: result.data });
                }
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
    }
    loadCouponStats = () => {
        let id = this.props.match.params.id;
        APIService.list(APIService.MEMBER + "/" + id + "/coupon_stats")
            .then(result => {
                if (result && result.data) {
                    this.setState({ coupon_stats: result.data });
                }
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
    }
    loadPlans = (obj) => {
        let plans = {};
        for(let [k, v] of Object.entries(obj.memberships)){
            APIService.get(APIService.PLAN, v.plan_id)
            .then(result => {
                if(result){
                    plans[k] = result;
                    this.setState({plans});
                    console.log(this.state);
                }
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
        }
    }
    canSubscribe = () => {
        let { obj } = this.state;
        if (obj.org_id === "FREE") return false;
        if (!obj.memberships) return true;
        var active = 0;
        for (var code of Object.keys(obj.memberships)) {
            let mshp = obj.memberships[code];
            if (mshp.status === "active") active++;
        }
        return active < 2;
    }
    handleDelete = () => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to delete member permanently. Please note that deleted member cannot be recovered!", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("DELETE", APIService.MEMBER + "/" + id)
                .then(obj => {
                    this.props.history.goBack();
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                });
        });
    }
    handleStatus = (activity, status) => {
        let { obj } = this.state;

        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to " + activity + " member.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("GET", APIService.MEMBER + "/" + id + "/status/" + status)
                .then(obj => {
                    this.setState({ isSubmitting: false }, () => { this.loadMember(); });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                });

            for (var code of Object.keys(obj.memberships)) {
                let mshp = obj.memberships[code];
                if(activity === 'activate'){
                    var req = {
                        org_id: obj.org_id,
                        m_id: parseInt(obj.m_id),
                        expires_at: mshp.expires_at,
                        remark: ''
                    }
                    APIService.call("POST", APIService.SUBSCRIPTION + "/" + mshp.mshp_id + "/extend", req, { debug: "t" })
                        .then(result => {
                            this.setState({ isSubmitting: false }, () => { this.loadMember(); });
                        })
                        .catch(error => {
                            this.setState({ isSubmitting: false });
                            this.props.alert(APIService.errorMessage(error));
                        });
                }
            }
        })
    }
    handleResetPassword = () => {
        if (this.state.isSubmitting) return;

        this.confirm("Confirmation", "Please confirm to send reset password e-mail to member.", () => {
            this.setState({ isSubmitting: true });
            let id = this.props.match.params.id;
            APIService.call("GET", APIService.MEMBER + "/" + id + "/reset_password")
                .then(obj => {
                    this.setState({ isSubmitting: false });
                })
                .catch(error => {
                    this.setState({ isSubmitting: false });
                    this.props.alert(APIService.errorMessage(error));
                });
        });
    }
    handleViewCoupons = () => {
        if (this.state.isSubmitting) return;
        let id = this.props.match.params.id;
        this.showCoupons(id);
    }
//    handleActivation = () => {
//        if (this.state.isSubmitting) return;
//
//        this.confirm("Confirmation", "Please confirm to send activation e-mail to member.", () => {
//                this.setState({ isSubmitting: true });
//                let id = this.props.match.params.id;
//                APIService.call("GET", APIService.MEMBER + "/" + id + "/activation")
//                    .then(obj => {
//                        this.setState({ isSubmitting: false });
//                    })
//                    .catch(error => {
//                        this.setState({ isSubmitting: false });
//                        this.props.alert(APIService.errorMessage(error));
//                    });
//        });
//    }
    handleSubscriptionLog = (membership) => {
        this.showSubscriptionLog(membership.plan_code, this.state.obj, membership);
    }
    handlePointLog = (point_ac) => {
        this.showPointLog("Point", this.state.obj, point_ac);
    }
    handleExtend = (membership) => {
        this.showExtendDialog(this.state.obj, membership);
    }
    handleTerminate = (membership) => {
        this.showTermDialog(this.state.obj, membership);
    }
    handleAddPoint = (point_ac) => {
        this.showAddPointDialog(this.state.obj, point_ac);
    }
    handleDeductPoint = (point_ac) => {
        this.showDeductPointDialog(this.state.obj, point_ac);
    }
    showImages = () => {
        const { obj } = this.state;
        if (!obj || !obj.profile) return;

        let user = AuthService.currentUser();
        if (!user) return;
        var token = user.token;
        token = encodeURIComponent(token);
        var images = [];
        for (var type in obj.profile.images) {
            let url = Global.getImage(obj.profile.images, type, "jwt=" + token);
            images.push({ name: type, url: url });
        }
        if (images.length === 0) {
            alert("No images");
            return;
        }
        this.showImagesDialog(images);
    }

    getImageType = (images) => {
        if (images.idphoto) return "idphoto";
        for (var key in images) {
            return key;
        }
        return null;
    }
    render() {
        if (!AuthService.userHasRole(['member'])) return null;
        let user = AuthService.currentUser();
        if (!user) return null;
        let token = user.token;
        const { classes } = this.props;
        const { obj, point_acs, coupon_stats, banInfo } = this.state;
        if (!obj) return null;
        let name = Global.getMemberName(obj, null);
        var workMM = Global.getNestedValue(obj, "survey.work-mm") ? Global.getNestedValue(obj, "survey.work-mm") : "";
        if(workMM === "others"){
          workMM = "None of the above";  
        }else{
            workMM = workMM.toUpperCase().substring(0,1) + workMM.toLowerCase().substring(1);
        }
        var income = Global.getNestedValue(obj, "survey.income-range") ? Global.getNestedValue(obj, "survey.income-range") : "";
        if(income === "0-10000" || income === "10001-15000" || income === "15001-20000"){
            income = '0-20000';
        }
        return (
            <Grid container m={16}>
                <Grid item xs={12} sm={8} align="right">
                    <Paper className={classes.paper}>
                        <Grid container m={24} align="left">
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h5">
                                    {obj.profile && obj.profile.images &&
                                        <Avatar className={classes.avatar} sizes="80px" src={Global.getImage(obj.profile.images, this.getImageType(obj.profile.images), "jwt=" + encodeURIComponent(token))} onClick={this.showImages} />
                                    }
                                    <Label data={name} />
                                </Typography>
                                <Typography variant="body1" color="textSecondary">ID: {obj.m_id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} align="right">
                                {obj.status !== "deleted" &&
                                    <RoleControl required={["member"]}>
                                        <IconButton component={Link} to={"/members/" + this.props.match.params.id + "/edit"}><EditIcon /></IconButton>
                                        <IconButton component="span" onClick={() => this.showUploadDialog()}><CameraIcon /></IconButton>
                                    </RoleControl>
                                }
                                <IconButton onClick={this.props.history.goBack}><CloseIcon /></IconButton>
                            </Grid>
                            {(obj.org_id !== "FREE") ? (
                                <React.Fragment>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="caption" color="textSecondary">FULL NAME (ENGLISH)</Typography>
                                        <Typography variant="body1" color="textPrimary"><Label data={obj} field="profile.names" name="full" locale="en" /></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="caption" color="textSecondary">FULL NAME (CHINESE)</Typography>
                                        <Typography variant="body1" color="textPrimary"><Label data={obj} field="profile.names" name="full" locale="zh" /></Typography>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="caption" color="textSecondary">FIRST NAME (ENGLISH)</Typography>
                                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="profile.names" name="first" locale="en" /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="caption" color="textSecondary">LAST NAME (ENGLISH)</Typography>
                                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="profile.names" name="last" locale="en" /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="caption" color="textSecondary">FIRST NAME (CHINESE)</Typography>
                                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="profile.names" name="first" locale="zh" /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="caption" color="textSecondary">LAST NAME (CHINESE)</Typography>
                                            <Typography variant="body1" color="textPrimary"><Label data={obj} field="profile.names" name="last" locale="zh" /></Typography>
                                        </Grid>
                                    </React.Fragment>
                                )
                            }
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">GENDER</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.genders} value={Global.getNestedValue(obj, "profile.gender")} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">DATE OF BIRTH</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj} field="profile.birthDay" />/<Label data={obj} field="profile.birthMonth" /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">MEMBER OF</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={obj.org_id} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">YEAR RANGE</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.getBirthYearRange(new Date().getFullYear())} value={Global.getNestedValue(obj, "profile.birthYearRange")} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">JOINED AT</Typography>
                                <Typography variant="body1" color="textPrimary"><DateLabel date={obj.created_at} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">CONTACTS</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                    <MobileIcon color="disabled" style={{ paddingRight: 8 }} /><Label data={obj} field="profile.contacts.msisdn" link="tel" />
                                </Typography>
                                <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'center' }}>
                                    <TelephoneIcon color="disabled" style={{ paddingRight: 8 }} /><Label data={obj} field="profile.contacts.tel" link="tel" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                    <MailIcon color="disabled" style={{ paddingRight: 8 }} /><Label data={obj} field="profile.contacts.email" link="mailto" />
                                </Typography>
                                <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'top' }}>
                                    <AddressIcon color="disabled" style={{ paddingRight: 8 }} /><AddressLabel data={obj} field="profile.contacts.addr" />
                                </Typography>
                                {(obj.org_id === "MM" || obj.org_id === "BC") &&
                                    <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'top' }}>
                                        <React.Fragment>
                                            <Label data={Global.areas} value={Global.getNestedValue(obj, "survey.residence-area")} /> &nbsp; /&nbsp;
                                                <Label data={Global.districts} value={Global.getNestedValue(obj, "survey.residence-place")} />&nbsp;/&nbsp;
                                                <Label data={Global.subdistricts} value={Global.getNestedValue(obj, "survey.residence-subdistrict")} />
                                        </React.Fragment>
                                    </Typography>
                                }
                            </Grid>
                            <Grid container item m={24}>
                                <Grid item xs={12} sm={12}>
                                    <Typography variant="subtitle2" color="textSecondary">MISCELLANEOUS</Typography>
                                    <Divider light={true} />
                                </Grid>
                                {obj.org_id === "FREE" &&
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="caption" color="textSecondary">PLACE OF RESIDENCE</Typography>
                                            <Typography variant="body1" color="textPrimary"><Label data={Global.districts} value={Global.getNestedValue(obj, "survey.residence-place")} /></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="caption" color="textSecondary">PLACE OF WORK</Typography>
                                            <Typography variant="body1" color="textPrimary"><Label data={Global.districts} value={Global.getNestedValue(obj, "survey.work-place")} /></Typography>
                                        </Grid>
                                    </React.Fragment>
                                }
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">OCCUPATION</Typography>
                                    <Typography variant="body1" color="textPrimary">
                                        {Global.getNestedValue(obj, "survey.occupation") === "others" ? (
                                            <Label data={Global.getNestedValue(obj, "survey.occupation-others")} />
                                        ) : (
                                                <Label data={Global.occupations(obj.org_id)} value={Global.getNestedValue(obj, "survey.occupation")} />
                                            )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">EDUCATION</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={Global.educations(obj.org_id)} value={Global.getNestedValue(obj, "survey.edu-level")} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">INCOME RANGE</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={Global.incomeRanges(obj.org_id)} value={income} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">LANGUAGE</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={Global.locales} value={Global.getNestedValue(obj, "profile.locale")} /></Typography>
                                </Grid>
                                {obj.org_id !== "FREE" &&
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="caption" color="textSecondary">SPOKEN LANGUAGE</Typography>
                                        <Typography variant="body1" color="textPrimary"><Label data={Global.spokenLanguages} value={Global.getNestedValue(obj, "profile.spoken_lang")} /></Typography>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">CHANNEL</Typography>
                                    <Typography variant="body1" color="textPrimary"><Label data={Global.getNestedValue(obj, "profile.channel")} /></Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="caption" color="textSecondary">OTHERS</Typography>
                                    <CheckLabel data={obj} field="profile.prefs.accept-newsletter" label="Accept receiving marketing / promotional updates via E-mail or SMS" />
                                    <CheckLabel data={obj} field="survey.work-mm" label={workMM} />
                                    <CheckLabel data={obj} field="survey.residence-taikoo" label="Residence of Taikoo" />
                                    <CheckLabel data={obj} field="survey.nowtv" label="NowTV Subscriber" />
                                    <CheckLabel data={obj} field="survey.nowtv-ch116" label="NowTV Ch116 Subscriber" />
                                    {obj.survey && obj.survey["mm-source"] &&
                                        <Typography variant="body1" color="textPrimary">
                                            Membership programme source: <MembershipSourceLabel
                                                value={Global.getNestedValue(obj, "survey.mm-source")}
                                                others={Global.getNestedValue(obj, "survey.mm-source-others")}
                                                options={Global.mmSources} />
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="caption" color="textSecondary">REMARK</Typography>
                                <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                    <Typography variant="body1" color="textPrimary" gutterBottom><Label data={obj.remark} /></Typography>
                                </div>
                                {banInfo && banInfo.remark &&
                                    <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                        <Typography variant="body1" style={{ color: Global.colors.banned }} gutterBottom><Label data={banInfo.remark} /></Typography>
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Status label={obj.status} />
                                {banInfo && banInfo.expires_at &&
                                    <Typography variant="caption" color="textSecondary">
                                        &nbsp;&nbsp;until <DateLabel date={banInfo.expires_at} format={Global.DisplayDateFormat} />
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={6} align="right">
                                <Typography variant="caption" color="textSecondary">
                                    <LastUpdated created_at={obj.created_at} created_by={obj.created_by} modified_at={obj.modified_at} modified_by={obj.modified_by} />
                                </Typography><Source data={obj} />
                            </Grid>
                        </Grid>
                    </Paper>
                    {obj.status !== "deleted" &&
                        <React.Fragment>
                            <RoleControl required={["member"]}>
                                <IconButton className={classes.iconButton} onClick={this.handleDelete}><DeleteIcon /></IconButton>
                            </RoleControl>
                            <RoleControl required={["member_activation"]}>
                                {(obj.status === "inactive" || (typeof obj.credentials !== "undefined" && obj.credentials && obj.credentials.status === "inactive")) &&
                                    <Button variant="contained" className={classes.button} onClick={() => this.showActivationDialog(obj)} disabled={this.state.isSubmitting}>Activation E-mail</Button>
                                }
                            </RoleControl>
                            <RoleControl required={["member_blacklist"]}>
                                {(obj.status === "inactive" || obj.status === "active") &&
                                    <Button variant="contained" className={classes.button} onClick={() => this.showBanDialog(obj)} disabled={this.state.isSubmitting}>Ban</Button>
                                }
                            </RoleControl>
                            <RoleControl required={["member_activation"]}>
                                {(obj.status === "banned" || obj.status === "inactive" || (obj.credentials && obj.credentials.status === "inactive")) &&
                                    <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("activate", "active")} disabled={this.state.isSubmitting}>Activate</Button>
                                }
                                {(obj.status === "active" || (obj.credentials && obj.credentials.status === "active")) &&
                                    <Button variant="contained" className={classes.button} onClick={() => this.handleStatus("deactivate", "inactive")} disabled={this.state.isSubmitting}>Deactivate</Button>
                                }
                            </RoleControl>
                            <RoleControl required={["member_reg"]}>
                                <Button variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting} component={Link} to={"/members/new?m_id=" + obj.m_id} >New Clone</Button>
                                {this.canSubscribe() &&
                                    <Button variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting} component={Link} to={"/members/" + obj.m_id + "/subscribe"} >
                                        Subscribe <AddIcon className={classes.rightIcon} />
                                    </Button>
                                }
                            </RoleControl>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Grid container m={16}>
                        {obj.memberships &&
                            Object.entries(obj.memberships).map(([plan_code, membership]) => (
                                <Grid item xs={12} sm={12} key={plan_code}>
                                    <Membership classes={classes} member={obj} membership={membership} plans={this.state.plans} handleExtend={this.handleExtend} handleTerminate={this.handleTerminate} handleTransaction={this.handleSubscriptionLog} />
                                </Grid>
                            ))}
                        {point_acs &&
                            point_acs.map(point_ac => (
                                <Grid item xs={12} sm={12} key={point_ac.ac_id} >
                                    <RewardPoint classes={classes} point_ac={point_ac} handleAddPoint={this.handleAddPoint} handleDeductPoint={this.handleDeductPoint} handleTransaction={this.handlePointLog} />
                                </Grid>
                            ))}
                        {coupon_stats && coupon_stats.length > 0 &&
                            <Grid item xs={12} sm={12}>
                                <Coupon classes={classes} stats={coupon_stats} handleViewCoupons={this.handleViewCoupons} />
                            </Grid>
                        }
                        {obj.credentials &&
                            <Grid item xs={12} sm={12}>
                                <Card>
                                    <CardHeader title={
                                        <Typography variant="body1" color="textSecondary" style={{ display: 'flex', alignItems: 'center' }}><CredentialsIcon fontSize="small" /> &nbsp;LOGIN CREDENTIALS</Typography>
                                    } />
                                    <CardContent>
                                        <Typography variant="caption" color="textSecondary">E-MAIL</Typography>
                                        <Typography variant="body1" color="textPrimary" gutterBottom><Label data={obj} field="credentials.email" link="mailto" /></Typography>
                                        {obj.credentials && obj.credentials.username &&
                                            <React.Fragment>
                                                <Typography variant="caption" color="textSecondary">USERNAME</Typography>
                                                <Typography variant="body1" color="textPrimary" gutterBottom><Label data={obj} field="credentials.username" /></Typography>
                                            </React.Fragment>
                                        }
                                        {obj.credentials && obj.credentials.sn_id &&
                                            <React.Fragment>
                                                <Typography variant="caption" color="textSecondary">SOCIAL NETWORK ID</Typography>
                                                <Typography variant="body1" color="textPrimary" gutterBottom><Label data={obj} field="credentials.sn_id" /></Typography>
                                            </React.Fragment>
                                        }
                                        <Grid container>
                                            <Grid item xs={12} sm={12} align="right">
                                                <Status label={obj.credentials.status} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        {obj.credentials.email && obj.credentials.email.length > 0 &&
                                            < RoleControl required={["member_reset_pass"]}>
                                                <Button size="small" color="primary" onClick={this.handleResetPassword}>RESET PASSWORD</Button>
                                            </RoleControl>
                                        }
                                    </CardActions>
                                </Card>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <TransDialog onSubscriptionLog={dialog => this.showSubscriptionLog = dialog} onPointLog={dialog => this.showPointLog = dialog} alert={this.props.alert} history={this.props.history} />
                <ExtendDialog onDialog={dialog => this.showExtendDialog = dialog} onRefresh={this.loadMember} alert={this.props.alert} />
                <TermDialog onDialog={dialog => this.showTermDialog = dialog} onRefresh={this.loadMember} alert={this.props.alert} />
                <AddPointDialog onDialog={dialog => this.showAddPointDialog = dialog} onRefresh={this.loadPoints} alert={this.props.alert} />
                <DeductPointDialog onDialog={dialog => this.showDeductPointDialog = dialog} onRefresh={this.loadPoints} alert={this.props.alert} />
                <CouponDialog onDialog={dialog => this.showCoupons = dialog} onRefresh={this.loadCouponStats} alert={this.props.alert} />
                <BanDialog onDialog={dialog => this.showBanDialog = dialog} onRefresh={this.loadMember} alert={this.props.alert} />
                <Carousel onDialog={dialog => this.showImagesDialog = dialog} />
                <UploadDialog onDialog={dialog => this.showUploadDialog = dialog} url={"members/" + obj.m_id + "/images/idphoto"} onRefresh={this.loadMember} alert={this.props.alert} />
                <ConfirmDialog onDialog={dialog => this.confirm = dialog} />
                <ActivationDialog onDialog={dialog => this.showActivationDialog = dialog} onRefresh={this.loadMember} alert={this.props.alert} />
            </Grid >
        );
    }
}

DetailPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DetailPage);
