import React from 'react';
import { Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { Label, DateLabel, CurrencyLabel } from '../../components/Label';
import { Global } from '../../Global';
import { APIService } from '../../services/API';

const styles = theme => ({
});


class TransactionDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "",
            m_id: 0,
            open: false,
            query: {},
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onSubscriptionLog(this.showSubscriptionLog);
        this.props.onPointLog(this.showPointLog);
    }
    onSelect = (event, row) => {
        this.props.history.push("/transactions/" + row.txn_id);
    }
    showPointLog = (title, member, point_ac) => {
        let query = {
            query: "cls:point;ac_id:" + point_ac.ac_id
        };
        this.setState({ open: true, title: title, m_id: member.m_id, isSubmitting: false, query: query });
    }
    showSubscriptionLog = (title, member, membership) => {
        let query = {
            query: "cls:subscription;mshp_id:" + membership.mshp_id
        };
        this.setState({ open: true, title: title, m_id: member.m_id, isSubmitting: false, query: query });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    render() {
        const cols = [
            {
                field: 'at', title: 'DATE TIME', cellStyle: { width: 200 }, render: row => (
                    <DateLabel date={row.at} format={Global.DisplayDateTimeFormat} />
                )
            },
            {
                field: 'type', title: 'TYPE', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4 }, render: row => (
                    <Label data={Global.transTypes} value={row.type} />
                )
            },
            {
                field: 'subtype', title: 'SUBTYPE', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4 }
            },
            {
                field: 'plan_id', title: 'PLAN ID', headerStyle: { padding: 4 }, cellStyle: { width: 100, padding: 4 }, render: row => (
                    <Label data={row} field="details.plan_id" />
                )
            },
            {
                field: 'offer_id', title: 'OFFER ID', headerStyle: { padding: 4 }, cellStyle: { width: 100, padding: 4 }, render: row => (
                    <Label data={row.details && row.details.offer_ids ? row.details.offer_ids.join(", ") : ""} />
                )
            },
            {
                field: 'cls', title: 'CAT', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4 }, render: row => (
                    <Label data={Global.transClasses} value={row.cls} />
                )
            },
            {
                field: 'order_id', title: 'ORDER ID', headerStyle: { padding: 1 }, cellStyle: { width: 200, padding: 4 }, render: row => (
                    <Link to={"/orders/" + row.order_id} style={{ textDecoration: 'none' }} onClick={(event) => {if(typeof row.m_id !== "undefined") event.stopPropagation();}}>{row.order_id}</Link>
                )
            },
            {
                field: 'txn_no', title: 'REF NO', headerStyle: { padding: 4 }, cellStyle: { width: 150, padding: 4 }
            },
            {
                field: 'channel', title: 'CHANNEL', headerStyle: { padding: 1 }, cellStyle: { width: 50, padding: 1 }
            },
            {
                field: 'cinema_id', title: 'CINEMA', headerStyle: { padding: 1 }, cellStyle: { width: 80, padding: 1 }
            },
            {
                field: 'dept_id', title: 'DEPT', headerStyle: { padding: 1 }, cellStyle: { width: 80, padding: 1 }
            },
            {
                field: 'amount', title: 'AMOUNT', type: 'numeric', sorting: false, headerStyle: { padding: 4 }, cellStyle: { width: 150, padding: 4 }, render: row => (
                    <CurrencyLabel data={row.amount} />
                )
            },
            {
                field: 'points', title: 'POINTS', type: 'numeric', sorting: false, cellStyle: { width: 150 }, render: row => (
                    <Label data={row.points} unit="pts" />
                )
            },
            {
                field: 'remark', title: 'REMARKS', headerStyle: { padding: 1 }, cellStyle: { width: 150 }, render: row => (
                    <Label data={row.remark}/>
                )
            }
        ];
        return <Dialog
            open={this.state.open}
            fullWidth={true}
            maxWidth="lg"
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{this.state.title} Transaction History</DialogTitle>
            <DialogContent>
                <MaterialTable title="" columns={cols} data={query =>
                    APIService.list(APIService.MEMBER + "/" + this.state.m_id + "/transactions",
                        query.page * query.pageSize,
                        query.pageSize,
                        query.orderBy ? query.orderBy.field : null,
                        query.orderDirection,
                        query.search,
                        this.state.query)
                        .then(result => {
                            return { data: result.data, page: result.offset / query.pageSize, totalCount: result.total }
                        })
                        .catch(error => {
                            this.props.alert(APIService.errorMessage(error));
                            return { data: [], page: 0, totalCount: 0 };
                        })
                } onRowClick={this.onSelect} options={{ pageSize: 10, toolbar: false }}
                    components={{
                        Container: props => (
                            <div>
                                {props.children}
                            </div>
                        )
                    }}
                     />
            </DialogContent>
        </Dialog>
    }
}

export default withStyles(styles)(TransactionDialog);