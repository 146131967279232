import React from 'react';
import { Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import MaterialTable from 'material-table';
import { Label, DateLabel } from '../../components/Label';
import Username from '../../components/Username';
import { Global } from '../../Global';
import { APIService } from '../../services/API';

const styles = theme => ({
});


class OfferLogDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            offer_id: 0,
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onShowLog(this.showLog);
    }
    showLog = (offer_id) => {
        this.setState({ open: true, offer_id: offer_id, isSubmitting: false });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    render() {
        const cols = [
            {
                field: 'created_at', title: 'ISSUED AT', headerStyle: { paddingRight: 4 }, cellStyle: { width: 200, paddingRight: 4 }, render: row => (
                    <DateLabel date={row.created_at} format={Global.DisplayDateTimeFormat} />
                )
            },
            {
                field: 'completed_at', title: 'COMPLETED AT', headerStyle: { padding: 4 }, cellStyle: { width: 200, padding: 4 }, render: row => (
                    <DateLabel date={row.completed_at} format={Global.DisplayDateTimeFormat} />
                )
            },
            {
                field: 'batch_id', title: 'BATCH_ID', cellStyle: { width: 200 }, render: row => (
                    <Label data={row.batch_id} />
                )
            },
            {
                field: 'total', title: 'TOTAL', type: 'numeric', headerStyle: { padding: 4 }, cellStyle: { width: 50, padding: 4 }, render: row => (
                    <Label data={row.total} />
                )
            },
            {
                field: 'members', title: 'MEMBERS', type: 'numeric', headerStyle: { padding: 8 }, cellStyle: { width: 50, padding: 8 }, render: row => (
                    <Label data={row.members} />
                )
            },
            {
                field: 'created_by', title: 'HANDLED BY', headerStyle: { padding: 4 }, cellStyle: { width: 150, padding: 4 }, render: row => (
                    <Username user={row.created_by} />
                )
            },
            {
                field: 'status', title: 'STATUS', headerStyle: { padding: 4 }, cellStyle: { width: 80, padding: 4 }, render: row => (
                    <Label data={row.status} />
                )
            },
            {
                field: 'failed_reason', title: 'REASON', render: row => (
                    <Label data={row.failed_reason} />
                )
            }
        ];
        return <Dialog
            open={this.state.open}
            fullWidth={true}
            maxWidth="lg"
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Offer Log</DialogTitle>
            <DialogContent>
                <MaterialTable title="" columns={cols} data={query =>
                    APIService.list(APIService.OFFER + "/" + this.state.offer_id + "/log",
                        query.page * query.pageSize,
                        query.pageSize,
                        query.orderBy ? query.orderBy.field : null,
                        query.orderDirection,
                        query.search,
                        this.state.query)
                        .then(result => {
                            return { data: result.data, page: result.offset / query.pageSize, totalCount: result.total }
                        })
                        .catch(error => {
                            this.props.alert(APIService.errorMessage(error));
                            return { data: [], page: 0, totalCount: 0 };
                        })
                } onRowClick={this.onSelect} options={{ pageSize: 10, toolbar: false }}
                    components={{
                        Container: props => (
                            <div>
                                {props.children}
                            </div>
                        )
                    }}
                     />
            </DialogContent>
        </Dialog>
    }
}

export default withStyles(styles)(OfferLogDialog);