import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, FormHelperText, FormControlLabel, Checkbox, TextField, MenuItem, Divider, Typography, withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormBase from '../../components/FormBase';
import FilterOptions from '../../components/FilterOptions';
import PeriodInput from '../../components/PeriodInput';
import { Global } from '../../Global';
import { parse } from 'date-fns';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class OfferCouponPage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            org_id: "",
            names: {
                en: "",
                zh: "",
            },
            promo_id: "",
            type: "coupon",
            nature: "",
            event_type: "adhoc",
            method: "",
            validity_begin: null,
            validity_end: null,
            coupon_validity_type: "fixed",
            coupon_validity_period: "",
            coupon_qty: "",
            quota_id: "",
            redemption_code: "",
            redemption_pass: "",
            recipients: {
                membership: [],
                "membership-group": []
            },
            details: {
                desc: { en: "", zh: "" },
                usage: { en: "", zh: "" },
                tnc: { en: "", zh: "" },
                conditions: {
                    "non-exclusive": false,
                    multi_ticket: false,
                    coupon_per_trans: 0,
                    coupon_per_member: 0,
                    points_eligible: false,
                    points_required: 0,
                    multi_redemption_code: "",
                    dynamic_redemption_code: false
                },
                attributes: {
                   buy_one_get_one: false
                }
            },
            remark: ""
        };
        this.state = {
            orgs: [],
            plans: [],
            plan_groups: [],
            natures: Global.offerNatures,
            quotas: [],
            quotas_qty: [],
            isSubmitting: false,
            errors: {}
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Campaign Offer" });
    }
    componentDidMount() {
        let user = AuthService.currentUser();
        if (user.org_id) {
            this.setState({ orgs: [{ value: user.org_id, label: user.org_id }] });
        } else {
            NameService.organisationList((orgs) => {
                this.setState({ orgs: orgs });
            })
        }
        NameService.planList((plans) => {
            this.setState({ allPlans: plans });
        })
        // load quotas
        APIService.list(APIService.QUOTA, 0, -1, null, null, null, { type: "offer" })
            .then(result => {
                var quotas = result.data.map(quota => (
                    { value: quota.quota_id, label: quota.quota_id + " (" + quota.qty + ")" }
                ));
                result.data.map(qty => (
                    this.state.quotas_qty[qty.quota_id] = qty.qty
                ));
                this.setState({ quotas: quotas });
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
            });
    }
    handleOrgSelect = name => event => {
        var org_id = event.target.value;
        var state = { [name]: org_id };
        let allPlans = this.state.allPlans;
        var plan_groups = [];
        var i, j, exist;
        if (org_id) {
            var plans = [];
            for (i = 0; i < allPlans.length; i++) {
                let plan = allPlans[i];
                if (plan.org_id === org_id) {
                    plans.push(plan);

                    if (!plan.plan_group_code) continue;
                    exist = false;
                    for (j = 0; j < plan_groups.length; j++) {
                        let plan_group = plan_groups[j];
                        if (plan_group.value === plan.plan_group_code) {
                            exist = true;
                            break;
                        }
                    }
                    if (!exist) {
                        plan_groups.push({ value: plan.plan_group_code, label: plan.plan_group_code });
                    }
                }
            }
            state.plans = plans;
            state.plan_groups = plan_groups;
        } else {
            for (i = 0; i < allPlans.length; i++) {
                let plan = allPlans[i];
                if (!plan.plan_group_code) continue;
                exist = false;
                for (j = 0; j < plan_groups.length; j++) {
                    let plan_group = plan_groups[j];
                    if (plan_group.value === plan.plan_group_code) {
                        exist = true;
                        break;
                    }
                }
                if (!exist) plan_groups.push({ value: plan.plan_group_code, label: plan.plan_group_code });
            }
            state.plans = allPlans;
            state.plan_groups = plan_groups;
        }
        state["recipients.membership"] = [];
        state["recipients.membership-group"] = [];
        this.setState(state);
    }
    handleOptionsChange = (id, options) => {
        this.setState({ [id]: options });
    }
    handleSelectMethod = name => event => {
        var natures = [];
        for (var nature of Global.offerNatures) {
            if (event.target.value === "redemption-code" && nature.value === "special") {
                continue;
            }
            natures.push(nature);
        }
        this.state["details.conditions.multi_redemption_code"] = "";
        this.setState({ [name]: event.target.value, nature: "", natures: natures });
    }
    handleSubmit = event => {
        event.preventDefault();
        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        let output = JSON.parse(JSON.stringify(this.obj));
        Validator.compact(output);
        if(output.org_id === "null"){
            output.org_id = null;
        }
        
        if (output.coupon_validity_type === "fixed") {
            delete output.coupon_validity_period;
        }
        if (output.event_type !== "adhoc") {
            delete output.method;
        }
        if (output.coupon_qty === "") {
            delete output.coupon_qty;
        } else {
            output.coupon_qty = parseInt(output.coupon_qty);
        }
        if(output.details.conditions.multi_redemption_code === "true"){
            output.details.conditions.multi_redemption_code = true;
        }else{
            delete output.details.conditions.multi_redemption_code;
        }
        
        if(!output.details.attributes.buy_one_get_one){
            delete output.details.attributes;
        }
               
        this.setState({
            isSubmitting: true
        });
        
        APIService.create(APIService.OFFER, output)
            .then(result => {
                if(result.details.conditions.multi_redemption_code && result.quota_id !== "" && !result.details.conditions.dynamic_redemption_code){
                    this.generatePromoCodeList(result.offer_id);
                }else{
                    this.props.alert("Offer created", "success");
                    this.props.history.push("/offers");
            }
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    generatePromoCodeList(id){
        var quotas;
        for(quotas in this.state.quotas_qty){
            var qty = 0;
            if(quotas === this.state.quota_id){
                qty = this.state.quotas_qty[quotas];
                APIService.call("POST", APIService.OFFER+"/"+id+"/details/conditions/multi-redemption-codes/codes", {quantity: qty})
                .then(obj => {
                    this.props.alert("Offer created", "success");
                    this.props.history.push("/offers");
                })
                .catch(error => {
                    this.props.alert(APIService.errorMessage(error));
                })
            }
        }
    }
    validateForm() {
        var errors = {};

        if (!this.obj.org_id) {
            errors["org_id"] = Global.errors["ErrorRequired"];
        }
        if (this.obj.promo_id.length > 64) {
            errors["promo_id"] = Global.errors["ErrorExceedMaxLength"];
        }
        if (!this.obj.validity_begin) {
            errors["validity_begin"] = Global.errors["ErrorRequired"];
        }
        if (!this.obj.validity_end) {
            errors["validity_end"] = Global.errors["ErrorRequired"];
        }
        if(!this.obj.nature){
            errors["nature"] = Global.errors["ErrorRequired"];
        }
        if (this.obj.validity_begin && this.obj.validity_end) {
            let begin_time = parse(this.obj.validity_begin, Global.DisplayDateFormat, new Date());
            let end_time = parse(this.obj.validity_end, Global.DisplayDateFormat, new Date());
            if (begin_time.getTime() >= end_time.getTime()) {
                errors["validity_end"] = Global.errors["ErrorEndTimeEarlier"];
            }
        }
        this.obj.details.conditions.coupon_per_trans = Validator.verifyNumber(this.obj.details.conditions.coupon_per_trans, value => {
            var err = null;
            if (isNaN(value) || !value) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.conditions.coupon_per_trans"] = err;
        });
        this.obj.details.conditions.coupon_per_member = Validator.verifyNumber(this.obj.details.conditions.coupon_per_member, value => {
            var err = null;
            if (isNaN(value) || !value) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["details.conditions.coupon_per_member"] = err;
        });

        if (this.obj.method) {
            if (this.obj.method === "redemption-code" && this.obj.details.conditions.multi_redemption_code === "false") {
                if (!this.obj.redemption_code) {
                    errors["redemption_code"] = Global.errors["ErrorRequired"];
                }
                this.obj.coupon_qty = 0;
            } 
//            else if(this.obj.quota_id === ""){
//                if (!this.obj.quota_id) {
//                    errors["quota_id"] = Global.errors["ErrorRequired"];
//                }
//            } 
            else if(this.obj.method === "redemption-code" && this.obj.nature === "movie"){
                if(this.state["details.conditions.multi_redemption_code"] === ""){
                    errors["multi_redemption_code"] = Global.errors["ErrorRequired"];
                }
            } else if (this.obj.method === "push" || this.obj.method === "claim") {
                let coupon_qty = Validator.verifyNumber(this.obj.coupon_qty, value => {
                    var err = null;
                    if (isNaN(value) || !value) {
                        err = Global.errors["ErrorInvalidValue"];
                    } else if (value < 0) {
                        err = Global.errors["ErrorNegativeValue"];
                    }
                    if (err) errors["coupon_qty"] = err;
                });
                if (!coupon_qty) {
                    errors["coupon_qty"] = Global.errors["ErrorInvalidValue"];
                } else {
                    this.obj.coupon_qty = coupon_qty;
                }
            }
        } else {
            if (this.obj.event_type === "adhoc") {
                errors["method"] = Global.errors["ErrorRequired"];
            }
        }
        if (this.obj.details.conditions.points_eligible) {
            let points_required = Validator.verifyNumber(this.obj.details.conditions.points_required, value => {
                var err = null;
                if (isNaN(value) || !value) {
                    err = Global.errors["ErrorInvalidValue"];
                } else if (value < 0) {
                    err = Global.errors["ErrorNegativeValue"];
                }
                if (err) errors["details.conditions.points_required"] = err;
            });
            if (!points_required) {
                errors["details.conditions.points_required"] = Global.errors["ErrorInvalidValue"];
            } else {
                this.obj.details.conditions.points_required = points_required;
            }
        } else {
            this.obj.details.conditions.points_required = 0;
        }

        if (this.obj.nature === "special") {
            if (!Validator.isValidRedemptionPass(this.obj.redemption_pass)) {
                errors["redemption_pass"] = Global.errors["ErrorInvalidValue"];
            }
        }

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }

    render() {
        if (!AuthService.userHasRole(['offer_coupon'])) return null;
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" gutterBottom>NEW COUPON OFFER</Typography>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="org_id"
                                    name="org_id"
                                    label="ORGANISATION"
                                    select
                                    value={this.state.org_id}
                                    onChange={this.handleOrgSelect("org_id")}
                                    required
                                    autoFocus
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: {
                                            className: classes.menu,
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem key="null" value="null"><em>ALL</em></MenuItem>
                                    {this.state.orgs.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["org_id"] && <FormHelperText error={true}>{errors["org_id"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="names.en"
                                    name="name.en"
                                    label="TITLE (ENGLISH)"
                                    value={this.state["names.en"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["names.en"] && <FormHelperText error={true}>{errors["names.en"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="names.zh"
                                    name="names.zh"
                                    label="TITLE (CHINESE)"
                                    value={this.state["names.zh"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["names.zh"] && <FormHelperText error={true}>{errors["names.zh"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    label="DESCRIPTION"
                                    multiline
                                    fullWidth
                                    value={this.state.desc}
                                    onChange={this.handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="promo_id"
                                    name="promo_id"
                                    label="E-COUPON TYPE"
                                    value={this.state.promo_id}
                                    onChange={this.handleChangeUpper}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["promo_id"] && <FormHelperText error={true}>{errors["promo_id"]}</FormHelperText>}
                                <FormHelperText>Maximum length of 64 characters</FormHelperText>
                            </Grid>
                            {/* <Grid item xs={12} sm={3}>
                                <TextField
                                    id="type"
                                    name="type"
                                    label="OFFER TYPE"
                                    value={this.state.type}
                                    onChange={this.handleSelect("type")}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {Global.couponTypes.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="event_type"
                                    name="event_type"
                                    label="EVENT TYPE"
                                    value={this.state.event_type}
                                    onChange={this.handleSelect("event_type")}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {Global.couponEventTypes.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="method"
                                    name="method"
                                    label="DELIVERY METHOD"
                                    value={this.state.method}
                                    onChange={this.handleSelectMethod("method")}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    select
                                    fullWidth
                                    required={this.state.event_type === "adhoc"}
                                    disabled={this.state.event_type !== "adhoc"}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {Global.couponMethods.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["method"] && <FormHelperText error={true}>{errors["method"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="nature"
                                    name="nature"
                                    label="OFFER NATURE"
                                    value={this.state.nature}
                                    onChange={this.handleSelect("nature")}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    required 
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    {this.state.natures.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["nature"] && <FormHelperText error={true}>{errors["nature"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <KeyboardDatePicker
                                    keyboard="true"
                                    label="VALIDITY START"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.validity_begin}
                                    onChange={this.handleSelectDate("validity_begin")}
                                    clearable
                                    required
                                    animateYearScrolling={false}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["validity_begin"] && <FormHelperText error={true}>{errors["validity_begin"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <KeyboardDatePicker
                                    keyboard="true"
                                    label="VALIDITY END"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.validity_end}
                                    onChange={this.handleSelectDate("validity_end")}
                                    clearable
                                    required
                                    animateYearScrolling={false}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["validity_end"] && <FormHelperText error={true}>{errors["validity_end"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="redemption_code"
                                    name="redemption_code"
                                    label="PROMO CODE"
                                    value={this.state["redemption_code"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    required={this.state["method"] === "redemption-code" && this.state["details.conditions.multi_redemption_code"] === "false"}
                                    disabled={this.state["method"] !== "redemption-code" || this.state["details.conditions.multi_redemption_code"] !== "false"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["redemption_code"] && <FormHelperText error={true}>{errors["redemption_code"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="redemption_pass"
                                    name="redemption_pass"
                                    label="MANUAL REDEEM CODE"
                                    value={this.state["redemption_pass"]}
                                    onChange={this.handleChange} 
                                    fullWidth
                                    disabled={this.state["nature"] !== "special"}
                                    required={this.state["nature"] === "special"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["redemption_pass"] && <FormHelperText error={true}>{errors["redemption_pass"]}</FormHelperText>}
                                <FormHelperText>Maximum 4 alphanumeric characters</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="coupon_qty"
                                    name="coupon_qty"
                                    label="COUPON QTY"
                                    value={this.state["coupon_qty"]}
                                    onChange={this.handleSelect("coupon_qty")}
                                    type="number"
                                    fullWidth
                                    disabled={this.state["event_type"] === "adhoc" && this.state["method"] === "redemption-code"}
                                    required={(this.state["event_type"] === "adhoc" && this.state["method"] !== "redemption-code") || (this.state["event_type"] !== "adhoc")}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["coupon_qty"] && <FormHelperText error={true}>{errors["coupon_qty"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="quota_id"
                                    name="quota_id"
                                    label="QUOTA"
                                    value={this.state["quota_id"]}
                                    onChange={this.handleSelect("quota_id")}
                                    select
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem value="">NONE</MenuItem>
                                    {this.state.quotas.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="multi_redemption_code"
                                    name="multi_redemption_code"
                                    label="PROMO CODE NATURE"
                                    value={this.state["details.conditions.multi_redemption_code"]}
                                    onChange={this.handleSelect("details.conditions.multi_redemption_code")}
                                    select
                                    fullWidth
                                    required={this.state["method"] === "redemption-code" && this.state["nature"] === "movie"}
                                    disabled={this.state["method"] !== "redemption-code" || this.state["nature"] !== "movie"}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {Global.promoCodeNature.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["multi_redemption_code"] && <FormHelperText error={true}>{errors["multi_redemption_code"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">COUPON DETAILS</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container m={24}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="details.desc.en"
                                            name="details.desc.en"
                                            label="DESCRIPTION (ENGLISH)"
                                            value={this.state["details.desc.en"]}
                                            onChange={this.handleChange}
                                            multiline
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="details.usage.en"
                                            name="details.usage.en"
                                            label="INSTRUCTION (ENGLISH)"
                                            value={this.state["details.usage.en"]}
                                            onChange={this.handleChange}
                                            multiline
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="details.tnc.en"
                                            name="details.tnc.en"
                                            label="TERMS &amp; CONDITIONS (ENGLISH)"
                                            value={this.state["details.tnc.en"]}
                                            onChange={this.handleChange}
                                            multiline
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container m={24}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="details.desc.zh"
                                            name="details.desc.zh"
                                            label="DESCRIPTION (CHINESE)"
                                            value={this.state["details.desc.zh"]}
                                            onChange={this.handleChange}
                                            multiline
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="details.usage.zh"
                                            name="details.usage.zh"
                                            label="INSTRUCTION (CHINESE)"
                                            value={this.state["details.usage.zh"]}
                                            onChange={this.handleChange}
                                            multiline
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField
                                            id="details.tnc.zh"
                                            name="details.tnc.zh"
                                            label="TERMS &amp; CONDITIONS (CHINESE)"
                                            value={this.state["details.tnc.zh"]}
                                            onChange={this.handleChange}
                                            multiline
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="coupon_validity_type"
                                    name="coupon_validity_type"
                                    label="COUPON VALIDITY"
                                    value={this.state["coupon_validity_type"]}
                                    onChange={this.handleSelect("coupon_validity_type")}
                                    select
                                    fullWidth
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {Global.couponValidityTypes.map(option => (
                                        <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <PeriodInput
                                    id="coupon_validity_period"
                                    name="coupon_validity_period"
                                    label="PERIOD"
                                    value={this.state["coupon_validity_period"]}
                                    onChange={this.handlePeriodChange}
                                    disabled={this.state["coupon_validity_type"] === "fixed"}
                                    required={this.state["coupon_validity_type"] === "custom"}
                                    fullWidth
                                />
                                {errors["coupon_validity_period"] && <FormHelperText error={true}>{errors["coupon_validity_period"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">RULES</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel  style={{marginRight: 80}}
                                    control={<Checkbox id="details.conditions.non-exclusive" color="primary" onChange={this.handleCheckbox} checked={this.state["details.conditions.non-exclusive"]} />}
                                    label={Global.getValueLabel(Global.offerRules, "non-exclusive")}
                                    />
                                <FormControlLabel
                                    control={<Checkbox id="details.attributes.buy_one_get_one" color="primary" onChange={this.handleCheckbox} checked={this.state["details.attributes.buy_one_get_one"]} />}
                                    label={Global.getValueLabel(Global.offerRules, "buy_one_get_one")}
                                /><br/>
                                <FormControlLabel style={{marginRight: 35}}
                                    control={<Checkbox id="details.conditions.multi_ticket" color="primary" onChange={this.handleCheckbox} checked={this.state["details.conditions.multi_ticket"]} />}
                                    label={Global.getValueLabel(Global.offerRules, "multi_ticket")}
                                />
                                <FormControlLabel
                                    control={<Checkbox id="details.conditions.dynamic_redemption_code" color="primary" onChange={this.handleCheckbox} checked={this.state["details.conditions.dynamic_redemption_code"]} />}
                                    label={Global.getValueLabel(Global.offerRules, "dynamic_redemption_code")}
                                /><br/>
                                <FormControlLabel
                                    control={<Checkbox id="details.conditions.points_eligible" color="primary" onChange={this.handleCheckbox} checked={this.state["details.conditions.points_eligible"]} />}
                                    label={Global.getValueLabel(Global.offerRules, "points_eligible")}
                                    disabled={this.state["method"] !== "claim"}
                                /><br/>
                                <TextField
                                    id="details.conditions.points_required"
                                    name="details.conditions.points_required"
                                    value={this.state["details.conditions.points_required"]}
                                    onChange={this.handleSelect("details.conditions.points_required")}
                                    type="number"
                                    disabled={this.state["method"] !== "claim" || this.state["details.conditions.points_eligible"] !== true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["details.conditions.points_required"] && <FormHelperText error={true}>{errors["details.conditions.points_required"]}</FormHelperText>}
                                <FormHelperText>{Global.getValueLabel(Global.offerRules, "points_required")}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="details.conditions.coupon_per_trans"
                                    name="details.conditions.coupon_per_trans"
                                    label="Redeem limit per transaction"
                                    value={this.state["details.conditions.coupon_per_trans"]}
                                    onChange={this.handleSelect("details.conditions.coupon_per_trans")}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    style={{width: 250}}
                                />
                                {errors["details.conditions.coupon_per_trans"] && <FormHelperText error={true}>{errors["details.conditions.coupon_per_trans"]}</FormHelperText>}
                                {/* <FormHelperText>{Global.getValueLabel(Global.offerRules, "coupon_per_trans")}</FormHelperText> */}
                                <div style={{ height: 24 }} />
                                <TextField
                                    id="details.conditions.coupon_per_member"
                                    name="details.conditions.coupon_per_member"
                                    label="Redeem limit per account"
                                    value={this.state["details.conditions.coupon_per_member"]}
                                    onChange={this.handleSelect("details.conditions.coupon_per_member")}
                                    type="number"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    style={{width: 250}}
                                />
                                {errors["details.conditions.coupon_per_member"] && <FormHelperText error={true}>{errors["details.conditions.coupon_per_member"]}</FormHelperText>}
                                {/* <FormHelperText>{Global.getValueLabel(Global.offerRules, "coupon_per_member")}</FormHelperText> */}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">APPLICABLE TO</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">SUBSCRIPTION</Typography>
                                <FilterOptions id="recipients.membership" options={this.state.plans} selected={this.state["recipients.membership"]} onChange={this.handleOptionsChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">SUBSCRIPTION GROUP</Typography>
                                <FilterOptions id="recipients.membership-group" options={this.state.plan_groups} selected={this.state["recipients.membership-group"]} onChange={this.handleOptionsChange} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form >
        );
    }
}

OfferCouponPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OfferCouponPage);
