import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, FormControl, Table, TableHead, TableBody, TableRow, TableCell, Typography, withStyles, Divider } from '@material-ui/core';
import {
    PhoneIphone as MobileIcon,
    Call as TelephoneIcon,
    MailOutline as MailIcon,
    Home as AddressIcon
} from '@material-ui/icons';
import FormBase from '../../components/FormBase';
import { Label, DateLabel, AddressLabel, CurrencyLabel, CheckLabel } from '../../components/Label';
import { Global } from '../../Global';
import { E164 } from '../../helpers/E164';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class MembershipSourceLabel extends React.Component {
    constructor(props) {
        super(props);

        let options = props.options;
        let value = props.value;
        let others = props.others;
        let selected = Global.getValueLabelPair(options, value);
        var label = "";
        if (selected) {
            if (selected.others) {
                if (others) {
                    label = others;
                } else {
                    label = selected.label;
                }
            } else {
                label = selected.label;
            }
        }
        this.state = {
            label: label
        };
    }

    render() {
        return <React.Fragment>{this.state.label}</React.Fragment>;
    }
}

class NewReviewForm extends FormBase {
    constructor(props) {
        super(props);
        this.state = {
            plan: props.plan,
            member: props.member,
            order: props.order,
            errors: {},
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onNext(this.commit);
    }
    commit = () => {
        if (this.state.isSubmitting) { return; }
        var req = this.props.member;

        let output = JSON.parse(JSON.stringify(req));
        Validator.compact(output);
        req = output;

        req.order = this.props.order;

        let initial = Global.getNestedValue(this.props.member, "profile.names.en.full");
        if (!initial) {
            alert("Missing name initial");
            return;
        }
        initial = initial.toUpperCase();
        let phone = Global.getNestedValue(this.props.member, "profile.contacts.msisdn");
        if (!phone) {
            alert("Missing mobile phone");
            return;
        }
        let tel = E164.parse(phone);
        req.credentials = {
            email: this.props.member.profile.contacts.email,
            pass: initial.substring(0, 1) + tel.number.substring(0, 6)
        };
        
        if(this.props.member.profile.dob !== null){
            var attr_birth_date = this.props.member.profile.dob.split("-");
            req.profile.birthDay = parseInt(attr_birth_date[2]);
            req.profile.birthMonth = parseInt(attr_birth_date[1]);
        }
        
        APIService.create(APIService.MEMBER, req, { debug: "t" })
            .then(member => {
                this.setState({ isSubmitting: false }, () => {
                    this.props.handler(member);
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    render() {
        const { classes } = this.props;
        return (
            <form className={classes.form}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h4" gutterBottom>Review Order</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} align="left">
                                <Typography variant="caption" color="textSecondary">ORDER NO</Typography>
                                <Typography variant="h6" color="textPrimary">{this.state.order.order_id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} align="right">
                                <Typography variant="caption" color="textSecondary">ORDER DATE</Typography>
                                <Typography variant="h6" color="textPrimary"><DateLabel date={this.state.order.created_at} format={Global.DisplayDateTimeFormat} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">MEMBER INFORMATION</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">FULL NAME (ENGLISH)</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={this.state.member} field="profile.names" name="full" locale="en" /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">FULL NAME (CHINESE)</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={this.state.member} field="profile.names" name="full" locale="zh" /></Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">GENDER</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.genders} value={Global.getNestedValue(this.state.member, "profile.gender")} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">DATE OF BIRTH</Typography>
                                <Typography variant="body1" color="textPrimary"><DateLabel date={Global.getNestedValue(this.state.member, "profile.dob")} format={Global.DisplayDateMonthFormat} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                    <MobileIcon color="disabled" style={{ paddingRight: 8 }} /><Label data={this.state.member} field="profile.contacts.msisdn" link="tel" />
                                </Typography>
                                <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'center' }}>
                                    <TelephoneIcon color="disabled" style={{ paddingRight: 8 }} /><Label data={this.state.member} field="profile.contacts.tel" link="tel" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">YEAR RANGE</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.getBirthYearRange(new Date().getFullYear())} value={Global.getNestedValue(this.state.member, "profile.birthYearRange")} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'center' }} gutterBottom>
                                    <MailIcon color="disabled" style={{ paddingRight: 8 }} /><Label data={this.state.member} field="profile.contacts.email" link="mailto" />
                                </Typography>
                                <Typography variant="body1" color="textPrimary" style={{ display: 'flex', alignItems: 'top' }}>
                                    <AddressIcon color="disabled" style={{ paddingRight: 8 }} /><AddressLabel data={this.state.member} field="profile.contacts.addr" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="caption" color="textSecondary">DISTRICT</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    <Label data={Global.areas} value={Global.getNestedValue(this.state.member, "survey.residence-area")} />&nbsp;/&nbsp;
                                    <Label data={Global.districts} value={Global.getNestedValue(this.state.member, "survey.residence-place")} />&nbsp;/&nbsp;
                                    <Label data={Global.subdistricts} value={Global.getNestedValue(this.state.member, "survey.residence-subdistrict")} />
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">OCCUPATION</Typography>
                                <Typography variant="body1" color="textPrimary">
                                    {Global.getNestedValue(this.state.member, "survey.occupation") === "others" ? (
                                        <Label data={Global.getNestedValue(this.state.member, "survey.occupation-others")} />
                                    ) : (
                                            <Label data={Global.occupations(this.state.member.org_id)} value={Global.getNestedValue(this.state.member, "survey.occupation")} />
                                        )}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">EDUCATION</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.educations(this.state.member.org_id)} value={Global.getNestedValue(this.state.member, "survey.edu-level")} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">INCOME RANGE</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.incomeRanges(this.state.member.org_id)} value={Global.getNestedValue(this.state.member, "survey.income-range")} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">LANGUAGE</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.locales} value={Global.getNestedValue(this.state.member, "profile.locale")} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">SPOKEN LANGUAGE</Typography>
                                <Typography variant="body1" color="textPrimary"><Label data={Global.spokenLanguages} value={Global.getNestedValue(this.state.member, "profile.spoken_lang")} /></Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">OTHERS</Typography>
                                <CheckLabel data={this.state.member} field="profile.prefs.accept-newsletter" label="Accept receiving marketing / promotional updates via E-mail or SMS" />
                                <CheckLabel data={this.state.member} field="survey.work-taikoo" label="Work in Taikoo" />
                                <CheckLabel data={this.state.member} field="survey.residence-taikoo" label="Residence of Taikoo" />
                                <CheckLabel data={this.state.member} field="survey.nowtv" label="NowTV Subscriber" />
                                <CheckLabel data={this.state.member} field="survey.nowtv-ch116" label="NowTV Ch116 Subscriber" />
                                {this.state.member.survey && this.state.member.survey["mm-source"] &&
                                    <Typography variant="body1" color="textPrimary">
                                        Membership programme source: <MembershipSourceLabel
                                            value={Global.getNestedValue(this.state.member, "survey.mm-source")}
                                            others={Global.getNestedValue(this.state.member, "survey.mm-source-others")}
                                            options={Global.mmSources} />
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="caption" color="textSecondary">REMARK</Typography>
                                <div style={{ whiteSpace: "pre-line", maxHeight: 100, overflow: 'auto' }}>
                                    <Typography variant="body1" color="textPrimary"><Label data={this.state.member.remark} /></Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">ORDER INFORMATION</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ITEM CODE</TableCell>
                                            <TableCell>DESCRIPTION</TableCell>
                                            <TableCell align="right">QTY</TableCell>
                                            <TableCell align="right">UNIT PRICE ({this.state.order.ccy})</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.order.items.map(item => (
                                            <TableRow key={item.code}>
                                                <TableCell>
                                                    <Typography variant="body1">{item.code}</Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body1">{item.name}</Typography>
                                                </TableCell>
                                                <TableCell align="right">{item.qty}</TableCell>
                                                <TableCell align="right">
                                                    <CurrencyLabel data={item.price} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell rowSpan={3} colSpan={2} />
                                            <TableCell align="right"><Typography color="textSecondary">TOTAL ({this.state.order.ccy})</Typography></TableCell>
                                            <TableCell align="right">
                                                <Typography variant="h5">
                                                    <CurrencyLabel data={this.state.order.total} />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"><Typography color="textSecondary">PAYMENT METHOD</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="h6"><Label data={Global.paymentMethods} value={this.state.order.pay_method} /></Typography></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="right"><Typography color="textSecondary">REF NO</Typography></TableCell>
                                            <TableCell align="right"><Typography variant="h6"><Label data={this.state.order.pay_txn_no} /></Typography></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Paper>
                </FormControl>
            </form >
        );
    }
}

NewReviewForm.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NewReviewForm);