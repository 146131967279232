import React from 'react';
import { FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, FormHelperText, Button, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { Validator } from '../../helpers/Validator';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2)
    }
});

class AddPointDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            m_id: 0,
            ac_id: "",
            points: "",
            remark: "",
            errors: {},
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (member, point_ac) => {
        this.setState({ open: true, m_id: parseInt(member.m_id), ac_id: parseInt(point_ac.ac_id), points: "", remark: "", errors: {}, isSubmitting: false });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleSubmit = event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        if (!this.validateForm()) return;

        this.setState({
            isSubmitting: true
        });

        var req = {
            ac_id: this.state.ac_id,
            points: Number(this.state.points).toFixed(2),
            remark: this.state.remark
        };
        APIService.call("POST", APIService.MEMBER + "/" + this.state.m_id + "/adj_points", req)
            .then(result => {
                this.setState({isSubmitting: false, open: false}, () => {
                    this.props.alert("Points adjusted", "success");
                    this.props.onRefresh();
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};

        let points = Validator.verifyDecimal(this.state.points, value => {
            var err = null;
            if (!value) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["points"] = err;
        });
        if (!points) {
            errors["points"] = Global.errors["ErrorInvalidValue"];
        }

        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        const { classes } = this.props;
        const { errors } = this.state;
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <form onSubmit={this.handleSubmit}>

                <DialogTitle id="form-dialog-title">Add Reward Points</DialogTitle>
                <DialogContent>
                    <DialogContentText>Add points to member's reward point account.</DialogContentText>
                    <FormControl required fullWidth className={classes.formControl}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="points"
                                    name="points"
                                    label="POINTS"
                                    value={this.state.points}
                                    onChange={this.handleChange}
                                    type="number"
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["points"] && <FormHelperText error={true}>{errors["points"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" disabled={this.state.isSubmitting}>OK</Button>
                    <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    }
}

export default withStyles(styles)(AddPointDialog);