import React from 'react';
import { FormControl, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, Grid, TextField, MenuItem, Button, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { Global } from '../../Global';
import { APIService } from '../../services/API';
import { Validator } from '../../helpers/Validator';

const styles = theme => ({
    formControl: {
        marginTop: theme.spacing(2)
    }
});

class ChangePaymentDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            order_id: "",
            pay_method: "",
            errors: {},
            open: false,
            isSubmitting: false
        };
    }
    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.show);
    }
    show = (order_id, pay_method) => {
        this.setState({ open: true, order_id: order_id, pay_method: pay_method, errors: {}, isSubmitting: false });
    }
    handleChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    }
    handleSelect = name => event => {
        this.setState({ [name]: event.target.value });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleSubmit = event => {
        if (this.state.isSubmitting) return;
        event.preventDefault();
        if (!this.validateForm()) return;

        this.setState({
            isSubmitting: true
        });

        var req = {
            pay_method: this.state.pay_method
        };
        APIService.update(APIService.ORDER, req, this.state.order_id)
            .then(result => {
                this.setState({ isSubmitting: false, open: false }, () => {
                    this.props.alert("Order updated", "success");
                    this.props.onRefresh();
                });
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};

        if (!this.state.pay_method) {
            errors["pay_method"] = Global.errors["ErrorRequired"];
        }
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }
    render() {
        const { classes } = this.props;
        const { errors } = this.state;
        return <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <form onSubmit={this.handleSubmit}>
                <DialogTitle id="form-dialog-title">Change Payment Method</DialogTitle>
                <DialogContent>
                    <DialogContentText>Changes payment method. Please note that this will not affect transaction record and settlement.</DialogContentText>
                    <FormControl required fullWidth className={classes.formControl}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="pay_method"
                                    name="pay_method"
                                    select
                                    value={this.state.pay_method}
                                    onChange={this.handleSelect("pay_method")}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                    <MenuItem key="" value=""><em>Not Specified</em></MenuItem>
                                    {Global.paymentMethods.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                {errors["pay_method"] && <FormHelperText error={true}>{errors["pay_method"]}</FormHelperText>}
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" color="primary" disabled={this.state.isSubmitting}>OK</Button>
                    <Button onClick={this.handleClose} color="secondary" disabled={this.state.isSubmitting}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    }
}

export default withStyles(styles)(ChangePaymentDialog);