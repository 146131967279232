import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Button, Typography, withStyles } from '@material-ui/core';
// import {} from '@material-ui/icons';
import { Global } from '../../Global';
import { NameService } from '../../services/Name';
import { AuthService } from '../../services/Auth';
import FilterOptions from '../../components/FilterOptions';
//import RangeInput from '../../components/RangeInput';

const styles = theme => ({
});

const FilterKeys = [
    "org",
    "membership",
    "membership-group",
    "membership-status",
    "gender",
    "profile.birthYearRange",
    "residence-area",
    "residence-place",
    "residence-subdistrict",
    "work-place",
    "occupation",
    "edu-level",
    "income-range",
    "mm-source",
    "prefs.contact-method",
    "misc",
    "channel",
    "locale",
    "spoken_lang",
    "status",
    "credentials.status"
];

const FilterAvailabilities = {
    "org": [""],
    "membership": ["BC", "MM"],
    "membership-group": ["BC", "MM"],
    "membership-status": ["BC", "MM"],
    "residence-subdistrict": ["BC", "MM"],
    "work-place": ["FREE"],
    "spoken_lang": ["BC", "MM"],
    "mm-source": ["MM"]
};

class FilterDialog extends React.Component {
    constructor(props) {
        super(props);

        let user = AuthService.currentUser();
        this.state = {
            user_org: user.org_id ? user.org_id : "",
            orgs: [],
            plans: [],
            plan_groups: [],
            mshp_status: [
                { value: "active", label: Global.getValueLabel(Global.membershipStatuses, "active") },
                { value: "inactive", label: Global.getValueLabel(Global.membershipStatuses, "inactive") },
                { value: "expired", label: Global.getValueLabel(Global.membershipStatuses, "expired") }
            ],
            open: false
        };
        for (var i = 0; i < FilterKeys.length; i++) {
            let key = FilterKeys[i];
            var selected = [];
            if (this.props.filters) {
                let values = this.props.filters[key];
                if (values) {
                    for (var j = 0; j < values.length; j++) {
                        selected.push(values[j]);
                    }
                }
            }
            this.state[key] = selected;
        }
    }

    // these for exporting method to parent
    componentDidMount() {
        this.props.onDialog(this.show);
        NameService.organisationList((orgs) => {
            this.setState({ orgs: orgs });
        });
        NameService.planList((plans) => {
            var plan_list = [];
            var plan_groups = [];
            let user_org = this.state.user_org;
            for (var i = 0; i < plans.length; i++) {
                let plan = plans[i];
                if (user_org && user_org !== plan.org_id) continue;
                var exist = false;
                for (var j = 0; j < plan_list.length; j++) {
                    let p = plan_list[j];
                    if (p.value === plan.value) {
                        exist = true;
                        break;
                    }
                }
                if (!exist) {
                    plan_list.push(plan);
                }

                if (!plan.plan_group_code) continue;
                exist = false;
                for (j = 0; j < plan_groups.length; j++) {
                    let plan_group = plan_groups[j];
                    if (plan_group.value === plan.plan_group_code) {
                        exist = true;
                        break;
                    }
                }
                if (exist) continue;
                plan_groups.push({ value: plan.plan_group_code, label: plan.plan_group_code });
            }
            this.setState({ plans: plan_list, plan_groups: plan_groups });
        });
    }
    show = () => {
        this.setState({ open: true });
    }
    isAvailable = (filter) => {
        if (this.state.user_org === "") return true;
        let scopes = FilterAvailabilities[filter];
        if (!scopes) return true;
        if (scopes.indexOf(this.state.user_org) !== -1) return true;
        return false;
    }
    handleChange = (id, options) => {
        this.setState({ [id]: options });
        // this.state.filters[id] = options;
    }
    handleSubmit = () => {
        var filters = {};
        for (var i = 0; i < FilterKeys.length; i++) {
            let key = FilterKeys[i];
            filters[key] = this.state[key];
        }
        
        this.setState({ open: false }, () => {
            this.props.onFiltersChange(filters);
        });
    }
    handleReset = () => {
        var filters = {};
        for (var i = 0; i < FilterKeys.length; i++) {
            let key = FilterKeys[i];
            filters[key] = [];
        }
        this.setState(filters);
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    render() {
        return <Dialog
            open={this.state.open}
            fullWidth={true}
            maxWidth="lg"
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Filters</DialogTitle>
            <DialogContent>
                <Grid container m={24}>
                    {this.isAvailable("org") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">ORGANISATION</Typography>
                            <FilterOptions id="org" options={this.state.orgs} selected={this.state["org"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("membership") && this.state.plans.length > 0 &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">SUBSCRIPTION</Typography>
                            <FilterOptions id="membership" options={this.state.plans} selected={this.state["membership"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("membership-group") && this.state.plan_groups.length > 0 &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">SUBSCRIPTION GROUP</Typography>
                            <FilterOptions id="membership-group" options={this.state.plan_groups} selected={this.state["membership-group"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("membership-status") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">SUBSCRIPTION STATUS</Typography>
                            <FilterOptions id="membership-status" options={this.state.mshp_status} selected={this.state["membership-status"]} onChange={this.handleChange} />
                            <Typography variant="caption" color="textSecondary">* For use only with subscription and subscription group, default active only if not specified</Typography>
                        </Grid>
                    }
                    {this.isAvailable("gender") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">GENDER</Typography>
                            <FilterOptions id="gender" options={Global.genders} selected={this.state["gender"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    <Grid item xs={12} sm={12}>
                        <Typography variant="caption" color="textSecondary">YEAR RANGE</Typography>
                        <FilterOptions id="profile.birthYearRange" options={Global.getBirthYearRange(new Date().getFullYear())} selected={this.state["profile.birthYearRange"]} onChange={this.handleChange} />
                    </Grid>
                    {this.isAvailable("residence-area") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">AREA OF RESIDENCE</Typography>
                            <FilterOptions id="residence-area" options={Global.areas} selected={this.state["residence-area"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("residence-place") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">DISTRICT OF RESIDENCE</Typography>
                            <FilterOptions id="residence-place" options={Global.districts} selected={this.state["residence-place"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("residence-subdistrict") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">SUBDISTRICT OF RESIDENCE</Typography>
                            <FilterOptions id="residence-subdistrict" options={Global.subdistricts} selected={this.state["residence-subdistrict"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("work-place") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">DISTRICT OF WORK</Typography>
                            <FilterOptions id="work-place" options={Global.districts} selected={this.state["work-place"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("occupation") && (this.state.user_org === "" || this.state.user_org === "BC" || this.state.user_org === "MM") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">OCCUPATION (BC/MM)</Typography>
                            <FilterOptions id="occupation" options={Global.occupationsBCMM} selected={this.state["occupation"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("occupation") && (this.state.user_org === "" || this.state.user_org === "FREE") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">OCCUPATION (FREE)</Typography>
                            <FilterOptions id="occupation" options={Global.occupationsFREE} selected={this.state["occupation"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("edu-level") && (this.state.user_org === "" || this.state.user_org === "BC") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">EDUCATION (BC)</Typography>
                            <FilterOptions id="edu-level" options={Global.educationsBC} selected={this.state["edu-level"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("edu-level") && (this.state.user_org === "" ||  this.state.user_org === "MM") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">EDUCATION (MM)</Typography>
                            <FilterOptions id="edu-level" options={Global.educationsMM} selected={this.state["edu-level"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("income-range") && (this.state.user_org === "" || this.state.user_org === "BC" || this.state.user_org === "MM") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">INCOME RANGE (BC/MM)</Typography>
                            <FilterOptions id="income-range" options={Global.incomeRangesBCMM} selected={this.state["income-range"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("income-range") && (this.state.user_org === "" || this.state.user_org === "FREE") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">INCOME RANGE (FREE)</Typography>
                            <FilterOptions id="income-range" options={Global.incomeRangesFREE} selected={this.state["income-range"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("mm-source") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">MM SOURCE</Typography>
                            <FilterOptions id="mm-source" options={Global.mmSources} selected={this.state["mm-source"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("misc") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">MISCELLANEOUS</Typography>
                            <FilterOptions id="misc" options={Global.miscOptions(this.state.user_org)} selected={this.state["misc"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("channel") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">CHANNEL</Typography>
                            <FilterOptions id="channel" options={Global.channels} selected={this.state["channel"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("locale") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">E-MAIL LANGUAGE</Typography>
                            <FilterOptions id="locale" options={Global.locales} selected={this.state["locale"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("spoken_lang") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">SPOKEN LANGUAGE</Typography>
                            <FilterOptions id="spoken_lang" options={Global.spokenLanguages} selected={this.state["spoken_lang"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("status") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">STATUS</Typography>
                            <FilterOptions id="status" options={Global.memberStatuses} selected={this.state["status"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    {this.isAvailable("credentials.status") &&
                        <Grid item xs={12} sm={12}>
                            <Typography variant="caption" color="textSecondary">CREDENTIAL STATUS</Typography>
                            <FilterOptions id="credentials.status" options={Global.credentialStatuses} selected={this.state["credentials.status"]} onChange={this.handleChange} />
                        </Grid>
                    }
                    <Grid item xs={12} sm={12}>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleSubmit} color="primary">OK</Button>
                <Button onClick={this.handleReset} color="secondary">Reset</Button>
                <Button onClick={this.handleClose} color="secondary">Cancel</Button>
            </DialogActions>
        </Dialog>
    }
}

export default withStyles(styles)(FilterDialog);