import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Button, FormControl, FormHelperText, TextField, Divider, Typography, withStyles } from '@material-ui/core';
import FormBase from '../../components/FormBase';
import FilterOptions from '../../components/FilterOptions';
import { KeyboardDatePicker  } from '@material-ui/pickers';
import { Global } from '../../Global';
import { Validator } from '../../helpers/Validator';
import { APIService } from '../../services/API';
import { AuthService } from '../../services/Auth';
import { NameService } from '../../services/Name';
import { parse } from 'date-fns';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`
    },
    button: {
        minWidth: 150,
        marginTop: `${theme.spacing(2)}px`,
        marginLeft: `${theme.spacing(2)}px`
    }
});

class EditPointPage extends FormBase {
    constructor(props) {
        super(props);

        this.obj = {
            offer_id: "",
            org_id: "",
            names: {
                en: "",
                zh: "",
            },
            desc: "",
            promo_id: "",
            type: "point",
            event_type: "adhoc",
            points: "",
            validity_begin: null,
            validity_end: null,
            recipients: {
                membership: [],
                "membership-group": []
            },
            remark: ""
        };
        this.state = {
            isSubmitting: false,
            errors: {},
            orgs: [],
            plans: [],
            plan_groups: [],
        };
        Validator.flattenNested(this.obj, this.state);
    }
    UNSAFE_componentWillMount() {
        this.props.onContext({ title: "Campaign Offer" });
    }
    loadPlans() {
        NameService.planList((plans) => {
            var list = [];
            var plan_groups = [];
            for (var i = 0; i < plans.length; i++) {
                let plan = plans[i];
                if (plan.org_id === this.state.org_id) {
                    list.push(plan);

                    if (!plan.plan_group_code) continue;
                    var exist = false;
                    for (var j = 0; j < plan_groups.length; j++) {
                        let plan_group = plan_groups[j];
                        if (plan_group.value === plan.plan_group_code) {
                            exist = true;
                            break;
                        }
                    }
                    if (!exist) plan_groups.push({ value: plan.plan_group_code, label: plan.plan_group_code });
                }
            }
            this.setState({ plans: list, plan_groups: plan_groups });
        })
    }
    componentDidMount() {
        APIService.get(APIService.OFFER, this.props.match.params.id)
            .then(obj => {
                var map = {};
                Validator.flattenNested(obj, map);

                this.setState(map);
                this.loadPlans();
            })
            .catch(error => {
                this.props.alert(APIService.errorMessage(error));
                this.props.history.push("/offers");
            })
    }
    handleOptionsChange = (id, options) => {
        this.setState({ [id]: options });
    }
    handleSubmit = event => {
        event.preventDefault();

        Validator.unflattenNested(this.obj, this.state);
        if (!this.validateForm()) return;

        let output = JSON.parse(JSON.stringify(this.obj));
        // Validator.compact(output); // NO DISCARD EMPTY
        output.points = parseInt(output.points);

        this.setState({
            isSubmitting: true
        });

        APIService.update(APIService.OFFER, output, output.offer_id)
            .then(result => {
                this.props.alert("Offer updated", "success");
                this.props.history.goBack();
            })
            .catch(error => {
                this.setState({ isSubmitting: false });
                this.props.alert(APIService.errorMessage(error));
            });
    }
    validateForm() {
        var errors = {};
        if (this.obj.promo_id.length > 64) {
            errors["promo_id"] = Global.errors["ErrorExceedMaxLength"];
        }
        let points = Validator.verifyNumber(this.obj.points, value => {
            var err = null;
            if (isNaN(value) || !value) {
                err = Global.errors["ErrorInvalidValue"];
            } else if (value < 0) {
                err = Global.errors["ErrorNegativeValue"];
            }
            if (err) errors["points"] = err;
        });
        if (!points) {
            errors["points"] = Global.errors["ErrorInvalidValue"];
        }
        if (!this.obj.validity_begin) {
            errors["validity_begin"] = Global.errors["ErrorRequired"];
        }
        if (!this.obj.validity_end) {
            errors["validity_end"] = Global.errors["ErrorRequired"];
        }
        if (this.obj.validity_begin && this.obj.validity_end) {
            let begin_time = parse(this.obj.validity_begin, Global.DisplayDateFormat, new Date());
            let end_time = parse(this.obj.validity_end, Global.DisplayDateFormat, new Date());
            if (begin_time.getTime() >= end_time.getTime()) {
                errors["validity_end"] = Global.errors["ErrorEndTimeEarlier"];
            }
        }
        if (!Validator.isEmpty(errors)) {
            this.setState({ errors: errors });
            return false;
        }
        return true;
    }

    render() {
        if (!AuthService.userHasRole(['offer_point'])) return null;
        const { classes } = this.props;
        const { errors } = this.state;
        return (
            <form className={classes.form} onSubmit={this.handleSubmit}>
                <FormControl required fullWidth>
                    <Paper className={classes.paper}>
                        <Grid container m={24}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="h4">{this.state["names.en"]}</Typography>
                                <Typography variant="body1" color="textSecondary">ID: {this.props.match.params.id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="org_id"
                                    name="org_id"
                                    label="ORGANISATION"
                                    value={this.state.org_id}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="names.en"
                                    name="name.en"
                                    label="TITLE (ENGLISH)"
                                    value={this.state["names.en"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["names.en"] && <FormHelperText error={true}>{errors["names.en"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    required
                                    id="names.zh"
                                    name="names.zh"
                                    label="TITLE (CHINESE)"
                                    value={this.state["names.zh"]}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["names.zh"] && <FormHelperText error={true}>{errors["names.zh"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="desc"
                                    name="desc"
                                    label="DESCRIPTION"
                                    multiline
                                    fullWidth
                                    value={this.state.desc}
                                    onChange={this.handleChange}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="promo_id"
                                    name="promo_id"
                                    label="PROMOTION ID"
                                    value={this.state.promo_id}
                                    onChange={this.handleChangeUpper}
                                    required
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["promo_id"] && <FormHelperText error={true}>{errors["promo_id"]}</FormHelperText>}
                                <FormHelperText>Maximum length of 64 characters</FormHelperText>
                            </Grid>
                            {/* <Grid item xs={12} sm={3}>
                                <TextField
                                    id="type"
                                    name="type"
                                    label="OFFER TYPE"
                                    value={this.state.type}
                                    onChange={this.handleSelect("type")}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {Global.pointTypes.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={3}>
                                <TextField
                                    id="event_type"
                                    name="event_type"
                                    label="EVENT TYPE"
                                    value={this.state.event_type}
                                    onChange={this.handleSelect("event_type")}
                                    SelectProps={{
                                        MenuProps: { className: classes.menu, },
                                    }}
                                    select
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {Global.pointEventTypes.map(option => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid> */}
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    id="points"
                                    name="points"
                                    label="POINTS"
                                    value={this.state["points"]}
                                    onChange={this.handleChange}
                                    type="number"
                                    fullWidth
                                    required
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                                {errors["points"] && <FormHelperText error={true}>{errors["points"]}</FormHelperText>}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <KeyboardDatePicker 
                                    keyboard="true"
                                    label="VALIDITY START"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.validity_begin}
                                    onChange={this.handleSelectDate("validity_begin")}
                                    clearable
                                    required
                                    animateYearScrolling={false}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["validity_begin"] && <FormHelperText error={true}>{errors["validity_begin"]}</FormHelperText>}
                                <FormHelperText>Date time is inclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <KeyboardDatePicker 
                                    keyboard="true"
                                    label="VALIDITY END"
                                    format={Global.DisplayDateFormat}
                                    placeholder="DD/MM/YYYY"
                                    value={this.state.validity_end}
                                    onChange={this.handleSelectDate("validity_end")}
                                    clearable
                                    required
                                    animateYearScrolling={false}
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                {errors["validity_end"] && <FormHelperText error={true}>{errors["validity_end"]}</FormHelperText>}
                                <FormHelperText>Date time is exclusive</FormHelperText>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    id="remark"
                                    name="remark"
                                    label="REMARK"
                                    value={this.state.remark}
                                    onChange={this.handleChange}
                                    multiline
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Typography variant="subtitle2" color="textSecondary">APPLICABLE TO</Typography>
                                <Divider light={true} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">SUBSCRIPTION</Typography>
                                <FilterOptions id="recipients.membership" options={this.state.plans} selected={this.state["recipients.membership"]} onChange={this.handleOptionsChange} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="caption" color="textSecondary">SUBSCRIPTION GROUP</Typography>
                                <FilterOptions id="recipients.membership-group" options={this.state.plan_groups} selected={this.state["recipients.membership-group"]} onChange={this.handleOptionsChange} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container item xs={12} alignItems="flex-start" justify="flex-end">
                        <Button type="submit" variant="contained" color="primary" className={classes.button} disabled={this.state.isSubmitting}>SAVE</Button>
                        <Button variant="contained" className={classes.button} onClick={this.props.history.goBack}>CANCEL</Button>
                    </Grid>
                </FormControl>
            </form >
        );
    }
}

EditPointPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditPointPage);